import { useMemo, useCallback } from 'react'
import { getIn, useFormikContext } from 'formik'

export interface ErrorContext {
    errorText: string | undefined,
    fieldErrors: (string | null)[]
}

export const useErrors = (fields: string[]) => {

    const { errors } = useFormikContext() || {}

    const fieldErrors = useMemo(() => fields.map((field: string) => getIn(errors, field)), [errors, fields])
    const errorText = useMemo(() => fieldErrors.find((error: string) => !!error), [fieldErrors])

    const errorContext: ErrorContext = useMemo(() => ({ errorText, fieldErrors }), [fieldErrors, errorText])
    const getError = useCallback((field: string) => getIn(errors, field), [errors])

    return useMemo(() => [errorContext, getError] as const, [errorContext, getError])
}