import React from 'react'
import { ThemeProvider } from 'styled-components'

export const GlobalThemeProvider: React.FC<any> = ({ theme, children, ...props }) => {

    return (
      <React.Fragment>
        <ThemeProvider theme={theme as { primary: string }}>
              { children }
        </ThemeProvider>
      </React.Fragment>
    )
  }