import React from 'react'
import styled from 'styled-components'
import Button, { ButtonProps } from 'antd/lib/button'

interface IconButtonProps {
  hidden?: boolean;
  Render?: React.FC;
}

export const StyledIconButtonBase = styled(Button)<{ mode?: 'light' | 'dark'}>`
  svg {
    color: ${p => p.mode === 'light' ? p.theme.primaryColor : 'white' };   
  }
`;

export const IconButton: React.FC<IconButtonProps & ButtonProps> = ({ 
    hidden, 
    Render = StyledIconButtonBase,
    ...props 
}) => {
  if (hidden) return null;
  return <Render {...props} />
}

export default IconButton