import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { Page, AuthGuard, FormLoading } from '../../components'
import { MassAddTeamsheetMember } from '../../components/add-teamsheet-members'
import { useFetchTeamsheet } from '../../graphql'
import { CreatedTeamsheet } from './NewTeamsheet'
import { message } from 'antd'

interface AddTeamsheetMembersProps {

}

export const AddTeamsheetMembers: React.FC<AddTeamsheetMembersProps> = ({ ...props }) => {

    const { teamsheetId }: any = useParams()
    const history = useHistory()

    const [teamsheet, setTeamsheet] = useState<CreatedTeamsheet | null>(null)

    const [fetch, { loading }] = useFetchTeamsheet(teamsheetId, {
        onCompleted: (result: any) => {
            if (result && result.teamsheet) setTeamsheet(result.teamsheet)
        },
        onError: (e: any) => {
            message.error({ content: 'Could not load requied teamsheet context!', duration: 4 })
        }
    })

    const handleBack = useCallback(() => 
        history.push(`/fixture/teamsheet/${teamsheetId}`)
    , [history, teamsheetId])

    const handleCancel = useCallback((stage: 'select' | 'commit', setStage: Function) => {
        setStage('select')
    }, [history, teamsheetId])

    if (loading) return (
        <FormLoading />
    )

    return (
        <AuthGuard>
            <Page card>
                <MassAddTeamsheetMember 
                    title='Import Teamsheet from Existing Team' 
                    onCancel={handleCancel}
                    onBack={handleBack}
                    card={false}
                    teamsheetId={teamsheetId} 
                    organisationId={teamsheet?.organisationId || ''}
                    sportId={teamsheet?.sportId || ''}
                />
            </Page>
        </AuthGuard>
    )
}