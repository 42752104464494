import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

export const NavButton = ({ url, children }: { url: string, children: any }) => {
  const history = useHistory();

  const handleOnClick = useCallback(() => history.push(url), [history, url]);

  return <Button type="link" onClick={handleOnClick}>{children}</Button>;
}

export default Button
