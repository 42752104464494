import React from 'react'
import styled from 'styled-components'
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useSession } from '@necta-tech/cognito'
import { Yup as yup } from '@bit/necta.hooks.get-form-data'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { useHistory, Redirect } from 'react-router-dom'
import { useFormData, useRedirectOnLogin } from '../hooks'
import { FormButton, Input, FormRow, Password } from '../components'
import { Form } from 'formik-antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LoginSchema = yup.object().shape({
  username: yup
    .string()
    .required()
    .trim()
    .label(''),
  password: yup
    .string()
    .required()
    .label('')
})

export const LoginCard = styled(Form)`

    background-color: white;
    box-shadow: 1px 1px 2px 1px #d7d7d7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    max-width: 530px;
    width: 100%;

    small {
        font-size: 12px;
        padding-left: 10px;
        color: ${ p => p.theme.primary };
        width: 100%;
        text-align: right;

        :hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`

const Button = styled(FormButton)`
  margin-top: 30px;
  width: 100%;
`;

const LoginTitle = styled.h1`
    color: ${ p => p.theme.primary };
    margin-top: 30px;
`

export const Login: React.FC<any> = (props) => {

  const history = useHistory()
  const [handleRedirect] = useRedirectOnLogin((path: string) => history.push(path))
  const [user, setUser] = useCognitoUser()

  const [handleSignIn] = useSession({
    onCompleted: (result: any) => {
      if (result) {
        setUser(result)
        handleRedirect()
      }
    }
  })

  const { fields, ...formCTX } = useFormData(LoginSchema, {
      onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        const email = values.username.includes('@') ? values.username.replace(/\s/g, '').toLowerCase() : values.username;
        const result = await handleSignIn(email, values.password)
        actions.resetForm()
        return result
      },
      onCompleted: () => {

      },
      onError: (e: any) => {
        //console.log(e)
      }
    }
  )

  if (user.inSession) return <Redirect to="/" />

  return (
    <Formik {...formCTX} enableReinitialize validateOnBlur >
      {({handleSubmit, isSubmitting, values}) => (
        <LoginCard layout='vertical'>
          <LoginTitle>Log In</LoginTitle>
          <FormRow>
            <Input
              gridProps={{
                sm: 24, md: 24, lg: 24, xl: 24, xxl: 24
              }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={'Username or email'}
              {...fields.username}
            />
            <Password
              gridProps={{
                sm: 24, md: 24, lg: 24, xl: 24, xxl: 24
              }}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={'Password'}
              {...fields.password}
            />
            <small onClick={() => history.push('/forgot-password')} >Forgot password?</small>
            <Button>Log In</Button>
          </FormRow>
        </LoginCard>
      )}
    </Formik>
  )
}
