import React, { FC, useCallback, useMemo } from 'react'
import { Input, PasswordProps } from 'formik-antd'
import styled from 'styled-components'
import { InputFieldProps, FieldProps } from './types'
import { FieldContainer } from './FieldContainer'
import { useFormikContext } from 'formik'
import { generatePassword } from '../utils';
import { Button } from 'antd';

const { Password: PasswordBase } = Input;

interface GeneratePasswordProps {
  generateButton: boolean;
}

const Component: React.FC<InputFieldProps<PasswordProps, FieldProps> & GeneratePasswordProps> = ({ ref = null, disabled, hidden, gridProps = {}, label, required, className, generateButton = false, addonAfter, ...props }) => {

    const { isSubmitting } = useFormikContext() || {}

    const _disabled = useMemo(() => disabled || isSubmitting, [disabled, isSubmitting])

    const afterAdornments = useMemo(() => {
      if (addonAfter) return addonAfter;
      else if (generateButton) return <GeneratePasswordBtn disabled={_disabled} />;
      return null;
    }, [addonAfter, generateButton, _disabled]);

    return (
        <FieldContainer
            {...gridProps}
            name={props.name}
            required={required}
            label={label}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <PasswordBase
                disabled={_disabled}
                addonAfter={afterAdornments}
                {...props}
            />
        </FieldContainer>
    )
}

export const Password = styled(Component)`
    button.ant-btn { 
        height: calc(100% - 2px);
        padding: 0;
        margin: 0;
    }
`

export const GeneratePassword = styled(Password)`
    button.ant-btn { 
        height: calc(100% - 2px);
        padding: 0;
        margin: 0;
    }
`

interface GeneratePasswordButtonProps {
  disabled?: boolean
}

export const GeneratePasswordBtn: FC<GeneratePasswordButtonProps> = ({ disabled, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const handleClick = useCallback((e: any) => setFieldValue('password', generatePassword()), [setFieldValue]);
  return (
    <Button type='text' onClick={handleClick} disabled={disabled}>
      generate
    </Button>
  );
};
