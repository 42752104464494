import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { initializeTheme } from './utils'

const ActionTypes = Object.freeze({
    TOGGLE_THEME: 'TOGGLE_THEME',
    LOAD_THEMES: 'LOAD_THEMES'
})

export const Actions = {
    toggleTheme: (key: string) => {
        return { type: ActionTypes.TOGGLE_THEME, payload: key }
    },
    loadThemes: (themes: any) => {
        return { type: ActionTypes.LOAD_THEMES, payload: themes }
    }
}

const DefaultState = {
    loaded: {},
    active: ''
}

export const antTheme = (state: any = DefaultState, { payload, type }: any) => {
    switch (type) {
        case ActionTypes.TOGGLE_THEME:
            return { ...state, active: payload }
        case ActionTypes.LOAD_THEMES:
            return initializeTheme(state, payload)
        default:
            return state
    }
}