import { useEffect, useCallback } from 'react'
import { useSports, LoadedSport } from '../../redux'
import { useAntTheme } from '../use-ant-theme'

type SearchProps = {
    id?: string,
    name?: string,
    appTheme?: string
}

/**
 * Returns a getter that finds the theme of a sport loaded
 * in sport config by the id of the sport.
 */
export const useGetSportTheme = () => {

    const [sportList] = useSports()
    const [{ loaded }] = useAntTheme()

    /**
     * @param sporId The id of the sport for which the theme should be object should be returned.
     * @returns The theme object of the specified sport (according to sport.appTheme)
     */
    const getSportTheme = useCallback((sportId: string) => {
        const sport = sportList.find((sport: LoadedSport) => sport.id === sportId)
        return sport ? loaded[sport.appTheme] : {};
    }, [sportList, loaded]);

    const getPrimaryColor = useCallback((query: SearchProps) => {
        const theme = loaded.find((theme: any) => {
            const {
                id,
                name,
                appTheme
            } = query
            if (id) {
                if (id !== theme.id) return false
            }
            if (name) {
                if (name !== theme.name) return false
            }
            if (appTheme) {
                if (appTheme !== theme.name) return false
            }
            return true
        })
        return theme ?? null
    }, [loaded])

    return getSportTheme
}


export const useFindSportTheme = () => {

    const [sportList] = useSports()
    const [{ loaded }] = useAntTheme()

    const findTheme = useCallback((query: SearchProps) => {
        const { id, name, appTheme } = query
        //if appTheme defined, return the theme
        if (appTheme) return loaded[appTheme]
        //otherwise, find the sport for either id or name
        const sport = sportList.find((sport: any) => {
            if (id) {
                if (id !== sport.id) return false
            }
            if (name) {
                if (name !== sport.name) return false
            }
            return true
        })
        //if sport found, return its appTheme
        if (sport) return loaded[sport.appTheme]
        //if no sport found, return null
        return null
    }, [loaded, sportList])

    return findTheme
}