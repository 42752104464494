import React from 'react'
import styled from 'styled-components'
import { Checkbox, CheckboxGroupProps } from 'formik-antd'
import { InputFieldProps, FieldProps } from './types'
import { FieldContainer } from './FieldContainer'
import { useFormikContext } from 'formik'
import { ColProps } from 'antd/lib/grid';

const { Group } = Checkbox

interface AddedProps {
  gridProps?: ColProps,
}

const Component: React.FC<InputFieldProps<CheckboxGroupProps, FieldProps> & AddedProps> = ({ hidden, label, required, className, gridProps , ...props }) => {

  const { isSubmitting } = useFormikContext() || {}

  return (
    <FieldContainer
      {...gridProps}
      name={props.name}
      label={label}
      required={required}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <Group
        disabled={isSubmitting}
        {...props}
      />
    </FieldContainer>

  )
}

export const CheckBoxGroup = styled(Component)`
`
