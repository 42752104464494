import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom'
import { Image, Layout, Spin } from 'antd'
import {
  LogoutPane,
  SideNav,
  Header,
  FilledOrgSelector,
  FilledSportSelector,
  NavItemsAuthorized,
  SideNavDrawer,
  AccountBanner,
  NotFound,
  SponsorBanner,
  IconButton,
} from '../components'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import {
  Dashboard,
  UserList,
  UserViewEdit,
  UserAdd,
  TeamAdd,
  TeamAddMember,
  TeamList,
  Team,
  Org,
  OrgList,
  OrgImport,
  OrgAdd,
  PlayerAdd,
  PlayerImport,
  TeamEditMember,
  CoachAdd,
  EditTeam,
  UnderConstruction,
  NotAuthorized,
  MyAccount,
  Settings,
  Fixture,
  FixtureAdd,
  FixtureList,
  Fixtures,
  TeamSheetAdd,
  TeamSheet,
  TeamsheetMembersImport,
  AddTeamsheetMember,
  AddTeamsheetMembers,
  EditTeamsheetMember,
  PendingFixtures,
  EventAdd,
  EventList,
  EventRequestList,
  Event,
} from '../pages'
import {
  useCurrentSport,
  useCurrentOrg,
  LoadedOrg,
  LoadedSport,
} from '../redux'
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { canSelectOrgs, getActiveOrgId, getIsAdmin, getParentOrg, getSelectedOrg } from '../utils';
import useWindowWidth from '@bit/necta.hooks.use-window-width/dist';
import ScrollToTop from '../components/scroll-to-top/ScrollToTop';
import CoachImport from '../pages/coach/Import';
import { useLoadData } from './hooks'
import { getRoutes } from './routes'

import PlayPro from '../assets/playpro.png';
import ThirdPartyProviders from '../pages/thirdPartyProvider';
import ViewEditThirdPartyProvider from '../pages/thirdPartyProvider/ViewEditThirdPartyProvider';
import ViewEditThirdPartyProviderContact from '../pages/thirdPartyProvider/ViewEditThirdPartyProviderContact';
import AddThirdPartyProviderContact from '../pages/thirdPartyProvider/AddThirdPartyProviderContact';
import AddThirdPartyProvider from '../pages/thirdPartyProvider/addThirdPartyProvider';

const { Content } = Layout

const Loading = styled.div`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${p => (p.theme.primaryColor + '33')};
`;

const Spinner = styled(Spin)`
  font-size: 20px;
`;

const NavInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const Pad = styled.div`
  padding: 5px 5px 0 5px;
  width: 100%;
`

const ForOrg = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  color: white;
  align-items: center;
  i {
    padding: 0 10px;
  }
`

const AuthedContent = styled(Content)`
  overflow-y: auto;
  background: none;
`

const TitleInner = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  line-height: 16px;
  padding: 3px 5px;
  cursor: pointer;
`;

const TitleSecondary = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  font-size: 11px;
  width: 100%;
  padding-top: 3px;
`

const Icon = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
  //background: none !important;
`;

const Alt = styled.span`
  font-weight: bold;
  font-style: italic;
`;

const slogans = ['Kicking off...', 'Gearing up...', 'Getting the ball rolling...', 'Teeing off...'];
const selectedSlogan = slogans[Math.floor(Math.random() * (slogans.length))];

const getImage = (image: string) => {
  return process.env.REACT_APP_S3_HOST + image;
}

// The scroll id to use for scrolling to the top on route change
export const SCROLL_ID = 'layout-container';

// TODO: these seem like quite commonly bundled selectors
const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  activeOrgId: useSelector(getActiveOrgId),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedOrg: useSelector(getSelectedOrg),
  parentOrg: useSelector(getParentOrg)
})

export const Authorized: React.FC<any> = (props) => {

  const [ width ] = useWindowWidth();

  const history = useHistory()
  const handleBack = useCallback(() => history.push('/'), [history])

  //load all user-session data
  const loading = useLoadData()

  const [currentOrg, setCurrentOrg] = useCurrentOrg()
  const [currentSport, setCurrentSport] = useCurrentSport()

  const { canSelectOrgs, parentOrg } = useSelectors()

  const handleOnOrgSelect = (value: LoadedOrg) => setCurrentOrg(value)
  const handleOnSportSelect = (value: LoadedSport) => setCurrentSport(value)

  const [open, setOpen] = useState(false);

  //generate side-nav routes based on account permissions
  const routes = useMemo(() => getRoutes(canSelectOrgs), [canSelectOrgs])


  const handleOpen = useCallback((_open: boolean) => (e?: any) => setOpen(_open), [open]);

  const titleAlt: React.ReactNode | null = useMemo(() => {
    if (!parentOrg?.primaryImageAlt) return null;
    return (
      <ForOrg>
        {parentOrg.primaryImageIcon && <Image style={{ width: 'auto', height: '45px' }} src={getImage(parentOrg.primaryImageIcon)} preview={false} />}
        <TitleInner>
          <Image width={180} src={getImage(parentOrg.primaryImageAlt)} preview={false} />
          <TitleSecondary><i>Powered by</i>
            <Icon src={PlayPro} />
            Play<Alt>Pro</Alt></TitleSecondary>
        </TitleInner>
      </ForOrg>
    );
  }, [parentOrg]);

  if (loading) return (
    <Loading>
      <Spinner spinning tip={selectedSlogan} size='large' />
    </Loading>
  );

  return (
    <React.Fragment>

      <SideNavDrawer
        title={<AccountBanner actions={[
          <IconButton
            shape='circle'
            type='text'
            icon={<MenuFoldOutlined />}
            onClick={handleOpen(false)}
            size='large'
          />
        ]}/>}
        width={276}
        placement={'left'}
        closable={false}
        onClose={handleOpen(false)}
        visible={open}
        key={'mobile-sidenav'}
      >
        <NavInner>
          <SponsorBanner />
          <Pad>
            <FilledSportSelector onChange={handleOnSportSelect} defaultValue={get(currentSport, 'id')} />
          </Pad>
          <Pad>
            <FilledOrgSelector hidden={!canSelectOrgs} value={currentOrg.id} onChange={handleOnOrgSelect} defaultValue={get(currentOrg, 'id')} />
          </Pad>
          <NavItemsAuthorized paths={routes} openOverride={true} />
        </NavInner>
      </SideNavDrawer>

      <Header
        actions={[
          <FilledSportSelector hidden={width < 768} onChange={handleOnSportSelect} defaultValue={get(currentSport, 'id')} />,
          <FilledOrgSelector hidden={!canSelectOrgs || width < 768} value={currentOrg.id} onChange={handleOnOrgSelect} defaultValue={get(currentOrg, 'id')} />,
        ]}
        leftActions={[
          <IconButton
            shape='circle'
            type='text'
            icon={<MenuUnfoldOutlined />}
            onClick={handleOpen(true)}
            size='large'
            hidden={width >= 768}
          />
        ]}
        titleAlt={titleAlt}
      />

      <Layout style={{background: 'none'}}>

        <SideNav title='PlayPro' paths={routes} hidden={width < 768} />

        <LogoutPane />
          <AuthedContent id={SCROLL_ID}>
            <ScrollToTop scrollId={SCROLL_ID} />
            <Switch>
              <Route exact path='/' component={Dashboard} />

              <Route exact path='/user/add' component={UserAdd} />
              <Route exact path='/user/list' component={UserList} />
              <Route exact path='/user/admins' component={UserList} />
              <Route exact path='/user/:shortid' component={UserViewEdit} />

              <Route exact path='/player/add' component={PlayerAdd} />
              <Route exact path='/player/list' component={UserList} />
              <Route exact path='/player/import' component={PlayerImport} />

              <Route exact path='/coach/add' component={CoachAdd} />
              <Route exact path='/coach/list' component={UserList} />
              <Route exact path='/coach/import' component={CoachImport} />

              <Route exact path='/team/add' component={TeamAdd} />
              <Route exact path='/team/:shortid/member/add' component={TeamAddMember} />
              <Route exact path='/team/member/edit/:shortid' component={TeamEditMember} />
              <Route exact path='/team/list' component={TeamList} />
              <Route exact path='/team/:shortid' component={Team} />
              <Route exact path='/team/edit/:shortid' component={EditTeam} />

              <Route exact path='/org/add' component={OrgAdd} />
              <Route exact path='/org/list' component={OrgList} />
              <Route exact path='/org/import' component={OrgImport} />
              <Route exact path='/org/admins' component={UserList} />
              <Route exact path='/org/add/admin' component={UserAdd} />
              <Route exact path='/org/:shortid' component={Org} />

              <Route exact path='/settings' component={Settings} />
              <Route exact path='/account' component={MyAccount} />

              <Route exact path='/forgot-password' render={(props) => <Redirect to='/' />} />
              <Route exact path='/login' render={(props) => <Redirect to='/' />} />

              <Route exact path='/fixture/add' component={FixtureAdd} />
              <Route exact path='/fixture/schedule' component={Fixtures} />
              <Route exact path='/fixture/list' component={FixtureList} />
              <Route exact path='/fixture/pending' component={PendingFixtures} />
              <Route exact path='/fixture/teamsheet/add/:fixtureId' component={TeamSheetAdd} />
              <Route exact path='/fixture/teamsheet/:teamsheetId/member/add' component={AddTeamsheetMember} />
              <Route exact path='/fixture/teamsheet/:teamsheetId/members/add' component={AddTeamsheetMembers} />
              <Route exact path='/fixture/teamsheet/:teamsheetId/members/import/' component={TeamsheetMembersImport} />
              <Route exact path='/fixture/teamsheet/member/edit/:shortid' component={EditTeamsheetMember} />
              <Route exact path='/fixture/teamsheet/:teamsheetId' component={TeamSheet} />
              <Route exact path='/fixture/:id' component={Fixture} />

              <Route exact path='/event/add' component={EventAdd} />
              <Route exact path='/event/list' component={EventList} />
              <Route exact path='/event/requests' component={EventRequestList} />
              <Route exact path='/event/:id' component={Event} />

              <Route exact path='/third-party-provider/view-edit/:id' component={ViewEditThirdPartyProvider} />
              <Route exact path='/third-party-provider/contact/view-edit/:id' component={ViewEditThirdPartyProviderContact} />
              <Route exact path='/third-party-provider/:providerId/contact/add' component={AddThirdPartyProviderContact} />
              <Route exact path='/third-party-provider/add' component={AddThirdPartyProvider} />
              <Route exact path='/third-party-provider/list' component={ThirdPartyProviders} />

              <Route exact path='/not-authorized/:type' component={NotAuthorized} />
              <Route render={(props) => <NotFound handleBack={handleBack} />} />

            </Switch>
          </AuthedContent>
      </Layout>
    </React.Fragment>
  )
}
