import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'
import { Button, Result, Skeleton, Modal, Tooltip } from 'antd'
import { Table as AntTable } from '../table'
import { useWidgetOrg, useWidgetData } from './use-widget-data'
import { useFetchFixtureList } from '../../graphql'
import { UpdateFixtureResult } from '../../pages/fixture/update-fixture-result'
import { ScoreCell } from '../../pages/fixture/ScoreCell'
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';
import { RenderOrg } from '../../pages/fixture';
import { useSports } from '../../redux/config';
import { Avatar } from '../Avatar';
import { getThumbnail } from '@bit/necta.hooks.s3'

const Table = styled(AntTable)`
    margin: 5px;
`

interface UpdateFixtureWidgetProps {

}

const UpdateFixtureWidget = () => {

    const { name: orgName } = useWidgetOrg()
    const selectedOrg = useSelector(getSelectedOrg);

    const [fixtures, setFixtures] = useState<any[]>([])
    const [errorText, setErrorText] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false)
    const [selectedFixtureId, setSelectedFixtureId] = useState<any>(null)

    const handleOnClose = useCallback(() => setVisible(false), [setVisible])
    const handleOpen = useCallback((id: string) => () => {
        setVisible(true)
        setSelectedFixtureId(id)
    }, [setVisible])

    const [handleFetch, { loading }] = useFetchFixtureList({
        variables: { fixtureQuery: { result: null }, orgId: selectedOrg?.id },
        onCompleted: (result) => {
            if (result && result.fixtures) setFixtures(cloneDeep(result.fixtures))
        },
        onError: (err: any) => {
            setErrorText(err.message)
        }
    })

    const [sportList, { getSport }] = useSports()

    const getName = useCallback((sportId: string) => {
        const sport = getSport(sportId);
        return sport?.name
    }, [getSport]);

    const getIcon = useCallback((sportId: string) => {
        return getThumbnail(getSport(sportId)?.appIcon);
    }, [getSport]);

    const columns = useMemo(() => [
        {
            title: 'Fixture',
            key: 'name',
            filter: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <Tooltip title={getName(rec.sportId)}><Avatar src={getIcon(rec.sportId)} size={18} inverse /> {val}</Tooltip>
        },
        {
            title: 'Home',
            export: true,
            key: 'homeOrgName',
            filtering: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <RenderOrg org={rec.homeOrg} name={val} result={rec.result} isHome={true} />
        },
        {
            title: 'Away',
            export: true,
            key: 'awayOrgName',
            filtering: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <RenderOrg org={rec.awayOrg} name={val}  result={rec.result} isHome={false} />
        },
        {
            title: 'Result',
            key: 'currentScore',
            getValues: (r: any) => r,
            render: (result: any, rec: any) => (
                <ScoreCell fixture={rec} onClick={handleOpen(rec.id)}>
                    UPDATE
                </ScoreCell>
            )
        }
    ], [handleOpen, getIcon, getName])

    if (errorText) return (
      <Result
        title='Ongoing Fixtures'
        status='error'
        subTitle={errorText}
      />
    )

    if (loading) return (
      <Skeleton paragraph={{ rows: 5 }} />
    )

    return (
        <div>
            <Modal
                visible={visible}
                footer={
                    <Button type='primary' onClick={handleOnClose}>
                        Cancel
                    </Button>
                }
                onCancel={handleOnClose}
            >
                <UpdateFixtureResult handleClose={handleOnClose} fixtureId={selectedFixtureId} />
            </Modal>
            <Table
                title='Update Fixture Results'
                data={fixtures}
                columns={columns}
                refresh={handleFetch}
                searchable={false}
                columnSelection={false}
                loading={loading}
                pagination={{
                  defaultPageSize: 5
                }}
                size='small'
            />
        </div>
    )
}

const StyledUpdateFixtureWidget = styled(UpdateFixtureWidget)`
`

export { StyledUpdateFixtureWidget as UpdateFixtureWidget }

export default StyledUpdateFixtureWidget
