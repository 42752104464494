import React from 'react'
import styled from 'styled-components'
import { Card as AntCard, Space } from 'antd'
import { CardProps } from 'antd/lib/card'

const Card = styled(AntCard)`
  @media only screen and (max-width: 500px) {
    .ant-card-body {
      padding: 5px;
    }
  }
`
interface WrapperProps extends CardProps {
    card?: boolean,
    extra?: React.ReactNode[],
    title?: React.ReactNode,
    style?: React.CSSProperties,
    className?: string
}

export const CardWrapper: React.FC<WrapperProps> = ({
    loading,
    card,
    children,
    extra,
    ...props
}) => {
    if (card) return (
        <Card
            {...props}
            extra={<Space>{extra}</Space>}
            loading={loading}
        >
            {children}
        </Card>
    )

    return <>{children}</>
}

export default CardWrapper
