import { useState, useMemo, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { message } from 'antd'
import { WIDGET_QUERY } from '../../graphql'
import { getSelectedOrg, getActiveOrg } from '../../utils'
import { useSelectors } from '../../hooks'
import { WidgetData, Breakdown } from './types'

interface Options {
  fetchOnMount?: boolean
}

export const useWidgetOrg = () => {

    const { selectedOrg, activeOrg } = useSelectors({
        selectedOrg: getSelectedOrg,
        activeOrg: getActiveOrg
    })

    return useMemo(() => {
      if (selectedOrg && selectedOrg.id) return selectedOrg
      if (activeOrg && activeOrg.id) return activeOrg
      return { id: '', name: '' } as const
    }, [selectedOrg, activeOrg])
}

export const useWidgetData = (options: Options = {}) => {

    const [data, setData] = useState<WidgetData[]>([])
    const { id: orgId } = useWidgetOrg()

    const [fetchData, { data: _data, ...ctx }] = useLazyQuery(WIDGET_QUERY, {
        variables: { orgId },
        onCompleted: (result: any) => {
            if (result && result.widgets) setData(parseData(result.widgets))
        },
        onError: (e: Error) => {
            message.error({ content: 'Could not fetch widget data!', duration: 4 })
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        fetchData()
    }, [orgId])

    const context = useMemo(() => ({ ...ctx, widgets: data } as const), [data, ctx])
    const ret = useMemo(() => [fetchData, context] as const, [fetchData, context])

    return ret
}

//parses widget breakdown data into an array of sports each holding their respective data
export const parseData = (widgets: any) => {
    return widgets.map((widget: any) => {
        const breakdown = widget.data.breakdown
        const _breakdown = Object.keys(breakdown).map((id: string) => {
            const sport = breakdown[id]
            return { id, name: sport.name, value: sport.count } as Breakdown
        })
        return { ...widget, data: { total: widget.data.total, breakdown: _breakdown } } as WidgetData
    })
}

export default useWidgetData