import React, { useMemo } from 'react';
import { get } from 'lodash';
import Moment from 'moment';
import { Field } from '../../../../utils/yup-mapper';
import Table from '../../../table';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { cloneDeep } from '@apollo/client/utilities';
import styled from 'styled-components';

const CUTOFF = 7;

export const TableOuter = styled.div`
  width: 100%;
`;

const getRowDisplay = (row: any, field: any) => {
  switch (get(field, 'typeInfo.type')) {
    case 'date':
      return row ? Moment(row).format('YYYY-MM-DD') : '';
    default:
      return row;
  }
};

interface Column {
  title: string;
  key: string;
  filtering?: boolean,
  sorting?: boolean,
  show?: boolean;
  render?: React.FC | string;
  fixed?: string;
}

const isNotEmpty = (obj: any): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] != null) {
      if (typeof obj[key] === 'object') return isNotEmpty(obj[key]);
      return true;
    }
  }
  return false;
};

// const isEmpty = (obj: any) => !Object.values(obj).some(x => (x !== null && x !== ''));

interface ImportTableProps {
  validData: any[],
  invalidData: any[],
  onDelete: (row?: any) => void;
  editRow: (row?: any) => void;
  fields: Field[];
  hidden?: boolean;
  // rowUpdated?: { key: string, oldData: any, newData: any };
}

export const ImportTables: React.FC<ImportTableProps> = ({
  validData,
  invalidData,
  onDelete,
  fields,
  editRow,
  hidden,
}) => {
  const getColumns = (fields: any[]): Column[] => {
    const columns: any[] = [];

    fields.forEach((field: any) => {
      if (field.fields) {
        const nestedCols = getColumns(field.fields);
        nestedCols.forEach((c: Column) => columns.push(c));
        return;
      }

      const column: Column = {
        title: field.label + (field.required ? '*' : ''),
        key: field.name,
        // type: field.type || 'string',
        filtering: true,
        sorting: true,
        render: (row: any) => getRowDisplay(row, field),
        show: field.required,
      };
      columns.push(column);
    });

    const cutoff = columns.length > CUTOFF ? CUTOFF : columns.length;

    const visible = columns.reduce((acc: number, curr: Column) => {
      if (curr.show) acc++;
      return acc;
    }, 0);

    if (visible < cutoff) {
      let currentlyVisible = visible;
      columns.map((column: Column) => {
        if (currentlyVisible < cutoff && !column.show) {
          column.show = true;
          currentlyVisible++;
        }
        return column;
      });
    }

    return columns;
  };

  const columns: Column[] = useMemo(() => [...getColumns(fields), {
    title: 'Actions',
    fixed: 'right',
    key: 'actions',
    render: (_: any, row: any) => <Popconfirm title={'Remove this row?'} onCancel={(e?: any) => e.stopPropagation()} onConfirm={(e: any) => {
      e.stopPropagation()
      onDelete(row)
    }}><DeleteOutlined onClick={(e?: any) => e.stopPropagation()}/></Popconfirm>,
  }], [fields]);

  const _valid = useMemo(() => cloneDeep(validData), [validData]);

  const _invalid = useMemo(() => cloneDeep(invalidData), [invalidData]);

  const hasValidData = useMemo(() => typeof validData !== 'undefined' && validData.length > 0, [validData]);

  const hasInvalidData = useMemo(
    () => typeof invalidData !== 'undefined' && invalidData.length > 0,
    [invalidData],
  );

  if (hidden) return null;

  return (
    <TableOuter>
      {hasInvalidData && (
        <Table
          title={'Invalid Data'}
          data={_invalid}
          columns={columns}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => editRow(record)
          })}
          expandable={{
            expandedRowRender: (record: any) => <p style={{ margin: 0 }}>{record.error}</p>
          }}
          rowClassName={'clickable'}
          searchable={false}
          exportable={false}
        />
      )}
      {hasValidData && (
        <Table
          title={'Valid Data'}
          data={_valid}
          columns={columns}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => editRow(record)
          })}
          rowClassName={'clickable'}
          searchable={false}
          exportable={false}
        />
      )}
    </TableOuter>
  );
};

export default ImportTables;
