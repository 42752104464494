import React, { useEffect, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  Input,
  TextArea, Tags, CheckBox, TeamRoleSelector, NotFound, UserSelector, FormLoading,
  AuthGuard
} from '../../components'
import { useFormData, Yup } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import { Radio, PageHeader, message, Skeleton, Col } from 'antd'
import { newTeamsheetMemberSchema } from './schema';

import { get, isEmpty } from 'lodash';
import { ADD_TEAMSHEET_MEMBER, useFetchTeamsheet } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const AddTeamsheetMember: React.FC<any> = (props) => {
  const { teamsheetId }: any = useParams()

  const history = useHistory()

  const [handleAdd] = useMutation(ADD_TEAMSHEET_MEMBER)

  const [teamsheet, setTeamsheet] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const hasOrg = useMemo(() => !!teamsheet?.organisationId, [teamsheet])

  const [fetchTeam, { loading }] = useFetchTeamsheet(teamsheetId, {
    onCompleted: (result: any) => {
        //console.log('fetch sheet:', JSON.stringify(result, null, 2))
      if (result) setTeamsheet({ ...result.teamsheet });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const { fields, initialValues: defaultValues, ...formikCTX } = useFormData(newTeamsheetMemberSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Adding team member...', duration: 10 });
      const result = await handleAdd({ variables: { newTeamsheetMemberData: { ...values } } })
      if (result) return result
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team member added!', duration: 2 });
      history.push('/fixture/teamsheet/' + teamsheetId)
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to add team member'), duration: 4 });
    }
  })
  const [teamsheetMember, setTeamsheetMember] = useState({ 
    ...defaultValues,
    teamsheetId,
  });

  const initialValues = useMemo(() => ({
    ...teamsheetMember,
    userId: hasOrg ? '' : null,
    isPlayer: !hasOrg,
    isCoach: false
  }), [hasOrg, teamsheetMember])

  const handleBack = useCallback(() => history.push('/fixture/teamsheet/' + teamsheetId), [history, teamsheetId]);

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam])

  const hasTeam = useMemo(() => teamsheet && !isEmpty(teamsheet), [teamsheet]);

  const handleUserSelected = useCallback((setFieldValue: any) => (_user: any) => {
    if (!_user || (user && (user.isPlayer !== _user.isPlayer || user.isCoach !== _user.isCoach))) {
      setFieldValue('isCaptain', false);
      setFieldValue('isHeadCoach', false);
      setFieldValue('teamRoleId', '');
      setFieldValue('teamRoleAltId', '');
    }
    if (_user) {
      setFieldValue('firstname', _user.firstname);
      setFieldValue('lastname', _user.lastname);
      setFieldValue('isPlayer', _user.isPlayer)
      setFieldValue('isCoach', _user.isCoach)
      setUser(_user);
    } else {
      setUser(null);
      setFieldValue('firstname', '');
      setFieldValue('lastname', '');
    }
  }, [setUser, teamsheetMember, user]);

  //if hasOrg === false, then user === null
  const isPlayer = useMemo(() => user && user.isPlayer, [user]);
  const isCoach = useMemo(() => user && user.isCoach, [user]);

  const handleOnToggle = useCallback((setFieldValue) => (e: any) => {
    const val = e.target.value
    if (val === 'Player') {
      setFieldValue('isPlayer', true)
      setFieldValue('isCoach', false)
      setFieldValue('isHeadCoach', false)
    }
    if (val === 'Coach') {
      setFieldValue('isPlayer', false)
      setFieldValue('isCoach', true)
      setFieldValue('isCaptain', false)
    }
  }, [])

  if (loading) return <FormLoading />;

  if (!teamsheetId || !hasTeam) {
    return (
        <NotFound subTitle={'Unable to find team'} handleBack={handleBack} handleRefresh={handleRefresh} />
    )
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={initialValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
          <Page title='Add Team Member' onBack={handleBack} card>
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>

                <Col hidden={hasOrg} {...GRID_PROPS}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Radio.Group
                      value={values.isCoach ? 'Coach' : 'Player'}
                      onChange={handleOnToggle(setFieldValue)}
                      options={[
                        { label: 'Player', value: 'Player' },
                        { label: 'Coach', value: 'Coach' }
                      ]}
                      optionType='button'
                      style={{marginBottom: '20px'}}
                    />
                  </Col>
                </Col>

                <UserSelector hidden={!hasOrg} placeholder='Type to search...' {...fields.userId} onChange={handleUserSelected(setFieldValue)} filters={{ organisationId: teamsheet.organisationId }}/>
                <Input {...fields.firstname} disabled={!user && hasOrg} />
                <Input {...fields.lastname} disabled={!user && hasOrg} />
                <Input {...fields.nickname} disabled={!user && hasOrg} />

                <TeamRoleSelector 
                  sport={teamsheet.sportId} 
                  disabled={!user && hasOrg} 
                  isPlayer={isPlayer || values.isPlayer} 
                  isCoach={isCoach || values.isCoach} 
                  {...fields.teamRoleId}
                />
                <TeamRoleSelector 
                  sport={teamsheet.sportId} 
                  disabled={!user && hasOrg} 
                  isPlayer={isPlayer || values.isPlayer} 
                  isCoach={isCoach || values.isCoach} 
                  {...fields.teamRoleAltId} 
                />

                <Tags isEditable={false} {...fields.tags} gridProps={GRID_PROPS} />

                <CheckBox 
                  {...fields.isCaptain} 
                  disabled={(!isPlayer && hasOrg) || (!hasOrg && !values.isPlayer)} 
                  gridProps={GRID_PROPS}
                >
                  Team Captain
                </CheckBox>
                <CheckBox 
                  {...fields.isHeadCoach} 
                  disabled={(!isCoach && hasOrg) || (!hasOrg && !values.isCoach)} 
                  gridProps={GRID_PROPS}
                >
                  Head Coach
                </CheckBox>

                <TextArea {...fields.description} gridProps={GRID_PROPS}/>

              </FormRow>

              <FormButton>
                ADD
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default AddTeamsheetMember

