import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar } from '../../../components';
import Table, { Filter, Sort } from '../../../components/table';
import { Card, Image } from 'antd';
import styled from 'styled-components';
import { getThumbnail } from '@bit/necta.hooks.s3'

const Score = styled.span`
    font-size: 16px;
`

const Result = styled.span`
    font-size: 12px;
    font-weight: bold;
`

const Values = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 2;
`

const Img = styled(Image)`
  border-radius: 50%;
  width: 35px;
`;

export const UserFixtureList: React.FC<any> = (props) => {
  const { user, loading } = props;

  const teamsheets = useMemo(() => user.teamsheets ? cloneDeep(user.teamsheets).filter((t: any) => t?.teamsheet?.fixture) : [], [user])
  const userOrg = useMemo(() => user.activeOrg?.id, [user]);

  const getResult = useCallback((record: any) => {
    const fixture = record?.teamsheet?.fixture;
    if (!fixture) return null;
    const result = fixture?.result;
    if (!result || !userOrg) return null;
    if (userOrg === fixture.homeOrgId) return result;
    if (userOrg === fixture.awayOrgId) return result === 'W' ? 'L' : (result === 'L' ? 'W' : result)
  }, [userOrg]);

  const columns = useMemo(() => [
    {
      title: 'Sport',
      filtering: true,
      sorting: true,
      key: 'teamsheet.sport',
      getValues: (r: any) => r?.name,
      render: (r: any) => <><Avatar src={getThumbnail(r?.appIcon)} inverse />&nbsp;&nbsp;{r.name}</>
    },
    {
      title: 'Team',
      filtering: true,
      sorting: true,
      key: 'teamsheet.name',
    },
    {
      title: 'Primary Role',
      filtering: true,
      sorting: true,
      key: 'teamRole.name',
    },
    {
      title: 'Secondary Role',
      filtering: true,
      sorting: true,
      key: 'teamRoleAlt.name',
    },
    {
      title: 'Result',
      key: 'teamsheet.fixture.currentScore',
      sorting: false,
      getValues: (r: any) => r,
      render: (result: string, rec: any) => (
        <Values>
          <Result>{getResult(rec)}</Result>
          <Score>{rec?.teamsheet?.fixture?.currentScore}</Score>
        </Values>
      ),
    }
  ], [getResult]);

  const history = useHistory();

  return (
    <Table
      data={teamsheets}
      columns={columns}
      loading={loading}
      searchable={false}
      exportable={false}
      columnSelection={false}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/fixture/${record?.teamsheet?.fixture?.id}`)
      })}
    />
  );
};

export default UserFixtureList;
