import { PlusOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "../../components";
import { AuthGuard, Page } from "../../components/page";
import {
  useFetchThirdPartyProviders,
} from "../../graphql";
import {
  CAMERAS,
  CAMERAS_TWO,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  COUNTRIES,
  ENGAGEMENT_STATUSES,
  GROUNDS,
  GROUNDS_TWO,
  mapArrayToObject,
  ORG_TYPES,
  PHASES,
  PRODUCTS,
  PRODUCTS_FOUR,
  PRODUCTS_THREE,
  PRODUCTS_TWO,
  REGIONS,
  SA_PROVINCES,
  SITE_SPECS,
  CAMERAMIXES,
} from "../../utils";

export const provinces = mapArrayToObject(SA_PROVINCES);
export const countries = mapArrayToObject(COUNTRIES);
export const contractTypes = mapArrayToObject(CONTRACT_TYPES);
export const siteSpecs = mapArrayToObject(SITE_SPECS);
export const contractStatuses = mapArrayToObject(CONTRACT_STATUSES);
export const engagementStatuses = mapArrayToObject(ENGAGEMENT_STATUSES);
export const orgTypes = mapArrayToObject(ORG_TYPES);
export const providerType = mapArrayToObject([
  "COMMENTATOR",
  "SCORER",
  "CAMERAMAN",
]);
export const regions = mapArrayToObject(REGIONS);
export const phases = mapArrayToObject(PHASES);
export const products = mapArrayToObject(PRODUCTS);
export const productstwo = mapArrayToObject(PRODUCTS_TWO);
export const productsthree = mapArrayToObject(PRODUCTS_THREE);
export const productsfour = mapArrayToObject(PRODUCTS_FOUR);
export const cameras = mapArrayToObject(CAMERAS);
export const camerastwo = mapArrayToObject(CAMERAS_TWO);
export const grounds = mapArrayToObject(GROUNDS);
export const groundsTwo = mapArrayToObject(GROUNDS_TWO);
export const cameramixess = mapArrayToObject(CAMERAMIXES);

export const ThirdPartyProvidersTable: FC = () => {
  const [contacts, setContacts] = useState([]);
  const [fetchThirdPartyProviders, { loading }] = useFetchThirdPartyProviders({
    onCompleted: ({thirdPartyProviders}) => {
      setContacts(thirdPartyProviders);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const history = useHistory();

  const columns: any[] = [
    {
      title: "Provider Name",
      filtering: true,
      sorting: true,
      key: "thirdPartyProviderName",
    },
    {
      title: "Registration Number",
      filtering: true,
      sorting: true,
      key: "registrationNumber",
    },
    {
      title: "Vat Number",
      filtering: true,
      sorting: true,
      key: "vatNumber",
    },
  ];

  return (
    <Table
      data={contacts}
      columns={columns}
      loading={loading}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/third-party-provider/view-edit/${record.id}`),
      })}
      refresh={() => fetchThirdPartyProviders()}
      title={"Third Party Providers"}
      exportTitle={"Organisations"}
      actions={
        <Tooltip title={`Add New Third Party Provider`}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => history.push(`/third-party-provider/add`)}
            shape="circle"
          />
        </Tooltip>
      }
    />
  );
};

export const ThirdPartyProviders: FC = () => {
  return (
    <AuthGuard needsActiveOrg>
      <Page card>
        <ThirdPartyProvidersTable />
      </Page>
    </AuthGuard>
  );
};

ThirdPartyProviders.defaultProps = {};

export default ThirdPartyProviders;
