import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import { CardWrapper } from '../CardWrapper'
import { Fixture, useSports } from '../../redux'
import { FixtureList } from '../widgets';
import { useAntTheme } from '../../hooks/use-ant-theme';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';
import { Divider, Empty } from 'antd';
import Plan from '../../assets/plan.png';
import { groupBy } from 'lodash';

const Divide = styled(Divider)`
  .anticon {
    margin-left: 8px;
  }
  svg {
    color: #00000073;
  }
`;

const FixtureGroup = styled.div`
  margin-bottom: 40px;
`;

const date = (item: any) => moment(item.dateStart).format('ddd, Do MMM YYYY');

interface MobileCalComponentProps {
  className?: string,
  style?: React.CSSProperties,
  card?: boolean,
  title?: React.ReactNode,
  extra?: React.ReactNode[],
  fixtures: Fixture[],
  loading?: boolean,
  fullScreen?: boolean,
  onPanelChange?: (values: any) => void,
  onClick?: (fixture: any) => void,
  value?: Moment,
  headerRender: React.ReactNode,
}

//TODO: review component flow and potential cell modal wrapping
const MobileCalComponent: React.FC<MobileCalComponentProps> = ({
  extra,
  title,
  card,
  headerRender,
  style,
  className,
  onClick,
  value,
  loading,
  fullScreen,
  fixtures,
  onPanelChange,
  ...props
}) => {

  return (
    <CardWrapper
      card={card}
      title={title}
      style={style}
      className={className}
      extra={extra}
      loading={loading}
    >
      {headerRender}
      <FixturesInner fixtures={fixtures} onClick={onClick} />
    </CardWrapper>
  )

}

const FixturesInner = ({ fixtures, onClick }: any) => {

  const selectedOrg = useSelector(getSelectedOrg);

  const [sportList] = useSports();
  const [{ loaded }] = useAntTheme();

  const grouped: any = useMemo(() => fixtures && fixtures.length > 0 ? groupBy(fixtures, date) : [], [fixtures]);

  if (!grouped || grouped.length === 0) return <Empty image={Plan} description={'No upcoming fixtures yet'} />;

  return (
    <>
      {Object.keys(grouped).map((k: string) =>
        <FixtureGroup>
          <Divide plain orientation="left">{k}</Divide>
          <FixtureList fixtures={grouped[k]} organisation={selectedOrg} sportList={sportList} loaded={loaded} onClick={onClick} />
       </FixtureGroup>
      )}
    </>
  )
}

export const MobileCalendar = styled(MobileCalComponent)`
    width: 92%;
`
