import styled from 'styled-components';
import { Avatar as AntAvatar } from 'antd';

export const Avatar = styled(AntAvatar)<{ inverse?: boolean }>`
  align-self: center;
  background: ${p => p.inverse ? '#ECF1F9' : p.theme.primaryColor} !important;
  svg {
    color: ${p => p.inverse ? p.theme.primaryColor : 'white' } !important;
  }
`;
