import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Page,
  NotFound,
  ProfilePictureUploader,
  FormRow,
  Input, TeamRoleSelector, Tags, CheckBox, TextArea, FormButton, FormLoading,
  AuthGuard
} from '../../components';
import { message, PageHeader, Skeleton } from 'antd';
import styled from 'styled-components';
import { UPDATE_TEAM_MEMBER, useFetchTeamMember } from '../../graphql';
import { Form } from 'formik-antd';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { teamMemberSchema } from './schema';
import { useFormData, Yup } from '../../hooks';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import PicPlaceholder from '../../assets/default_profile.png';
import { cleanError } from '../../helpers/error-helper';

const finalSchema = teamMemberSchema.shape({

})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const TeamEditMember: React.FC = () => {
  const { shortid }: any = useParams()

  const history = useHistory()

  const [handleUpload] = useMutation(UPDATE_TEAM_MEMBER);

  const [teamMember, setTeamMember] = useState<any>(null);

  const [fetchTeam, { loading }] = useFetchTeamMember({
    variables: { id: shortid },
    onCompleted: (result: any) => {
      setTeamMember({ ...result.teamMember });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Updating team member...', duration: 10 });
      const { id, team, user, teamId, teamRole, teamRoleAlt, isPlayer, isCoach, ...updateTeamMember } = values;
      return handleUpload({ variables: { id, updateTeamMember  } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team member updated!', duration: 2 });
      history.push('/team/' + teamMember.teamId)
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to update team member'), duration: 4 });
    }
  })

  const handleBack = useCallback(() => {
    if (teamMember && teamMember.teamId) history.push('/team/' + teamMember.teamId)
    else history.push('/team/list');
  }, [teamMember, history]);

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam]);

  const sportId = useMemo(() => get(teamMember, 'team.sportId', ''), [teamMember]);
  const isPlayer = useMemo(() => get(teamMember, 'user.isPlayer', ''), [teamMember]);
  const isCoach = useMemo(() => get(teamMember, 'user.isCoach', ''), [teamMember]);

  if (loading) return <FormLoading />;

  if (!teamMember || isEmpty(teamMember)) {
    return <NotFound subTitle={'Unable to find team member'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={teamMember} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title='Edit Team Member' onBack={handleBack} card>
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <Input {...fields.firstname} />
                <Input {...fields.lastname} />
                <Input {...fields.nickname} />
                <TeamRoleSelector sport={sportId} isPlayer={isPlayer} isCoach={isCoach} {...fields.teamRoleId} />
                <TeamRoleSelector sport={sportId} isPlayer={isPlayer} isCoach={isCoach} {...fields.teamRoleAltId} />
                <Tags isEditable={false} {...fields.tags} gridProps={GRID_PROPS} />
                <CheckBox {...fields.isCaptain} disabled={!isPlayer} gridProps={GRID_PROPS}>Team Captain</CheckBox>
                <CheckBox {...fields.isHeadCoach} disabled={!isCoach} gridProps={GRID_PROPS}>Head Coach</CheckBox>
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              <FormButton>
                UPDATE
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
};

export default TeamEditMember;
