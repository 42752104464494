import { Button } from 'antd'
import styled from 'styled-components'

export const ButtonLink = styled(Button)<{ align: 'left' | 'right' }>`
    display: flex;
    align-items: center;
    span {
        color: ${ p => p.theme.primaryColor };
    }
    padding: 0;
    @media only screen and (min-width: 499px) {
      justify-content: ${ p => p.align === 'left' ? 'flex-start' : 'flex-end' };
      align-self: ${ p => p.align === 'left' ? 'flex-start' : 'flex-end' };    
    }
`

export default ButtonLink
