import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Button, message, Modal, Skeleton, Radio } from 'antd'
import { Form as AntForm } from 'formik-antd'
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADD_SCORE, FIXTURE } from '../../../graphql'
import { Input, FormRow, SubmitButton, TextArea } from '../../../components'
import { useFormData, useHideActions } from '../../../hooks'
import * as yup from 'yup'
import { useFixtureDetails } from '../ViewFixture'
import { updateFixtureResultSchema } from './schema'
import { useSelector } from 'react-redux'
import { getActiveOrgId } from '../../../utils'

const Form = styled(AntForm)`

`

const Error = styled.div`
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: red;
`


export interface UpdateFixtureResultProps {
    fixtureId: string,
    handleClose?: () => void,
    submitText?: React.ReactNode,
    onSubmitted?: () => void
}

export const UpdateFixtureResult: React.FC<UpdateFixtureResultProps> = ({
    fixtureId,
    handleClose,
    submitText,
    onSubmitted,
    ...props
}) => {

    const [fixture, setFixture] = useState<any>({ id: fixtureId })

    const [fetch, { loading: fetching, error }] = useLazyQuery(FIXTURE, {
        onCompleted: (result: any) => {
            if (result && result.fixture) setFixture((fixture: any) => ({ ...fixture, ...result.fixture }))
        },
        onError: (e: any) => {
            message.error({ content: 'Could not load Fixture!', duration: 4 })
        }
    })

    useEffect(() => {
        if (fixtureId) fetch({
            variables: { id: fixtureId }
        })
    }, [fixtureId, fetch])

    const [update, { loading }] = useMutation(ADD_SCORE, {
        onCompleted: (result: any) => {
            message.success({ content: 'Successfully updated fixture!', duration: 4 })
        },
        onError: (error: any) => {
            message.error({ content: 'Could not update fixture!', duration: 4 })
        }
    })

    const { fields, initialValues: $initialValues, ...formik } = useFormData(updateFixtureResultSchema, {
        onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
            const result = await handleUpdate(values)()
            return result
        }
    })

    const handleUpdate = useCallback((values: any) => async () => {
            message.loading({ content: 'Updating Fixture...', duration: 10 })
            const { radioResult, ...rest } = values
            const result = await update({
                variables: {
                    newScoreData: {
                        ...rest,
                        result: radioResult,
                        fixtureId: fixtureId
                    },
                }
            })
            if (result && onSubmitted) onSubmitted()
            return result
    }, [update, onSubmitted, fixtureId])

    const handleOnResultChange = useCallback((setFieldValue: any) => (e: any) => {
        setFieldValue('radioResult', e.target.value)
    }, [])

    const {
        score
    } = useFixtureDetails(fixture)

    const initialValues = useMemo(() => {
        return {
            value: fixture?.currentScore,
            description: fixture?.currentScoreDesc,
            radioResult: fixture?.result || 'P'
        }
    }, [fixture])


    const grid = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        xxl: 24
    }

    const hideHome = useHideActions(fixture.homeOrgId)
    const hideAway = useHideActions(fixture.awayOrgId)

    const activeOrgId = useSelector(getActiveOrgId)

    const hide = useMemo(() => {
        if (fixture?.status === 'Cancelled') return true;
        if (hideHome && hideAway) return true
        if (hideHome && fixture.homeOrgId === activeOrgId) return true
        if (hideAway && fixture.awayOrgId === activeOrgId) return true
        return false
    }, [hideHome, hideAway, fixture, activeOrgId, fixture])

    if (fetching) return <Skeleton paragraph={{ rows: 5 }} />

    if (error) return <Error>
        Something went wrong!
        <br />
        Could not load fixture!
        <Button hidden={!handleClose} type='primary' onClick={handleClose}>Close</Button>
    </Error>


    return (
        <Formik
            {...formik}
            initialValues={initialValues}
            enableReinitialize
            validateOnBlur
        >
            {({ values, isSubmitting, handleSubmit, setFieldValue, dirty }) => (
                <Form layout='vertical'>
                    <FormRow disabled={hide}>
                        <Input gridProps={grid} { ...fields.value } />
                        <Radio.Group
                            value={values.radioResult}
                            options={[
                                { value: 'W', label: 'Home Win' },
                                { value: 'L', label: 'Away Win' },
                                { value: 'D', label: 'Draw' },
                                { value: 'P', label: 'In Progress' },
                            ]}
                            onChange={handleOnResultChange(setFieldValue)}
                            optionType='button'
                            style={{ margin: '5px 0 10px 0' }}
                        />
                        <TextArea
                            gridProps={grid}
                            { ...fields.description }
                        />
                        <SubmitButton
                            onClick={handleUpdate(values)}
                            hidden={hide}
                            style={{ width: '100%'}}
                            buttonType='primary'
                        >
                            { submitText || 'UPDATE' }
                        </SubmitButton>
                    </FormRow>
                </Form>
            )}
        </Formik>
    )
}
