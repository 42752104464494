import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import { useSelector } from 'react-redux';
import { getActiveOrg } from '../../utils';
const SponsorContainer = styled.div`
`;

const getImage = (image: string) => {
  return process.env.REACT_APP_S3_HOST + image + '?w=480';
}

interface NavFooterProps {
  collapsed?: boolean;
}

export const SponsorBanner: FC<NavFooterProps> = ({ collapsed}) => {

  const activeOrg = useSelector(getActiveOrg);

  if (!activeOrg?.sponsor?.primaryImageAlt || collapsed) return null;

  return (
    <SponsorContainer>
      <Image src={getImage(activeOrg.sponsor.primaryImage)} />
    </SponsorContainer>
  );
};

SponsorBanner.defaultProps = {};

export default SponsorBanner;
