import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  ContactNumber,
  Input,
  GeneratePassword,
  Select,
  OrgSelector
} from '../../components'
import {
  canSelectOrgs,
  COUNTRIES,
  getActiveOrgId,
  getIsAdmin,
  getSelectedOrg,
  mapArrayToObject,
  SA_PROVINCES
} from '../../utils';
import { useFormData, Yup } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import { message } from 'antd'
import { LocationSearch } from '../../components/location-search';
import { userSchema } from './schema';
import { get } from 'lodash';
import { ADD_USER } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';

const provinces = mapArrayToObject(SA_PROVINCES)
const countries = mapArrayToObject(COUNTRIES)

const roles = [
  { id: 'Admin', name: 'Super Admin' },
  { id: 'Organisation', name: 'School Admin' }
];

const finalSchema = userSchema.shape({
  role: Yup
    .string()
    .default('Organisation')
    .required()
    .label('Role'),
})

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  activeOrgId: useSelector(getActiveOrgId),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedOrg: useSelector(getSelectedOrg),
})

export const UserAdd: React.FC<any> = (props) => {

  const history = useHistory()

  const { isAdmin, selectedOrg, canSelectOrgs } = useSelectors()

  const [handleAdd] = useMutation(ADD_USER)

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating user...', duration: 10 });
      return handleAdd({ variables: { newUser: { ...values } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'User created!', duration: 2 });
      const userId = get(result, 'data.addLogin.shortid');
      if (userId) history.push('/user/' + userId);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create user'), duration: 4 });
    }
  })
  const [user] = useState({ ...initialValues, activeOrgId: get(selectedOrg, 'id') });

  const handleBack = useCallback(() => history.goBack(), [history]);

  return (
    <Formik { ...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Page title='Add User' onBack={handleBack} card>
          <Form layout={'vertical'}>
            <ProfilePictureUploader disabled={isSubmitting} fieldKey="profilePicUrl" defaultPic={PicPlaceholder} />

            <FormRow gutter={6}>
              <Input {...fields.firstname} />
              <Input {...fields.lastname} />
              <ContactNumber
                numberField={fields.contactNumber}
                countryCodeField={fields.contactNumberCountryCode}
              />
              <Input {...fields.email} />
              <LocationSearch {...fields.address} parentKey={'address'} />
              <Input {...fields.address.addressLine1} />
              <Select {...fields.address.country} options={countries} />
              <Select {...fields.address.state} options={provinces} />
              {/*<Input {...fields.address.postalCode} />*/}
              <GeneratePassword {...fields.password} generateButton />
              <Select {...fields.role} options={roles} disabled={!isAdmin} />
              <OrgSelector hidden={ values.role === 'Admin' } disabled={!canSelectOrgs} {...fields.activeOrgId}  />
              {/* TODO: org selection if Org role */}
            </FormRow>

            <FormButton>
              ADD
            </FormButton>

          </Form>
        </Page>
      )}
    </Formik>
  )
}

export default UserAdd
