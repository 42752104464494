import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import * as yup from 'yup'
import AutoForm from '../../../components/auto-form';
import { Page, AuthGuard, FormRow, Input, TextArea, TeamRoleSelectorComponent, FormLoading } from '../../../components';
import { useFormData, useImport } from '../../../hooks';
import { getActiveOrgId, getIsAdmin, canSelectOrgs } from '../../../utils/selectors';
import { playerSchema, Player } from '../../../pages/user/schema';
import { importTeamsheetMembersSchema, confirmTeamsheetMembersImportSchema } from './schema'
import { exportDataToExcel } from '../../../utils';
import { message, Modal, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FullButtonLg } from '../../../components';
import { OrgSelector } from '../../../components/org-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../../components/importer';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../../components'
import { useHideImportActions } from '../../../hooks'
import { ADD_TEAMSHEET, ADD_TEAMSHEET_MEMBERS, IMPORT_USERS_MUTATION } from '../../../graphql/queries'
import { cleanError } from '../../../helpers/error-helper';
import { useHistory, useParams } from 'react-router';
import { newTeamsheetSchema } from '../schema'
import { LoadedSport, useCurrentSport, useSports } from '../../../redux';
import { useFetchTeamsheet } from '../../../graphql';

const { confirm } = Modal;

const { schema, initialValues } = getFormData(importTeamsheetMembersSchema);

const mapRole = (teamRoles: any[], role: string) => {
  const selectedRole = teamRoles.find((teamRole: any) => teamRole.name === role)
  if (role) return selectedRole.id
  return undefined
}

export const TeamsheetMembersImport: React.FC<any> = () => {

  const [sports] = useSports()
  const { teamsheetId }: any = useParams()
  const [teamsheet, setTeamsheet] = useState<any>({ id: teamsheetId })
  const history = useHistory();

  const [fetch, { loading, error }] = useFetchTeamsheet(teamsheetId, {
    onCompleted: (result: any) => {
      if ( result && result.teamsheet ) setTeamsheet(result.teamsheet)
    },
    onError: (e: any) => {
      message.error({ content: 'Could not load Team Sheet!', duration: 4 })
    }
  })

  const sport = useMemo(() => {
    const sportId = teamsheet.sportId
    const sport = sports.find((sport: LoadedSport) => sport.id === sportId)
    return sport ?? {}
  }, [teamsheet, sports])

  const module = useMemo(() => {
    return sport?.name ?? ''
  }, [sport])

  const teamRoles = useMemo(() => sport.teamRoles, [sport])

  const handleMapRoles = useCallback((members: any[], teamRoles: any[]) => {
    return members.map((member: any) => {
      const { teamRole, teamRoleAlt, ...rest } = member
      return {
        ...rest,
        teamRoleId: mapRole(teamRoles, teamRole),
        teamRoleAltId: mapRole(teamRoles, teamRoleAlt)
      }
    })
  }, [])

  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
  } = useImport(initialValues, module, schema);

  const [players, setPlayers] = useState<Player[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);
  const canSelect = useSelector(canSelectOrgs)

  const activeOrganisationId = useSelector(getActiveOrgId);

  const [importTeamsheet] = useMutation(ADD_TEAMSHEET_MEMBERS);

  const exportPlayers = useCallback(() => {
    exportDataToExcel(fields, players, 'playpro-teamsheet-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [players]);

  const clearData = useCallback(() => {
    handleClear();
    setPlayers([]);
    setImportSuccess(false);
  }, [handleClear, setPlayers, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setPlayers(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setPlayers, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData();
      history.push(`/fixture/teamsheet/${teamsheetId}`);
    } else if (players?.length > 0) {
      setPlayers([]);
    } else {
      handleCancel();
    }
  }, [clearData, players, setPlayers, handleCancel, importSuccess, history]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmTeamsheetMembersImportSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid players to your Teamsheet account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, organisationId } = values;
          const playerData = [...handleMapRoles(sanitize(players), teamRoles)]
          try {
            // await importTeamsheet({
            //     variables: { newTeamsheetMembersData: {
            //         teamsheetMembers:  playerData,
            //         teamsheetId
            //     } },
            // });
            setImportSuccess(true);

            if (downloadExcel) {
              exportPlayers();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: cleanError(ex, 'Something went wrong'), duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrganisationId,
      }),
    [activeOrganisationId, isAdmin],
  );

  const {
    hideCancel,
    hideDownload,
    hideSave
  } = useHideImportActions(importSuccess, values, players)

  if (loading) return <FormLoading />

  return (
    <AuthGuard needsActiveOrg>
      <Page
        card
        title='Import Teamsheet'
        onBack={hasValues ? back : undefined}
        extra={[
          <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
          <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
          <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
        ]}
      >
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || players?.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />

            <FullButtonLg hidden={!hasValues || players?.length > 0} onClick={next}>
              Next
            </FullButtonLg>

            {players?.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {players?.length} player{players?.length !== 1 && 's'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrganisationId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import team sheets</ImportConfirm>}
                      <ConfirmForm>
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}

        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}

      </Page>

      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>

    </AuthGuard>
  );
};

export default TeamsheetMembersImport;
