import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  ContactNumber,
  Input,
  TextArea,
  GeneratePassword,
  Select,
  OrgSelector,
  DatePicker
} from '../../components'
import {
  canSelectOrgs,
  COUNTRIES,
  getActiveOrgId,
  getIsAdmin,
  getSelectedOrg,
  mapArrayToObject,
  SA_PROVINCES,
  isMinor
} from '../../utils';
import { useFormData } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import { message,  Tabs, Collapse as Collapser } from 'antd'
import { LocationSearch } from '../../components/location-search';
import { AuthGuard } from '../../components'
import { playerAddSchema } from '../user/schema';
import { get } from 'lodash';
import { ADD_USER } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';

const { Panel } = Collapser
const { TabPane } = Tabs;

const provinces = mapArrayToObject(SA_PROVINCES)
const countries = mapArrayToObject(COUNTRIES)

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  activeOrgId: useSelector(getActiveOrgId),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedOrg: useSelector(getSelectedOrg),
})

export const PlayerAdd: React.FC<any> = (props) => {

  const history = useHistory()

  const { canSelectOrgs, selectedOrg } = useSelectors()

  const [handleAdd] = useMutation(ADD_USER)

  const { fields, initialValues, ...formikCTX } = useFormData(playerAddSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating player...', duration: 10 });
      return handleAdd({ variables: { newUser: { ...values } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Player created!', duration: 2 });
      const userId = get(result, 'data.addLogin.shortid');
      if (userId) history.push('/user/' + userId);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create player'), duration: 4 });
    }
  })
  const [user] = useState({ ...initialValues, activeOrgId: get(selectedOrg, 'id') });

  const handleBack = useCallback(() => history.goBack(), [history]);

  return (
    <AuthGuard
      needsActiveOrg
    >
      <Formik { ...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page
            title='Add Player'
            onBack={handleBack}
            card
          >
            <Form layout={'vertical'}>
            <Tabs defaultActiveKey={'Player Info'} tabPosition={'top'} size={'large'}>
            <TabPane tab={'Primary Info'} key={'Primary Info'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="profilePicUrl" defaultPic={PicPlaceholder} />

              <Collapser
                defaultActiveKey={[
                  'contact',
                  'stats',
                  'parent',
                  'security',
                  'location'
                ]}
                ghost
              >

                <Panel key='contact' header='Contact Information'>

                  <FormRow>
                    <Input {...fields.firstname} />
                    <Input {...fields.lastname} />
                  
                
                    
                   
                    
                    
                    <ContactNumber
                      numberField={fields.contactNumber}
                      countryCodeField={fields.contactNumberCountryCode}
                    />
                    <Input {...fields.email} />
                    <DatePicker {...fields.dateOfBirth} />
                  </FormRow>
                </Panel>

                <Panel key='stats' header='Player Stats'>
                  <FormRow>
                    <Input {...fields.weight} />
                    <Input {...fields.height} />
                    <Input {...fields.shoeSize} />
                  </FormRow>
                </Panel>

                { isMinor(values.dateOfBirth) && values.dateOfBirth &&
                  <Panel key='parental' header='Parental Contact'>
                    <FormRow>
                      <Input {...fields.parentContactEmail} />
                      <ContactNumber
                        numberField={fields.parentContactNumber}
                        countryCodeField={fields.parentContactNumberCountryCode}
                      />
                    </FormRow>
                  </Panel>
                }

                <Panel key='location' header='Location'>

                  <FormRow>
                    <LocationSearch {...fields.address} parentKey={'address'} />
                    <Input {...fields.address.addressLine1} />
                    <Select {...fields.address.country} options={countries} />
                    <Select {...fields.address.state} options={provinces} />

                  </FormRow>
                </Panel>

                <Panel key='security' header='Security'>
                  <FormRow>
                    <OrgSelector disabled={!canSelectOrgs} {...fields.activeOrgId}  />
                    <GeneratePassword {...fields.password} generateButton />
                  </FormRow>
                </Panel>

              </Collapser>

              <FormButton>
                ADD
              </FormButton>
              </TabPane>
              <TabPane tab={'Commentators and Camera operators'} key={'Commentators and Camera operators'}>
             <FormRow>
                <Input {...fields.language} />
                <Input {...fields.camop} />
                <Input {...fields.producer} />
                <Input {...fields.experience} />
                <Input {...fields.clinear} />
                <Input {...fields.cregion} />
                <Input {...fields.csports} />
                <Input {...fields.aimobile} />
                <Input {...fields.aiop} />
                <Input {...fields.scorer} />
                <TextArea {...fields.ccomments} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }}/>

             </FormRow>
             <FormButton>
                ADD
              </FormButton>

             </TabPane>
             </Tabs> 
            
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default PlayerAdd
