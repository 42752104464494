import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import reportWebVitals from './reportWebVitals';

import { store, persistor } from './config/redux-config'
import { apolloClient } from './config/apollo-config'
import './config/amplify-config'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: "https://ca88fdc3183540578ef1c22913aee81e@o230337.ingest.sentry.io/5579740",
  autoSessionTracking: true,
  integrations(integrations) {
    // get all integrations
    return integrations
      // remove `GlobalHandlers`
      .filter(i => i.name !== 'GlobalHandlers')
      // override `ReportingObserver` with custom config
      .concat([new Integrations.BrowserTracing()])
  },
//
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Root client={apolloClient} store={store} persistor={persistor} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
