import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import { ProfilePictureUploader } from '../../../components/profile-picture-uploader';
import PicPlaceholder from '../../../assets/default_profile.png';
import { ContactNumber, FormButton, FormRow, Input, Select, TextArea } from '../../../components';
import { LocationSearch } from '../../../components/location-search';
import { COUNTRIES, getIsAdmin, mapArrayToObject, SA_PROVINCES } from '../../../utils';
import { useUpdateUser } from '../../../graphql';
import { useFormData } from '../../../hooks/use-form-data';
import * as Yup from 'yup';
import { userSchema } from '../schema';
import { message } from 'antd';
import { get } from 'lodash';
import { cleanError } from '../../../helpers/error-helper';

const provinces = mapArrayToObject(SA_PROVINCES)
const countries = mapArrayToObject(COUNTRIES)

const finalSchema = userSchema.shape({
  password: Yup
    .string()
    .nullable()
    .label('Password'),
})

interface EditProfileProps {
  user: any;
  edit?: boolean;
}

const key = 'user-update';

export const EditMyProfile: FC<EditProfileProps> = (props) => {
  const { user, edit } = props;

  const [handleUpload] = useUpdateUser({});

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', key, duration: 10 });
      const { id, number, organisationId, createdAt, activeOrg, sportInfo, documents, teams, images, linkedOrganisations, contactNumberFull, parentContactNumberFull, teamsheets, undefined, ...rest } = values; // Destructure other values here
      return handleUpload({
        variables: {
          id,
          user: {
            ...rest,
          },
        },
      });
    },
    onError: (error: any) => {
      //console.log(error);
      message.error({ content: cleanError(error, 'Unable to update account'), key, duration: 4 });
    },
    onCompleted: () => {
      message.success({ content: 'Account updated!', key, duration: 2 });
    },
  });

  return (
    <Formik {...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
      {({handleSubmit, isSubmitting, setFieldValue, values}) => (
        <Form layout={'vertical'}>
          <ProfilePictureUploader disabled={!edit || isSubmitting} fieldKey="profilePicUrl" defaultPic={PicPlaceholder} />

          <FormRow disabled={!edit}>
            <Input {...fields.firstname} />
            <Input {...fields.lastname} />
            <ContactNumber
              numberField={fields.contactNumber}
              countryCodeField={fields.contactNumberCountryCode}
            />
            <Input {...fields.email} disabled />
            <LocationSearch {...fields.address} parentKey={'address'} defaultValue={get(values, 'address.fullAddress')} />
            <Input {...fields.address.addressLine1} />
            <Select {...fields.address.country} options={countries} />
            <Select {...fields.address.state}  options={provinces} />
            <TextArea {...fields.bio} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }}/>
          </FormRow>

          <FormButton hidden={!edit}>
            SAVE
          </FormButton>

        </Form>
      )}
    </Formik>
  );
};

EditMyProfile.defaultProps = {};

export default EditMyProfile;
