import { LoadedSport } from '../redux'

export const getDefaultRoleOverrides = (sports: LoadedSport[]) => {
    const overrides: any[] = [];
    sports.forEach((sport: any) => {
        const roles = (sport.teamRoles || []).map((role: any) => role.name)
        const module = sport.name
        const push = {
            module,
            type: 'string',
            description: `one of: ${roles.map((role: any) => role + ', ')}`,
            options: [
                ...roles
            ]
        };
        overrides.push({ ...push, field: 'teamRole' });
        overrides.push({ ...push, field: 'teamRoleAlt' });
    });
    return overrides;
}
