import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Layout, Space } from 'antd'
import PlayPro from '../assets/playpro.png';
import { Avatar } from './Avatar';
import { useHistory } from 'react-router';

const { Header: UnstyledHead } = Layout

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 18px;
    padding-left: 18px;
    height: inherit;
    @media only screen and (max-width: 768px) {
      padding-left: 8px;
      padding-right: 8px;
    }
`

const Title = styled.div<{ mode?: 'light' | 'dark'}>`
  color: ${p => p.mode === 'light' ? p.theme.primaryColor : 'white' };
  font-size: 18px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled(Avatar)`
  margin-right: 15px;
`;

const Alt = styled.span`
  font-weight: bold;
  font-style: italic;
`;

const Head = styled(UnstyledHead)`
  height: 50px;
`;

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  actions?: React.ReactNode[],
  leftActions?: React.ReactNode[],
  titleAlt?: React.ReactNode,
  mode?: 'light' | 'dark';
}

export const HeaderComponent: React.FC<HeaderProps> = ({ leftActions = [], titleAlt, actions = [], className, hidden, mode, ...props}) => {

  const history = useHistory();

  const handleHome = useCallback(() => history.push('/'), [history]);

  if (hidden) return null;

  return (
    <Head className={`${className || ''} header`}>
        <Container>
          <Space>
            { leftActions }
          </Space>
          <Title mode={mode} onClick={handleHome}>
            {titleAlt ? titleAlt : (
              <>
                <Icon src={PlayPro} inverse/>
                Play<Alt>Pro</Alt>
              </>
            )}
          </Title>
          <Space>
            { actions }
          </Space>
        </Container>
    </Head>
  )
}

export const Header = styled(HeaderComponent)<{ mode?: 'light' | 'dark'}>`
    ${p => p.theme ? css`width: ${p.theme.width};` : ''};
    background: ${p => p.mode === 'light' ? 'white' : p.theme.primaryColor } !important;
    padding: 0;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 0 2px 6px #d7d7d7 !important;
    -moz-box-shadow: 0 2px 6px #d7d7d7 !important;
    box-shadow: 0 2px 6px #d7d7d7 !important;
`
