import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Card, message, Tooltip, Button, Tabs, Collapse as Collapser, Popconfirm, Space, PageHeader, Radio } from 'antd'
import { Form as AntForm } from 'formik-antd'
import { get, cloneDeep, compact } from 'lodash'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Formik, FormikValues, FormikHelpers } from 'formik'
import {
  Filter,
  TextArea,
  Table,
  Page,
  AuthGuard,
  FormRow,
  Input,
  SportSelector,
  ProfilePictureUploader,
  FormLoading,
  TeamRole,
  Avatar,
  FormButton
} from '../../components'
import PicPlaceholder from '../../assets/default_profile.png';
import { Team, Teamsheet, useCurrentOrg, useSports } from '../../redux'
import { useFetchTeamsheet, ARCHIVE_TEAMSHEET_MEMBER, UPDATE_TEAMSHEET } from '../../graphql'
import { AGE_GROUP_OPTIONS, getActiveOrgId, getIsAdmin, toCaps } from '../../utils'
import { teamsheetSchema, UpdateTeamsheet } from './schema'
import { useFormData, useHideActions } from '../../hooks'
import { cleanError } from '../../helpers/error-helper'
import { useSelector } from 'react-redux'
import { ReloadOutlined, BorderOutlined, MenuOutlined } from '@ant-design/icons';
import { Select, SelectOption } from '../../components'
import { TeamSheetCards } from '../team/components';

const { TabPane } = Tabs
const { Panel } = Collapser

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

const Form = styled(AntForm)`
`

const Collapse = styled(Collapser)`
    width: 100%;
`

const Name = styled.span`
  margin-left: 5px;
`

interface TeamSheetProps {

}

const FULL_PROPS = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24
}

export const TeamSheet: React.FC<TeamSheetProps> = ({ ...props }) => {

  const { teamsheetId }: any = useParams()
  const history = useHistory()


  const [handleUpdate, { loading: updating }] = useMutation(UPDATE_TEAMSHEET, {
      onError: (e: any) => {
          message.error({ content: 'Could not update Teamsheet', duration: 4 })
      }
  })

  const { fields, initialValues, ...formikCTX } = useFormData(teamsheetSchema, {
      onSubmit: async (values: FormikValues, actions: FormikHelpers<UpdateTeamsheet>) => {
          const { id, createdBy, teamsheetMembers, organisation, sport, team, ...rest } = values
          const result = await handleUpdate({ variables: {
              id: teamsheetId,
              updateTeamsheet: { ...rest }
          }})
          if (result?.data?.updateTeamsheet) setTeamsheet({ ...teamsheet, ...result?.data?.updateTeamsheet })
      },
      onCompleted: (result: any) => {
        message.success({ content: 'Teamsheet updated successfully!', duration: 4 })
      },
      onError: (e: any) => {
          message.error({ content: 'Could not update Teamsheet!', duration: 4 })
      }
  })

  const [teamsheet, setTeamsheet] = useState<Teamsheet>({
      ...initialValues
  })


  const [fetch, { loading }] = useFetchTeamsheet(teamsheetId, {
      onCompleted: (result: any) => {
          if (result) setTeamsheet({ ...initialValues, ...result.teamsheet })
      },
      onError: (e: any) => {
          message.error({ content: 'Could not load Teamsheet!', duration: 4 })
      }
  })

  const [removeTeamMember, { loading: removing }] = useMutation(ARCHIVE_TEAMSHEET_MEMBER);

  const handleRemove = useCallback((id: string) => async (e?: any) => {
    e.stopPropagation();
    message.loading({ content: 'Removing team member...', duration: 10 });
    try {
      await removeTeamMember({ variables: { id } })
      setTeamsheet((teamsheet: any) => {
        const _team = { ...teamsheet };
        _team.teamsheetMembers = teamsheet?.teamsheetMembers.filter((t: any) => t.id !== id);
        return _team
      })
      message.success({ content: 'Team member has been removed', duration: 2 })
    } catch (ex) {
      //console.log(ex);
      message.error({ content: cleanError(ex, 'Unable to remove team member'), duration: 3 })
    }
  }, [setTeamsheet, removeTeamMember])

  const handleAddMember = useCallback(() => history.push(`/fixture/teamsheet/${teamsheetId}/member/add`), [history, teamsheetId])
  const handleAddMembers = useCallback(() => history.push(`/fixture/teamsheet/${teamsheetId}/members/add`), [history, teamsheetId])
  const handleImport = useCallback(() => history.push(`/fixture/teamsheet/${teamsheetId}/members/import`), [history, teamsheetId])
  const handleBack = useCallback(() => history.push(`/fixture/${teamsheet.fixtureId}`), [history, teamsheet])
  const handleTeamClick = useCallback(() => history.push(`/team/${teamsheet.teamId}`), [history, teamsheet])

  const [currentOrg] = useCurrentOrg();

  const hideActions = useHideActions(currentOrg?.id, teamsheet?.fixture);

  const handleRefresh = useCallback(() => {
    fetch({ variables: { id: teamsheetId }})
  }, [fetch, teamsheetId]);

  const [mode, setMode] = useState('Cards');
  const handleSetMode = useCallback((e: any) => setMode(e?.target?.value), [setMode])

  if (loading || !teamsheet?.teamsheetMembers) return (
    <FormLoading />
  )

  return (
      <AuthGuard needsActiveOrg>
          <Page
              card
              title='Teamsheet'
              onBack={handleBack}
              extra={[
                  <Tooltip title="Refresh">
                    <Button type="dashed" shape="circle" onClick={handleRefresh} icon={<ReloadOutlined />} />
                  </Tooltip>,
                  <Button hidden={hideActions} type='primary' onClick={handleAddMember}>Add Team Member</Button>,
                  <Button hidden={hideActions || !teamsheet?.organisationId} type='primary' onClick={handleAddMembers}>Import from Team</Button>,
                  <Button hidden={hideActions || !!teamsheet?.organisationId} type='primary' onClick={handleImport}>Import from Spreadsheet</Button>
              ]}
          >
              <Tabs defaultActiveKey='members'>
                  <TabPane tab='Team Members' key='members'>
                    <div>
                      <Tooltip title={'Change Display Mode'}>
                        <Radio.Group
                          value={mode}
                          onChange={handleSetMode}
                          options={[
                            { label: <BorderOutlined />, value: 'Cards' },
                            { label: <MenuOutlined />, value: 'List' }
                          ]}
                          optionType='button'
                        />
                      </Tooltip>
                    </div>
                    <TeamSheetTable teamsheet={teamsheet} handleRemove={handleRemove} loading={loading} removing={removing} hideActions={hideActions} hidden={mode !== 'List'} />
                    <TeamSheetCards teamsheet={teamsheet} handleRemove={hideActions ? undefined : handleRemove} removing={removing} hidden={mode !== 'Cards'} />
                  </TabPane>
                  <TabPane tab='Information' key='info'>
                      <Formik {...formikCTX} initialValues={teamsheet} enableReinitialize validatenBlur>
                          {({ isSubmitting, values, handleSubmit, errors }) => (
                              <Form layout='vertical'>
                                  <ProfilePictureUploader disabled={isSubmitting || hideActions} fieldKey="primaryImage" defaultPic={PicPlaceholder} />
                                  <Collapse defaultActiveKey={['general']} ghost>
                                      <Panel header='General' key='general'>
                                          <FormRow disabled={hideActions}>
                                              <Input {...fields.name} />
                                              <Select options={AGE_GROUP_OPTIONS} {...fields.ageGroup} allowClear />
                                              { teamsheet.team && (
                                                <Select {...fields.teamId} disabled onClick={handleTeamClick}>
                                                  <SelectOption value={teamsheet?.team?.id} label={teamsheet?.team?.name}>
                                                    <Icon src={getThumbnail(teamsheet?.team?.primaryImage)} size="small" icon={<TeamOutlined />} inverse />
                                                    {teamsheet?.team?.name}
                                                  </SelectOption>
                                                </Select>
                                              )}
                                              <SportSelector disabled filterDefault {...fields.sportId} />
                                              <TextArea gridProps={FULL_PROPS} {...fields.description} rows={7} />
                                          </FormRow>
                                      </Panel>
                                  </Collapse>
                                  <FormButton hidden={hideActions} type='submit'>SAVE</FormButton>
                              </Form>
                          )}
                      </Formik>
                  </TabPane>
              </Tabs>
          </Page>
      </AuthGuard>
  )
}

interface TeamSheetTableProps {
  teamsheet: Teamsheet,
  handleRemove: (id: string) => (e?: any) => void,
  loading?: boolean,
  removing?: boolean,
  hideActions?: boolean
  hidden?: boolean
}
const TeamSheetTable = ({ teamsheet, handleRemove, loading, removing, hidden, hideActions }: TeamSheetTableProps) => {

  const history = useHistory();

  const [sportList] = useSports();

  const selected = useMemo(() => teamsheet?.teamsheetMembers, [teamsheet])

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  const { teamRoles } = useMemo(() => sportList.find((sport: any) => sport.id === get(teamsheet, 'sportId')) || {}, [sportList, teamsheet])

  const columns = useMemo(() => {
    const allTeamRoles = compact((teamRoles || []).map((tr: any) => tr.id !== '' && Object.assign({ text: tr.name, value: tr.name})));
    const filters = {
      filters: [ ...allTeamRoles ],
      filterMultiple: true,
      type: Filter.SELECT
    }
    return [
      {
        title: '',
        key: 'primaryImage',
        export: false,
        width: 40,
        render: (r: any) => <Avatar src={getThumbnail(r)} icon={<UserOutlined />} inverse />
      },
      {
        title: 'Primary Role',
        filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
        sorting: true,
        getValues: (r: any) => r,
        key: 'teamRole.name',
        render: (r: any, rec: any) => <><TeamRole row={rec} /><Name>{r}</Name></>
      },
      {
        title: 'First Name',
        filtering: true,
        sorting: true,
        key: 'firstname',
      },
      {
        title: 'Last Name',
        filtering: true,
        sorting: true,
        key: 'lastname',
      },
      {
        title: 'Nickname',
        filtering: true,
        sorting: true,
        key: 'nickname',
      },
      {
        title: 'Secondary Role',
        filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
        sorting: true,
        key: 'teamRoleAlt.name'
      },
      { title: '', key: 'id', hidden: hideActions, render: (id: string) =>
          <Popconfirm
            title='Are you sure you want to remove this team member?'
            onConfirm={handleRemove(id)}
            onCancel={handleCancel}
            okText='Yes'
            cancelText='No'
          >
            <Button hidden={hideActions} onClick={handleCancel} type='link' danger>Remove</Button>
          </Popconfirm>
      },
    ];
  }, [teamRoles, handleRemove, handleCancel, hideActions]);

  const handleNavigate = useCallback((record: any) => (e: any) => {
    e.stopPropagation();
    history.push(`/fixture/teamsheet/member/edit/${record.id}`)
  }, [history]);

  if (hidden) return null;

  return (
    <Table
      data={selected || []}
      columns={columns}
      loading={loading}
      onRow={(record: any, rowIndex: any) => ({
        onClick: handleNavigate(record)
      })}
      title={'Teamsheet'}
    />
  );
}
