import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useDrawerOpen } from '@bit/necta.hooks.use-drawer-open'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

interface CollapseButtonProps extends React.HTMLAttributes<HTMLElement> {

}

export const CollapseButtonComponent: React.FC<CollapseButtonProps> = ({ ...props }) => {
    const [open, setOpen] = useDrawerOpen()
    const handleOnClick = useCallback(() => setOpen(!open), [setOpen, open])
    const Icon = useMemo(() => open ? <ArrowLeftOutlined /> : <ArrowRightOutlined />, [open])
    return <Button icon={Icon} shape='circle' size='small' onClick={handleOnClick} {...props} />
}

export const CollapseButton = styled(CollapseButtonComponent)`
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 3;

    transform: translateX(50%);
    svg {
        color: white;
    }

    &.ant-btn:focus {
        background-color: ${ p=> p.theme.primaryColor };
    }

    &.ant-btn {
        background-color: ${ p => p.theme.primaryColor };
        border: none;
        :hover {
            background-color: ${ p => p.theme.secondaryColor };
            box-shadow: 0 0 2px gray;
        }
    }

`
