import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import Modal, { ModalProps } from 'antd/lib/modal'
import { useSports, LoadedSport } from '../../../redux'
import { SportInfo } from './types'
import { DashedTag, SportSelector } from '../../../components'
import { ADD_SPORT_INFO } from '../../../graphql'
import { useMutation } from '@apollo/client'
import { PlusOutlined } from '@ant-design/icons';
import SportTag from './SportTag';

const Add = styled(DashedTag)`
  padding: 2px 12px !important;
`;

interface SportTagsProps {
  value: SportInfo[],
  userId: string
}

const TagRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 10px;
    overflow-x: scroll;
    /*Hide scrollbars*/
    ::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;  /* Hide scrollbars for Internet Explorer 10+ */
    scrollbar-width: none;  /* Hide scrollbars for Firefox */
`

export const SportTags: React.FC<SportTagsProps> = ({ userId, value: tags, ...props }) => {
  const [sportList] = useSports()

  const [isSelecting, setSelecting] = useState<boolean>(false)
  const [selected, setSelected] = useState<LoadedSport | null>(null)

  const [addSportInfo, { loading }] = useMutation(ADD_SPORT_INFO, {
    onCompleted: (result: any) => {
      setSelecting(false)
    }
  })

  const _getModalOptions = useCallback(() => sportList
    .filter((sport: LoadedSport) => !tags.find((tag: SportInfo) => tag.sport.id === sport.id) && sport.name !== 'PlayPro')
    .map((sport: LoadedSport) => (
      <Select.Option key={sport.id} value={sport.id} label={sport.name}>{sport.name}</Select.Option>
    )), [tags, sportList])

  const handleOnTagAdd = useCallback((e: any) => {
    setSelecting(true)
  }, [tags, sportList])

  const handleOnSelected = useCallback((value: LoadedSport) => {
    setSelected(value)
  }, [setSelected])

  const handleOnOk = useCallback(async () => {
    try {
      await addSportInfo({ variables: {
          newSportInfoData: {
            userId,
            sportId: (selected as LoadedSport).id
          }
        } })
    } catch (e) {
      alert(e)
    } finally {
      setSelecting(false)
      setSelected(null)
    }
  }, [userId, selected, setSelected, setSelecting])

  const handleOnCancel = useCallback(() => setSelecting(false), [setSelecting])

  return <TagRow>
    {
      tags.map((tag: SportInfo) => <SportTag sportInfo={tag} scroll />)
    }
    <Add onClick={handleOnTagAdd}>
      <PlusOutlined /> Add
    </Add>
    <Modal
      confirmLoading={loading}
      title='Select a Sport'
      visible={isSelecting}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
    >
      <SportSelector
        disabled={loading}
        allowClear={false}
        getOptions={_getModalOptions}
        onChange={handleOnSelected}
      />
    </Modal>
  </TagRow>
}
