import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { FieldContainer } from '../FieldContainer'
import { useEvents, LoadedEvent, useSetEvents } from '../../redux'
import { get, isEmpty, uniqBy } from 'lodash'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';
import { useFetchEventList } from '../../graphql/hooks';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

type Event = LoadedEvent

const { Option } = Select

interface EventSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  loading?: boolean
  //TODO: define these properly
  valueKey?: keyof Event,
  labelKey?: keyof Event,
  getOptions?: (orgs: Partial<Event>[]) => React.ReactNode[],
  showPicture?: boolean;
  filterDefault?: boolean; // Filter out the default event from options
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  allowClear?: boolean,
  currentEvent?: Event,
}

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a event from a currently loaded list. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the LoadedEvent type.
 */
export const EventSelectorComponent: React.FC<EventSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  { getFormik, getOptions, labelKey = 'name', valueKey = 'id', name = '', onChange, disabled, label, required, hidden, gridProps, className, defaultValue, loading, showPicture = true, filterDefault = true, currentEvent, ...props }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')

  const selectedOrg = useSelector(getSelectedOrg);

  const setEvents = useSetEvents()

  const [fetchEvents, { loading: eventsLoading }] = useFetchEventList({
    variables: { eventQuery: { organiserId: selectedOrg?.id  }},
    onCompleted: (result: any) => {
      if ( result && result.events ) {
        setEvents(result.events);
        // Clear selected event on selectedOrg change
        const allEvents = currentEvent ? result.events.concat(currentEvent) : result.events;
        const found = allEvents.find((event: Event) => get(event, valueKey, null) === selected);
        if (!found) handleOnChange(null);
      }
    }
  })

  // fetch currently loaded events
  const [loadedEvents] = useEvents()

  const eventList = useMemo(() => currentEvent ? uniqBy((loadedEvents ?? []).concat(currentEvent), valueKey) : loadedEvents, [loadedEvents, currentEvent, valueKey])

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const getEvent = useCallback((key: string | number | symbol, value: any) => eventList.find((event: Event) => get(event, key, null) === value), [eventList])

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value)
    if (onChange) onChange(getEvent(valueKey, value))
    setSelected(value)
  }, [getEvent, valueKey, name, formik, onChange, isFormik])

  /**
   * Select Options constructor. Builds an array of options children from loaded event list.
   */
  const _getOptions = useCallback(() => eventList.map((event: Event) => {
    if (!filterDefault || (event.id && event.id !== '')) {
      return (
        <Option key={event.id} value={event[valueKey] as string} label={event[labelKey]}>
          {showPicture && <Icon src={getThumbnail(event.primaryImage || '')} size="small" inverse />}
          {event[labelKey]}
        </Option>
      )
    }
    return null
  }), [eventList, filterDefault, valueKey, labelKey, showPicture])

  useEffect(() => {
    if (eventList.length > 0) setSelected(defaultValue)
    // if (eventList.length === 0) setSelected(null)
  }, [eventList, defaultValue])

  const isLoading = useMemo(() => loading || eventsLoading, [loading, eventsLoading]);

  //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)


  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <FormikSelect
        prefix={<TeamOutlined className="site-form-item-icon" />}
        name={name}
        onChange={handleOnChange}
        disabled={disabled || isLoading}
        loading={isLoading}
        {...props}
      >
        { /* call option getOptions prop to fetch children. default to map over orgs. */}
        {getOptions ? getOptions(eventList) : _getOptions()}
      </FormikSelect>
    </FieldContainer>
  )

  if (hidden) return null;

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<TeamOutlined className='site-form-item-icon' />}
      placeholder={'Choose a event'}
      loading={isLoading}
      className={`${className} ant-event-selector`}
      onChange={handleOnChange}
      disabled={disabled || isLoading}
      {...props}
    >
      {eventList.length > 0 && (getOptions ? getOptions(eventList) : _getOptions())}
    </Select>
 )
}

EventSelectorComponent.defaultProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'label',
  getFormik: true,
}

export const EventSelector = styled(EventSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`

export const FilledEventSelector = styled(EventSelector)`
    .ant-select-selector, input {
      border: none !important;
    }
    .ant-select-selector {
      background: ${p => p.theme.secondaryColor} !important;
      border-radius: 6px !important;
      -webkit-box-shadow: 0 1px 2px #33333333 !important;
      -moz-box-shadow: 0 1px 2px #33333333 !important;
      box-shadow: 0 1px 2px #33333333 !important;
    }
    .ant-select-clear {
      background: ${p => p.theme.secondaryColor};
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      color: #eee !important;
    } 
`;

FilledEventSelector.defaultProps = {
  filterDefault: false
}
