import * as Yup from 'yup'
import moment from 'moment'
import { addressSchema } from '../../utils'

export const valiDate = (dateStart: any, schema: any) => {
    if (!dateStart) return schema
    const date = moment(dateStart)
    if (!date.isValid()) return schema
    return schema.min(date.toDate())
}

const validateOrg = (orgName: string, schema: any) => {
    if (!orgName) return Yup.string().required('Choosing an Organisation is required!')
    return Yup.string().nullable()
}

const validateOrgName = (showOrgName: boolean, schema: any) => {
    if (showOrgName) return Yup.string().required('Organisation Name is a required field!')
    return Yup.string().nullable()
}

export const fixtureSchema = Yup.object().shape({
    sportId: Yup.string().required().label('Sport'),
    name: Yup.string().required().label('Name'),
    description: Yup.string().label('Description'),
    time: Yup.string().oneOf(['80', '60', '90', 'other']).default('60'),
    dateStart: Yup.date()
    .required('Please enter a valid start date')
    .typeError('Please enter a valid start date')
    .meta(['date'])
    .label('Start Date'),
    dateEnd: Yup.date()
    .required('Please enter a valid end date')
    .when('dateStart', valiDate)
    .typeError('Please enter a valid end date')
    .meta(['date'])
    .label('End Date'),
    isHosting: Yup.boolean().default(false).label('I Am Hosting').default(true),
    isAway: Yup.boolean().default(false).label('I Am Away'),
    showAwayOrgName: Yup.boolean().default(false),
    showHomeOrgName: Yup.boolean().default(false),
    homeOrgId: Yup.string().when('homeOrgName', validateOrg).nullable().label('Home'),
    homeOrgName: Yup.string().when('showHomeOrgName', validateOrgName).label('Home Name'),
    awayOrgId: Yup.string().when('awayOrgName', validateOrg).nullable().label('Away'),
    awayOrgName: Yup.string().when('showAwayOrgName', validateOrgName).label('Away Name'),
    ownedById: Yup.string().nullable().label('Owned By'),
    address: addressSchema.label('Location'),
    primaryContactName: Yup.string().nullable().label('Primary Contact Person'),
    primaryContactCell: Yup.string().nullable().label('Contact Number'),
    primaryContactCellCountryCode:  Yup.string().nullable(),
    primaryContactEmail: Yup.string().nullable().label('Email'),
    eventId: Yup.string().nullable().label('Event')

})
