import React, { FC, useCallback } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Page } from '../../components/page';
import { message, PageHeader } from 'antd';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import PicPlaceholder from '../../assets/default_profile.png';
import {
  CheckBoxGroup,
  ContactNumber,
  FormButton,
  FormRow,
  Input,
  OrgSelector,
  Select,
  TextArea,
  AuthGuard,
  SponsorSelector, CheckBox
} from '../../components';
import { LocationSearch } from '../../components/location-search';
import { Form } from 'formik-antd';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useFormData } from '../../hooks/use-form-data';
import { ADD_ORG } from '../../graphql';
import { orgSchema } from './schema';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  canSelectOrgs,
  COUNTRIES,
  getActiveOrgId,
  getIsAdmin,
  getSelectedOrg,
  mapArrayToObject, ORG_TYPES,
  SA_PROVINCES
} from '../../utils';
import { get } from 'lodash';
import { cleanError } from '../../helpers/error-helper';
import { cameras, engagementStatuses, products, regions, schoolTypes, siteSpecs } from './ViewEdit';
import { useSponsorSuggestions } from '../../redux/config';

const provinces = mapArrayToObject(SA_PROVINCES)
const countries = mapArrayToObject(COUNTRIES)
const orgTypes = mapArrayToObject(ORG_TYPES)

const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  activeOrgId: useSelector(getActiveOrgId),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedOrg: useSelector(getSelectedOrg),
})

export const OrgAdd: FC = () => {

  const history = useHistory()

  const { isAdmin, selectedOrg, canSelectOrgs, activeOrgId } = useSelectors()

  const [handleAdd] = useMutation(ADD_ORG)

  const { fields, ...formikCTX } = useFormData(orgSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', duration: 10 });
      // TODO: re-implement policies
      const { attachPolicies, orgType, ...org } = values;
      const _orgType = !orgType.length ? undefined : orgType;
      return handleAdd({ variables: { id: org.id, newOrg: {
          ...org,
            orgType: _orgType
        }
      }})
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Organisation added!', duration: 2 });
      const orgId = get(result, 'data.addOrganisation.id');
      if (orgId) history.push('/org/' + orgId);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to add organisation'), duration: 4 });
      // presentError(e)
    }
  })

  const sponsorSuggestions = useSponsorSuggestions()

  const handleBack = useCallback(() => history.goBack(), [history]);

  return (
    <AuthGuard needsActiveOrg needsSelectOrgs>
    <Formik { ...formikCTX} enableReinitialize validateOnBlur>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Page title='Add Organisation' onBack={handleBack} card>
          <Form layout={'vertical'}>
            <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

            <FormRow gutter={6}>
              <Input {...fields.name} />
              <Input {...fields.primaryContactName} />
              <ContactNumber
                numberField={fields.primaryContactCell}
                countryCodeField={fields.primaryContactCellCountryCode}
              />
              <Input {...fields.primaryContactEmail} />
              <Input {...fields.headOfSchool} />
              <Input {...fields.headOfSport} />
              <LocationSearch {...fields.address} parentKey={'address'} />
              <Input {...fields.address.addressLine1} />
              <Select {...fields.address.country} options={countries} />
              <Select {...fields.address.state} options={provinces} />
              <Select {...fields.region} options={regions} allowClear disabled={!canSelectOrgs} />
              <OrgSelector hidden={!isAdmin} {...fields.parentOrgId}  />
              <SponsorSelector {...fields.sponsorId} />
              <Select {...fields.sponsorInfo} options={sponsorSuggestions} allowClear mode={'tags'} />
              <Select {...fields.orgType} options={orgTypes} defaultValue={'School'} />
              {/*Currently disabled because these policies arent in use*/}
              <CheckBoxGroup
                hidden
                {...fields.attachPolicies}
                options={['CanCreateOrganisation', 'CanCreateUser', 'CanCreateTeam']}
                gridProps={GRID_PROPS}
              />
              <Select {...fields.schoolType} options={schoolTypes} allowClear disabled={!canSelectOrgs} />
              <Select {...fields.siteSpecs} options={siteSpecs} allowClear mode={'tags'} />
              <Select {...fields.cameraType} options={cameras} disabled={!canSelectOrgs} />
              <Select {...fields.product} options={products} disabled={!canSelectOrgs} />
              <Input {...fields.serialNumber} />
              <Input {...fields.serialnumbertwo} />
              <CheckBox {...fields.testing} hidden={!isAdmin} gridProps={GRID_PROPS}>Test data</CheckBox>
              <TextArea {...fields.description} gridProps={GRID_PROPS} />
            </FormRow>

            <FormButton>ADD</FormButton>
          </Form>
        </Page>
      )}
    </Formik>
    </AuthGuard>
  );
};

OrgAdd.defaultProps = {};

export default OrgAdd;
