import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Input as ScoreInput } from 'antd'
import { useFixtureDetails } from '../../pages/fixture/ViewFixture'

const Cell = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const ScoreButton = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex: 1;

  color: ${ p => p.theme.primaryColor };
`

const Score = styled.span`
    font-size: 16px;
`

const Result = styled.span`
    font-size: 12px;
    font-weight: bold;
`

const Values = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 2;
`

const ScoreInputBase = styled(ScoreInput)`
    padding: 0;
    margin: 0;
  .ant-input-group-addon {
    border: none !important;
  }
`

export interface ScoreCellProps {
  onClick: (e?: any) => void,
  fixture: any
}

export const ScoreCell: React.FC<ScoreCellProps> = ({ children, fixture, onClick, ...props }) => {

    const {
        result,
        score
    } = useFixtureDetails(fixture);

  return (
      <Cell>
          <Values hidden={!score && !result}>
              <Score hidden={!score}>{score}</Score>
              <Result hidden={!result}>{result}</Result>
          </Values>
          <ScoreButton onClick={onClick}>
            {children}
          </ScoreButton>
      </Cell>
  )
}
