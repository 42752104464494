import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Empty, message, Skeleton } from 'antd';
import { FilterValues } from '../calendar';
import moment from 'moment';
import styled from 'styled-components';
import { useCurrentOrg, useSports } from '../../redux/config';
import { useAntTheme } from '../../hooks/use-ant-theme';
import { LoadedSport } from '../../redux';
import { Avatar } from '../Avatar';
import { getThumbnail } from '@bit/necta.hooks.s3/dist/s3-helpers';
import gql from 'graphql-tag';
import { fixtureQueryFields, teamsheetFieldsMin } from '../../graphql/fragments';
import { useIsParentOrAdmin } from '../../hooks';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { FieldTimeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Plan from '../../assets/plan.png';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';

const FIXTURES_TODAY = gql`
  query($status: String, $fixtureQuery: FixtureQuery!, $orgId: String) {
    fixtureSearch(status: $status, fixtureQuery: $fixtureQuery, orgId: $orgId) {
      ${fixtureQueryFields}
      homeTeam {
        ${teamsheetFieldsMin}
      }
      awayTeam {
        ${teamsheetFieldsMin}
      }
    }
  }
`

const Title = styled.div`
  b {
    font-size: 16px;
    color: ${p => p.theme.primaryColor};
    flex: 1;
  }
  font-size: 14px;
  margin: 10px;
`;

const FixturesOuter = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 420px;
  overflow-y: auto;
  height: 100%;
  .ant-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ListItem = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
  border-radius: 5px;
  padding: 10px;
  margin: 2px;
  color: white;
  line-height: 15px;
  background-color: ${p => p.color};
  cursor: pointer;
  .ant-avatar {
    margin-right: 5px;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
`;

const PageTitle = () => {
  return <Title><b>Fixtures Today</b><Right>{moment().format('dddd, Do MMMM YYYY')}</Right></Title>;
}

interface IProps {
  [x: string]: any;
}

export const DayCalendar: FC<IProps> = () => {

  const selectedOrg = useSelector(getSelectedOrg);

  const [fixtures, setFixtures] = useState<any[]>([]);

  const [fetch, { loading }] = useLazyQuery(FIXTURES_TODAY, {
    onCompleted: (result: any) => {
      if (result && result.fixtureSearch) {
        setFixtures(result.fixtureSearch)
      }
    },
    onError: (error: any) => {
      message.error({ content: 'Failed to load Fixtures!', duration: 4 })
    },
    fetchPolicy: 'network-only'
  })

  const handleFetch = useCallback((values: FilterValues = {}) => {
    fetch({
      variables: {
        fixtureQuery: {
          dateStart: moment().startOf('day').format(),
          dateEnd: moment().add(1, 'day').startOf('day').format()
        },
        status: 'Confirmed',
        orgId: selectedOrg?.id
      }
    })
  }, [fetch, selectedOrg]);

  const [sportList] = useSports();
  const [{ loaded }] = useAntTheme();

  useEffect(() => handleFetch(), [handleFetch]);

  if (loading) return (
    <Skeleton paragraph={{ rows: 5 }} />
  )

  if (!fixtures) return null;

  if (fixtures.length === 0) return <FixturesOuter><PageTitle /><Empty image={Plan} description={'No upcoming fixtures today'} /></FixturesOuter>

  return (
    <FixturesOuter>
      <PageTitle />
      <FixtureList fixtures={fixtures} organisation={selectedOrg} sportList={sportList} loaded={loaded} />
    </FixturesOuter>
  );
};

DayCalendar.defaultProps = {};

// fixtures = fixture list to display
// organisation = active or selected org
// sportList = useSports hook
// loaded = currently loaded theme from useAntTheme
// Org, SportList and loaded are passed in from parent to reduce lag
interface FixtureListProps {
  fixtures: any[],
  organisation: any,
  sportList: any[],
  loaded: any,
  onClick?: (fixture: any) => void,
}
export const FixtureList = ({ fixtures, organisation, sportList, loaded, onClick }: FixtureListProps) => {

  const history = useHistory();

  const getSport = useCallback((sportId: string) => {
    return sportList.find((sport: LoadedSport) => sport.id === sportId);
  }, [sportList]);

  const getColor = useCallback((sportId: string) => {
    const sport = getSport(sportId);
    const theme = sport ? loaded[sport.appTheme] : {};
    return theme?.['@secondary-color'];
  }, [getSport, loaded]);

  const getIcon = useCallback((sportId: string) => {
    return getThumbnail(getSport(sportId)?.appIconAlt);
  }, [getSport]);

  const getMyTeamName = useCallback((fixture: any) => {
    if (organisation?.id === fixture.homeOrgId) return fixture.homeTeam?.name;
    if (organisation?.id === fixture.awayOrgId) return fixture.awayTeam?.name;
    if (!fixture.homeTeam || !fixture.awayTeam) return fixture.homeOrgName + ' vs ' + fixture.awayOrgName;
    return fixture.homeTeam.name + ' vs ' + fixture.awayTeam.name;
  }, [organisation]);

  const handleClick = useCallback((fixture: any) => (e?: any) => {
    e.stopPropagation();
    if (onClick) return onClick(fixture);
    history.push('/fixture/' + fixture.id);
  }, [history, onClick]);

  return (
    <>
      {fixtures.map((f: any) => {
        return <ListItem color={getColor(f.sportId)} onClick={handleClick(f)}>
          <Avatar src={getIcon(f.sportId)} inverse />
          <Left>
            <b>{f.name}</b>
            <i>{getMyTeamName(f)}</i>
          </Left>
          <Right>
            <div><FieldTimeOutlined /> {moment(f.dateStart).format('HH:mm a')}</div>
          </Right>
        </ListItem>
      })}
    </>
  );
}

export default DayCalendar;
