import { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { FILE_ADDED, FILE_DELETED } from '../queries';
import { sortByDate } from '../../helpers/sort-helper';

export const useFileSubscription = (
  id: string,
  fileType?: 'IMAGE' | 'DOCUMENT' | null, // The type of file to monitor
  initialFiles?: any[],
  options?: {
    onAdded?: (result: any) => void;
    onDeleted?: (result: any) => void;
  },
) => {
  const [files, setFiles] = useState<any[]>([]);

  const onAdded = useSubscription(FILE_ADDED, {
    variables: { id, fileType },
    onSubscriptionData: (sub: any) => {
      try {
        const newFile = sub.subscriptionData.data.FileAdded;
        setFiles([...files, newFile]);
        if (options?.onAdded) options.onAdded(newFile);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  const onDeleted = useSubscription(FILE_DELETED, {
    variables: { id, fileType },
    onSubscriptionData: (sub: any) => {
      try {
        const removedFile = sub.subscriptionData.data.FileDeleted;
        const filteredFiles = files.filter((file: any) => file.id !== removedFile.id);
        setFiles([...filteredFiles]);
        if (options?.onDeleted) options.onDeleted(removedFile);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (initialFiles && initialFiles.length) setFiles([...sortByDate(initialFiles)]);
  }, [initialFiles]);

  return [files, onAdded, onDeleted] as const;
};
