import React, { useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ADD_TEAMSHEET } from '../../graphql'
import { CardWrapper, Avatar, statusColors } from '../../components'
import { UserOutlined } from '@ant-design/icons'
import { Fixture } from '../../redux'
import { TeamLink } from './TeamLink'
import { CoachLink } from './CoachLink'
import { Input, Form } from 'antd'
import { useHideActions } from '../../hooks'
import { useSelector } from 'react-redux'
import { getActiveOrg } from '../../utils'
import { getThumbnail } from '@bit/necta.hooks.s3'

const parseAddress = (address: any) => {
  if (!address || !address.addressLine1) return ''
  const city = address.city ?? address.town
  const state = address.state ?? address.province
  return `${address.addressLine1}, ${city}, ${state}`
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px 40px;

    @media only screen and (max-width: 600px) {
        padding: 10px;
    }
`

const colors: any = {
  Win: 'green',
  Lose: 'red',
  Draw: 'orange',
  'In Progress': 'orange'
}

interface ResultProps {
  color?: string
}

const Result = styled.div<ResultProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;

    span {
        width: 100px;
        text-align: center;
    }

    #result {
        ${ p => p.color && css`color: ${ p.color };` }
    }

`

const Labels = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
     h1 {
         width: 100px;
         text-align: center;
     }
`

const Status = styled.span<{ color: string, align?: 'left' | 'right' }>`
    color: ${ p => p.color };
    text-align: left;
    padding: 2px;
    @media only screen and (min-width: 499px) {
      ${ p => p.align && css`text-align: ${p.align};` };  
    }
`

const LargeStatus = styled(Status)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    width: 100%;
`

const Date = styled.p`
    font-size: 16px;
`

const Title = styled.h1`
    font-size: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    p.time, p.location {
        font-size: 14px;
        margin-bottom: 0;
        padding-bottom: 0;
        width: 150px;
        text-align: center;
    }

`

const About = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`

const TeamBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    font-size: 16px;
    width: 100%;
    
    p.title {
        font-weight: bold;
    }
    a {
        color: ${p => p.theme.primaryColor};
        :hover {
            cursor: pointer;
        }
    }
    
    @media only screen and (min-width: 499px) {
        width: 180px;
    
        &.right {
            p {
                text-align: right;
            }
        }
    
        &.right > a {
            text-align: right;
        }
    
    }
`

const TeamName = styled.p<{ align?: string }>`
  display: flex;
  align-items: center;
  .ant-avatar {
    margin-right: 5px;
  }
  @media only screen and (min-width: 499px) {   
    flex-direction: ${p => p.align === 'right' ? 'row-reverse' : 'row'};
    .ant-avatar {
      margin-left: ${p => p.align === 'right' ? '5px' : '0px'};
      margin-right: ${p => p.align === 'right' ? '0px' : '5px'};
    }
  }
`
const VS = styled.div`
  margin: 5px;
  font-style: italic;
  align-self: center;
  @media only screen and (max-width: 500px) {   
    margin: 20px 0;
    text-align: left;
    width: 100%;
  }
`;


export const useFixtureDetails = (fixture: Fixture) => {
  console.log(fixture)

  const homeOrgName = useMemo(() => {
    if (!fixture.homeOrgId && !fixture.homeOrgName) return 'no home org'
    if (fixture.homeOrgId) return fixture.homeOrg?.name
    return fixture.homeOrgName
  }, [fixture])
  const awayOrgName = useMemo(() => {
    if (!fixture.awayOrgId && !fixture.awayOrgName) return 'no away org'
    if (fixture.awayOrgId) return fixture.awayOrg?.name
    return fixture.awayOrgName
  }, [fixture])

  const homeTeamName = useMemo(() => {
    return fixture.homeTeam?.name || 'no team sheet'
  }, [fixture])
  const awayTeamName = useMemo(() => {
    return fixture.awayTeam?.name || 'no team sheet'
  }, [fixture])
  const address = useMemo(() => parseAddress(fixture.address), [fixture])

  const homeTeamImage = useMemo(() => fixture.homeTeam?.primaryImage || undefined, [fixture])
  const awayTeamImage = useMemo(() => fixture.awayTeam?.primaryImage || undefined, [fixture])

  const homeScore = useMemo(() => {
    if (!fixture.homeOrgScore) return 0
    let score = 0;
    try {
      score = parseInt(fixture.homeOrgScore)
    } catch (e) {
      console.error(e)
    } finally {
      return score
    }
  }, [fixture])

  const awayScore = useMemo(() => {
    if (!fixture.awayOrgScore) return 0
    let score = 0;
    try {
      score = parseInt(fixture.awayOrgScore)
    } catch (e) {
      console.error(e)
    } finally {
      return score
    }
  }, [fixture])

  const homeStatus = useMemo(() => {
    if (!fixture.homeOrgStatus) return 'Pending'
    return fixture.homeOrgStatus
  }, [fixture])
  const awayStatus = useMemo(() => {
    if (!fixture.awayOrgStatus && fixture.awayOrgId) return 'Pending'
    return fixture.awayOrgStatus
  }, [fixture])

  const result = useMemo(() => {
    if (fixture.result === 'W') {
      return 'Home Victory';
    }
    if (fixture.result === 'L') {
      return 'Away Victory';
    }
    if (fixture.result === 'D') return 'Draw'
    return ''
  }, [fixture])

  const status = useMemo(() => {
    return fixture.status
  }, [fixture])

  const score = useMemo(() => {
    return fixture.currentScore
  }, [fixture])

  return {
    homeOrgName,
    awayOrgName,
    homeTeamName,
    awayTeamName,
    homeTeamImage,
    awayTeamImage,
    address,
    score,
    homeScore,
    awayScore,
    result,
    homeStatus,
    awayStatus,
    status
  } as const
}

interface ViewFixtureProps {
  card?: boolean,
  fixture: Fixture,
  hidden?: boolean,
  hideActions?: boolean
}

export const ViewFixture: React.FC<ViewFixtureProps> = ({
  card,
  fixture,
  hidden,
  hideActions,
  ...props
}) => {

  const date = useMemo(() => moment(fixture.dateStart), [fixture])
  const dateEnd = useMemo(() => moment(fixture.dateEnd), [fixture])

  const activeOrg = useSelector(getActiveOrg)

  const {
    homeOrgName,
    awayOrgName,
    homeTeamName,
    awayTeamName,
    homeTeamImage,
    awayTeamImage,
    address,
    score,
    result,
    homeStatus,
    awayStatus
  } = useFixtureDetails(fixture)

  const hideHome = useHideActions(fixture.homeOrgId, fixture)
  const hideAway = useHideActions(fixture.awayOrgId, fixture)

  if (hidden) return null

  return (
    <CardWrapper
      card={card}
    >
      <Container>
        <Date>{date.format('dddd, MMM Do YYYY')}</Date>
        <Title>
          <p className='time'>{date.format('HH:mm')} - {dateEnd.format('HH:mm')}</p>
          Match
          { fixture.address && <p className='location'>{address}</p> }
        </Title>
        <About>
          <TeamBlock>
            <p className='title'>Home</p>
            <p>{homeOrgName}</p>
            <TeamName align='left'>
              <Avatar src={getThumbnail(homeTeamImage || '')} icon={<UserOutlined />} inverse />
              {' '}
              {homeTeamName}
            </TeamName>
            <Status color={statusColors[homeStatus]}>{homeStatus}</Status>
            <TeamLink
              id={fixture.homeTeamId}
              fixtureId={fixture.id}
              orgId={fixture?.homeOrgId}
              align='left'
              hidden={hideHome}
            />
            {/*<CoachLink*/}
            {/*  id={fixture.awayTeam?.headCoachId}*/}
            {/*  fixtureId={fixture.id}*/}
            {/*  teamsheetId={fixture.homeTeamId}*/}
            {/*  hidden={!fixture.homeTeamId || hideActions}*/}
            {/*/>*/}
          </TeamBlock>
          <VS>VS</VS>
          <TeamBlock className='right'>
            <p className='title'>Away</p>
            <p>{awayOrgName}</p>
            <TeamName align='right'>
              <Avatar src={getThumbnail(awayTeamImage || '')} icon={<UserOutlined />} inverse />
              {' '}
              {awayTeamName}
            </TeamName>
            <Status align='right' color={statusColors[awayStatus || 'P']}>{awayStatus}</Status>
            <TeamLink
              id={fixture.awayTeamId}
              fixtureId={fixture.id}
              orgId={fixture?.awayOrgId}
              align='right'
              hidden={hideAway}
            />
            {/*<CoachLink*/}
            {/*    id={fixture.awayTeam?.headCoachId}*/}
            {/*    fixtureId={fixture.id}*/}
            {/*    teamsheetId={fixture.awayTeamId}*/}
            {/*    hidden={!fixture.awayTeamId || hideActions}*/}
            {/*    align='right'*/}
            {/*/>*/}
          </TeamBlock>
        </About>
        <LargeStatus color={statusColors[fixture.status || 'Pending']}>
          <span>{fixture.status}</span>
        </LargeStatus>
        <Labels>
          <h1 hidden={!score}>Score</h1>
          <h1 hidden={!result}>Result</h1>
        </Labels>
        <Result color={colors[result]}>
          <span hidden={!score}>{score}</span>
          <span hidden={!result} id='result'>{result}</span>
        </Result>
      </Container>
    </CardWrapper>
  )
}
