import React, { FC, useCallback, useMemo, useState } from 'react';
import { Bar as AntBar } from '@ant-design/charts';
import styled from 'styled-components';
import { GraphData } from './types';
import { Empty } from 'antd';

const TitleOuter = styled.div`
  padding: 7px;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Subtitle = styled.div`
  font-size: 14px;
`;

const DEFAULT_CONF: any = {
  xField: 'value',
  yField: 'label',
  seriesField: 'label',
  legend: { position: 'bottom-left' },
};

interface IProps {
  data: GraphData[];
  config?: any;
  title?: string;
  subtitle?: string;
  selectedKeys?: any,
  loading?: boolean;
  [x: string]: any;
}

const Bar: FC<IProps> = ({ config = DEFAULT_CONF, data, title, subtitle, selectedKeys, loading }) => {

  const [width, setWidth] = useState(null);
  const div = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const finalConfig = useMemo(() => {
    const _config = {
      ...config,
      width,
      loading,
      data,
    };
    if (!_config.legend) _config.legend = {};
    if (selectedKeys) _config.legend.selected = selectedKeys;
    return _config;
  }, [config, data, width, loading, selectedKeys]);

  if (!finalConfig) return null;

  if (data.length <= 0 && !loading) return <>
    <TitleOuter style={{ textAlign: 'center' }}><Title>{title}</Title><Subtitle>{subtitle}</Subtitle></TitleOuter>
    <Empty style={{ marginBottom: 10 }} />
  </>

  return (
    <div ref={div} style={{ padding: 10 }}>
      <TitleOuter><Title>{title}</Title><Subtitle>{subtitle}</Subtitle></TitleOuter>
      <AntBar {...finalConfig} />
    </div>
  );
};

Bar.defaultProps = {};

export default Bar;
