import React, { useEffect, useMemo, useCallback, useState } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import moment, { Moment } from 'moment'
import {
  FormButton,
  FormRow,
  Page,
  Input,
  OrgSelector,
  OrgSelectOption as Option,
  DatePicker,
  ContactNumber,
  TextArea,
  Select, ProfilePictureUploader
} from '../../components'
import { useFormData, useIsParentOrAdmin } from '../../hooks';
import { Form } from 'formik-antd'
import { message, Collapse as Collapser, Col, Radio, Checkbox } from 'antd'
import { AuthGuard, CheckBox } from '../../components'
import { cleanError } from '../../helpers/error-helper';
import { SportSelector } from '../../components'
import { eventSchema } from './schema'
import { LocationSearch } from '../../components/location-search';
import { useCurrentOrg, useCurrentSport, LoadedOrg as Org } from '../../redux';
import { mapArrayToObject, SA_PROVINCES, COUNTRIES, canSelectOrgs, capitalize, getActiveOrg, getIsAdmin } from '../../utils'

import { useMutation } from '@apollo/client'
import { ADD_EVENT } from '../../graphql'
import { useSelector } from 'react-redux';
import PicPlaceholder from '../../assets/default_profile.png';

const countries = mapArrayToObject(COUNTRIES)
const provinces = mapArrayToObject(SA_PROVINCES)
const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };
const { Panel } = Collapser

const Participant = styled.h1`
  font-size: 14px;
  font-weight: 200;
`

const FULL_PROPS = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24
}

const Check = ({ hidden, children }: any) => {
  if (hidden) return null;
  return (
    <Col {...FULL_PROPS} style={{ marginBottom: 15 }}>
      {children}
    </Col>
  )
}

const cleanValues = (values: any) => {
  return {
    ...values,
    isHosting: undefined,
    isAway: undefined,
    showAwayOrgName: undefined,
    showHomeOrgName: undefined,
    time: undefined,
    homeOrgId: values.homeOrgId === 'other' ? undefined : values.homeOrgId,
    awayOrgId: values.awayOrgId === 'other' ? undefined : values.awayOrgId,
  }
}

export const EventAdd: React.FC<any> = (props) => {

  const history = useHistory()
  const [currentSport] = useCurrentSport()
  const activeOrg = useSelector(getActiveOrg)
  const isParentOrAdmin = useIsParentOrAdmin()

  const [addEvent, { error }] = useMutation(ADD_EVENT)

  const { fields, initialValues, ...formikCTX } = useFormData(eventSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating Event...', duration: 10 })
      ///if not parent, default owner to current user's active org

      const ownedById = !isParentOrAdmin ? activeOrg?.id : values.organiserId;
      const result = await addEvent({ variables: {
        newEventData: { ...cleanValues(values), ownedById }
      }})
      if (!error) actions.resetForm()
      if (result) {
        const eventId = result.data?.addEvent?.id
        if (!eventId) return result
        history.push(`/event/${eventId}`)
      }
      return result
    },
    onCompleted: (result: any) => {
        message.success({ content: 'New Event created!'})
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create Event'), duration: 4 });
    }
  })

  const [event] = useState({
    ...initialValues,
    organiserId: !isParentOrAdmin ? activeOrg?.id : undefined,
  })

  const getOwnerOrgOptions = useCallback((values: any) => (orgs: Org[]) => {
    const { showHomeOrgName, showAwayOrgName } = values;
    if (!isParentOrAdmin) return [];
    if (showHomeOrgName || showAwayOrgName) return [];
    const options = orgs.filter((org: Org) => {
      const passes = org.id === values.homeOrgId
        || org.id === values.awayOrgId
        || org.id === activeOrg?.id
      const isOther = org.id === 'other'
      return passes && !isOther
    })
    return options.map((org: Org) => org ? (
      <Option key={org.id} value={org.id} label={org.name}>{org.name}</Option>
    ) : null)
  }, [isParentOrAdmin, activeOrg])

  const handleTime = useCallback((startDate: string, setFieldValue: any) => (e: any) => {
    const endDate = moment(startDate)
    if (e.target.value === 'other') {
      setFieldValue('time', e.target.value)
      return
    }
    endDate.add(parseInt(e.target.value), 'hours')
    setFieldValue('dateEnd', endDate.format())
    setFieldValue('time', e.target.value)
  }, [])

  const updateTime = useCallback((time: string, setFieldValue: any) => (date: any, dateString: string) => {
    if (time === 'other') {
      return;
    }
    date.add(parseInt(time), 'hours')
    setFieldValue('dateEnd', date.format())
  }, [])

  const copyLocation = useCallback((values: any, setValue: any) => (e: any) => {
    if (e.target.checked) {
      setValue('address.addressLine1', activeOrg?.address?.addressLine1);
      setValue('address.country', activeOrg?.address?.country);
      setValue('address.state', activeOrg?.address?.state);
    } else {
      setValue('address.addressLine1', '');
      setValue('address.country', '');
      setValue('address.state', '');
    }
  }, [activeOrg]);

  const copyContact = useCallback((values: any, setValue: any) => (e: any) => {
    if (e.target.checked) {
      setValue('primaryContactCell', activeOrg?.primaryContactCell);
      setValue('primaryContactCellCountryCode', activeOrg?.primaryContactCellCountryCode);
      setValue('primaryContactEmail', activeOrg?.primaryContactEmail);
      setValue('primaryContactName', activeOrg?.primaryContactName)
    } else {
      setValue('primaryContactCell', '');
      setValue('primaryContactCellCountryCode', '');
      setValue('primaryContactEmail', '');
      setValue('primaryContactName', '')
    }
  }, [activeOrg]);

  return (
    <AuthGuard
      needsActiveOrg
    >
      <Formik { ...formikCTX} initialValues={event} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors, setErrors }) => (
          <Page
            title='Add Event'
            card
          >
            <Form layout={'vertical'}>

              <Collapser
                defaultActiveKey={[
                    'event',
                    'contact',
                    'time',
                    'location'
                ]}
                ghost
              >

                <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

                <Panel key='event' header='Event Information'>
                    <FormRow>
                        <Input
                          placeholder='e.g. CRICKET: U19A JEPPE VS U19C KES'
                          { ...fields.name }
                        />
                         <Input
                          placeholder='e.g. CRICKET: U19A JEPPE VS U19C KES'
                          { ...fields.club }
                        />
                        <Input
                          placeholder='e.g. The Dome'
                          { ...fields.venue }
                        />
                         <Input
                          placeholder='Sport Type'
                          { ...fields.sportType }
                        />
                        <OrgSelector
                          hidden={!isParentOrAdmin}
                          {...fields.organiserId}
                          // getOptions={getOwnerOrgOptions(values)}
                        />
                        <TextArea
                          gridProps={FULL_PROPS}
                          {...fields.description}
                        />
                    </FormRow>
                </Panel>

                <Panel key='time' header='Time'>
                  <FormRow>
                    <Col {...FULL_PROPS}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <Participant>Duration:</Participant>
                        <Radio.Group
                          value={values.time}
                          options={[
                            { label: '8 hours', value: '8' },
                            { label: '1 day', value: '24' },
                            { label: '2 days', value: '48' },
                            { label: '3 days', value: '72' },
                            { label: 'Other', value: 'other'}
                          ]}
                          onChange={handleTime(values.dateStart, setFieldValue)}
                          optionType='button'
                          style={{ marginBottom: '30px' }}
                        />
                      </Col>
                    </Col>

                    <DatePicker
                      format='MMM Do YYYY, HH:mm'
                      { ...fields.dateStart }
                      showTime={{ format: 'HH:mm' }}
                      onChange={updateTime(values.time, setFieldValue)}
                    />
                    <DatePicker
                      format='MMM Do YYYY, HH:mm'
                      disabled={values.time !== 'other'}
                      { ...fields.dateEnd }
                      showTime={{ format: 'HH:mm' }}
                    />
                  </FormRow>
                </Panel>

                <Panel key='location' header='Location'>
                  <FormRow>
                    <Check hidden={isParentOrAdmin}>
                      <Checkbox onChange={copyLocation(values, setFieldValue)}>Same as My Organisation</Checkbox>
                    </Check>
                    <LocationSearch { ...fields.address} parentKey='address' />
                    <Input {...fields.address.addressLine1} />
                    <Select {...fields.address.country} options={countries} />
                    <Select {...fields.address.state}  options={provinces} />
                  </FormRow>
                </Panel>

                <Panel key='contact' header='Contact Information'>
                  <FormRow>
                    <Check hidden={isParentOrAdmin}>
                      <Checkbox onChange={copyContact(values, setFieldValue)}>Same as My Organisation</Checkbox>
                    </Check>
                    <Input { ...fields.primaryContactName } />
                    <ContactNumber
                      numberField={fields.primaryContactCell}
                      countryCodeField={fields.primaryContactCellCountryCode}
                    />
                    <Input { ...fields.primaryContactEmail } />
                    <CheckBox {...fields.requestBroadcasting} gridProps={GRID_PROPS}>Request Broadcasting</CheckBox>
                  </FormRow>
                </Panel>

              </Collapser>

              <FormButton>
                ADD
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}


export default EventAdd
