import { ActionTypes } from './actions'
import { isEmpty } from 'lodash'
import { LoadedOrg, LoadedSport, ConfigState, LoadedSponsor, LoadedEvent } from '../types'
import { getDefaultRoleOverrides } from '../../utils/get-default-role-overrides'

/**
 * The default value for a non selected sport config
 */
const DefaultSport: LoadedSport = {
    id: '',
    name: 'PlayPro',
    createdBy: '',
    appTheme: 'DEFAULT',
    appIcon: '_resources/sports/playpro.png',
    appIconAlt: '_resources/sports/playpro-alt.png',
    description: '',
    isVerified: false,
    teamRoles: null
}

const DefaultSponsor: LoadedSponsor = {
    id: '',
    name: 'None',
}

const DefaultEvent: LoadedEvent = {
    id: '',
    name: 'None',
}

/**
 * The default value for config state.
 * Each field defaults to it's corresponding default value
 * if set to a falsy value.
 */
const DefaultState = {
    sports: [DefaultSport],
    sponsors: [DefaultSponsor],
    events: [DefaultEvent],
    currentSport: DefaultSport,
    currentOrg: null,
    tableConfig: {},
    roleOverrides: null
}

/**
 * The config state reducer
 */
export const config = (state: ConfigState = DefaultState, { type, payload }: any) => {
    switch (type) {

        case ActionTypes.SET_CONFIG:
            return { ...payload }

        case ActionTypes.SET_CURRENT_SPORT:
            return getNewCurrentSportState(state, payload)

        case ActionTypes.SET_SPORTS:
            return getNewSportsState(state, payload)

        case ActionTypes.SET_CURRENT_ORG:
            return getNewCurrentOrgState(state, payload)

        case ActionTypes.SET_CURRENT_TABLE_CONFIG:
            return getNewTableConfigState(state, payload)

        case ActionTypes.SET_SPONSORS:
            return getNewSponsorState(state, payload)

        case ActionTypes.SET_EVENTS:
            return getNewEventState(state, payload)

        default:
            return state
    }
}

//return new state with updated currentSport. Defaults to currentState if invalid.
const getNewCurrentSportState = (state: ConfigState, payload: LoadedSport) => {
    //check if new currentSport matches any loaded sport. If payload === null, will default to Default Config.
    const name = payload ? payload.name : 'PlayPro'
    const sport = payload ? payload : state.sports.find((sport: LoadedSport) => sport.name === name)
    if (sport) return { ...state, currentSport: sport }
    console.warn('sport config for', JSON.stringify(payload), 'not found.')
    return state
}

/**
 * returns a new state slice with updated sports configs
 * @param state the current config state
 * @param payload the new sports config array
 */

const getNewSportsState = (state: ConfigState, payload: LoadedSport[]) => {
    if (Array.isArray(payload)) {
        const defaultRoleOverrides = getDefaultRoleOverrides(payload)
        return {
            ...state,
            sports: [ DefaultSport, ...payload ],
            roleOverrides: defaultRoleOverrides
        }
    }
    console.warn('invalid sports payload at config.SET_SPORTS',
                    JSON.stringify(payload, null, 2))
    return state
}

//return new state with updated currentOrg. Defaults to null if value is falsy.
const getNewCurrentOrgState = (state: ConfigState, payload: string) => {
    if (payload) return { ...state, currentOrg: payload }
    return { ...state, currentOrg: null };
}

/**
 * returns a new config state slice with updated table config.
 * @param state the current state of the config slice
 * @param payload the new table config
 */
const getNewTableConfigState = (state: ConfigState, payload: any) => {
    if (payload)
        return {
            ...state,
            tableConfig: getNewTableConfig(state.tableConfig, payload)
        }
    return {}
}

/**
 * returns a new table config based on payload content
 * @param prevConfig the previous table config
 * @param payload the new table config
 */
const getNewTableConfig = (prevConfig: any, payload: any) => {
    if (isEmpty(payload)) return  { ...prevConfig }
    return { prevConfig, ...payload }
}

const getNewSponsorState = (state: ConfigState, payload: LoadedSponsor[]) => {
    if (isEmpty(payload)) return state
    return { ...state, sponsors: payload }
}

const getNewEventState = (state: ConfigState, payload: LoadedEvent[]) => {
    // if (payload == null) return state
    return { ...state, events: payload }
}
