/**
 * A Function that capitalizes the first letter of a string.
 * @param word The word that should be capitalized.
 * @returns A string where the first letter has been capitalize.
 */
export const capitalize = (word?: string) => {
    if (!word) return ''
    return word.charAt(0).toUpperCase() + word.substring(1)
}

/**
 * A Function that determines whether a given word is 1. a word and
 * 2. a capitalizable word as per the english dictionary.
 * @param word The word to be tested.
 */
export const isCapWord = (word: string) => {
    if (!word) return false
    return true
}

/**
 * A reducer function that reduces an array of words to a string of capitalized words.
 * Can be used as the first parameter to string[].reduce(). the second parameter would be an empty string.
 * @param msg The accumulating string.
 * @param word The current word in the words array.
 * @param i The index of the current words in the word array.
 * @param words The array of words to reduce.
 * @returns A string containing the accumulator message concatinated with
 * the current word in the words array.
 */
export const reduceWords = (msg: string, word: string, i: number, words: string[]) => {
    const isWord = isCapWord(word)
    const appendix = (i !== words.length - 1 && isWord) ? ' ' : ''
    return msg.concat(capitalize(word), appendix)
}

/**
 * A Function that capitalizes each word in a given string.
 * @param msg The string for which each word should be capitalized.
 * @returns A string where all the words have been capitalized.
 */
export const toCaps = (msg: string) => {
    if (!msg) return ''
    const words = msg.split(' ')
    return words.reduce(reduceWords, '')
}