import * as Yup from 'yup'

export const importTeamsheetMembersSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .required('First name is required')
    .label('First Name'),
  lastname: Yup
    .string()
    .required('Last name is required')
    .label('Last Name'),
  nickname: Yup
    .string()
    .nullable()
    .notRequired()
    .label('Nick Name'),
  description: Yup
    .string()
    .nullable()
    .notRequired()
    .label('Description'),
  primaryImage: Yup
    .string()
    .nullable()
    .notRequired()
    .label('Picture'),
  tags: Yup
    .array()
    .nullable()
    .notRequired()
    .nullable()
    .label('Tags'),
  teamRole: Yup
    .string()
    .nullable()
    .notRequired()
    .meta(['select'])
    .label('Role'),
  teamRoleAlt: Yup
    .string()
    .nullable()
    .notRequired()
    .meta(['select'])
    .label('Alternative Role'),
  isHeadCoach: Yup
    .boolean()
    .nullable()
    .notRequired()
    .label('Head Coach'),
  isCaptain: Yup
    .boolean()
    .nullable()
    .notRequired()
    .label('Team Captain'),
  isPlayer: Yup
    .boolean()
    .nullable()
    .notRequired()
    .label('Player'),
  isCoach: Yup
    .boolean()
    .nullable()
    .notRequired()
    .label('Coach')
})

export const confirmTeamsheetMembersImportSchema = Yup.object().shape({
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
})