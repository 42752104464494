import React, { useState, useCallback } from 'react';
import { cloneDeep } from 'lodash'
import {
  Page,
  AuthGuard,
  FormLoading
} from '../../components';
import { Moment } from 'moment'
import { EventRequestTable } from './EventRequestTable'
import { useFetchEventList } from '../../graphql'
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';

interface EventListProps {
}

export const EventRequestList: React.FC<EventListProps> = ({ ...props }) => {

  const selectedOrg = useSelector(getSelectedOrg);

  const [events, setEvents] = useState<any[]>([])
  const [fetch, { loading }] = useFetchEventList({
    variables: { eventQuery: { organiserId: selectedOrg?.id  }},
    onCompleted: (result: any) => {
      if (result && result.events) setEvents(cloneDeep(result.events))
    },
    onError: (error: any) => {
      message.error({ content: 'Something went wrong!\nCould not load events...', duration: 4 })
    },
    fetchPolicy: 'network-only'
  })

  if (loading) return <FormLoading />

  return (
    <AuthGuard needsActiveOrg>
      <Page card>
        <EventRequestTable withUpdate onRefresh={fetch} loading={loading} events={events} />
      </Page>
    </AuthGuard>
  )
};

export default EventRequestList;