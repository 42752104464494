import React, { useCallback, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FormLoading, NotFound, Page, AuthGuard, RefreshAction } from '../../components'
import { useFetchUserByShortid } from '../../graphql'
import { Button, Space, Tabs, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { EditProfile, UserTeamList, EditSportInfo, LinkedOrganisations, UserFixtureList } from './components';
import FileUploader from '../../components/file-uploader';
import { useSelector } from 'react-redux';
import { getIsAdmin } from '../../utils';
import { ReloadOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

interface Props {
  mode: 'view' | 'edit'
}

export const User: React.FC<Props> = ({ mode }) => {
  const { shortid }: any = useParams()

  const history = useHistory()
  const [edit] = useState(true)

  const [user, setUser] = useState<any>(null);

  const isAdmin = useSelector(getIsAdmin);

  const [fetchUser, { loading }] = useFetchUserByShortid({
    variables: { shortid },
    onCompleted: (result: any) => {
      setUser({ ...result.userByShortid });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const handleRefresh = useCallback((e?: any) => fetchUser({ variables: { shortid } }), [shortid, fetchUser])

  const handleBack = useCallback(() => history.goBack(), [history]);

  const userType = useMemo(() => {
    if (user?.isPlayer) return 'Player';
    if (user?.isCoach) return 'Coach';
    return 'User';
  }, [user])

  if (loading) return <FormLoading />;

  if (!user || isEmpty(user)) {
    return <NotFound subTitle={'Unable to find user'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard
      needsActiveOrg
    >
      <Page title={`${edit ? 'Edit' : 'View'} ${ userType }`} onBack={handleBack} card extra={[
          <RefreshAction onClick={handleRefresh} />
        ]}>
        <Tabs defaultActiveKey={'Info'} tabPosition={'top'} size={'large'}>
          <TabPane tab={`${userType} Info`} key={`Info`}>
            <EditProfile user={user} edit={edit} />
          </TabPane>
          <TabPane tab={'Sport Data'} key={'Sport Data'}>
            <EditSportInfo user={user} />
          </TabPane>
          <TabPane tab={'Teams'} key={'Teams'}>
            <UserTeamList user={user} />
          </TabPane>
          <TabPane tab={'Fixtures'} key={'Fixtures'}>
            <UserFixtureList user={user} />
          </TabPane>
          {
            isAdmin && <TabPane tab={'Organisations'} key={'Organisations'}>
              <LinkedOrganisations user={user} refresh={handleRefresh} />
            </TabPane>
          }
          <TabPane tab={'Documents'} key={'Documents'}>
            <FileUploader id={user.id} parentType={'user'} fileType={'DOCUMENT'} initialFiles={user.documents} />
          </TabPane>
          <TabPane tab={'Media'} key={'Media'}>
            <FileUploader id={user.id} parentType={'user'} initialFiles={user.images} />
          </TabPane>
        </Tabs>
      </Page>
    </AuthGuard>
  )
}
