import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar, Table, Page, Filter, Sort, NotFound, FormLoading, TeamRole } from '../../components';
import {
  Button,
  Card,
  Col, Divider,
  Empty,
  message,
  PageHeader,
  Popconfirm,
  Radio,
  Result, Row,
  Skeleton,
  Space,
  Tooltip
} from 'antd';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { ARCHIVE_TEAM_MEMBER, useFetchTeam } from '../../graphql';
import { useParams } from 'react-router-dom';
import { compact, isEmpty, orderBy } from 'lodash';
import { UserOutlined, BorderOutlined, MenuOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useSports } from '../../redux/config';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { cleanError } from '../../helpers/error-helper';
import { TeamCards } from './components';
import { Team as TeamT } from '../../redux';
import styled from 'styled-components';

const Name = styled.span`
  margin-left: 5px;
`
export const Team: React.FC = () => {

  const history = useHistory()

  const { shortid }: any = useParams()

  const [team, setTeam] = useState<any>(null);

  const [fetchTeam, { loading }] = useFetchTeam({
    variables: { id: shortid },
    onCompleted: (result: any) => {
      setTeam({ ...result.team });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const [removeTeamMember, { loading: removing }] = useMutation(ARCHIVE_TEAM_MEMBER);

  const handleRemove = useCallback((id: string) => async (e?: any) => {
    e.stopPropagation();
    if (removing) return;
    message.loading({ content: 'Removing team member...', duration: 10 });
    try {
      await removeTeamMember({ variables: { id } })
      const _team = { ...team };
      _team.teamMembers = team?.teamMembers.filter((t: any) => t.id !== id);
      setTeam(_team);
      message.success({ content: 'Team member has been removed', duration: 2 })
    } catch (ex) {
      //console.log(ex);
      message.error({ content: cleanError(ex, 'Unable to remove team member'), duration: 3 })
    }
  }, [team, setTeam, removeTeamMember, removing])

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam])

  const handleBack = useCallback(() => history.push('/team/list'), [history]);

  const handleAddMember = useCallback(() => history.push(`/team/${shortid}/member/add`), [history]);

  const handleEdit = useCallback(() => history.push(`/team/edit/${shortid}`), [history]);

  const hasTeam = useMemo(() => team && !isEmpty(team), [team]);

  const [mode, setMode] = useState('Cards');
  const handleSetMode = useCallback((e: any) => setMode(e?.target?.value), [setMode])

  if (loading) return <FormLoading />;

  if (!hasTeam) {
    return <NotFound subTitle={'Unable to find team'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <Page card>
      <PageHeader
        style={{width: '100%'}}
        title={team.name}
        extra={<Space>
          {/*<Button onClick={handleEdit}>Edit Team</Button>*/}
          <Tooltip title={'Change Display Mode'}>
            <Radio.Group
              value={mode}
              onChange={handleSetMode}
              options={[
                { label: <BorderOutlined />, value: 'Cards' },
                { label: <MenuOutlined />, value: 'List' }
              ]}
              optionType='button'
            />
          </Tooltip>
          <Tooltip title="Refresh">
            <Button type="dashed" shape="circle" onClick={handleRefresh} icon={<ReloadOutlined />} />
          </Tooltip>
          <Tooltip title={'Edit Team'}>
            <Button shape="circle" icon={<EditOutlined />} onClick={handleEdit} />
          </Tooltip>
          <Tooltip title={'Add Member'}>
            <Button shape="circle" type="primary" icon={<PlusOutlined />} onClick={handleAddMember} />
          </Tooltip>
        </Space>}
        onBack={handleBack}
      />
      <TeamTable team={team} handleRemove={handleRemove} loading={loading} removing={removing} hidden={mode !== 'List'} />
      <TeamCards team={team} handleRemove={handleRemove} removing={removing} hidden={mode !== 'Cards'} />
    </Page>
  )
};

interface TeamTableProps {
  team: TeamT,
  handleRemove: (id: string) => (e?: any) => void,
  loading?: boolean,
  removing?: boolean,
  hidden?: boolean
}
const TeamTable = ({ team, handleRemove, loading, removing, hidden }: TeamTableProps) => {

  const history = useHistory();

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  const [sportList] = useSports()

  const { teamRoles } = useMemo(() => sportList.find((sport: any) => sport.id === get(team, 'sportId')) || [], [sportList, team])

  const columns = useMemo(() => {
    const allTeamRoles = compact((teamRoles || []).map((tr: any) => tr.id !== '' && Object.assign({ text: tr.name, value: tr.name})));
    const filters = {
      filters: [ ...allTeamRoles ],
      filterMultiple: true,
      type: Filter.SELECT
    }
    return [
      {
        title: '',
        key: 'primaryImage',
        export: false,
        width: 40,
        render: (r: any) => <Avatar src={getThumbnail(r)} icon={<UserOutlined />} inverse />
      },
      {
        title: 'Primary Role',
        filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
        sorting: true,
        getValues: (r: any) => r,
        key: 'teamRole.name',
        render: (r: any, rec: any) => <><TeamRole row={rec} /><Name>{r}</Name></>
      },
      {
        title: 'First Name',
        filtering: true,
        sorting: true,
        key: 'firstname'
      },
      {
        title: 'Last Name',
        filtering: true,
        sorting: true,
        key: 'lastname',
      },
      {
        title: 'Nickname',
        filtering: true,
        sorting: true,
        key: 'nickname',
      },
      {
        title: 'Secondary Role',
        filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
        sorting: true,
        key: 'teamRoleAlt.name'
      },
      { title: '', key: 'id', render: (id: string) =>
          <Popconfirm
            title='Are you sure you want to remove this team member?'
            onConfirm={handleRemove(id)}
            okButtonProps={{ loading: removing }}
            onCancel={handleCancel}
            okText='Yes'
            cancelText='No'
          >
            <Button onClick={handleCancel} type='link' danger>Remove</Button>
          </Popconfirm>
      },
    ];
  }, [teamRoles, handleRemove, handleCancel]);

  const handleNavigate = useCallback((record: any) => (e: any) => {
    e.stopPropagation();
    history.push(`/team/member/edit/${record.id}`)
  }, [history]);

  if (hidden || !team) return null;

  return (
    <Table
      data={team.teamMembers || []}
      columns={columns}
      loading={loading}
      onRow={(record: any, rowIndex: any) => ({
        onClick: handleNavigate(record)
      })}
      // refresh={handleRefresh}
      exportTitle={team.name}
    />
  );
}

export default Team;
