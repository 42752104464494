import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Table,
} from '../../components';
import moment, { Moment } from 'moment'
import styled from 'styled-components';
import { useCurrentOrg } from '../../redux/config';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { PictureOutlined } from '@ant-design/icons';

// const statuses = mapArrayToOptions(EVENT_STATUSES);

interface EventTableProps {
  events: any[],
  loading?: boolean,
  onRefresh?: () => void,
  withUpdate?: boolean
}

export const EventTable: React.FC<EventTableProps> = ({
  events,
  loading,
  onRefresh,
  withUpdate,
  ...props
}) => {

  const history = useHistory();
  const [selectedOrg] = useCurrentOrg()

  const columns = useMemo(() => {
    return [
      {
        title: 'Picture',
        key: 'primaryImage',
        export: false,
        width: 40,
        render: (r: any) => <Avatar src={getThumbnail(r)} icon={<PictureOutlined/>} inverse />
      },
      {
        title: 'Event',
        export: true,
        key: 'name',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Venue',
        export: true,
        key: 'venue',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Club',
        export: true,
        key: 'club',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Sport Type',
        export: true,
        key: 'sportType',
        filtering: true,
        sorting: true,
      },
      // {
      //   title: 'Status',
      //   key: 'status',
      //   hidden: true,
      //   filtering: {
      //     filters: statuses,
      //     filterMultiple: true,
      //     type: Filter.SELECT
      //   },
      //   sorting: true,
      //   getValues: (r: string) => r,
      //   render: (r: string) => <EventStatus status={r} />,
      //   export: true
      // },
      {
        title: 'Contact Name',
        export: true,
        key: 'primaryContactName',
        filtering: true,
        show: false,
      },
    
      
      {
        title: 'Contact Cell',
        export: true,
        key: 'primaryContactCellFull',
        filtering: true,
        show: false,
      },
      {
        title: 'Contact Email',
        export: true,
        key: 'primaryContactEmail',
        filtering: true,
        show: false,
      },
      {
        title: 'Date Start',
        key: 'dateStart',
        sorting: true,
        render: (r: any) => moment(r).format('MMMM, Do - HH:mm')
      },
      {
        title: 'Date End',
        key: 'dateEnd',
        sorting: true,
        render: (r: any) => moment(r).format('MMMM, Do - HH:mm')
      },
      {
        title: 'Total Fixtures',
        key: 'fixtures',
        sorting: true,
        export: false,
        render: (r: any) => r?.length ?? '0'
      },
      {
        title: 'Shortid',
        export: true,
        key: 'shortid',
        hidden: true,
        filtering: true,
        sorting: true,
      },
     
    ];
  }, []);

  const extra = useMemo(() => selectedOrg?.id ? `- ${selectedOrg?.name}` : '', [selectedOrg]);

  return (
    <Table
      title={`All Events ${extra}`}
      data={events}
      columns={columns}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/event/${record.id}`)
      })}
      refresh={onRefresh}
      exportTitle={'Teams'}
      loading={loading}
    />
  );

};

export default EventTable;
