import React, { FC } from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IFilterProps } from '../../helpers/filters';

interface ITextFilterProps {
  col: any;
  setSearchInput?: any;
}

const TextFilter: FC<ITextFilterProps & IFilterProps> = (props) => {
  const { setSearchInput, col, setSelectedKeys, selectedKeys, clearFilters, confirm } = props;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => setSearchInput && setSearchInput(node)}
        placeholder={`Search ${col.title}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

TextFilter.defaultProps = {};

export default TextFilter;
