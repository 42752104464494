import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Tag as AntTag } from 'antd';
import { LoadedSport, useSports } from '../../../redux';
import { useAntTheme, useScrollTo } from '../../../hooks';
import { SportInfo } from './types';

const MORE: SportInfo = {
  sport: {id: 'more', name: 'More...', appIcon: '', appTheme: '' },
  id: 'more',
  teamRoleId: '',
  teamRoleAltId: '',
  tags: [],
  accolades: ''
};

const Tag = styled(AntTag)<{color?: string, small?: boolean}>`
  border-color: ${p => p.color};
  color: ${p => p.color};
  padding: ${p => p.small ? '0 7px' : '2px 12px'} !important;
  margin: ${p => p.small ? '1px' : '5px'} !important;
  cursor: pointer;
`

interface TagsProps {
  sportInfo: SportInfo[];
}

export const SmallSportTags: FC<TagsProps> = ({ sportInfo }) => {
  return <>
    { sportInfo.slice(0, 3).map((s: any) => <SportTag sportInfo={s} small />) }
    { sportInfo.length >= 4 && <SportTag sportInfo={MORE} small />}
  </>
}

interface SportTagProps {
  sportInfo: SportInfo;
  scroll?: boolean; // if click event should trigger a scroll
  onClick?: (id: string) => void; // callback event for when tag is clicked
  small?: boolean; // if the tag is small or not
}

export const SportTag: FC<SportTagProps> = ({ sportInfo, scroll, onClick, small }) => {
  const [sportList] = useSports();
  const [{ loaded }] = useAntTheme();
  const handleScroll = useScrollTo();

  const _getSportTheme = useCallback((sportId: string) => {
    const sport = sportList.find((sport: LoadedSport) => sport.id === sportId)
    return sport ? loaded[sport.appTheme] : {};
  }, [sportList, loaded]);

  const theme = useMemo(() => _getSportTheme(sportInfo.sport.id), [_getSportTheme, sportInfo]);

  const _handleClick = useCallback((id: string) => (e: any) => {
    if (onClick) onClick(id);
    if (scroll) handleScroll(id)(e);
  }, [onClick, scroll, handleScroll]);

  return (
    <Tag
      color={theme['@secondary-color']}
      onClick={_handleClick(sportInfo.sport.name + '-form')}
      small={small}
    >
      {sportInfo.sport.name}
    </Tag>
  );
};

SportTag.defaultProps = {
  small: false
};

export default SportTag;
