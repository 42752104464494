import React, { Component, ErrorInfo } from 'react'
import styled from 'styled-components'
import { Button as AntButton, message } from 'antd'
import Result from 'antd/lib/result'
import { captureException } from '@sentry/react'


const Button = styled(AntButton)`

`

export interface FallbackProps {
    info: ErrorInfo,
    error: Error
    componentStack: string | null;
}

export type Fallback = React.ReactNode | ((props: FallbackProps) => React.ReactNode)

export interface ErrorBoundaryProps {
    maxRestartAttempts: number,
    children: React.ReactElement | React.ReactElement[] | React.ReactNode,
    shouldRestart?: boolean,
    silent: boolean,
    width?: number,
    fallback?: Fallback,
    triggerOnCatch?: boolean,
    disabled?: boolean,
    style?: React.CSSProperties
}

export interface ErrorBoundaryState {
    collapsed?: boolean
    info: any,
    error: any,
    hasError: boolean,
    hasReported: boolean,
    restartAttempts: number
}

type State = ErrorBoundaryState
type Props = ErrorBoundaryProps


/**
 * An Error boundary component that catches react stack errors. Includes an optional fallback prop.
 * The fallback prop is either a ReactNode or a function that returns a ReactNode
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {

    static defaultProps = {
      children: null,
      silent: false,
      shouldRestart: false,
      maxRestartAttempts: 5,
      disabled: false
    };

    constructor(props: Props) {
      super(props);
      this.componentDidCatch = this.componentDidCatch.bind(this);
      this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    state: State = {
      info: null,
      error: null,
      hasError: false,
      hasReported: false,
      restartAttempts: 0,
    };

    toggleCollapse() {
      this.setState((prevState: State) => ({
        ...prevState,
        collapsed: !prevState.collapsed,
      }));
    }

    componentDidCatch(error: Error, info: any) {
      const { shouldRestart, maxRestartAttempts, triggerOnCatch, disabled } = this.props;
      if (disabled) return
      const { restartAttempts } = this.state;
      this.setState({
        hasError: true,
        error,
        info,
      });

      if (triggerOnCatch) captureException(error)

      if (shouldRestart && (restartAttempts < maxRestartAttempts)) {
        setTimeout(() => {
          console.warn('Component encountered an error. Attempting to restart...');
          this.setState(prevState => ({
            ...prevState,
            hasError: false,
            restartAttempts: prevState.restartAttempts + 1,
          }));
        }, 150);
      }
    }

    render() {
      const { children, silent, fallback, disabled, style } = this.props;
      const { error, info, hasReported }  = this.state
      const componentStack = info?.componentStack

      const getExtra = (hasReported: boolean) => hasReported ? [] : (
        [
          <Button onClick={(e: any) => {
            message.open({ type: 'success', duration: 2, content: 'The error has been reported!'})
            this.setState({ ...this.state, error: null, hasError: false, hasReported: true })
          }}>
            report
          </Button>
        ]
      )

      if (disabled) return children

      if (this.state.hasError) {
        if (fallback) {
            if (typeof fallback === 'function') return !silent ? fallback({ error, info, componentStack }) : null
            return !silent ? fallback : null
        }

        return !silent ? (
          <Result
            style={style}
            status="error"
            title={error.message}
            subTitle="Click below to let us know!"
            extra={getExtra(hasReported)}
          />

        ) : null;
      }
      return children;
    }

  }



  export default ErrorBoundary;