import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  Input,
  TextArea, Tags, CheckBox, TeamRoleSelector, NotFound, UserSelector, FormLoading,
  AuthGuard
} from '../../components'
import { useFormData, Yup } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import { PageHeader, message, Skeleton } from 'antd'
import { teamMemberSchema } from './schema';
import { get, isEmpty } from 'lodash';
import { ADD_TEAM_MEMBER, useFetchTeam } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';

const finalSchema = teamMemberSchema.shape({
  teamId: Yup
    .string()
    .required()
    .label('Team'),
  userId: Yup
    .string()
    .required()
    .label('User'),
})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const TeamAddMember: React.FC<any> = (props) => {
  const { shortid }: any = useParams()

  const history = useHistory()

  const [handleAdd] = useMutation(ADD_TEAM_MEMBER)

  const [team, setTeam] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const [fetchTeam, { loading }] = useFetchTeam({
    variables: { id: shortid },
    onCompleted: (result: any) => {
      setTeam({ ...result.team });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Adding team member...', duration: 10 });
      return handleAdd({ variables: { newTeamMemberData: { ...values } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team member added!', duration: 2 });
      history.push('/team/' + shortid)
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to add team member'), duration: 4 });
    }
  })
  const [teamMember, setTeamMember] = useState({ ...initialValues, teamId: shortid });

  const handleBack = useCallback(() => history.push('/team/' + shortid), [history]);

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam])

  const hasTeam = useMemo(() => team && !isEmpty(team), [team]);

  const handleUserSelected = useCallback((setFieldValue: any) => (_user: any) => {
    if (!_user || (user && (user.isPlayer !== _user.isPlayer || user.isCoach !== _user.isCoach))) {
      setFieldValue('isCaptain', false);
      setFieldValue('isHeadCoach', false);
      setFieldValue('teamRoleId', '');
      setFieldValue('teamRoleAltId', '');
    }
    if (_user) {
      setFieldValue('firstname', _user.firstname);
      setFieldValue('lastname', _user.lastname);
      setUser(_user);
    } else {
      setUser(null);
      setFieldValue('firstname', '');
      setFieldValue('lastname', '');
    }
  }, [setUser, teamMember, user]);

  const isPlayer = useMemo(() => user && user.isPlayer, [user]);
  const isCoach = useMemo(() => user && user.isCoach, [user]);

  if (loading) return <FormLoading />;

  if (!shortid || !hasTeam) {
    return <NotFound subTitle={'Unable to find team'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={teamMember} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title='Add Team Member' onBack={handleBack} card>
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <UserSelector placeholder='Type to search...' {...fields.userId} onChange={handleUserSelected(setFieldValue)} filters={{ organisationId: team.organisationId }}/>
                <Input {...fields.firstname} disabled={!user} />
                <Input {...fields.lastname} disabled={!user} />
                <Input {...fields.nickname} disabled={!user} />
                <TeamRoleSelector sport={team.sportId} disabled={!user} isPlayer={isPlayer} isCoach={isCoach} {...fields.teamRoleId} />
                <TeamRoleSelector sport={team.sportId} disabled={!user} isPlayer={isPlayer} isCoach={isCoach} {...fields.teamRoleAltId} />
                <Tags isEditable={false} {...fields.tags} gridProps={GRID_PROPS} />
                <CheckBox {...fields.isCaptain} disabled={!isPlayer} gridProps={GRID_PROPS}>Team Captain</CheckBox>
                <CheckBox {...fields.isHeadCoach} disabled={!isCoach} gridProps={GRID_PROPS}>Head Coach</CheckBox>
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              <FormButton>
                ADD
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default TeamAddMember
