import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Col, ColProps, Row, RowProps } from 'antd/lib/grid'
import { default as CardBase, CardProps } from 'antd/lib/card'

const FakeCard = styled.div`
  padding: 5px;
  border-radius: 2px;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface WidgetProps {
  path?: string;
  disabled?: boolean;
  cardProps?: CardProps;
  onClick?: (e?: any) => void
}

export const WidgetCard: React.FC<WidgetProps & ColProps> = ({ onClick, cardProps = {}, children, path, disabled, hidden, ...props }) => {

  const history = useHistory()

  const handleOnClick = useCallback(() => {
    if (onClick) onClick()
    if (path) history.push(path)
  }, [path]);

  if (hidden) return null;

  return (
    <Col {...props}>
      <FakeCard onClick={handleOnClick}>
        {children}
      </FakeCard>
    </Col>
  )
}

WidgetCard.defaultProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 8,
  disabled: false,
  hidden: false
}

const Card = styled(CardBase)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

interface Props {
  path?: string;
  disabled?: boolean;
  Icon?: React.ReactNode;
  title?: string;
  cardProps?: CardProps;
  onClick?: (e?: any) => void;
  gridProps?: ColProps;
}

export const DashCard: React.FC<Props & ColProps> = ({
    children,
    onClick,
    cardProps = {},
    title = '',
    Icon,
    path,
    disabled,
    gridProps,
    ...props
}) => {

    const history = useHistory()

    const handleOnClick = useCallback(() => {
        const _onClick = (e: any) => {
            if (onClick) onClick()
            if(path) history.push(path)
        }
        if (!!path || !!onClick) return _onClick
        return undefined
    }, [path, onClick, history])

    return (
        <Col {...props} {...gridProps}>
            <Card {...cardProps} hoverable onClick={handleOnClick()}>
                { Icon || null }
                { title && <span>{title}</span> }
                { children }
            </Card>
        </Col>
    )
}

DashCard.defaultProps = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 8,
    disabled: false,
    hidden: false
}

const GridOuter = styled(Row)`
  padding: 20px 40px 40px 40px;
  @media only screen and (max-width: 1100px) {
    padding: 20px 20px 40px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px 30px 10px;
  }
  width: 100%;
`

type DashGridProps = RowProps

export const DashGrid: React.FC<DashGridProps> = ({ gutter, children }) => {

    return (
        <GridOuter justify="center" gutter={gutter}>
            { children }
        </GridOuter>
    )
}

DashGrid.defaultProps = {
    gutter: 12,
    justify: 'center'
}
