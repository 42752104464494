import React from 'react'
import moment from 'moment'
import { Modal, Button } from 'antd'
import { Moment } from 'moment'
import { Fixture } from '../../redux'
import { FixtureTable } from './FixtureTable'
import useWindowWidth from '@bit/necta.hooks.use-window-width/dist';

interface FixturesModalProps {
    fixtures: Fixture[],
    show: boolean,
    onClose: () => void,
    date: Moment
}

export const FixturesModal: React.FC<FixturesModalProps> = ({ date, fixtures, show, onClose, ...props }) => {
    const [width] = useWindowWidth()
    return <Modal
        title={`Fixture list on ${moment(date).format('MMM Do')}`}
        visible={show}
        closable={false}
        footer={<Button onClick={onClose}>Close</Button>}
        width={width - 500}
    >
        <FixtureTable fixtures={fixtures} />
    </Modal>
}

export default FixturesModal
