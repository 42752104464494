import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Space, Tooltip } from 'antd'
import styled, { css } from 'styled-components'
import  { Moment } from 'moment'
import { Button, Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { RangeValue } from './types'
import { SportSelector } from '../sport-selector'
import { LoadedSport } from '../../redux'
import { O_DIRECTORY } from 'constants'

const { Item: FormItem } = Form

interface FilterProps {
    initialValues?: FilterValues,
    onChange?: (values: FilterValues) => void,
    onSubmit?: (values: FilterValues) => void,
    values?: FilterValues,
    hidden?: boolean
}

export interface FixtureSearch {
    id: string,
    name: string,
    createdBy: string,
    isVerified: boolean,
    archived: boolean,
    description: string,
    dateStart: Moment | null,
    dateEnd: Moment | null,
    sportId: string,
    homeOrgId: string,
    awayOrgId: string,
    ownedById: string,
    homeOrgName: string,
    awayOrgName: string,
    homeOrgScore: string,
    awayOrgScore: string,
    homeTeamId: string,
    awayTeamId: string,
    result: string,
    status: string,
}

export type FilterValues = Partial<FixtureSearch>

const Bar = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 20px;
`

const Container = styled(Space)`
    width: inherit;
    height: inherit;
    margin-bottom: 40px;
`

const Search = styled(Button)<{$active?: boolean}>`
    &.ant-btn {
        ${p => p.$active ? css`color: ${p.theme.primaryColor};` : '' };
    }
`

export const CalendarFilter: React.FC<FilterProps> = ({ 
    initialValues,
    values: $values, 
    onSubmit: $onSubmit,
    onChange: $onChange,
    children,
    hidden,
    ...props 
}) => {

    const [values, setValues] = useState<FilterValues>(initialValues ?? {
    })

    const dirty = useRef<boolean>(false)

    useEffect(() => {
        if ($onChange) $onChange(values)
    }, [values])

    const onChange = useCallback(
        (e?: any) => {
            const newValues = { ...values, [e.target.name]: e.target.value}
            setValues(newValues)
            if (!dirty.current) dirty.current = true
        },
        [setValues, values]
    )

    const onSportChange = useCallback((sport: LoadedSport) => {
        if (!dirty.current) dirty.current = true
        setValues(
            (values: FilterValues) => ({ ...values, sportId: sport?.id })
        )
    }, [setValues])

    const filterOptions = useCallback((sportList: LoadedSport[]) => (
        sportList.map((sport: LoadedSport) => {
            if (sport.name === 'PlayPro') return { ...sport, name: 'All' }
            return sport
        })
    ), [])

    //wrapper around onSubmit prop
    const onSubmit = useCallback((e: any) => {
        e.preventDefault()
        if ($onSubmit) $onSubmit(values)
        if (dirty.current) dirty.current = false
    }, [$onSubmit, values])

    //override local state if state provided
    useEffect(() => {
        if ($values) setValues($values)
    }, [$values])

    if (hidden) return (
        <Bar>
            <Container>
                { children }
            </Container>
        </Bar>
    )

    return (
        <Bar onSubmit={onSubmit}>
            <Container>
                <Tooltip title='search by Fixture name'>
                    <Input name='name' placeholder='Fixture Name...' value={values.name} onChange={onChange} />
                </Tooltip>
                <Tooltip title='filter by Sport'>
                    <SportSelector placeholder='Sport...' value={values.sportId} filterOptions={filterOptions} onChange={onSportChange} />
                </Tooltip>
            </Container>
            { children }
        </Bar>
    )
}
