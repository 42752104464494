import { useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Actions } from './redux'
import { parseKey } from './utils'

export * from './redux'

const selector = (state: any) => state.theme

export const loadThemes = (store: any, themes: any) => {
    store.dispatch(Actions.loadThemes(themes))
}

export const useSetAntTheme = () => {
    const dispatch = useDispatch()
    return useCallback((theme: string) => dispatch(Actions.toggleTheme(theme)), [dispatch])
}

export const useJSThemeObject = () => {
    const { active, loaded } = useSelector(selector)
    return useMemo(() => {
        const current = loaded[active]
        return (Object.keys(current || {}) as any).reduce((obj: any, curr: string) => ({ ...obj, [parseKey(curr)]: current[curr] }), {})
    }, [active, loaded])
}

export const useToggleTheme = () => {
    const { active, loaded } = useSelector(selector)
    const setAntTheme = useSetAntTheme()

    const themes = useMemo(
        () => Object.keys(loaded).reduce((obj: any, curr: string) => ({ ...obj, [curr]: curr }), {}),
        [loaded]
    )

    const toggleTheme = useCallback((theme: string) => {
        if ((Object.keys(themes) as any).includes(theme)) setAntTheme(theme)
    }, [setAntTheme, themes])

    return [
        toggleTheme,
        {
            active,
            keys: themes
        }
    ] as const
}

export const useAntTheme = () => {
    const { loaded } = useSelector(selector)
    const [toggleTheme, { keys, active }] = useToggleTheme()
    const themeObject = useJSThemeObject()
    useEffect(() => {
        (window as any).less.modifyVars(loaded[active])
    }, [active, loaded])

    return [
        {
            theme: themeObject,
            active,
            loaded,
            keys
        },
        toggleTheme
    ] as const
}


export const useLoadThemes = (Themes: any) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(Actions.loadThemes(Themes))
    }, [])
}
