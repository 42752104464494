import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Calendar as Cal} from 'antd'
import { Moment } from 'moment'
import { CardWrapper } from '../CardWrapper'
import { Fixture } from '../../redux'
import { CalendarProps } from 'antd/lib/calendar/generateCalendar'

export * from './CalendarFilter'
export * from './Cell'
export * from './CellTip'
export * from './use-calendar'

interface CalendarComponentProps extends CalendarProps<Moment> {
    className?: string,
    style?: React.CSSProperties,
    card?: boolean,
    title?: React.ReactNode,
    extra?: React.ReactNode[],
    loading?: boolean,
    fullScreen?: boolean,
    onPanelChange?: (values: any) => void,
}

//TODO: review component flow and potential cell modal wrapping
export const CalendarComponent: React.FC<CalendarComponentProps> = ({
    extra,
    title,
    card,
    headerRender,
    style,
    className,
    dateCellRender,
    onSelect,
    value,
    loading,
    fullScreen,
    onPanelChange,
    ...props
}) => {


    const styleProps = !card ? { style, className } : {}

    return (
        <CardWrapper
            card={card}
            title={title}
            style={style}
            className={className}
            extra={extra}
            loading={loading}
        >
            <Cal
                {...styleProps}
                value={value}
                headerRender={headerRender}
                dateCellRender={dateCellRender}
                onSelect={onSelect}
                fullscreen={fullScreen}
                onPanelChange={onPanelChange}
            />
        </CardWrapper>
    )

}

export const Calendar = styled(CalendarComponent)`
    width: 92%;
`

export * from './MobileCalendar';
