import { useMutation } from "@apollo/client";
import { fieldNameFromStoreName } from "@apollo/client/cache";
import { Span } from "@sentry/tracing";
import {
  Button,
  Collapse as Collapser,
  message,
  Popconfirm,
  Tabs,
  Checkbox 
} from "antd";
import { CheckBox } from '../../components'
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Form } from "formik-antd";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import PicPlaceholder from "../../assets/default_profile.png";
import {
  AuthGuard,
  ContactNumber,
  DatePicker,
  FormButton,
  FormLoading,
  FormRow,
  Input,
  LocationSearch,
  NotFound,
  OrgSelector,
  Page,
  ProfilePictureUploader,
  Select,
  Table,
  TextArea
} from "../../components";
import {
  ARCHIVE_EVENT,
  UPDATE_EVENT,
  useFetchEvent,
  useFetchThirdPartyProvidersLinkedToEvent
} from "../../graphql";
import { cleanError } from "../../helpers/error-helper";
import { useFormData, useHideActions, useIsParentOrAdmin } from "../../hooks";
import { COUNTRIES, mapArrayToObject, SA_PROVINCES, BROADCAST_STATUS } from "../../utils";
import { FixtureTable } from "../fixture";
import { eventSchema } from "./schema";

const { TabPane } = Tabs;

const countries = mapArrayToObject(COUNTRIES);
const provinces = mapArrayToObject(SA_PROVINCES);
const broadcastRequests = mapArrayToObject(BROADCAST_STATUS);

const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };


const Save = styled(FormButton)`
  margin-top: 30px;
`;

const { Panel } = Collapser;

const FULL_PROPS = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24,
};

const packValues = (values: any) => {
  const {
    id,
    shortid,
    createdBy,
    organiser,
    hasPassed,
    primaryContactCellFull,
    fixtures,
    ...updateEvent
  } = values;

  return updateEvent;
};

const ThirdPartyProvidersTable: FC<{ eventId: string }> = ({ eventId }) => {
  const {data, refetch, loading} = useFetchThirdPartyProvidersLinkedToEvent(
    {
      variables: { id: eventId },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const history = useHistory();

  const columns = [
    {
      title: "Provider Name",
      filtering: true,
      sorting: true,
      key: "thirdPartyProviderName",
    },
    {
      title: "Registration Number",
      filtering: true,
      sorting: true,
      key: "registrationNumber",
    },
    {
      title: "Vat Number",
      filtering: true,
      sorting: true,
      key: "vatNumber",
    },
  ];

  return (
    <Table
      data={data == null || data.event == null || data.event.thirdPartyProviders == null ? [] : data.event.thirdPartyProviders}
      columns={columns}
      loading={loading}
      onRow={(record: any) => ({
        onClick: () =>{
          history.push(`/third-party-provider/view-edit/${record.id}`)
        },

      })}
      refresh={() => refetch()}
      title={"Third Party Providers"}
      exportTitle={"ThirdPartyProviders"}
    />
  );
};

export const Event: React.FC<any> = (props) => {
  const history = useHistory();
  const { id }: any = useParams();
  const [event, setEvent] = useState<any>(null);

  const [handleUpdate, { loading: uploading }] = useMutation(UPDATE_EVENT);
  const [visible, setVisible] = useState(false);

  const [cancelEvent, { loading: cancelling }] = useMutation(ARCHIVE_EVENT, {
    variables: { eventId: id, accept: false },
    onCompleted: (result: any) => {
      setVisible(false);
      message.success({ content: "Event Cancelled", duration: 4 });
      history.push("/event/list");
    },
    onError: (err: any) => {
      setVisible(false);
      message.error({ content: "Failed to cancel event", duration: 4 });
    },
  });

  const [, { loading }] = useFetchEvent(id, {
    onCompleted: (result: any) => {
      if (result && result.event) {
        const { dateStart, dateEnd }: any = result.event;
        setEvent({
          ...result.event,
          dateStart: moment(dateStart),
          dateEnd: moment(dateEnd),
        });
      }
    },
  });

  const { fields, initialValues, ...formikCTX } = useFormData(eventSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: "Updating Event...", duration: 10 });
      const updateEvent = packValues(values);
      const result = await handleUpdate({
        variables: {
          id,
          updateEvent,
        },
      });
      return result;
    },
    onCompleted: (result: any) => {
      if (result && result.event) setEvent({ ...event, ...result.event });
      message.success({ content: "Event updated!", duration: 4 });
    },
    onError: (e: any) => {
      message.error({
        content: cleanError(e, "Unable to view Event"),
        duration: 4,
      });
    },
  });

  const showPopconfirm = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleBack = useCallback(() => history.goBack(), [history]);
  const handleCancelClick = useCallback(() => cancelEvent(), [cancelEvent]);
  const handleClosePopconfirm = useCallback(
    () => setVisible(false),
    [setVisible]
  );

  const hideActions = useHideActions(undefined, event);
  const isParentOrAdmin = useIsParentOrAdmin();

  if (loading) return <FormLoading />;

  if (!event) return <NotFound subTitle={"Unable to find event"} />;

  return (
    <AuthGuard needsActiveOrg>
      <Page title={event.name} onBack={handleBack} card>
        <Tabs
          defaultActiveKey={"event-data"}
          tabPosition={"top"}
          size={"large"}
        >
          <TabPane tab={`Event Data`} key={`event-data`}>
            <Formik
              {...formikCTX}
              initialValues={event}
              enableReinitialize
              validateOnBlur
            >
              {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                <Form layout={"vertical"}>
                  <Collapser
                    defaultActiveKey={["event", "time", "contact", "location"]}
                    ghost
                  >
                    <ProfilePictureUploader
                      disabled={isSubmitting}
                      fieldKey="primaryImage"
                      defaultPic={PicPlaceholder}
                    />

                    <Panel key="event" header="Event Information">
                      <FormRow disabled={hideActions}>
                        <Input {...fields.name} />
                        <Input placeholder="e.g. The Dome" {...fields.venue} />
                        <Input placeholder="" {...fields.sportType} />
                        <Input placeholder="" {...fields.club} />
                        <OrgSelector
                          hidden={!isParentOrAdmin}
                          {...fields.organiserId}
                          // getOptions={getOwnerOrgOptions(values)}
                        />
                        <TextArea
                          gridProps={FULL_PROPS}
                          {...fields.description}
                        />
                      </FormRow>
                    </Panel>

                    <Panel key="time" header="Time">
                      <FormRow disabled={hideActions}>
                        <DatePicker
                          format="MMM Do YYYY, HH:mm"
                          {...fields.dateStart}
                          showTime={{ format: "HH:mm" }}
                        />
                        <DatePicker
                          format="MMM Do YYYY, HH:mm"
                          {...fields.dateEnd}
                          showTime={{ format: "HH:mm" }}
                        />
                      </FormRow>
                    </Panel>

                    <Panel key="location" header="Location">
                      <FormRow disabled={hideActions}>
                        <LocationSearch
                          {...fields.address}
                          parentKey="address"
                        />
                        <Input {...fields.address.addressLine1} />
                        <Select
                          {...fields.address.country}
                          options={countries}
                        />
                        <Select {...fields.address.state} options={provinces} />
                        
                      </FormRow>
                    </Panel>

                    <Panel key="contact" header="Contact Information">
                      <FormRow disabled={hideActions}>
                        <Input {...fields.primaryContactName} />
                        <ContactNumber
                          numberField={fields.primaryContactCell}
                          countryCodeField={
                            fields.primaryContactCellCountryCode
                          }
                        />
                        <Input {...fields.primaryContactEmail} />
                        <CheckBox {...fields.requestBroadcasting} gridProps={GRID_PROPS}>Request Broadcasting</CheckBox>
                      </FormRow>
                    </Panel>
                  </Collapser>

                  <Save
                    hidden={hideActions}
                    loading={uploading}
                    disabled={cancelling}
                  >
                    SAVE
                  </Save>
                </Form>
              )}
            </Formik>
          </TabPane>
          <TabPane tab={`Broadcasting Requests`} key={`eventrequests-data`}>
            <Formik
              {...formikCTX}
              initialValues={event}
              enableReinitialize
              validateOnBlur
            >
              {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                <Form layout={"vertical"}>
                 
                      <FormRow disabled={hideActions}>
                 
                        <Select {...fields.broadcastRequest} options={broadcastRequests} />
          
                      </FormRow>
                    
                  

                  <Save
                    hidden={hideActions}
                    loading={uploading}
                    disabled={cancelling}
                  >
                    SAVE
                  </Save>
                </Form>
              )}
            </Formik>
          </TabPane>
          <TabPane tab={`Fixtures`} key={`fixtures`}>
            <FixtureTable fixtures={event.fixtures} />
          </TabPane>
          <TabPane tab={`Providers`} key={`providers`}>
            <ThirdPartyProvidersTable eventId={id} />
          </TabPane>

          {!hideActions && (
            <TabPane tab="Settings" key="settings">
              <p>Cancel event:</p>
              <Popconfirm
                title="Are you sure you want to cancel this event? Fixtures won't be cancelled."
                visible={visible}
                onConfirm={handleCancelClick}
                okButtonProps={{ loading: cancelling }}
                onCancel={handleClosePopconfirm}
              >
                <Button type={"primary"} danger onClick={showPopconfirm}>
                  Cancel Event
                </Button>
              </Popconfirm>
            </TabPane>
          )}
        </Tabs>
      </Page>
    </AuthGuard>
  );
};


export default Event;
