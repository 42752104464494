import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Result, Collapse as Collapser } from 'antd'
import { SportInfoForm } from './SportInfoForm'
import { SportTags } from './SportTags'
import { User, SportInfo } from './types'
import { useSportInfoSubscription } from '../../../graphql/hooks';
import { LoadedSport, useSports } from '../../../redux'
import NotFoundSvg from '../../../assets/404.svg';

const { Panel } = Collapser

const Collapse = styled(Collapser)`
    width: 100%;
`

interface EditSportInfoProps {
    user: User
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
`

export const EditSportInfo: React.FC<EditSportInfoProps> = ({ user, ...props }) => {

    const [sportInfo] = useSportInfoSubscription(user.id, user.sportInfo)
    const [sportList] = useSports()

    const defaultActiveKey = useMemo(() => sportList.map((sport: LoadedSport) => sport.id), [sportList])

    return <Container className='something'>
        <SportTags userId={user.id} value={sportInfo} />
        { sportInfo.length <= 0 &&
            <Result
              title='No sport info yet'
              subTitle={'Click the plus icon to add sport information'}
              icon={<img src={NotFoundSvg} alt={'No sport info'}/>}
              // TODO: call to action button
            />
        }
        <Collapse defaultActiveKey={defaultActiveKey} ghost>
        { sportInfo.map((sportInfo: SportInfo) => (
            <Panel header={sportInfo.sport.name} key={sportInfo.sport.id}>
                <SportInfoForm sportInfo={sportInfo} />
            </Panel>
        ))}
        </Collapse>
    </Container>
}
