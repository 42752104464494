import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DayCalendar, AuthGuard, Page, Pie, WidgetCard, DashGrid, UpdateFixtureWidget, Stat } from '../components';
import { useCurrentUser } from '@bit/necta.hooks.current-user';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { useCurrentOrg, useSports } from '../redux/config';
import { getActiveOrg, getIsAdmin } from '../utils';
import { useAntTheme, useIsParentOrAdmin } from '../hooks';
import { PendingFixtureTable } from './fixture';
import { LoadedSport } from '../redux';

const DashPage = styled(Page)`
  justify-content: flex-start;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  h1 {
    font-weight: bold;
    color: ${p => p.theme.primaryColor};
  }
`;

const smallGridProps = { md: 8, lg: 8, xl: 8, xxl: 8 };
const largeGridProps = { md: 16, lg: 16, xl: 16, xxl: 16 };
const fullGridProps = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

const params = [
  {
    id: "kzISCya-c",
    key: "playerCount",
    params: {}
  },
  {
    id: "-9Fw6mUDX",
    key: "coachCount",
    params: {}
  },
  {
    id: "ssUTSewm7",
    key: "teamStats",
    params: {}
  },
  {
    id: "cTGUWC_HO", // Count X
    key: "fixtureCountHome",
    params: {
      x: 'fixture',
      orgField: 'homeOrgId'
    }
  },
  {
    id: "cTGUWC_HO", // Count X
    key: "fixtureCountAway",
    params: {
      x: 'fixture',
      orgField: 'awayOrgId'
    }
  },
  {
    id: "Q3egWdNKQ", // Count X by Y
    key: "orgCounts",
    params: {
      x: 'organisation',
      y: 'contractStatus',
      orgField: 'parentOrgId',
      overrideOrgId: null,
      filters: { orgType: 'School' }
    }
  }
];

const QUERY = gql`
    query($q: [WidgetQuery!]!, $o: String) {
        widgets(widgetQueries: $q, organisationId: $o) {
            id
            key
            name
            data
        }
    }
`;

export const Dashboard: React.FC<any> = props => {
  return (
    <AuthGuard checkForOrg>
      <DashboardInner />
    </AuthGuard>
  );
};

export const DashboardInner: React.FC<any> = props => {
  const [currentOrg] = useCurrentOrg();
  const [user] = useCurrentUser();
  const canSelectOrgs = useIsParentOrAdmin()
  const isAdmin = useSelector(getIsAdmin);
  const activeOrg = useSelector(getActiveOrg);
  // const canSelectOrgs = useSelector(canSelectOrgs);

  const [data, setData] = useState<any>({
    teamStats: [],
    playerCount: [],
    coachCount: [],
    fixtureHomeTotal: 0,
    fixtureAwayTotal: 0,
    teamTotal: 0,
    playerTotal: 0,
    coachTotal: 0
  });

  const overridenParams = useMemo(() => {
    const _params = [ ...params ]
    if (!isAdmin && activeOrg) {
      const found = _params.find((p: any) => p.key === 'orgCounts')
      if (found) found.params.overrideOrgId = activeOrg.id
    }

    return _params;
  }, [isAdmin, activeOrg])

  const [fetchData, { loading }] = useLazyQuery(QUERY, {
    // TODO: org selection
    variables: { q: overridenParams, o: currentOrg?.id },
    fetchPolicy: 'network-only',
    onCompleted: (result: any) => {
      if (result?.widgets) {
        const newData = {
          teamStats: get(findWidget('teamStats', result.widgets), 'data.breakdown', []),
          playerCount: get(findWidget('playerCount', result.widgets), 'data.breakdown', []),
          coachCount: get(findWidget('coachCount', result.widgets), 'data.breakdown', []),
          fixtureHomeTotal: get(findWidget('fixtureCountHome', result.widgets), 'data.count', 0),
          fixtureAwayTotal: get(findWidget('fixtureCountAway', result.widgets), 'data.count', 0),
          teamTotal: get(findWidget('teamStats', result.widgets), 'data.total', 0),
          playerTotal: get(findWidget('playerCount', result.widgets), 'data.total', 0),
          coachTotal: get(findWidget('coachCount', result.widgets), 'data.total', 0),
          orgCounts: get(findWidget('orgCounts', result.widgets), 'data.totals', []),
          // assetCount: get(findWidget('assetCount', result.widgets), 'data.count', 0),
        };
        setData({ ...newData });
      }
    },
  });

  const findWidget = useCallback((key: string, widgets: any[]) => widgets.find((w: any) => w.key === key), []);

  const baseOrg = useMemo(() => (canSelectOrgs ? currentOrg : user?.activeOrg), [canSelectOrgs, currentOrg, user]);

  const policies = useMemo(() => (baseOrg?.policies || []).map((policy: any) => policy.name) ?? [], [baseOrg]);

  const hasPolicy = useCallback((policy: string) => policies.includes(policy), [policies]);

  const [sportList] = useSports();
  const [{ loaded }] = useAntTheme();

  const getColour = useCallback((sportName: string) => {
    if (sportName === 'other') return '#a5a5b0';
    const sport = sportList.find((sport: LoadedSport) => sport.name === sportName)
    const theme = sport ? loaded[sport.appTheme] : {};
    return theme?.['@secondary-color'];
  }, [sportList, loaded]);

  const config = useMemo(() => Object.assign({
    color: ({ label }: any) => {
      return getColour(label);
    }
  }), [getColour]);

  const orgTotal = useMemo(() => {
    if (!data || !data.orgCounts) return []
    return data.orgCounts.reduce((acc: number, curr: any) => acc += curr.value, 0)
  }, [data])

  console.log(data.orgCounts)

  useEffect(() => fetchData(), [fetchData]);

  if (!user) return null;

  return (
    <DashPage>
      <DashGrid gutter={[8, 8]}>
        <WidgetCard {...smallGridProps} hidden={!canSelectOrgs}>
          <Stats>
            <h1>All Schools Summary</h1>
            <Stat title='Total' value={orgTotal} loading={loading} />
            { data.orgCounts?.map((o: any) => o.label !== 'null' && <Stat title={o.label} value={o.value} loading={loading} />)}
          </Stats>
        </WidgetCard>
        <WidgetCard>
          <Pie data={data.teamStats} title={'Teams'} subtitle={'by sport'} loading={loading} extraConfig={config} useColor />
        </WidgetCard>
        <WidgetCard>
          <Pie data={data.playerCount} title={'Players'} subtitle={'by sport'} loading={loading} extraConfig={config} useColor />
        </WidgetCard>
        <WidgetCard {...largeGridProps}>
          <DayCalendar />
        </WidgetCard>
        <WidgetCard {...smallGridProps}>
          <Stats>
            <h1>Statistics</h1>
            <Stat title='Players' value={data.playerTotal} loading={loading} />
            <Stat title='Coaches' value={data.coachTotal} loading={loading} />
            <Stat title='Teams' value={data.teamTotal} loading={loading} />
            <Stat title='Home Fixtures' value={data.fixtureHomeTotal} loading={loading} />
            <Stat title='Away Fixtures' value={data.fixtureAwayTotal} loading={loading} />
          </Stats>
        </WidgetCard>
        <WidgetCard {...fullGridProps}>
          <UpdateFixtureWidget />
        </WidgetCard>
        <WidgetCard {...fullGridProps}>
          <PendingFixtureTable size='small' />
        </WidgetCard>
      </DashGrid>
    </DashPage>
  );
};

export default Dashboard;
