// Query partials or fragments for reused data patterns

export const scoreResultFields = `
      id
      fixtureId
      description
      value
      exactTimestamp
`

// TODO: add secondary image after added to backend
export const sponsorFields = `
  id
  name
  primaryImage
  secondaryImage
  description
`;

export const fixtureQueryFields = `
  id
  name
  createdBy
  isVerified
  archived
  description
  dateStart
  dateEnd
  sportId
  homeOrgId
  awayOrgId
  ownedById
  homeOrgName
  awayOrgName
  homeOrg {
    id
    name
  }
  awayOrg {
    id
    name
  }
  homeOrgScore
  awayOrgScore
  homeTeamId
  awayTeamId
  result
  currentScore
  status
`

export const teamsheetMemberFields = `
  id
  shortid
  firstname
  nickname
  lastname
  primaryImage
  secondaryImage
  description
  teamRoleId
  teamRole {
   id
   name
  }
  teamRoleAltId
  teamRoleAlt {
   id
   name
  }
  createdBy
  isHeadCoach
  isCaptain
  archived
  userId
  isPlayer
  isCoach
`


export const teamFields = `
  id
  name
  primaryImage
  description
  organisationId
  sportId
  captainId
  headCoachId
  ageGroup
`;

export const teamRoleFields = `
  id
  name
  description
  isForCoach
  isForPlayer
  isVerified
`;

export const locationFields = `
  addressLine1
  addressLine2
  country
  state
  postalCode
  buildingName
  addressType
  name
  complexName
  postalCode
  town
  door
  floor
  country
  longitude
  latitude
  description
  fullAddress
`;


export const teamMemberFields = `
  id
  firstname
  lastname
  nickname
  primaryImage
  secondaryImage
  description
  teamRoleId
  teamRoleAltId
  tags
  isHeadCoach
  isCaptain
  isPlayer
  isCoach
  userId
`;

// All data fields for a user
// If we change the usage of id then we will create a "safe" fragment containing
// Non-sensitive data (eg no id/role/active org)
// Backend may also cherry-pick some data out later
export const userFields = `
  id
  email
  firstname
  lastname
  contactNumberCountryCode
  contactNumber
  contactNumberFull
  parentContactNumberCountryCode
  parentContactNumber
  parentContactNumberFull
  shortid
  dateOfBirth
  profilePicUrl
  isPlayer
  isCoach
  qualifications
  certifications
  bio
  height
  weight
  shoeSize
  csports
  language
  aimobile
  aiop
  scorer
  camop
  producer
  experience
  clinear
  ccomments
  cregion
  role
  activeOrgId
  createdAt
  medicalAidScheme
  medicalAidNumber
  idNumber
  passportNumber
`;

export const organisationFields = `
  id
  name
  primaryContactName
  primaryContactCell
  primaryContactCellCountryCode
  headContactCellCountryCode
  headOfSportContactCellCountryCode
  itContactCellCountryCode
  groundsContactCellCountryCode
  marketingContactCellCountryCode
  primaryContactCellFull
  itContactCell
  marketingContactCell:
  itContactCell
  headOfSportContactCell
  groundsContactCell
  headContactCell
  primaryContactEmail
  headContactEmail
  headOfSportContactEmail
  itContactEmail
  groundsContactEmail
  marketingContactEmail
  ownedById
  description
  primaryImage
  primaryImageAlt
  primaryImageIcon
  bannerImage
  websiteUrl
  type
  isVerified
  motd
  createdAt
  siteSpecs
  contractStatus
  dateContractStart
  dateContractEnd
  contractType
  orgType
  headOfSchool
  headOfSport
  itOfSchool
  groundsOfSchool
  marketingContactName
  engagementStatus
  isContracted
  region
  cameraType
  cameraMix
  schoolType
  product
  serialNumber
  phase
  sponsorInfo
  sponsorSchool
  sponsorOther
  shortid
  shortName
  scoreboard
  link
  cameraTypeTwo
  cameraTypeThree
  productTwo
  productThree
  productFour
  productSix
  productFive
  fieldLocation
  fieldLocationTwo
  fieldLocationThree
  serialNumberTwo
  serialNumberThree
  serialNumberSix
  serialNumberFour
  serialNumberFive

`;

export const sportFields = `
  id
  createdBy
  name
  appTheme
  appIcon
  appIconAlt
  description
  isVerified
`;

export const sportInfoFields = `
  id
  accolades
  teamRoleId
  teamRoleAltId
  sportId
  tags
  createdAt
`;

export const fileFields = `
  id
  name
  key
  signedUrl
  secure
  createdAt
  description
`;

export const thirdPartyViewAllFields = `
  id
  thirdPartyProviderName
  registrationNumber
  vatNumber
`

export const thirdPartyFields = `
id
linkedOrgId
thirdPartyProviderName
registrationNumber
contacts{
  id
  linkedThirdPartyProviderId
  firstname
  lastname
  dateOfBirth
  type
  email
  phone
  address
  notes
  idOrPassport
}
vatNumber
policeClearance
serviceAgreement
insuranceDocs
`

export const thirdPartyProviderContactFields = `
  id
  firstname
  lastname
  dateOfBirth
  type
  email
  phone
  address
  notes
  idOrPassport
`

export const allUserData = `
  ${userFields}
  activeOrg {
    ${organisationFields}
    childOrgs {
      id
      name
    }
    parentOrg {
      id
      name
      primaryImage
      primaryImageAlt
      primaryImageIcon      
      bannerImage
      websiteUrl
    }
    sponsor {
      id
      name
      primaryImage
    }
  }
  address {
    ${locationFields}
  }
  sportInfo {
    ${sportInfoFields}
    teamRole {
      ${teamRoleFields}
    }
    teamRoleAlt {
      ${teamRoleFields}
    }
    sport {
      ${sportFields}
    }
  }
`;

export const teamsheetFieldsMin = `
  id
  shortid
  createdBy
  name
  description
  primaryImage
  organisationId
  fixtureId
  sportId
  captainId
  headCoachId
  status
  result
  isVerified
  archived
  teamId
  ageGroup
`;

export const fixtureFieldsMin = `
  id
  shortid
  name
  dateStart
  dateEnd
  status
  result
  currentScore
  currentScoreDesc
  primaryContactName
  primaryContactCell
  primaryContactCellCountryCode
  primaryContactCellFull
  primaryContactEmail
  homeOrgId
  homeOrgName
  awayOrgId
  awayOrgName
`;

export const allUserDataSingle = `
  ${allUserData}
  teams {
    ${teamMemberFields}
    teamRole {
      ${teamRoleFields}
    }
    teamRoleAlt {
      ${teamRoleFields}
    }
    team {
      ${teamFields}
      sport {
        name
        appIcon
        id
      }
      captain {
        firstname
        lastname
      }
      headCoach {
        firstname
        lastname
      }
    }
  }
  images {
    ${fileFields}
  }
  documents {
    ${fileFields}
  }
  linkedOrganisations {
    ${organisationFields}
  }
  teamsheets {
    ${teamsheetMemberFields}
    teamsheet {
      ${teamsheetFieldsMin}
      sport {
        name
        appIcon
        id
      }
      fixture {
        ${fixtureFieldsMin}
      }
    }
  }  
`;

export const teamsheetFields = `
  ${teamsheetFieldsMin}
  teamsheetMembers {
    ${teamsheetMemberFields}
  }

  sport {
    ${sportFields}
  }
  
  team {
    ${teamFields}
  }

  organisation {
    ${organisationFields}
  }
`

export const eventFieldsMin = `
  id
  name
  venue
  club
  sportType
  broadcastRequest
  requestBroadcasting
  createdBy
  isVerified
  hasPassed
  description
  dateStart
  dateEnd
  zones
  primaryContactName
  primaryContactCell
  primaryContactCellCountryCode
  primaryContactCellFull
  primaryContactEmail
  primaryImage
  shortid
`

export const fixtureFields = `
  ${fixtureFieldsMin}
  createdBy
  description
  ownedById
  homeOrgStatus
  homeOrg {
    ${organisationFields}
  }
  homeTeamId
  homeTeam {
    ${teamsheetFields}
  }
  awayOrgStatus
  awayOrg {
    ${organisationFields}
  }
  awayTeamId
  awayTeam {
    ${teamsheetFields}
  }
  sportId
  homeOrgScore
  awayOrgScore
  isVerified
  archived
  address {
    ${locationFields}
  }
  eventId
  event {
    ${eventFieldsMin}
  }
`

export const eventFields = `
  ${eventFieldsMin}
  organiserId
  organiser {
    id
    name
  }
  address {
    ${locationFields}
  }
  fixtures {
    ${fixtureFieldsMin}
  }

`
export const thirdPartyProviderFields = `
id
linkedOrgId
contacts
thirdPartyProviderName
registrationNumber
vatNumber
policeClearance
serviceAgreement
insuranceDocs
`
