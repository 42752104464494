import React, { useState } from 'react';
import { presentError } from '@necta-tech/alert';
import { zipObject } from 'lodash';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Field, mapDataIntoSchema } from '../../../../utils/yup-mapper';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DropzoneOuter = styled.div`
  border-image: none;
  &:hover {
    color: ${p => p.theme.primaryColor};
    border-color: ${p => p.theme.primaryColor};
  }
  border-color: #d9d9d9;
  border-style: dashed;
  border-width: 1px;
  border-radius: 2px;
  background: #FAFAFA;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
`;

const DropzoneInner = styled.div`
  height: 100%;
  color: black;
  text-align: center;
  padding: 2rem 1.5rem;
`;

const DATE_FORMAT = 'yyyy/mm/dd';

const isEmpty = (object: any) => !Object.values(object).some(x => (x != null && x !== ''));

export interface Column {
  title: string;
  field: string;
  filtering?: boolean,
  hidden?: boolean;
  render?: React.FC | string;
}

interface SpreadsheetImportProps {
  hidden?: boolean;
  onComplete: (data: any) => void;
  fields: Field[];
}

export const SpreadsheetImport: React.FC<SpreadsheetImportProps> = ({ onComplete, fields, hidden }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const fileSelected = (files: any) => {
    if (loading) return;

    setLoading(true);
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const binaryData = new Uint8Array(e.target.result);
        const workbook = XLSX.read(binaryData, { type: 'array', cellDates: true, cellNF: false, cellText: false });
        const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const [headers, ...body] = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, dateNF: DATE_FORMAT });

        const keys = headers.map((h: string) => h.replace('*', ''));

        const data = body.map((row: any) => zipObject(keys, row)).filter((row: any) => !isEmpty(row));

        const mappedData = data.map(row => mapDataIntoSchema(row, fields));
        console.log(mappedData);

        setLoading(false);
        onComplete(mappedData);
      } catch (e) {
        console.error(e);
        presentError('Invalid sheet format', 'Error');
        setLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  if (hidden) return null;

  return (
    <DropzoneOuter>
      <Dropzone onDrop={acceptedFiles => fileSelected(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DropzoneInner>
                <UploadOutlined />
                <h4>{loading ? 'Importing sheet...' : 'Drop a spreadsheet or click to upload'}</h4>
              </DropzoneInner>
            </div>
          </section>
        )}
      </Dropzone>
    </DropzoneOuter>
  );
};

export default SpreadsheetImport;
