import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { FormikValues, FormikHelpers, Formik } from 'formik'
import * as Yup from 'yup'
import { forgotPassword, forgotPasswordSubmit } from '@necta-tech/cognito'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { UserOutlined, MessageOutlined, LockOutlined } from '@ant-design/icons';
import { FormButton, FormRow, Input, PageHeader, Password } from '../components'
import { useFormData, useAuthRedirect } from '../hooks'
import { LoginCard } from './Login';
import { message } from 'antd';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { cleanError } from '../helpers/error-helper';
import { regExPassword, regExEmail, EMAIL_ERROR, PASSWORD_ERROR } from '../utils'

const Button = styled(FormButton)`
  margin-top: 30px;
  width: 100%;
`;

const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

export const SendOTP: React.FC<any> = props => {

    const [{ inSession }] = useCognitoUser()

    const schema = useMemo(() => Yup.object().shape({
        email: Yup
            .string()
            .email()
            .matches(regExEmail, EMAIL_ERROR)
            .required()
            .trim()
            .label(''),
    }), [])

    const history = useHistory()

    const { fields, ...formCTX } = useFormData(schema, {
        onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
            const email = values.email.replace(/\s/g, '').toLowerCase()
            message.loading({ content: 'Sending OTP...', duration: 10 });
            await forgotPassword(email)
            message.success({ content: 'OTP sent' });
            history.push('/reset-password', { email })
        },
        onCompleted: () => { },
        onError: (e: any) => {
            message.error({ content: cleanError(e, 'Unable to send OTP') });
        }
    })

    const handleBack = useCallback(() => history.push('/login'), [history]);

    const predicate = useCallback(() => inSession, [inSession])
    useAuthRedirect('/', predicate)

    return (
        <Formik {...formCTX} enableReinitialize validateOnBlur >
            {({handleSubmit, isSubmitting, values}) => (
                <LoginCard>
                    <PageHeader title='Send OTP' onBack={handleBack} />
                    <FormRow>
                        <Input
                          gridProps={GRID_PROPS}
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder={'Username or email'}
                          {...fields.email}
                        />
                        <Button>Send</Button>
                    </FormRow>
                </LoginCard>
            )}
        </Formik>
    )
}

export const ResetPassword: React.FC<any> = props => {

    const [{ inSession }] = useCognitoUser()

    const schema = Yup.object().shape({
        otp: Yup
            .string()
            .required()
            .label(''),
        password: Yup
            .string()
            .required()
            .matches(regExPassword, PASSWORD_ERROR)
            .label('')
    })

    const history = useHistory()
    const { state }: any = useLocation()

    const { fields, ...formCTX } = useFormData(schema, {
        onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
            message.loading({ content: 'Resetting password...', duration: 0 });
            return forgotPasswordSubmit(state.email, values.otp, values.password).then((result: any) => {
                message.success({ content: 'Password reset successfully' });
                history.push('/login')
            }).catch((e: any) => {
                message.error({ content: cleanError(e, 'Password Reset Failed') });
            })
        }
    })

    const handleBack = useCallback(() => history.push('/forgot-password'), [history]);

    if (inSession) return <Redirect to='/' />

    return (
        <Formik {...formCTX} enableReinitialize validateOnBlur >
            {({handleSubmit, isSubmitting, values}) => (
              <LoginCard>
                  <PageHeader title='Change Password' onBack={handleBack} />
                  <FormRow>
                      <Input
                        gridProps={GRID_PROPS}
                        prefix={<MessageOutlined className="site-form-item-icon" />}
                        autocomplete='otp'
                        placeholder={'OTP'}
                        {...fields.otp}
                      />
                      <Password
                        gridProps={GRID_PROPS}
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        autocomplete='new-password'
                        placeholder={'New Password'}
                        {...fields.password}
                      />
                      <Button>Change Password</Button>
                  </FormRow>
              </LoginCard>
            )}
        </Formik>
    )
}
