import React, { useCallback, useMemo, FC } from 'react';
import styled from 'styled-components'
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import { ProfilePictureUploader } from '../../../components/profile-picture-uploader';
import PicPlaceholder from '../../../assets/default_profile.png';
import { ContactNumber, DatePicker, FormButton, FormRow, GeneratePassword, Input, Select, TextArea } from '../../../components';
import { LocationSearch } from '../../../components/location-search';
import { COUNTRIES, getIsAdmin, mapArrayToObject, ROLES, SA_PROVINCES, isMinor } from '../../../utils';
import { useAdminUpdateUser } from '../../../graphql';
import { useFormData } from '../../../hooks/use-form-data';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { userSchema } from '../schema';
import { message, Collapse as Collapser } from 'antd';
import { get, isEmpty } from 'lodash';
import { getCurrentUser } from '../../../utils'
import { User } from './types'

import { useUserSchema } from '../../../hooks'
import moment from 'moment';
import { cleanError } from '../../../helpers/error-helper';

const { Panel } = Collapser

const Collapse = styled(Collapser)`
  width: 100%;
`

const finalSchema = userSchema.shape({
  password: Yup
    .string()
    .label('Password'),
})

const roles = mapArrayToObject(ROLES)
const provinces = mapArrayToObject(SA_PROVINCES)
const countries = mapArrayToObject(COUNTRIES)

interface EditProfileProps {
  user: any;
  edit?: boolean;
}

const Title = styled.h1`
  margin-bottom: 30px;
`

const key = 'user-update';

export const EditProfile: FC<EditProfileProps> = (props) => {
  const { user, edit } = props;

  const { isPlayer, isCoach, dateOfBirth } = user
  //expecting DoB to be required on PlayerAdd

  const [handleUpload] = useAdminUpdateUser({});
  const isAdmin = useSelector(getIsAdmin);
  const { id: currentUserId } = useSelector(getCurrentUser);

  const { id } = user
  const isCurrentUser = useMemo(() => id === currentUserId, [id, currentUserId])

  const [{ fields, initialValues, ...formikCTX }, sanitize] = useUserSchema(user, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', key, duration: 10 });
      const { id, number, organisationId, createdAt, activeOrg, sportInfo, documents, teams, images, linkedOrganisations, contactNumberFull, parentContactNumberFull, teamsheets, undefined, ...rest } = values; // Destructure other values here
      const result = await handleUpload({
        variables: {
          id,
          user: {
            ...rest,
          },
        },
      });
      //console.log(JSON.stringify(result, null, 2))
      return result
    },
    onError: (error: any) => {
      //console.log(error);
      // TODO: error processing for antd message component
      // presentError(error, 'Unable to update user');
      // message.destroy(key);
      message.error({ content: cleanError(error, 'Unable to update user'), key, duration: 4 });
    },
    onCompleted: () => {
      // TODO: This is showing success even when it fails
      message.success({ content: 'User updated!', key, duration: 2 });
    },
  });

  return (
    <Formik {...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
      {({handleSubmit, isSubmitting, setFieldValue, values}) => (
        <Form layout={'vertical'}>
          <ProfilePictureUploader disabled={!edit || isSubmitting} fieldKey="profilePicUrl" defaultPic={PicPlaceholder} />

          <Collapse defaultActiveKey={['contact', 'location', 'security', 'stats']} ghost>

            <Panel key='contact' header='Contact information'>
              <FormRow disabled={!edit}>
                <Input {...fields.firstname} />
                <Input {...fields.lastname} />
                <ContactNumber
                  numberField={fields.contactNumber}
                  countryCodeField={fields.contactNumberCountryCode}
                />
                <Input disabled={isCurrentUser} {...fields.email} />
                <DatePicker hidden={!user.isPlayer || !fields.dateOfBirth} {...fields.dateOfBirth} />
                <TextArea {...fields.bio} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }}/>
              </FormRow>
            </Panel>

            { isPlayer &&
              <Panel key='stats' header='Player Stats'>
                <FormRow disabled={!edit}>
                  <Input {...fields.weight} />
                  <Input {...fields.height} />
                  <Input {...fields.shoeSize} />
                  <Input {...fields.language} />
                  <Input {...fields.camop} />
                  <Input {...fields.producer} />
                  <Input {...fields.experience} />
                  <Input {...fields.clinear} />
                  <TextArea {...fields.ccomments} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }}/>
                  <Input {...fields.cregion} />
                  <Input {...fields.csports} />
                  <Input {...fields.aimobile} />
                  <Input {...fields.aiop} />
                  <Input {...fields.scorer} />
                </FormRow>
              </Panel>
            }

            { isPlayer && isMinor(values.dateOfBirth) &&
              <Panel key='parental' header='Parental Contact'>
                <FormRow disabled={!edit}>
                  <Input {...fields.parentContactEmail} />
                  <ContactNumber
                    numberField={fields.parentContactNumber}
                    countryCodeField={fields.parentContactNumberCountryCode}
                  />
                </FormRow>
              </Panel>
            }

            <Panel key='verification' header='Verification'>
              <FormRow disabled={!edit}>
                <Input {...fields.medicalAidScheme} />
                <Input {...fields.medicalAidNumber} />
                <Input {...fields.idNumber} />
                <Input {...fields.passportNumber} />
              </FormRow>
            </Panel>

            <Panel key='location' header='Location'>
              <FormRow disabled={!edit}>
                <LocationSearch {...fields.address} parentKey={'address'} defaultValue={get(values, 'address.fullAddress')} />
                <Input {...fields.address.addressLine1} />
                <Select {...fields.address.country} options={countries} />
                <Select {...fields.address.state}  options={provinces} />
                {/* TODO: we should make it easier / cleaner to make an element fullwidth */}
                {/* TODO: Perhaps some breakpoint configs */}
              </FormRow>
            </Panel>

            <Panel key='security' header='Security'>
              <FormRow disabled={!edit}>
                <GeneratePassword disabled={isCurrentUser} {...fields.password} generateButton />
                <Select {...fields.role} options={roles} disabled={!isAdmin} />
              </FormRow>
            </Panel>

          </Collapse>

          <FormButton loading={isSubmitting} hidden={!edit}>
            SAVE
          </FormButton>

        </Form>
      )}
    </Formik>
  );
};

EditProfile.defaultProps = {};

export default EditProfile;
