import * as yup from 'yup'

export const updateFixtureResultSchema = yup.object().shape({

    value: yup.string().nullable().label('Score'),

    homeValue: yup
        .string()
        .nullable()
        .label('Home Score'),

    awayValue: yup
        .string()
        .nullable()
        .label('Away Score'),

    description: yup
        .string()
        .nullable()
        .label('Description'),

    radioResult: yup.string().oneOf(['W', 'L', 'D', 'P']).label('Result')
})
