import React, { useCallback } from 'react'
import styled from 'styled-components'
import { startCase } from 'lodash'
import { Select as SelectBase, SelectProps } from 'formik-antd'
import { InputFieldProps, FieldProps } from './types'
import { FieldContainer } from './FieldContainer'

const { Option } = SelectBase

type OptionProps = {
    id: string | number;
    name: string;
};

interface AddedProps {
    options?: OptionProps[] | [],
}

const Component: React.FC<InputFieldProps<SelectProps, AddedProps & FieldProps>> = ({ hidden, gridProps, options = [], label, required, className, children, ...props }) => {

    const getCase = useCallback((name) => startCase(name), [])

    return (
        <FieldContainer
            { ...gridProps }
            name={props.name}
            required={required}
            label={label}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <SelectBase
                {...props}
            >
                { children || 
                    (options.length > 0 && (options.map(({name, id}: OptionProps) => 
                        <Option key={id} value={id}>{getCase(name)}</Option>
                    )))
                }
            </SelectBase>
        </FieldContainer>
    )
}

export { Option as SelectOption }
export const Select = styled(Component)``