import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar, Table, Page, Filter, Sort, AuthGuard } from '../../components';
import styled from 'styled-components';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { useFetchOrgList } from '../../graphql';
import { PictureOutlined, PlusOutlined } from '@ant-design/icons';
import { compact, get } from 'lodash';
import { useSponsors } from '../../redux/config';
import { Button, Tooltip } from 'antd';
import {
  CAMERAS,CAMERAS_TWO,CAMERAS_THREE,
  CONTRACT_STATUSES,
  CONTRACT_TYPES, ENGAGEMENT_STATUSES,
  GROUNDS,GROUNDS_TWO,GROUNDS_THREE,
  mapArrayToObject,
  mapArrayToOptions,
  ORG_TYPES, PHASES, PRODUCTS, REGIONS,PRODUCTS_TWO,PRODUCTS_THREE,PRODUCTS_FOUR,
  PRODUCTS_SIX,
  PRODUCTS_FIVE,
  SCHOOL_TYPES, SITE_SPECS,CAMERAMIXES
} from '../../utils';

const orgTypes = mapArrayToOptions(ORG_TYPES);
const schoolTypes = mapArrayToOptions(SCHOOL_TYPES);
const contractStatuses = mapArrayToOptions(CONTRACT_STATUSES);
const siteSpecs = mapArrayToOptions(SITE_SPECS);
const engagementStatuses = mapArrayToOptions(ENGAGEMENT_STATUSES);
const regions = mapArrayToOptions(REGIONS);
const phases = mapArrayToOptions(PHASES);
const cameraTypes = mapArrayToOptions(CAMERAS);
const cameraTypesTwo = mapArrayToOptions(CAMERAS_TWO);
const cameraTypesThree = mapArrayToOptions(CAMERAS_THREE);
const cameraMixes = mapArrayToOptions(CAMERAMIXES);

const _columns: any[] = [
  {
    title: 'Picture',
    key: 'primaryImage',
    export: false,
    width: 40,
    render: (r: any) => <Avatar src={getThumbnail(r)} icon={<PictureOutlined/>} inverse />
  },
  {
    title: 'Shortid',
    filtering: true,
    show: false,
    key: 'shortid',
  },
  {
    title: 'Organisation',
    filtering: true,
    sorting: true,
    key: 'name',
  },
  {
    title: 'Primary Contact',
    filtering: true,
    sorting: true,
    key: 'primaryContactName',
  },
  {
    title: 'Contact Email',
    filtering: true,
    sorting: true,
    key: 'primaryContactEmail',
  },
  {
    title: 'Contact Number',
    filtering: true,
    show: false,
    key: 'primaryContactCellFull',
  },
  {
    title: 'Head of School',
    filtering: true,
    show: false,
    key: 'headOfSchool',
  },

 
  {
    title: 'Head of Sport',
    filtering: true,
    show: false,
    key: 'headOfSport',
  },
  {
    title: 'Hierarchy',
    sorting: true,
    show: false,
    // filtering: {
    //   filters: [
    //     {
    //       text: 'Sub',
    //       value: 'Sub',
    //     },
    //     {
    //       text: 'Parent',
    //       value: 'Parent',
    //     },
    //   ],
    //   filterMultiple: true,
    //   type: Filter.SELECT
    // },
    key: 'childOrgs',
    render: (childOrgs: any) => childOrgs && childOrgs.length > 0 ? 'Parent' : 'Sub'
  },
  {
    title: 'Org Type',
    key: 'orgType',
    filtering: {
      filters: orgTypes,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'School Type',
    key: 'schoolType',
    show: false,
    filtering: {
      filters: schoolTypes,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Contract Status',
    key: 'contractStatus',
    
    filtering: {
      filters: contractStatuses,
      filterMultiple: true,
      
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'School Type',
    key: 'schoolType',
    show: false,
    filtering: {
      filters: schoolTypes,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Region',
    key: 'region',
    show: false,
    filtering: {
      filters: regions,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Phase',
    key: 'phase',
    show: false,
    filtering: {
      filters: phases,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
 
  {
    title: 'Camera Type One',
    key: 'cameraType',
    show: false,
    filtering: {
      filters: cameraTypes,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },

  {
    title: 'Camera Type Two',
    key: 'cameraTypeTwo',
    show: false,
    filtering: {
      filters: cameraTypesTwo,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Camera Type Three',
    key: 'cameraTypeThree',
    show: false,
    filtering: {
      filters: cameraTypesThree,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Camera Mix',
    key: 'cameraMix',
    show: false,
    filtering: {
      filters: cameraMixes,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  
  
  {
    title: 'Site Specs',
    key: 'siteSpecs',
    show: false,
    getValues: (r: any) => r.siteSpecs.join(', '),
    filtering: {
      filters: siteSpecs,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'Engagement Status',
    key: 'engagementStatus',
    show: false,
    filtering: {
      filters: engagementStatuses,
      filterMultiple: true,
      type: Filter.SELECT
    },
    sorting: true,
  },
  {
    title: 'School Sponsor',
    sorting: true,
    filtering: {
      filterMultiple: true,
      type: Filter.SELECT
    },
    key: 'sponsorSchool',
    render: (sponsor: any) => sponsor ? sponsor : 'None'
  },
  {
    title: 'SSS Sponsor',
    sorting: true,
    filtering: {
      filters: [
        {
          text: 'MTN',
          value: 'MTN',
        },
        {
          text: 'Nashua',
          value: 'Nashua',
        },
        {
          text: 'None',
          value: 'None',
        },
      ],
      filterMultiple: true,
      type: Filter.SELECT
    },
    key: 'sponsorId',
    render: (sponsor: any) => sponsor ? sponsor : 'None'
  },
  {
    title: 'Advertisers',
    sorting: true,
    filtering: {
      filterMultiple: true,
      type: Filter.SELECT
    },
    key: 'sponsorInfo',
    render: (sponsor: any) => sponsor ? sponsor : 'None'
  },

  {
    title: 'Other Sponsor',
    sorting: true,
    filtering: {
      filterMultiple: true,
      type: Filter.SELECT
    },
    key: 'sponsorOther',
    render: (sponsor: any) => sponsor ? sponsor : 'None'
  },
];
export const OrgList: React.FC = () => {

  const history = useHistory();

  const [orgs, setOrgs] = useState([]);

  const [fetchOrgs, { loading }] = useFetchOrgList({
    onCompleted: (result: any) => {
      setOrgs(cloneDeep(result.organisations));
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const [sponsorList] = useSponsors();

  const columns = useMemo(() => {
    // Add sponsor names to sponsors column in multi-select mode
    const cols = [..._columns];
    const sponsor = cols.find((c: any) => c.key === 'sponsor.name');
    if (sponsor && sponsorList) {
      const sponsors = compact(sponsorList.map((s: any) => s.id !== '' && Object.assign({ text: s.name, value: s.name})));
      sponsor.filtering = {
        filters: [ ...sponsors ],
        filterMultiple: true,
        type: Filter.SELECT
      };
    }
    return cols;
  }, [_columns, sponsorList])

  const handleAdd = useCallback(() => history.push('/org/add'), [history]);

  return (
    <AuthGuard needsActiveOrg>
      <Page card>
        <Table
          data={orgs}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/org/${record.id}`)
          })}
          refresh={fetchOrgs}
          title={'Organisation List'}
          exportTitle={'Organisations'}
          actions={<Tooltip title={`Add New Organisation`}><Button icon={<PlusOutlined />} onClick={handleAdd} shape="circle"/></Tooltip>}
        />
      </Page>
    </AuthGuard>
  )
};

export default OrgList;
