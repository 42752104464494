import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Skeleton } from 'antd';
import { useFetchSportList } from '../../graphql/hooks';
import { cloneDeep } from '@apollo/client/utilities';
import { Heading, PanelVariant, SCard, SubHeading, TabButtonSizer, Text } from '../../pages';
import { useHistory } from 'react-router';
import { getThumbnail } from '@bit/necta.hooks.s3'

const Tag = styled.div`
    width: 100%;
    text-align: center;
    color: #666;
    font-size: 16px;
    padding-top: 15px;
`;

const Sport = styled(Col)`
  display: grid;
  justify-content: center;
  align-content: center;
`;

export const SportList: React.FC<any> = (props) => {

    const history = useHistory();

    const [sports, setSports] = useState([]);

    const [fetchSports, { loading }] = useFetchSportList({
        onCompleted: (result: any) => {
            if (result?.sports) {
                setSports(cloneDeep(result.sports));
            }
        }
    });

    return (
      <>
          <PanelVariant pad={'mixed'} id={'Sports'}>
              <SCard>
                  {loading && <Skeleton active avatar paragraph={{ rows: 5 }}/>}
                  {!loading && (
                    <>
                      <Heading><b>Our Sports</b></Heading>
                      <br/>
                      <SubHeading>There are currently {sports.length} Sports catered for in detail on the platform, with more coming soon! </SubHeading>
                      <br/>
                      <Text>If you need a Sport that isn't on the list, please <Button style={{marginLeft: '-14px'}} type='link' onClick={() => history.push('/contactus')}>get in touch.</Button></Text>
                    </>
                )}
              </SCard>
          </PanelVariant>
        {!loading && (
          <Row gutter={[20, 50]}>
            {sports.map((sport: any) =>
              <Sport xs={{span:12}} sm={{span:8}} md={{span:6}} lg={{ span: 4 }}>
                <img
                  style={{maxHeight:'100px'}}
                  alt={`playpro sport ${sport?.name?.toLowerCase()}`}
                  src={getThumbnail(sport?.appIcon || '')} />
                <Tag>
                  <p>{sport?.name}</p>
                </Tag>
              </Sport>
            )}
          </Row>
        )}

        <PanelVariant pad={'mixed'}>
            <TabButtonSizer>
                <Button type="primary" onClick={() => history.push('/contactus')}>Sign Up!</Button>
            </TabButtonSizer>
        </PanelVariant>
      </>

    );
}
