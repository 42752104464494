import React, { useCallback, useState } from 'react';
import { FileUpload } from '../../components/file-upload';
import Swal from 'sweetalert2'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user/dist';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const useUploadImage = (
  onComplete?: (result?: any) => void,
  path: string = 'profilePicture',
  single: boolean = true,
  width: number = 800,
) => {
  const [value, setValue] = useState();

  const [cognitoUser] = useCognitoUser();

  const handleComplete = useCallback((result?: any) => {
    setValue(result);
    if (onComplete) onComplete(result);
  }, [onComplete, setValue]);

  const uploadImage = useCallback((defaultFiles?: File[]) => {
    MySwal.fire({
      title: 'Upload Profile Picture',
      html: <FileUpload path={path || ''} id={cognitoUser.username} type="image" single={single} onComplete={handleComplete} defaultFile={defaultFiles} />,
      width: width + 'px',
      showConfirmButton: false,
      showCloseButton: true
    });
  }, [cognitoUser]);

  return {
    uploadImage,
    value
  } as const;
}
