import React, { useEffect, useMemo } from 'react';
import { useCognitoUser } from '@bit/necta.hooks.cognito-user';
import { useLocation, useHistory } from 'react-router-dom';
import { useSetRedirectOnLogin } from '../../hooks';

interface SessionGuardProps {
  pushTo: string,
  validPaths?: string[]
}

/**
 * useSessionGuard take a config argument, with a required pushTo path and option validPaths array.
 * It will listen to cognito session changes, check if the current path matches a valid path
 * and redirect to pushTo if necessary. This handles several cases:
 * When a session is terminated, the site redirects to pushTo.
 * When a logged out route is valid and navigated to outside of session, session guard will allow this.
 * While not in session, all child routes of session will be unmounted.
 *
 * validPaths is an array of react-router paths, they are the paths that can be navigated to when not in session
 */
export const useSessionGuard = ({ pushTo, validPaths = [] }: SessionGuardProps) => {
  const [{ inSession }] = useCognitoUser();
  const setRedirectOnLogin = useSetRedirectOnLogin();
  const { pathname } = useLocation();
  const history = useHistory();

  //check if re-routing is necessary
  const push = useMemo(
    () => !inSession && !validPaths.includes(pathname) && pathname !== '/login',
    [inSession, validPaths, pathname]
  )

  //redirect to pushTo if push is true
  useEffect(() => {
    if (push) {
      setRedirectOnLogin(pathname)
      history.push(pushTo)
    }
  }, [push, pushTo, setRedirectOnLogin, pathname, history])

  //return the state of cognito session
  return inSession
}


export const SessionGuard: React.FC<SessionGuardProps> = ({ pushTo, validPaths = [], ...props }) => {
  const inSession = useSessionGuard({ pushTo, validPaths })
  //unmount children when not in session
  if (!inSession) return null
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default SessionGuard;
