import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Collapse as Collapser,
  message,
  Tabs,
  Tooltip,
} from "antd";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Form } from "formik-antd";
import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormButton, FormRow, Input, Table } from "../../components";
import FileUploader from "../../components/file-uploader";
import { AuthGuard, FormLoading, Page } from "../../components/page";
import { ADD_THIRD_PARTY_PROVIDER } from "../../graphql";
import { cleanError } from "../../helpers/error-helper";
import { useFormData } from "../../hooks/use-form-data";

import {
  CAMERAS,
  CAMERAS_TWO,
  CAMERAMIXES,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  COUNTRIES,
  ENGAGEMENT_STATUSES,
  GROUNDS,
  GROUNDS_TWO,
  mapArrayToObject,
  ORG_TYPES,
  PHASES,
  PRODUCTS,
  PRODUCTS_FOUR,
  PRODUCTS_THREE,
  PRODUCTS_TWO,
  REGIONS,
  SA_PROVINCES,
  SITE_SPECS,
} from "../../utils";
import { ThirdPartyProviderPrimaryInfoSchema } from "./schema";

const { TabPane } = Tabs;

const { Panel } = Collapser;

export const provinces = mapArrayToObject(SA_PROVINCES);
export const countries = mapArrayToObject(COUNTRIES);
export const contractTypes = mapArrayToObject(CONTRACT_TYPES);
export const siteSpecs = mapArrayToObject(SITE_SPECS);
export const contractStatuses = mapArrayToObject(CONTRACT_STATUSES);
export const engagementStatuses = mapArrayToObject(ENGAGEMENT_STATUSES);
export const orgTypes = mapArrayToObject(ORG_TYPES);
export const providerType = mapArrayToObject([
  "COMMENTATOR",
  "SCORER",
  "CAMERAMAN",
]);
export const regions = mapArrayToObject(REGIONS);
export const phases = mapArrayToObject(PHASES);
export const products = mapArrayToObject(PRODUCTS);
export const productstwo = mapArrayToObject(PRODUCTS_TWO);
export const productsthree = mapArrayToObject(PRODUCTS_THREE);
export const productsfour = mapArrayToObject(PRODUCTS_FOUR);
export const cameras = mapArrayToObject(CAMERAS);
export const camerastwo = mapArrayToObject(CAMERAS_TWO);
export const grounds = mapArrayToObject(GROUNDS);
export const groundsTwo = mapArrayToObject(GROUNDS_TWO);
export const cameramixess = mapArrayToObject(CAMERAMIXES);

type Props = Readonly<{
  contacts: any[];
}>;

export const ContactTable: FC<Props> = ({ contacts }: Props) => {
  const history = useHistory();

  const columns: any[] = [
    {
      title: "Firstname",
      filtering: true,
      sorting: true,
      key: "firstname",
    },
    {
      title: "Lastname",
      filtering: true,
      sorting: true,
      key: "lastname",
    },
    {
      title: "Date Of Birth",
      filtering: true,
      sorting: true,
      key: "dateOfBirth",
    },
    {
      title: "Email",
      filtering: true,
      show: true,
      key: "email",
      sorting: true,
    },
    {
      title: "Phone",
      filtering: true,
      show: true,
      key: "phone",
      sorting: true,
    },
    {
      title: "Address",
      filtering: true,
      show: true,
      key: "address",
      sorting: true,
    },
    {
      title: "Type",
      filtering: true,
      show: true,
      key: "type",
      sorting: true,
    },
  ];

  return (
    <Table
      data={contacts}
      columns={columns}
      loading={false}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () =>
          history.push(`/third-party-provider/contact/view-edit/${record.id}`),
      })}
      title={"Contact List"}
      exportTitle={"Organisations"}
      actions={
        <Tooltip title={`Add New Organisation`}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => alert("hey")}
            shape="circle"
          />
        </Tooltip>
      }
    />
  );
};

export const AddThirdPartyProvider: FC = () => {
  const history = useHistory();
  const [handleAdd, { loading }] = useMutation(ADD_THIRD_PARTY_PROVIDER);

  const { fields, initialValues, ...formikCTX } = useFormData(
    ThirdPartyProviderPrimaryInfoSchema,
    {
      onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        message.loading({ content: "Loading...", duration: 10 });
        // const newThirdPartyProviderData = values;
        return handleAdd({
          variables: {
            newThirdPartyProvider: values,
          },
        });
      },
      onCompleted: () => {
        message.success({
          content: "Third Party Provider Contact updated!",
          duration: 2,
        });
      },
      onError: (e: any) => {
        message.error({
          content: cleanError(
            e,
            "Unable to update Third Party Provider Contact"
          ),
          duration: 4,
        });
        // presentError(e)
      },
    }
  );

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrg>
      <Formik
        {...formikCTX}
        initialValues={{}}
        enableReinitialize
        validateOnBlur
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title="Add Third Party Provider" onBack={handleBack} card>
            <Form layout={"vertical"}>
              <Tabs
                defaultActiveKey={"thirdPartyProviderContactInfo"}
                tabPosition={"top"}
                size={"large"}
              >
                <TabPane tab={"Primary Info"} key={"Primary Info"}>
                  <FormRow gutter={6}>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.thirdPartyProviderName} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.registrationNumber} />
                    </Col>

                    <Col className="gutter-row" span={8}>
                      <Input {...fields.vatNumber} />
                    </Col>
                  </FormRow>
                  <FormButton>CREATE</FormButton>
                  {/* <Collapser ghost>
                    <Panel key="contact" header="Contacts">
                      <ContactTable contacts={[]} />
                    </Panel>
                  </Collapser> */}
                </TabPane>
                <TabPane tab={"Documents"} key={"Documents"}>
                  <FileUploader
                    id={"IMAGES"}
                    parentType={"organisation"}
                    fileType={"IMAGE"}
                    initialFiles={[]}
                  />
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

AddThirdPartyProvider.defaultProps = {};

export default AddThirdPartyProvider;
