import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { cloneDeep } from 'lodash'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  Input,
  OrgSelector,
  SportSelector,
  TextArea,
  AuthGuard,
  PageHeader,
  SubmitButton
} from '../../components'
import { canSelectOrgs, getActiveOrgId, getIsAdmin, getSelectedOrg, getSelectedSport } from '../../utils';
import { useFormData, Yup } from '../../hooks';
import { Card as AntCard } from 'antd'
import { Teamsheet, TeamsheetMember } from '../../redux'
import { NewTeamSheet, CreatedTeamsheet } from './NewTeamsheet'
import { MassAddTeamsheetMember } from '../../components/add-teamsheet-members';

const Card = styled(AntCard)`
    width: 90%;
`

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  selectedOrgId: useSelector(getSelectedOrg),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedSport: useSelector(getSelectedSport)
})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

interface TeamSheetAddProps {
}

export const TeamSheetAdd: React.FC<TeamSheetAddProps> = ({ 
    ...props
}) => {

  const { fixtureId }: any = useParams()
  const history = useHistory()


  const [createdTeamsheet, setTeamsheet] = useState<CreatedTeamsheet | null>(null)

  const onSubmitted = useCallback((values: CreatedTeamsheet) => {
    setTeamsheet(cloneDeep(values))
  }, [setTeamsheet])

  const { isAdmin, selectedOrgId, canSelectOrgs, selectedSport } = useSelectors()

  const handleAway = useCallback(() => {
    history.push(`/fixture/teamsheet/${createdTeamsheet?.id}`)
  }, [history, createdTeamsheet])

  const handleBack = useCallback(() => history.push(`/fixture/${fixtureId}`), [history, fixtureId])

  if (!createdTeamsheet) return (
    <AuthGuard>
      <Page onBack={handleBack}>
        <NewTeamSheet
            card={false}
            fixtureId={fixtureId}
            onSubmitted={onSubmitted}
            onBack={handleBack}
        />
      </Page>
    </AuthGuard>
  )

  if (createdTeamsheet) return (
    <AuthGuard>
      <Page onBack={handleBack}>
        <MassAddTeamsheetMember
          title='Populate your teamsheet!'
          teamsheetId={createdTeamsheet?.id || ''}
          organisationId={createdTeamsheet?.organisationId || ''}
          sportId={createdTeamsheet?.sportId || ''}
          onSubmitted={handleAway}
        />
      </Page>
    </AuthGuard>
  )

  return null

}

export default TeamSheetAdd
