import Amplify from 'aws-amplify';
import { cognitoConfig, User, refresh } from '@necta-tech/cognito';
import Swal from 'sweetalert2';
import { Actions } from '@bit/necta.hooks.cognito-user'
import { s3config } from '@bit/necta.hooks.s3'
import { store } from './redux-config'

const amp_config = process.env.REACT_APP_AMP_CONFIG_TOGGLE === 'true';

const chain = () => setTimeout(() => {
  if (store.getState().cognitoUser.inSession) refresh();
});

if (amp_config) {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AUTH_REGION,
      // REQUIRED - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AWS_BUCKET, //REQUIRED -  Amazon S3 bucket
        region: process.env.REACT_APP_AWS_REGION //OPTIONAL -  Amazon service region
      }
    }
  });

  s3config(process.env.REACT_APP_S3_HOST)

  const cognitoSuccess = (user: User) => {
    store.dispatch(Actions.setCognitoUser(user));
  };

  const cognitoFailure = (message?: string, title?: string) => {
    Swal.fire(title || 'Authentication failure', message, 'error');
    const user = { inSession: false };
    store.dispatch(Actions.setCognitoUser(user));
  };

  cognitoConfig(cognitoSuccess, cognitoFailure);

  chain()
}

export const startRefresh = () => chain()
