import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Page,
  NotFound,
  ProfilePictureUploader,
  FormRow,
  Input, TeamRoleSelector, Tags, CheckBox, TextArea, FormButton, FormLoading,
  AuthGuard
} from '../../components';
import { Col, message, PageHeader, Radio, Skeleton } from 'antd';
import styled from 'styled-components';
import { UPDATE_TEAMSHEET_MEMBER, useFetchTeamsheetMember } from '../../graphql';
import { Form } from 'formik-antd';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { teamsheetMemberSchema } from './schema';
import { useFormData, Yup } from '../../hooks';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import PicPlaceholder from '../../assets/default_profile.png';
import { cleanError } from '../../helpers/error-helper';


const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const EditTeamsheetMember: React.FC = () => {
  const { shortid }: any = useParams()

  const history = useHistory()

  const [handleUpload] = useMutation(UPDATE_TEAMSHEET_MEMBER);

  const [teamsheetMember, setTeamsheetMember] = useState<any>(null);

  const [fetchTeam, { loading }] = useFetchTeamsheetMember(shortid, {
    onCompleted: (result: any) => {
      //console.log(result);
      setTeamsheetMember({ ...result.teamsheetMember });
    },
    onError: (error: any) => {
      //console.log(error)
      message.error({ content: 'Could not load Team Member', duration: 4 })
    }
  })

  const { fields, initialValues: $initialValues, ...formikCTX } = useFormData(teamsheetMemberSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Updating team member...', duration: 10 });
      const { id, team, user, userId, teamId, teamRole, teamRoleAlt, teamsheet, createdBy, userType, ...updateTeamsheetMember } = values;
      return handleUpload({ variables: { id, updateTeamsheetMember  } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team member updated!', duration: 2 });
      history.push('/fixture/teamsheet/' + teamsheetMember.teamsheet?.id)
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to update team member'), duration: 4 });
    }
  })

  const contains = useMemo(() => teamsheetMember && !isEmpty(teamsheetMember) && teamsheetMember.teamsheet?.id, [teamsheetMember])

  const handleBack = useCallback(() => {
    if (contains) history.push('/fixture/teamsheet/' + teamsheetMember.teamsheet?.id)
    else history.goBack();
  }, [contains, teamsheetMember, history]);

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam]);

  const sportId = useMemo(() => get(teamsheetMember, 'teamsheet.sportId', ''), [teamsheetMember]);
  const isPlayer = useMemo(() => get(teamsheetMember, 'isPlayer', ''), [teamsheetMember]);
  const isCoach = useMemo(() => get(teamsheetMember, 'isCoach', ''), [teamsheetMember]);

  const initialValues = useMemo(() => ({
    ...teamsheetMember,
    userType: (isCoach && 'Coach') || (isPlayer && 'Player') || ''
  }), [teamsheetMember, isCoach, isPlayer])

  const handleOnToggle = useCallback((setFieldValue) => (e: any) => {
    const val = e.target.value
    if (val === 'Player') {
      setFieldValue('isPlayer', true)
      setFieldValue('isCoach', false)
    }
    if (val === 'Coach') {
      setFieldValue('isPlayer', false)
      setFieldValue('isCoach', true)
    }
  }, [])

  if (loading) return <FormLoading />;

  if (!contains) {
    return <NotFound subTitle={'Unable to find team member'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={initialValues} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title='Edit Team Member' onBack={handleBack} card>
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <Col hidden={!!teamsheetMember.userId} {...GRID_PROPS}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Radio.Group
                      value={values.isCoach ? 'Coach' : 'Player'}
                      onChange={handleOnToggle(setFieldValue)}
                      options={[
                        { label: 'Player', value: 'Player' },
                        { label: 'Coach', value: 'Coach' }
                      ]}
                      optionType='button'
                      style={{marginBottom: '20px'}}
                    />
                  </Col>
                </Col>
                <Input {...fields.firstname} />
                <Input {...fields.lastname} />
                <Input {...fields.nickname} />
                <TeamRoleSelector sport={sportId} isPlayer={values.isPlayer} isCoach={isCoach} {...fields.teamRoleId} />
                <TeamRoleSelector sport={sportId} isPlayer={values.isPlayer} isCoach={isCoach} {...fields.teamRoleAltId} />
                <Tags isEditable={false} {...fields.tags} gridProps={GRID_PROPS} />
                <CheckBox {...fields.isCaptain} disabled={!values.isPlayer} gridProps={GRID_PROPS}>Team Captain</CheckBox>
                <CheckBox {...fields.isHeadCoach} disabled={!values.isCoach} gridProps={GRID_PROPS}>Head Coach</CheckBox>
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              <FormButton>
                UPDATE
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
};

export default EditTeamsheetMember;
