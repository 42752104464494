import React from 'react'
import styled from 'styled-components'
import { TimePicker as TimePickerBase, DatePickerProps } from 'formik-antd'
import { useFormikContext } from 'formik'
import { FieldContainer } from './FieldContainer'
import { InputFieldProps } from './types'

interface AddedProps {
    label?: string
}

const Component: React.FC<InputFieldProps<DatePickerProps, AddedProps>> = ({ hidden, label, required, gridProps, className, ...props }) => {

    const{ isSubmitting } = useFormikContext() || {}

    return (
        <FieldContainer
            { ...gridProps }
            name={props.name}
            label={label}
            required={required}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <TimePickerBase
              disabled={isSubmitting}
              format={'HH:mm'}
              { ...props }
            />
        </FieldContainer>
    )
}

export const TimePicker = styled(Component)`
    .ant-picker {
        width: 100%;
    }
`