import React, { FC } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import { FormButton, FormRow, GeneratePassword } from '../../../components';
import { useUpdateUser } from '../../../graphql';
import { useFormData } from '../../../hooks/use-form-data';
import * as Yup from 'yup';
import { message } from 'antd';
import { checkPassword } from '@necta-tech/cognito';
import { regExPassword, PASSWORD_ERROR } from '../../../utils'

const finalSchema = Yup.object().shape({
  currentPassword: Yup
    .string()
    .required()
    .label('Confirm Current Password'),
  password: Yup
    .string()
    .required()
    .matches(regExPassword, PASSWORD_ERROR)
    .label('New Password'),
})

const CForm = styled(Form)`
  max-width: 500px;
  margin: 0 auto !important;
  text-align: center;
  h3 {
    padding: 10px;
  }
`;

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

interface ChangePasswordProps {
  user: any;
}

export const ChangePassword: FC<ChangePasswordProps> = ({ user }) => {

  const [handleUpload] = useUpdateUser({});

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', duration: 10 });
      try {
        await checkPassword(values.currentPassword);
        const response = await handleUpload({
          variables: {
            id: user.id,
            user: {
              password: values.password,
            },
          },
        });
        message.success({ content: 'Password updated!', duration: 2 });
        actions.resetForm();
      } catch(e) {
        // TODO: error cleaning
        if (e?.code === 'NotAuthorizedException') message.error({ content: 'Incorrect password', duration: 4});
        else message.error({ content: e?.message || 'An error occurred', duration: 4});
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <Formik {...formikCTX} initialValues={user} enableReinitialize validateOnBlur>
      {({ }) => (
        <CForm layout={'vertical'}>
          <h3><i>This will change your log in credentials.</i></h3>
          <FormRow>
            <GeneratePassword {...fields.password} gridProps={GRID_PROPS} generateButton />
            <GeneratePassword {...fields.currentPassword} gridProps={GRID_PROPS} />
          </FormRow>

          <FormButton>
            Change Password
          </FormButton>

        </CForm>
      )}
    </Formik>
  );
};

ChangePassword.defaultProps = {};

export default ChangePassword;
