import React, { FC, useCallback } from 'react';
import Page from '../components/page';
import { Button, Result, Space } from 'antd';
import { useHistory } from 'react-router';
import ConstructionSvg from '../assets/500.svg';

interface UnderConstructionProps {
  subTitle?: string | React.ReactNode;
  handleBack: () => void;
  icon?: React.ReactNode;
}

export const UnderConstruction: FC<UnderConstructionProps> = ({  subTitle, handleBack, children, icon }) => {
  const history = useHistory();

  const _handleBack = useCallback(() => handleBack ? handleBack() : history.push('/'), [handleBack, history]);

  return (
    <Page>
      <Result
        title='Coming Soon'
        subTitle={subTitle || <>Offsides! We are still working on this. For more info, see <a target='_blank' href='https://necta.atlassian.net/servicedesk/customer/portal/3'>Help</a>.</>}
        icon={icon || <img src={ConstructionSvg} alt={'Under construction'} />}
        extra={
          <Space>
            <Button onClick={_handleBack} type={'primary'}>Go Home</Button>
            { children }
          </Space>
        }
      />
    </Page>
  );
};

UnderConstruction.defaultProps = {};

export default UnderConstruction;
