import { Yup } from '../../hooks';
import {
  generatePassword,
  PASSWORD_ERROR,
  regExPassword,
  regExEmail,
  EMAIL_ERROR
} from '../../utils';

const validateRole = (role: string, schema: any) => {
  if (role !== 'Admin') return Yup.string().required('Please select an organisation.')
  return Yup.string().nullable();
}

/** base schema for editing/viewing profiles */
export const userSchema = Yup.object().shape({
  email: Yup
    .string()
    .trim()
    .required()
    .matches(regExEmail, EMAIL_ERROR)
    .label('Email'),
  password: Yup
    .string()
    .required()
    .matches(regExPassword, PASSWORD_ERROR)
    .label('Password'),
  firstname: Yup
    .string()
    .required()
    .label('First Name(s)'),
  lastname: Yup
    .string()
    .required()
    .label('Last Name'),
  contactNumberCountryCode: Yup.string()
    // .required('Please provide a country code!')
    .nullable()
    .label('Country Code')
    .default('+27'),
  contactNumber: Yup.string()
    // .required('Please provide a country code!')
    .nullable()
    .label('Contact Number'),

  role: Yup
    .string()
    .default('User')
    .required()
    .label('Role'),
  activeOrgId: Yup
    .string()
    // .required()
    .when('role', validateRole)
    .label('Organisation'),
  profilePicUrl: Yup
    .string()
    .nullable()
    .label('Profile Picture'),
  medicalAidScheme: Yup
    .string()
    .nullable()
    .label('Medical Aid Scheme'),
  medicalAidNumber: Yup
    .string()
    .nullable()
    .label('Medical Aid Number'),
  idNumber: Yup
    .string()
    .nullable()
    .label('ID Number'),
  passportNumber: Yup
    .string()
    .nullable()
    .label('Passport Number'),

  address: Yup.object().shape({
    addressLine1: Yup
      .string()
      .nullable()
      .label('Address Line 1'),
    state: Yup
      .string()
      .nullable()
      .label('Province'),
    country: Yup
      .string()
      .nullable()
      .label('Country'),
    postalCode: Yup
      .string()
      .nullable()
      .label('Postal Code')
  }).nullable().label('Search for address'),
  bio: Yup
    .string()
    .nullable()
    .label('Bio')
})

  /** used mainly for imports */
export const baseUserSchema = Yup.object().shape({

    email: Yup
      .string()
      .trim()
      .required('Email is required')
      .typeError('Email is required')
      .matches(regExEmail, EMAIL_ERROR)
      .label('Email'),
    password: Yup
      .string()
      .required('A strong password is required')
      .matches(regExPassword, PASSWORD_ERROR)
      .default(generatePassword())
      .meta(['password'])
      .label('Password'),
    firstname: Yup
      .string()
      .required('First name is required')
      .typeError('First name is required')
      .label('First Name(s)'),
    lastname: Yup
      .string()
      .required('Last name is required')
      .required('Last name is required')
      .label('Last Name'),
    contactNumberCountryCode: Yup.string()
      .nullable()
      .notRequired()
      .label('Country Code')
      .default('+27'),
    contactNumber: Yup.string()
      .nullable()
      .notRequired()
      .label('Contact Number'),
    medicalAidScheme: Yup
      .string()
      .notRequired()
      .nullable()
      .label('Medical Aid Scheme'),
    medicalAidNumber: Yup
      .string()
      .notRequired()
      .nullable()
      .label('Medical Aid Number'),
    idNumber: Yup
      .string()
      .notRequired()
      .nullable()
      .label('ID Number'),
    // role: Yup
    //   .string()
    //   .default('User')
    //   .required()
    //   .label('Role'),

    address: Yup.object().shape({
      addressLine1: Yup
        .string()
        .nullable()
        .notRequired()
        .label('Address Line 1'),
      state: Yup
        .string()
        .nullable()
        .notRequired()
        .label('Province'),
      country: Yup
        .string()
        .nullable()
        .notRequired()
        .label('Country'),
      postalCode: Yup
        .string()
        .nullable()
        .notRequired()
        .label('Postal Code')
    }).nullable().notRequired().label('Search for address'),
    bio: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Bio'),
    shortid: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Shortid'),
    // category: Yup.string()
    //   .nullable()
    //   .notRequired()
    //   .oneOf([null, ...CATEGORIES])
    //   .meta(['select'])
    //   .label('Category'),
});

export const playerSchema = baseUserSchema.shape({
  // isPlayer: Yup
  //   .boolean()
  //   .default(true)
  //   .label('Player'),
  dateOfBirth: Yup.date()
    //  .nullable()
    .required('Please enter a valid date of birth')
    .typeError('Please enter a valid date of birth')
    .meta(['date'])
    .label('Date of Birth'),
});

export const playerStatsSchema = Yup.object().shape({
  weight: Yup
    .string()
    .nullable()
    .label('Weight (kg)'),
  height: Yup
    .string()
    .nullable()
    .label('Height (cm)'),
  language: Yup
    .string()
    .nullable()
    .label(' Language'),
  aimobile: Yup
    .string()
    .nullable()
    .label('AI Mobile Operations'),
  aiop: Yup
    .string()
    .nullable()
    .label('AI Operation'),
  scorer: Yup
    .string()
    .nullable()
    .label('Scorer'),
  camop: Yup
    .string()
    .nullable()
    .label('Camera Operator'),
  producer: Yup
    .string()
    .nullable()
    .label('Producer'),
  cregion: Yup
    .string()
    .nullable()
    .label(' Region'),
  experience: Yup
    .string()
    .nullable()
    .label(' Experience'),  
  clinear: Yup
    .string()
    .nullable()
    .label('Linear'),
  csports: Yup
    .string()
    .nullable()
    .label(' Language'),
  ccomments: Yup
    .string()
    .nullable()
    .label(' Comments'),
  shoeSize: Yup
    .string()
    .nullable()
    .label('Shoe Size (UK)')
})

export const parentContactSchema = Yup.object().shape({
  parentContactNumberCountryCode: Yup
    .string()
    .nullable()
    .label('Country Code')
    .default('+27'),
  parentContactNumber: Yup
    .string()
    .nullable()
    .label('Parental Contact Number'),
  parentContactEmail: Yup
    .string()
    .trim()
    .matches(regExEmail, EMAIL_ERROR)
    .label('Parent Contact Email'),
})

export const userEditSchema = userSchema.shape({
  password: Yup
    .string()
    .matches(regExPassword, PASSWORD_ERROR)
    .label('Password'),
  activeOrgId: Yup
    .string()
    .nullable()
    .label('Org')
})

/** use when editing/viewing a player */
export const playerEditSchema = userEditSchema.shape({

  dateOfBirth: Yup.date()
  //  .nullable()
    .required('Please enter a valid date of birth')
    .typeError('Please enter a valid date of birth')
    .meta(['date'])
    .label('Date of Birth'),
}).concat(parentContactSchema)
  .concat(playerStatsSchema)

/** use when adding players */
export const playerAddSchema = userSchema.shape({
  dateOfBirth: Yup.date()
  //  .nullable()
    .typeError('Please enter a valid date of birth')
    .required('Please enter a valid date of birth')
    .meta(['date'])
    .label('Date of Birth'),
  isPlayer: Yup
    .boolean()
    .default(true)
}).concat(parentContactSchema)
  .concat(playerStatsSchema)

export const coachSchema = baseUserSchema.shape({
  // isCoach: Yup
  //   .boolean()
  //   .default(true),
})

export const coachAddSchema = userSchema.shape({
  isCoach: Yup
    .boolean()
    .default(true),
})

/** use then editing/viewing a coach */
export const coachEditSchema = userEditSchema.shape({

})


/** used for confirming imports */
export const confirmUserImportSchema = Yup.object().shape({
  organisationId: Yup.string()
    .required('Please select an organisation')
    .typeError('Please select an organisation')
    .label('Organisation'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});

export type Player = Yup.InferType<typeof playerSchema>;
export type EditPlayer = Yup.InferType<typeof playerEditSchema>;
export type AddPlayer = Yup.InferType<typeof playerAddSchema>;

export type Coach = Yup.InferType<typeof coachSchema>;
export type EditCoach = Yup.InferType<typeof coachEditSchema>;
