import * as Yup from 'yup'

export const newTeamsheetSchema = Yup.object().shape({
  fixtureId: Yup.string().required().label('Fixture'),
  name: Yup.string().required().label('Name'),
  description: Yup.string().nullable().label('Description'),
  organisationId: Yup.string().label('Organisation'),
  primaryImage: Yup.string().label('Image'),
  teamId: Yup.string().nullable().label('Linked Team'),
  ageGroup: Yup.string().nullable().label('Age Group'),
})

export const teamsheetSchema = Yup.object().shape({
  name: Yup.string().required().label('Name'),
  description: Yup.string().nullable().label('Description'),
  organisationId: Yup.string().nullable().label('Organisation'),
  sportId: Yup.string().nullable().label('Sport'),
  primaryImage: Yup.string().nullable().label('Image'),
  captainId: Yup.string().nullable().label('Captain'),
  headCoachId: Yup.string().nullable().label('Head Coach'),
  teamId: Yup.string().nullable().label('Linked Team'),
  ageGroup: Yup.string().nullable().label('Age Group'),
})

export const teamsheetMemberSchema = Yup.object().shape({
  firstname: Yup.string().label('First Name'),
  lastname: Yup.string().label('Last Name'),
  nickname: Yup.string().nullable().label('Nick Name'),
  description: Yup.string().nullable().label('Description'),
  primaryImage: Yup.string().nullable().label('Picture'),
  tags: Yup.array().nullable().label('Tags'),
  teamRoleId: Yup.string().nullable().label('Role'),
  teamRoleAltId: Yup.string().nullable().label('Alternative Role'),
  isHeadCoach: Yup.boolean().label('Head Coach'),
  isCaptain: Yup.boolean().label('Team Captain'),
  archived: Yup.boolean().label('archived')
})

export const newTeamsheetMemberSchema = Yup.object().shape({
  firstname: Yup.string().label('First Name'),
  lastname: Yup.string().label('Last Name'),
  nickname: Yup.string().nullable().label('Nick Name'),
  description: Yup.string().nullable().label('Description'),
  primaryImage: Yup.string().nullable().label('Picture'),
  userId: Yup.string().nullable().label('User'),
  tags: Yup.array().label('Tags'),
  teamRoleId: Yup.string().label('Role'),
  teamRoleAltId: Yup.string().label('Alternative Role'),
  isHeadCoach: Yup.boolean().label('Head Coach'),
  isCaptain: Yup.boolean().label('Team Captain'),
  isPlayer: Yup.boolean().label('Player'),
  isCoach: Yup.boolean().label('Coach')
})

export type NewTeamsheet = Yup.InferType<typeof newTeamsheetSchema>
export type UpdateTeamsheet = Yup.InferType<typeof teamsheetSchema>
export type NewTeamMember = Yup.InferType<typeof newTeamsheetMemberSchema>
export type TeamsheetMember = Yup.InferType<typeof teamsheetMemberSchema>

export default newTeamsheetSchema
