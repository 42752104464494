import React, { useMemo } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Button, Popconfirm } from 'antd'
import { Avatar } from '../Avatar'
import { TeamRole } from '../TeamRole'

interface ColumnContext {
    filters: any,
    handleCancel: () => void,
    handleRemove: (id: string) => (e?: any) => void,
    allTeamRoles: any
}

export const useColumns = (context: ColumnContext) => {

    const {
        filters, 
        handleCancel,
        handleRemove,
        allTeamRoles
    } = context

    return useMemo(() => (
        [
            {
              title: 'Picture',
              key: 'primaryImage',
              export: false,
              render: (r: any) => <Avatar src={getThumbnail(r)} icon={<UserOutlined />} inverse />
            },
            {
              title: 'Role',
              key: 'role',
              dataIndex: [],
              render: (row: any) => <TeamRole row={row} />
            },
            {
              title: 'First Name',
              filtering: true,
              sorting: true,
              key: 'firstname',
            },
            {
              title: 'Last Name',
              filtering: true,
              sorting: true,
              key: 'lastname',
            },
            {
              title: 'Nickname',
              filtering: true,
              sorting: true,
              key: 'nickname',
            },
            {
              title: 'Primary Role',
              filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
              sorting: true,
              key: 'teamRole.name',
            },
            {
              title: 'Secondary Role',
              filtering: allTeamRoles?.length > 0 ? { ...filters } : true,
              sorting: true,
              key: 'teamRoleAlt.name'
            },
            { title: '', key: 'id', render: (id: string) =>
                <Popconfirm
                  title='Are you sure you want to remove this team member?'
                  onConfirm={handleRemove(id)}
                  onCancel={handleCancel}
                  okText='Yes'
                  cancelText='No'
                >
                  <Button onClick={handleCancel} type='link' danger>Remove</Button>
                </Popconfirm>
            },
        ]
    ), [filters, handleCancel, handleRemove, allTeamRoles])

}
