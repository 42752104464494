import { useEffect, useCallback } from 'react'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { useSelectors } from '../use-selectors'
import { useHistory } from 'react-router-dom'

type AuthRedirectHook = (path: string, predicate: () => boolean) => void

/**
 * An authentication hook that triggers a redirect based on a boolean predicate.
 * @param path The path to redirect to.
 * @param predicate The functional predicate that triggers the redirect.
 */
export const useAuthRedirect: AuthRedirectHook = (path, predicate) => {
    const history = useHistory()

    useEffect(() => {
        //review trigger condition
        if (!predicate) history.push(path)
    }, [predicate, path])
}