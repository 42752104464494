import moment from 'moment';
import { get } from 'lodash';
import Moment from 'moment';

export const getAge = (date: string, period: 'years' | 'months' | 'days' = 'years') => {
  if (date == null) return '';
  const dob = moment(date);
  const current = moment();
  const age = current.diff(dob, period);
  return age;
};

export const checkRange = (range: string, toTest: number | string): boolean => {
  if (range.includes('-')) {
    const split = range.split('-');
    const tLower = parseInt(split[0]);
    const tUpper = parseInt(split[1]);
    if (toTest >= tLower && toTest <= tUpper) return true;
  } else if (parseInt(range) === toTest) {
    return true;
  }
  return false;
};

export const findAge = (terms: any, dateOfBirth: any, period: 'years' | 'months' | 'days' = 'years'): boolean => {
  if (terms.length <= 0) return true;
  if (dateOfBirth == null) return false;
  const dob = getAge(dateOfBirth, period);

  if (Array.isArray(terms)) {
    for (const t of terms) {
      if (checkRange(t, dob)) return true;
    }
  } else {
    if (checkRange(terms, dob)) return true;
  }

  return false;
};

export const getDateShort = (date: string) => {
  if (date == null) return '';
  const d = moment(date);
  return d.format("DD MMM 'YY");
};

export const getDateFull = (date: string) => {
  if (date == null) return '';
  const d = moment(date);
  return d.format("DD MMM 'YY - HH:mm");
};

export const getDateFormatted = (date: string) => {
  if (date == null) return '';
  const d = moment(date);
  return d.format('YYYY/MM/DD');
};

export const findGenericExact = (terms: any, match: any): boolean => {
  if (terms.length <= 0) return true;
  if (match == null) return false;
  if (Array.isArray(terms)) {
    for (const t of terms) {
      if (t === match) return true;
    }
  } else {
    if (terms === match) return true;
  }
  return false;
};

export const findGeneric = (terms: any, match: any | any[]): boolean => {
  if (terms.length <= 0) return true;
  if (match == null) return false;
  const _match = Array.isArray(match) ? match.map((m: any) => m.toLowerCase()) : match.toLowerCase();
  if (Array.isArray(terms)) {
    for (const t of terms) {
      if (_match.includes(t.toLowerCase())) return true;
    }
  } else {
    if (_match.includes(terms.toLowerCase())) return true;
  }
  return false;
};

export const exportTableCSV = (columns: any[], data: any[], title: string) => {
  const cols = columns.filter((c: any) => c.show && c.export !== false);
  const dataToExport = data.map((d: any) => {
    return cols.map((c: any) => {
      if (c.getValues) {
        return c.getValues(get(d, c.key));
      }
      if (c.render) {
        const render = c.render(get(d, c.key));
        if (typeof render === 'string') return render;
      }
      return get(d, c.key);
    });
  });
  const headers = cols.reduce((obj, item) => {
    return { ...obj, [item.key]: item.title };
  }, {})
  const filename = 'Necta_' + title + '_Export_' + Moment().format('YYYY-MM-DD');
  exportToCSV(headers, dataToExport, filename);
}


const objectToCSVRow = (dataObject: any) => {
  const dataArray = [];
  for (const o in dataObject) {
    const innerValue = dataObject[o]===null ? '' : dataObject[o].toString();
    let result = innerValue.replace(/"/g, '""');
    result = '"' + result + '"';
    dataArray.push(result);
  }
  return dataArray.join(',') + '\r\n';
}

const exportToCSV = (headers: any[], arrayOfObjects: any[], filename: string) => {
  if (!arrayOfObjects.length) {
    return;
  }

  let csvContent = "data:text/csv;charset=utf-8,";

  // headers
  csvContent += objectToCSVRow(headers);

  arrayOfObjects.forEach(function(item){
    csvContent += objectToCSVRow(item);
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
}

export const renderArray = (row: any, key: string) => {
  if (!row || !row[key]) return '';
  if (row[key].length <= 0) return '';
  return row[key].join(', ');
}
