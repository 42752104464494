import React, { useState, useCallback } from 'react';
import { cloneDeep } from 'lodash'
import {
  Page,
  AuthGuard,
  FormLoading
} from '../../components';
import { Moment } from 'moment'
import { Fixture } from '../../redux'
import { FixtureTable } from './FixtureTable'
import { useFetchFixtureList } from '../../graphql'
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';
import {  Button, Space  } from 'antd'

declare const window: any;

interface FixtureListProps {
}

export const FixtureList: React.FC<FixtureListProps> = ({ ...props }) => {

  const selectedOrg = useSelector(getSelectedOrg);

  const [fixtures, setFixtures] = useState<Fixture[]>([])



let gapi = window.gapi

var CLIENT_ID = "363861410342-ugtivtjg1qisgqgfs8s1pr2tj998s7gc.apps.googleusercontent.com"
var API_KEY = "AIzaSyDOHJ-iE5ExosJAMwpKwa0QNraHtu1Nk28"
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.events"
  
  const handleClick = () => {
    gapi.load('client:auth2', () => {
    //  console.log('loaded client')
  
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name:'fixtures calendar',
      })
  
      gapi.client.load('calendar', 'v3', () => console.log('bam!'))
  
      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
  
        console.log(fixtures)
        
        var event = {  
          
          
            'summary': fixtures[1].name,
            'location': '',
            'description': fixtures[1].homeOrgName + ' vs ' + fixtures[1].awayOrgName ,
            'start': {
              'dateTime': fixtures[1].dateStart,
              'timeZone': 'Africa/Johannesburg'
            },
            'end': {
              'dateTime': fixtures[1].dateEnd,
              'timeZone': 'Africa/Johannesburg'
            },
            'recurrence': [
              'RRULE:FREQ=DAILY;COUNT=1'
            ],
            'attendees': [
              
            ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
              ]
            }
  
          }
          
        
  
        
        
  
         
        
  
        var request = gapi.client.calendar.events.insert({
          'calendarId': 'primary',
          'resource': event,
        })
  
        request.execute((event: any) => {
          //console.log(event)
          window.open(event.htmlLink)
        })
        
  
        /*
            Uncomment the following block to get events
        */
        
        // get events
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then((response: any) => {
          const events = response.result.items
         // console.log('EVENTS: ', events)
        })
        
    
  
      })
    })
  }
  
  const [fetch, { loading }] = useFetchFixtureList({
    variables: { fixtureQuery: {}, orgId: selectedOrg?.id },
    onCompleted: (result: any) => {
      if (result && result.fixtures) setFixtures(cloneDeep(result.fixtures))
      
    },
    onError: (error: any) => {
      message.error({ content: 'Something went wrong!\nCould not load fixtures...', duration: 4 })
    },
    fetchPolicy: 'network-only'
  })
  
  

  if (loading) return <FormLoading />

  return (
    <AuthGuard needsActiveOrg>
      <Page card>
        <FixtureTable withUpdate onRefresh={fetch} loading={loading} fixtures={fixtures} />
        <Button type="primary"  onClick={handleClick}>Add Fixtures to Calendar</Button>
      </Page>
    </AuthGuard>
  )
};

export default FixtureList;
