import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { useSports, useCurrentSport } from '../../redux'
import { FieldContainer } from '../FieldContainer'
import { isDisabled } from './util'
import { isEmpty } from 'lodash'
import { TeamRole } from './types'

const { Option } = Select

interface TeamRoleSelectorProps extends React.HTMLAttributes<HTMLElement>{
    disabled?: boolean,
    label?: string,
    required?: boolean,
    hidden?: boolean,
    gridProps?: ColProps,
    defaultValue?: any,
    name?: string,
    onChange?: (value: any) => void,
    //TODO: define these properly
    valueKey?: keyof TeamRole,
    labelKey?: keyof TeamRole,
    getOptions?: (orgs: Partial<TeamRole>[]) => React.ReactNode[],
    //TODO: update this to a more sophisticated prop
    getFormik?: boolean,
    isPlayer?: boolean,
    isCoach?: boolean,
    sport?: string
}


//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const TeamRoleSelectorComponent: React.FC<TeamRoleSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
    { sport, isPlayer = false, isCoach = false, getFormik = true, getOptions, labelKey = 'name', valueKey = 'id', name = '', onChange, disabled, label, required, hidden, gridProps, className, defaultValue, ...props }
) => {


    //the current selected value state if no formik context
    const [selected, setSelected] = useState<string>('')

    // fetch loaded sports from config
    const [sportList] = useSports()
    // fetch currently active sport's name if exists
    const [{ name: currentSport }] = useCurrentSport()
    // conditionally fall back to currently active sport if sport prop if undefined
    const _sport = useMemo(() => sport ? sport : currentSport.name, [currentSport, sport])
    // fetch respective teamRoles object from sport chosen above
    const { teamRoles } = useMemo(() => sportList.find((sport: any) => sport[valueKey] === _sport) ?? [], [_sport, sportList, valueKey])

    const formik = useFormikContext() || {}
    const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

    /**
     * onChange handler to select component.
     * defaults to local state if formik context is empty.
     * calls optional onChange prop to parent component.
     */
    const handleOnChange = useCallback((value: any) => {
        const _value = value === undefined ? null : value; // Ensure value is not set as undefined
        if (isFormik) formik.setFieldValue(name, _value)
        if (onChange) onChange(_value)
        setSelected(_value)
    }, [name, formik, onChange, isFormik])

    /**
     * Select Options constructor. Builds an array of options children from loaded role list.
     */
    const _getOptions = useCallback(() => teamRoles && teamRoles.map((teamRole: TeamRole) => (
        <Option
            disabled={isDisabled(isCoach, isPlayer, teamRole)}
            key={teamRole.id}
            value={teamRole[valueKey] as string}
            label={teamRole[labelKey]}
        >
            {teamRole[labelKey]}
        </Option>
    )), [teamRoles, isCoach, isPlayer, valueKey, labelKey])

    useEffect(() => {
        if (teamRoles?.length > 0 && !selected && defaultValue) handleOnChange(defaultValue)
    }, [teamRoles, defaultValue])

    //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)

    //if formik context is not empty, render with Formik FieldContainer
    if (isFormik) return (
        <FieldContainer
            { ...gridProps }
            name={name}
            required={required}
            label={label}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <FormikSelect
                prefix={<TeamOutlined className="site-form-item-icon" />}
                name={name}
                onChange={handleOnChange}
                disabled={disabled}
                {...props}
            >
                { /* call option getOptions prop to fetch children. default to map over roles. */}
                {getOptions ? getOptions(teamRoles) : _getOptions()}
            </FormikSelect>
        </FieldContainer>
    )

    //fallback return using no formik context
    return (
        <Select
            value={selected}
            prefix={<TeamOutlined className='site-form-item-icon' />}
            placeholder={'Pick a role'}
            className={`${className} ant-team-role-selector`}
            onChange={handleOnChange}
            disabled={disabled}
            {...props}
        >
            {teamRoles?.length > 0 && (getOptions ? getOptions(teamRoles) : _getOptions())}
        </Select>
    )
}

TeamRoleSelectorComponent.defaultProps = {
    allowClear: true,
    showSearch: true,
    optionFilterProp: 'label',
    getFormik: true,
}

export const TeamRoleSelector = styled(TeamRoleSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
