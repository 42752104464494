import { useState } from 'react'
import { useSetCognitoUser } from '@bit/necta.hooks.cognito-user'
import { logout } from '@necta-tech/cognito'

interface Options {
    onLogout?: () => void
}

/**
 * useLogout is a hook meant to generate a logout handler callback whenever a logout routine
 * is required in a parent component. It mutates the redux store's cognitoUser property and thus 
 * requires '@necta-tech/cognito' and '@bit/necta.hooks.cognito-user' as peer dependencies.
 * @param options Optional handlers executed at different stages of the logout process.
 * @returns An array containing a logout callback an an object with the logout context.
 */
export const useLogout = (options: Options = {}) => {
    const setCognitoUser = useSetCognitoUser()
    const [result, setResult] = useState(null)
    const [error, setError] = useState(null)

    const { onLogout } = options

    const handleLogout = async () => {
        try {
            const result = await logout()
            setResult(result)
            setCognitoUser({ inSession: false })
            if (onLogout) onLogout()
        } catch(e) {
            setError(e)
        }
    }

    return [
        handleLogout,
        {
            result,
            loading: !!result,
            error
        } as const
    ] as const;
}

export default useLogout