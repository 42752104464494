import { createGlobalStyle } from 'styled-components'
import '../styles/vars.less'

export const Global = createGlobalStyle<{theme: any}>`
  body, textarea {
    margin: 0;
    // font-family: 'Century Gothic', 'Franklin Gothic Medium', Arial, sans-serif;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  h1, h2, h3 {
    // font-family: 'Century Gothic', 'Franklin Gothic Medium', Arial, sans-serif;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #0F4C81;
  }

  header.header {
    background-color: ${p => p.theme.primaryColor} ;
  }

  header.ant-layout-header {
    h1 {
      color: white;
    }
     button.ant-btn {
       color: white;
     }
  }

`;