import React from 'react';
import TextFilter from '../../components/text-filter';
import { get } from 'lodash';
import { findAge } from '../../../../helpers';

const elements: any = {
  searchInput: null,
};

const setSearchInput = (el: any) => {
  elements.searchInput = el;
}

export interface IFilterProps {
  setSelectedKeys: any;
  selectedKeys: any;
  clearFilters: any;
  confirm: any;
}

const defaultFilter = (col: any) => ({
  filterDropdown: (filterProps: IFilterProps) => <TextFilter col={col} setSearchInput={setSearchInput} {...filterProps} />,
  // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: col?.filtering?.customFilter ? col?.filtering?.customFilter(col) : basicFilter(col),
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => elements.searchInput.select(), 100);
    }
  },
});

const selectFilter = (col: any) => ({
  // filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: col?.filtering?.customFilter ? col?.filtering?.customFilter(col) : basicFilter(col)
});

const dateRangeFilter = (period?: any) => (col: any) => (value: any, record: any) => {
  const id = typeof col.dataIndex === 'string' ? col.dataIndex : col.dataIndex.join('.');
  const val = col.getValues ? col.getValues(get(record, id)) : get(record, id);
  if (val == null) return '';
  return findAge(value, val, period)
}

export const basicFilter = (col: any) => (value: any, record: any) => {
  const id = typeof col.dataIndex === 'string' ? col.dataIndex : col.dataIndex.join('.');
  const val = col.getValues ? col.getValues(get(record, id)) : get(record, id);
  if (val == null) return '';
  return val.toString().toLowerCase().includes(value.toLowerCase());
}

export const getColFilters = (col: any) => {
  if (!col || !col.filtering) return {};
  if (col.filtering === true) return Filter.DEFAULT(col);
  const { type, ...otherFilterProps } = col.filtering;
  return { ...type(col), ...otherFilterProps };
};

export const Filter = {
  DEFAULT: defaultFilter,
  SELECT: selectFilter,
  DATE_RANGE: dateRangeFilter,
};
