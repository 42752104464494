import React from 'react'
import { CloseOutlined as Close } from '@ant-design/icons'
import { PageAction } from './page'

interface ImportCancelProps {
    onClick: (e?: Event) => void,
    hidden?: boolean,
    disabled?: boolean,
    tooltip?: string
}

export const ImportCancelAction: React.FC<ImportCancelProps> = ({ tooltip, disabled, hidden, onClick, ...props }) => {

    return (
        <PageAction 
            hidden={hidden} 
            disabled={disabled} 
            tooltip={ tooltip ?? 'Cancel Import' }
            onClick={onClick}
        >
            <Close />
        </PageAction>
    )
}