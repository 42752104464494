import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { presentError } from '@necta-tech/alert';

import { uploadFile, checkLimit, DEFAULT_LIMIT, uploadError } from '@bit/necta.hooks.s3'

import Dropzone from 'react-dropzone';

//change component to MuiContainer if buggy
export const CContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
  color: white;
  & > h3 {
    text-align: left;
    width: 100%;
  }
  & > p {
    text-align: left;
    width: 100%;
  }
`;

export const PfpDiv = styled.div`
  border: 2px dashed rgb(10, 53, 90);
  border-image: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

export const DropzoneInner = styled.div`
  height: 100%;
  color: black;
  text-align: center;
  padding: 3rem 2rem;
`;

type FileUploadProps = {
  id?: string;
  path: string;
  type?: string;
  single?: boolean;
  limit?: number;
  level?: string;
  name?: string;
  useFileNames?: boolean;
  onComplete?: Function;
  onAllComplete?: Function;
  defaultFile?: File[];
};

export const FileUpload = ({ id, path, type, single, limit, level, name, useFileNames, onComplete, onAllComplete, defaultFile }: FileUploadProps) => {
  const [loading, setLoading] = useState(false);

  const _path = id ? id + (path ? '/' + path : '') : path;

  const _limit = limit ? limit : DEFAULT_LIMIT;

  const uploadSingle = useCallback((file: any) => {
    setLoading(true);
    uploadFile(file, { path: _path, prefix: 'profilepic' }).then(result => {
      setLoading(false);
      if (onComplete) onComplete(result.key);
    });
  }, [setLoading, onComplete, _path]);

  const fileSelected = useCallback((files: any[]) => {
    // if (!loading) {
      if (single === true) {
        uploadSingle(files[0]);
        return;
      }
      setLoading(true);
      checkLimit(_path, files.length, _limit)
        .then(() => {
          const promises: any[] = [];
          for (const f of files) {
            const customName = name ? name : useFileNames ? f.name : null;
            promises.push(
              uploadFile(f, { path: _path, level, customName }).then(({ key }) => {
                if (onComplete) onComplete({ name: f.name, key, documentClass: type });
              })
            );
          }
          Promise.all(promises)
            .then(() => {
              // Done
              setLoading(false);
              if (onAllComplete) onAllComplete();
            })
            .catch(ex => {
              presentError(ex, 'Unable to upload all files');
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
          uploadError('The max amount of files allowed in this directory is ' + _limit + '. Please delete some files to make space.');
        });
    // }
  }, [uploadSingle, setLoading, onComplete, onAllComplete, _path, level, _limit, name, single, type, useFileNames]);

  const handleFileSelect = useCallback((files: any[]) => {
    if (!loading) fileSelected(files);
  }, [loading, fileSelected]);

  useEffect(() => {
    if (defaultFile != null) {
      fileSelected(defaultFile);
    }
  }, [defaultFile, fileSelected]);

  return (
    <CContainer>
      <PfpDiv>
        <Dropzone onDrop={acceptedFiles => handleFileSelect(acceptedFiles)} disabled={loading}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <DropzoneInner>
                  <h4> {!loading ? 'Drop files or click to upload' : 'Files are uploading...'} </h4>
                </DropzoneInner>
              </div>
            </section>
          )}
        </Dropzone>
      </PfpDiv>
    </CContainer>
  );
};

export default FileUpload;
