import React from 'react';
import { useHistory} from 'react-router-dom'
import styled from 'styled-components';
import { Layout, Card, Carousel, Row, Col, Button, Tabs, Affix} from 'antd';
import {IdcardOutlined, UserOutlined, AppstoreOutlined, TeamOutlined, CalendarOutlined, MobileOutlined, TrophyOutlined} from '@ant-design/icons'
// SVGS
import {ReactComponent as PPBar} from '../assets/welcome/pp_bar.svg';
import {ReactComponent as  PPBarSM} from '../assets/welcome/pp_bar_sm.svg';
// LOGOS
import SSSLogo from '../assets/welcome/sss_logo.png';
// IMAGES
import Mobile1 from '../assets/welcome/mobile1.png';
import Mobile2 from '../assets/welcome/mobile2.png';
import Desktop from '../assets/welcome/desktop.png';
import Team from '../assets/welcome/team.png';
import Equip from '../assets/welcome/equip.png';
import Goalee from '../assets/welcome/goalee.png';
import Roadmap from '../assets/welcome/roadmap.png';
//COMPONENTS
import { SportList } from '../components/welcome-components/sport-list';
import { SCROLL_ID } from '../app';


const { Content } = Layout;
const { TabPane } = Tabs;

export const DesignNav = styled.div`
    display: flex;
    justify-content: end;
`;

export const PContent = styled(Content)`
    padding-top: 40px;
`;

interface CProps {
    pad?: string;
}
export const Container = styled.div<CProps>`

    @media only screen and (min-width: 1400px) {
        width: 1400px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 20px';
            else if (p.pad === 'mdalt') return '40px 10px';
            return '0 20px';
        }};
    }

    @media only screen and (max-width: 1399px) {
        width: 1024px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 20px';
            else if (p.pad === 'mdalt') return '40px 10px';
            return '0 20px';
        }};
    }

    @media only screen and (max-width: 1024px) {
        width: 768px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 20px';
            else if (p.pad === 'mdalt') return '40px 10px';
            return '0 20px';
        }};
    }

    @media only screen and (max-width: 768px) {
        width: 480px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 20px';
            else if (p.pad === 'mdalt') return '40px 10px';
            return '0 20px';
        }};
    }
    
    @media only screen and (max-width: 480px) {
        width: 400px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 20px';
            else if (p.pad === 'mdalt') return '40px 10px';
            return '0 20px';
        }};
    }

    @media only screen and (max-width: 375px) {
        width: 330px;
        margin: 0 auto;
        padding: ${p => {
            if (p.pad === 'lg') return '60px 0px';
            else if (p.pad === 'md') return '40px 10px';
            else if (p.pad === 'mdalt') return '40px 0px';
            return '0px';
        }};
    }
`;

export const Heading = styled.h1`
    font-size: 36px;
    line-height: 44px;
    color: ${p => p.theme.primaryColor};

    @media only screen and (max-width: 1024px) {
        font-size: 24px;
        line-height: 26px;
    }
    
    @media only screen and (max-width: 480px) {
        font-size: 20px;
        line-height: 22px;
    }
`;

export const SubHeading = styled.h3`
    font-size: 24px;
    line-height: 30px;
    color: ${p => p.theme.primaryColor};

    @media only screen and (max-width: 1024px) {
        font-size: 22px;
        line-height: 24px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 22px;
    }
    
    @media only screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 20px;
    }
`;

export const MHeading = styled.h3<{ primary?: boolean }>`
    color: ${p => p.primary ? p.theme.primaryColor : '#666'};
`;

export const SCard = styled(Card)`
    box-shadow: 0px 2px 2px #EEE;
    margin: 0 auto;
    border-radius: 5px !important;
    padding: 40px 40px;
    height: 100%;

    @media only screen and (max-width: 1399px) {
        padding: 20px 10px;
    }
    
    @media only screen and (max-width: 1200px) {
        padding: 10px 0px;
    }


    @media only screen and (max-width: 968px) {
        padding: 0;
    }
`;

export const Text = styled.p`
    color: #666;
    line-height: 2em;
`;

export const List = styled.ul`
    color: #666;
    line-height: 2.5em;
    margin-left: -16px;
`;

interface GProps {
    size?: string;
}
export const ImgGrid = styled(Col)<GProps>` 
    display: grid;
    align-content: center;
    justify-content: center;

    @media only screen and (min-width: 1400px) {
        height: ${p => {
            if (p.size === 'lg') return '400px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1399px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1024px) {
        height: ${p => {
            if (p.size === 'lg') return '200px';
            else if (p.size === 'md') return '200px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 768px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }
    
    @media only screen and (max-width: 480px) {
        height: ${p => {
            if (p.size === 'lg') return '250px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '200px';
        }};
    }
`;

interface FlexProps {
    size?: string;
}
export const ImgFlex = styled(Col)<FlexProps>` 
    display: flex;
    align-content: center;
    justify-content: center;

    @media only screen and (min-width: 1400px) {
        height: ${p => {
            if (p.size === 'lg') return '400px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1399px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1024px) {
        height: ${p => {
            if (p.size === 'lg') return '200px';
            else if (p.size === 'md') return '200px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 768px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }
    
    @media only screen and (max-width: 480px) {
        height: ${p => {
            if (p.size === 'lg') return '250px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }
`;

interface IProps {
    size?: string;
}
export const ISizer = styled.img<IProps>`
    @media only screen and (min-width: 1400px) {
        height: ${p => {
            if (p.size === 'lg') return '400px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1399px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 1024px) {
        height: ${p => {
            if (p.size === 'lg') return '250px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }

    @media only screen and (max-width: 768px) {
        height: ${p => {
            if (p.size === 'lg') return '300px';
            else if (p.size === 'md') return '300px';
            else if (p.size === 'sm') return '200px';
            return '100%';
        }};
    }
    
    @media only screen and (max-width: 480px) {
        height: ${p => {
            if (p.size === 'lg') return '250px';
            else if (p.size === 'md') return '200px';
            else if (p.size === 'sm') return '200px';
            return '200px';
        }};
    }
`;

interface PProps {
    pad?: string;
}
export const PanelVariant = styled.div<PProps>`
    padding: ${p => {
        if (p.pad === 'mixed') return '30px 0 60px 0';
        else if (p.pad === 'top-lg') return '30px 0';
        else if (p.pad === 'top-xs') return '10px 0';
        return 'none';
    }};
`;

export const ButtonSizer = styled.div`
    display: grid; 
    padding: 40px 20px;

    @media only screen and (max-width: 1399px) { 
        padding: 40px 20px;
    }
`;

export const TextCenter = styled.div`
    text-align: center;
`;

const OrgIco = styled(AppstoreOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const CoachIco = styled(IdcardOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const PlayerIco = styled(UserOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const TeamIco = styled(TeamOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const CalendarIco = styled(CalendarOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const MobileIco = styled(MobileOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

const FestIco = styled(TrophyOutlined)`
    font-size: 2em;
    color: ${p => p.theme.primaryColor} !important;
    padding-bottom: 20px;
`;

export const TabButtonSizer = styled.div`
    display: grid; 
    padding: 40px 0 0 0;
`;

const PCarousel = styled(Carousel)`
    margin-top: -80px;
    color: ${p => p.theme.primaryColor};
    line-height: 160px;
    text-align: left;
    background-image: linear-gradient(transparent 20%, white 1%, white 66.66%, white 90%, transparent 10%);
    
    && .slick-dots li button {
        margin-top: 25px;
        background: ${p => p.theme.primaryColor} !important;
    }

    @media only screen and (max-width: 1024px) {
        margin-top: -30px;
    }

    @media only screen and (max-width: 990px) {
        height: 100%;
        background: #fff;
        margin-top: 0;
        border-radius: 5px;
        text-align: center;
    }
`;

const Slide = styled.div`
  height: 400px;
  padding: 0 200px 0 200px;

  @media only screen and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) {
    padding: 0 200px 0 200px;
    height: 300px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 100px 0 100px;
    height: 100%;
  }

  @media only screen and (max-width: 990px) {
    padding: 50px;
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    padding: 40px;
    height: 100%;
  }
  
  @media only screen and (max-width: 480px) {
    padding: 20px;
    height: 100%;
  }
`;

const SlideContent = styled(Col)`
    display: grid;
    align-content: center;
    padding-top: 40px;

    @media only screen and (min-width: 1400px) {
    }
  
    @media only screen and (max-width: 1399px) {
    }
  
    @media only screen and (max-width: 1024px) {
        padding: 30px 0 30px;
    }

    @media only screen and (max-width: 990px) {
        padding: 20px 0 20px;
      }
`;

const SlideImage = styled(Col)`
    display: grid;
    justify-items: center;
    align-content: center;
`;

const ClientLogo = styled.div`
    padding: 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

const LogoImage = styled.img `
    width: 100%;
`;

const PlayBar = styled(PPBar)`
    width: 100%;
    height: auto;
    padding: 40px 0;

    @media only screen and (max-width: 990px) {
        display: none;
    }
`;

const PlayBarSM = styled(PPBarSM)`
    width: 100%;
    height: auto;
    padding: 40px 0;
    
    @media only screen and (min-width: 991px) {
        display: none;
    }
`;


const PTabs = styled(Tabs)`
    && {   
        .ant-tabs-nav-list {
            padding: 0 10px;
            display: flex;
        }
        .ant-tabs-tab-btn{
            text-align: center;
            color: #7a7a7a;
            text-align: center;
            width: 100%;
        }
        .ant-tabs-tab {
            text-align: center;
            transition: background 0.2s ease;
            background: #fff;
            flex: 1;
            margin: 0 -1px 0 -1px;
            height: 40px;
            box-shadow: 0px 1px 1px #ccc;
            border-radius: 2px;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
            border: 1.5px solid ${p => p.theme.primaryColor} !important;
            color: #fff !important;
            transition: background 0.2s ease;
            background: ${p => p.theme.primaryColor} !important; 
            text-align: center;
        }
        .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #fff !important;
        }
        .ant-tabs-nav::before {
            border-bottom: none !important;
        }
        .ant-tabs-nav .ant-tabs-tab {
            border: 1.5px solid #C9D1E0;
            font-size: 16px;
        }
    }
`;

const Pane = styled(TabPane)`
    padding: 0 10px;
`;

export const Welcome: React.FC<any> = (props) => {

  const history = useHistory()

  const scrollTo = (id: string) => {
      setTimeout(() => {
        const el = document.getElementById(id);
        const parent = document.getElementById(SCROLL_ID);
        if (el && parent) {
            const offset = el.offsetTop - 100;
            try {
                parent.scroll({ top: offset, left: 0, behavior: 'smooth' })
            } catch (ex) {
                parent.scroll(offset, 0);
            }
        }
      })
  }

  return (
    <PContent>
        <Container>
            <PlayBar/>
            <PlayBarSM/>
            <PCarousel
                dots={false}
                autoplay
                autoplaySpeed={5000}>
                <Slide>
                    <Row gutter={20}>
                        <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Heading>Where <b><i>talent </i></b>meets <b><i>opportunity</i></b> </Heading>
                        </SlideContent>
                        <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                            <ISizer size={'lg'} alt='PlayPro Sport Management Platform' src={Team} />
                        </SlideImage>
                    </Row>
                </Slide>

                <Slide>
                    <Row gutter={20}>
                        <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                            <SubHeading>Keep track of <b><i>Players</i></b>, keep track of <b><i>Teams</i></b>.<br/> Stay ahead of the <b><i>game</i></b></SubHeading>
                        </SlideContent>
                        <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                            <ISizer size={'lg'} alt='PlayPro Sport Management Platform' src={Mobile1} />
                        </SlideImage>
                    </Row>
                </Slide>

                <Slide>
                    <Row gutter={20}>
                        <SlideContent xs={{ span: 24 }} lg={{ span: 12 }}>
                            <SubHeading>Help your future <b><i>Sport Stars</i></b> build their history as they <b><i>live</i></b> it</SubHeading>
                        </SlideContent>
                        <SlideImage xs={{ span: 24 }} lg={{ span: 12 }}>
                            <ISizer size={'lg'} alt='PlayPro Sport Management Platform' src={Desktop} />
                        </SlideImage>
                    </Row>
                </Slide>

            </PCarousel>
        </Container>


        <ButtonSizer>
            <Button type="primary" onClick={() => history.push('/contactus')}>Sign Up!</Button>
        </ButtonSizer>

        <Container pad={'md'}>
            <SubHeading style={{ paddingBottom: '20px' }}>Join the <strong><i>Best</i></strong></SubHeading>
            <Row gutter={30}>
            <Col className='gutter-row' xs={{ span: 12 }} md={{span: 8}} lg={{ span: 4 }}>
                <ClientLogo>
                    <LogoImage style={{ maxWidth: '100%' }} alt='PlayPro SuperSportSchools' src={SSSLogo} />
                </ClientLogo>
            </Col>
            </Row>
        </Container>

        <Container pad={'md'}>
            <SCard>
                <Heading>What is <b>PlayPro?</b></Heading>
                <MHeading>PlayPro is a software platform built for Sports Administration at schools, clubs, academies, and universities.</MHeading>
                <Text>
                    It allows Sports Administrators to create profiles of all Players and Coaches within their Organisation, and then link those Players and/or Coaches to a Sport and Team.<br/>
                    Player/Coach documentation can be added to their profiles, along with images and videos.
                </Text>
            </SCard>
        </Container>

        <Container  pad={'mdalt'}>
            <PTabs
            defaultActiveKey="1"
            centered
            type="card"
            renderTabBar={(props: any, TabBar: any) => <Affix offsetTop={5} target={() => document.getElementById(SCROLL_ID)}><TabBar {...props} /></Affix>}
            onChange={(newKey: string) => scrollTo(newKey)}
            >
                <Pane tab="Profiles" key="Profiles">
                    <PanelVariant pad={'mixed'} id="Profiles">
                            <Row gutter={[40,40]}>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <SCard>
                                        <Heading><b>Profiles</b></Heading>
                                        <br/>
                                        <MHeading>Manage the different aspects of your Sports with profiles and categories tailored to their needs.</MHeading>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Row gutter={20}>
                                            <Col span={8}>
                                                <TextCenter>
                                                    <TeamIco />
                                                    <SubHeading>Teams</SubHeading>
                                                </TextCenter>
                                            </Col>
                                            <Col span={8}>
                                                <TextCenter>
                                                    <CoachIco />
                                                    <SubHeading>Coaches</SubHeading>
                                                </TextCenter>
                                            </Col>
                                            <Col span={8}>
                                                <TextCenter>
                                                    <PlayerIco />
                                                    <SubHeading>Players</SubHeading>
                                                </TextCenter>
                                            </Col>
                                        </Row>
                                    </SCard>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <ImgGrid>
                                        <ISizer size={'lg'} alt='PlayPro Sport Management' src={Goalee}/>
                                    </ImgGrid>
                                </Col>
                                <Col span={24}>
                                    <SCard>
                                        <Row gutter={[20, 40]}>
                                            <Col span={24}>
                                                <SubHeading>Player Profiles</SubHeading>
                                                <Text>
                                                    A Player Profile is where all information pertaining to a Player within your school will be found. <br/>
                                                    The intention of one central profile is so that all info relating to Player can be consolidated and used as an effective history or CV for the Player; from documentation/forms to all the Sports they represent and the images/videos of them performing.
                                                </Text>
                                            </Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                <div>
                                                    <MHeading primary><b>A Profile has 5 Sections:</b></MHeading>
                                                </div>
                                                <PanelVariant pad={'top-xs'}>
                                                    <MHeading>1. Player Information</MHeading>
                                                    <Text>
                                                        Names, contact details, height, weight, and more.
                                                    </Text>
                                                </PanelVariant>
                                                <PanelVariant pad={'top-xs'}>
                                                    <MHeading>2. Sport Data</MHeading>
                                                    <Text>
                                                        This tab contains all the Sports the Player participates in, what their roles are in those Sports,
                                                        (i.e. Striker in Hockey) and any comments and/or Tags related to that particular Sport and Player.
                                                    </Text>
                                                </PanelVariant>
                                                <PanelVariant pad={'top-xs'}>
                                                    <MHeading>3. Teams</MHeading>
                                                    <Text>
                                                        The Teams tab is a dynamic list of all the Teams that Player is currently representing, and has previously represented, in each Sport.
                                                    </Text>
                                                </PanelVariant>
                                            </Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                <ImgGrid size={'lg'}>
                                                    <ISizer size={'lg'} alt='PlayPro Sport Management' src={Desktop} />
                                                </ImgGrid>
                                            </Col>
                                            <Col span={24}>
                                                <PanelVariant pad={'top-xs'}>
                                                    <MHeading>4. Documents</MHeading>
                                                    <Text>
                                                        Any documentation relating a Player can be stored in this central place. If a Coach or Admin ever needs anything that relates to this Player they simply need to search a Player and open their profile.
                                                    </Text>
                                                </PanelVariant>
                                                <PanelVariant pad={'top-xs'}>
                                                    <MHeading>5. Media</MHeading>
                                                    <Text>Sports-related pictures and/or videos can be stored in this tab and referred to at any point for coaching purposes, or whatever else the Organisation or Players might wish to use this media.
                                                    </Text>
                                                </PanelVariant>
                                            </Col>
                                        </Row>
                                    </SCard>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <SCard>
                                        <div>
                                            <ImgFlex size={'lg'}>
                                                <ISizer alt='PlayPro Sport Team Management Webapp Platform' src={Team} />
                                            </ImgFlex>
                                        </div>
                                        <div>
                                            <br/>
                                            <SubHeading>
                                                Team Profiles
                                            </SubHeading>
                                            <br/>
                                            <Text>
                                                A Team profile is a feature that is used to consolidate a group of Player profiles into an environment that will allow for Coaches to manage that particular Team (e.g. U16 Water polo, 1st Rugby etc.).
                                                <br />
                                                <br />
                                                In this feature primary and secondary roles or positions can be assigned to Team Members, notes or comments can be made, and Tags added to each Team Member (Tags like injuries of record, Sponsors, particular skill sets etc.)
                                            </Text>
                                        </div>
                                    </SCard>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <SCard>
                                        <div>
                                            <ImgFlex size={'lg'}>
                                                <ISizer alt='PlayPro Sport Coach Management Webapp Platform' src={Equip} />
                                            </ImgFlex>
                                        </div>
                                        <div>
                                            <br/>
                                            <SubHeading>
                                                Coach Profiles
                                            </SubHeading>
                                            <br/>
                                            <Text>
                                                As a Coach on PlayPro you will hold a similar profile to that of a Player, with the ability to view Sports that you Coach,
                                                Teams you are linked to, important Documents and Media on you and/or your Teams.
                                                <br />
                                                <br />
                                                You will also be able to view all Teams within your Organisation, and edit those which you are assigned as ‘Head Coach.’
                                            </Text>
                                        </div>
                                    </SCard>
                                </Col>
                            </Row>
                            <TabButtonSizer>
                                <Button type="primary" onClick={() => history.push('/contactus')}>Sign Up!</Button>
                            </TabButtonSizer>
                    </PanelVariant>
                </Pane>

                <Pane tab="Access" key="Access">
                    <PanelVariant pad={'mixed'} id="Access">
                        <Row gutter={[40,40]} style={{padding: '0 0 40px 0'}}>
                            <Col span={24}>
                                <SCard>
                                    <Heading><b>Access Levels</b></Heading>
                                    <br/>
                                    <MHeading>
                                        In PlayPro there are two key types of access to data; one being <b>edit rights</b>, and the other being <b>view rights</b>.
                                    </MHeading>
                                    <br/>
                                    <Text>
                                        As the names suggest <b>edit rights</b> enable a user to create, edit, remove and view information on Profiles and Teams.
                                        <b>View rights</b> only allow a user to look at information and nothing more (like download and/or change info).
                                    </Text>
                                    <Text>
                                        From the outset only pre-appointed Admins within an Organisation will have access to the system to create, edit and view information.
                                    </Text>
                                    <Text>
                                        <b>No member of another school/Organisation will be able to view personal information on your Players, Coaches and Teams.</b>
                                    </Text>
                                    <Text>
                                        A more extensive view of PlayPro’s privacy policy can be viewed <Button style={{marginLeft: '-14px'}} type='link' onClick={() => window.open(`${process.env.REACT_APP_S3_HOST}uploads/privacy-policy-playpro.pdf`)}>here.</Button>
                                    </Text>
                                </SCard>
                            </Col>
                            <Col lg={{ span: 24 }} xl={{ span: 8}}>
                                <SCard>
                                    <TextCenter>
                                        <OrgIco />
                                        <SubHeading>Organisation Admin</SubHeading>
                                    </TextCenter>
                                    <br/>
                                    <List>
                                        <li>Create, view and edit all Player profiles.</li>
                                        <li>Create, view and edit all Coach profiles.</li>
                                        <li>Create, view and edit all Teams and/or Team Members.</li>
                                        <li>Assign permissions to any User, eg: promote a Player’s rights to those of a Coach, or assign edit permissions to a Team for a Coach.</li>
                                    </List>
                                </SCard>
                            </Col>
                            <Col lg={{ span: 24 }} xl={{ span: 8}}>
                                <SCard>
                                    <TextCenter>
                                        <CoachIco />
                                        <SubHeading>Coaches</SubHeading>
                                    </TextCenter>
                                    <br/>
                                    <List>
                                        <li>View all Player and Coach profiles.</li>
                                        <li>View and edit only the Teams that are assigned to that Coach profile, e.g. Coach ‘John’ is assigned to U16 Rugby, U14 Netball and U15 Hockey and can therefore view and edit those.</li>
                                        <li>Edit roles of Team Members. </li>
                                        <li>View Teams they are not linked to.</li>
                                    </List>
                                </SCard>
                            </Col>
                            <Col lg={{ span: 24 }} xl={{ span: 8}}>
                                <SCard>
                                    <TextCenter>
                                        <PlayerIco />
                                        <SubHeading>Players</SubHeading>
                                    </TextCenter>
                                    <br/>
                                    <List>
                                        <li>View and Edit own profile.</li>
                                        <li>View Teams they are a part of.</li>
                                        <li>View Team Members of Teams they are a part of. </li>
                                    </List>
                                    <br/>
                                    <ImgFlex size='sm' style={{padding:'0'}}>
                                        <ISizer alt='PlayPro Mobile Friendly' src={Mobile2} />
                                    </ImgFlex>
                                </SCard>
                            </Col>
                        </Row>
                        <TabButtonSizer>
                            <Button type="primary" onClick={() => history.push('/contactus')}>Sign Up!</Button>
                        </TabButtonSizer>
                    </PanelVariant>
                </Pane>

                <Pane tab="Sports" key="Sports">
                    <SportList />
                </Pane>

                <Pane tab="Roadmap" key="Roadmap">
                    <PanelVariant pad={'mixed'} id="Access">
                        <Row gutter={[40,40]} style={{padding: '0 0 40px 0'}}>
                            <Col span={24}>
                                <SCard>
                                    <Row gutter={[15,20]}>
                                        <Col xs={{span: 24}} lg={{span: 12}}>
                                            <Heading><b>Roadmap</b></Heading>
                                            <br/>
                                            <MHeading>
                                                We're not done yet!
                                            </MHeading>
                                            <br/>
                                            <Text>
                                                There is plenty more to come from PlayPro to equip you with the best possible tools for managing sports.
                                                <br/>
                                                <br/>
                                                Here's a quick peek into what we have on the radar!
                                            </Text>
                                        </Col>
                                        <Col xs={{span: 24}} lg={{span: 12}}>
                                            <ImgFlex size={'md'}>
                                                <ISizer alt='PlayPro Sport Management Platform Roadmap' src={Roadmap} />
                                            </ImgFlex>
                                        </Col>
                                    </Row>
                                </SCard>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <SCard>
                                    <Row gutter={[10,10]}>
                                        <Col xs={{span: 4}} md={{span: 1}}>
                                            <CalendarIco />
                                        </Col>
                                        <Col xs={{span: 20}} md={{span: 23}}>
                                            <SubHeading>Fixtures:</SubHeading>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Text>
                                        Have a realtime view of who’s playing who, where and at what time.
                                        <br/>
                                        <br/>
                                        You will be able to link Teams to fixtures in order to see who is playing in the Team in what positions.
                                        <br/>
                                        When a fixtures has been completed the linked Coach or Administrator will be prompted to input the result and scores.
                                    </Text>
                                </SCard>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <SCard>
                                    <Row gutter={[10,10]}>
                                        <Col xs={{span: 4}} md={{span: 1}}>
                                            <MobileIco />
                                        </Col>
                                        <Col xs={{span: 20}} md={{span: 23}}>
                                            <SubHeading>Mobile App:</SubHeading>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Text>
                                        Whilst PlayPro is already a PWA (Progressive Web App) with advanced mobile capability and responsiveness, we will be focusing on the more key ‘mobile oriented’ aspects of the platform and will be building those into a mobile app.
                                        <br/>
                                        <br/>
                                        This way you will be able to receive important notifications and interact with your school the way you want to! Most importantly you can ensure you or your players' profiles are as up to date and informative as possible.
                                    </Text>
                                </SCard>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <SCard>
                                    <Row gutter={[10,10]}>
                                        <Col xs={{span: 4}} md={{span: 1}}>
                                            <FestIco />
                                        </Col>
                                        <Col xs={{span: 20}} md={{span: 23}}>
                                            <SubHeading>Festivals:</SubHeading>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Text>
                                        With PlayPro’s Festival feature schools, parents and fans alike can access any and all info related to big school events (tournaments, derbies, inter-house days etc.) and follow favourites, pin particular fixtures and so much more, all in one place!
                                    </Text>
                                </SCard>
                            </Col>
                        </Row>
                        <TabButtonSizer>
                            <Button type="primary" onClick={() => history.push('/contactus')}>Sign Up!</Button>
                        </TabButtonSizer>
                    </PanelVariant>
                </Pane>

            </PTabs>
        </Container>
    </PContent>
  );
};

export default Welcome;
