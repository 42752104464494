import React from 'react'
import { FileExcelOutlined as File } from '@ant-design/icons'
import { PageAction } from './page'


interface DownloadTemplateActionProps {
    onClick: (e?: Event) => void,
    hidden?: boolean,
    disabled?: boolean
}

export const DownloadTemplateAction: React.FC<DownloadTemplateActionProps> = ({ disabled, hidden, onClick, ...props }) => {

    return (
        <PageAction 
            hidden={hidden} 
            disabled={disabled} 
            tooltip='Download Template'
            onClick={onClick}
        >
            <File />
        </PageAction>
    )
}