
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import {  Collapse as Collapser } from 'antd';
import { FormLoading, NotFound, Page, AuthGuard } from '../../components/page';
import { Button, Card, message, PageHeader, Result, Skeleton, Tabs, Col,Row } from 'antd';
import { ProfilePictureUploader } from '../../components/profile-picture-uploader';
import PicPlaceholder from '../../assets/default_profile.png';
import {
  CheckBox,
  CheckBoxGroup,
  ContactNumber, DatePicker,
  FormButton,
  FormRow,
  Input,
  OrgSelector,
  Select, SponsorSelector,
  TextArea
} from '../../components';
import { LocationSearch } from '../../components/location-search';
import { Form } from 'formik-antd';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useFormData } from '../../hooks/use-form-data';
import { ADD_ORG, ADD_USER, UPDATE_ORG, useFetchOrg, useFetchUserByShortid } from '../../graphql';
import { orgSchema } from './schema';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  CAMERAS,CAMERAS_TWO,CAMERAS_THREE,
  canSelectOrgs,
  CONTRACT_STATUSES,
  CONTRACT_TYPES, ENGAGEMENT_STATUSES,
  getActiveOrgId,
  getIsAdmin,
  getSelectedOrg, GROUNDS, ORG_TYPES, PHASES, PRODUCTS, REGIONS, SCHOOL_TYPES,GROUNDS_TWO,GROUNDS_THREE,PRODUCTS_TWO, PRODUCTS_THREE,PRODUCTS_FOUR,PRODUCTS_FIVE, PRODUCTS_SIX, CAMERAMIXES,
  SITE_SPECS
} from '../../utils';
import { get, isEmpty } from 'lodash'
import { SA_PROVINCES, COUNTRIES, mapArrayToObject } from '../../utils'
import { cleanError } from '../../helpers/error-helper';
import FileUploader from '../../components/file-uploader';
import { useSponsorSuggestions } from '../../redux/config';

const { TabPane } = Tabs;

const { Panel } = Collapser;



export const provinces = mapArrayToObject(SA_PROVINCES)
export const countries = mapArrayToObject(COUNTRIES);
export const contractTypes = mapArrayToObject(CONTRACT_TYPES);
export const siteSpecs = mapArrayToObject(SITE_SPECS);
export const contractStatuses = mapArrayToObject(CONTRACT_STATUSES);
export const engagementStatuses = mapArrayToObject(ENGAGEMENT_STATUSES);
export const orgTypes = mapArrayToObject(ORG_TYPES);
export const schoolTypes = mapArrayToObject(SCHOOL_TYPES);
export const regions = mapArrayToObject(REGIONS);
export const phases = mapArrayToObject(PHASES);
export const products = mapArrayToObject(PRODUCTS);
export const productstwo = mapArrayToObject(PRODUCTS_TWO);
export const productsthree = mapArrayToObject(PRODUCTS_THREE);
export const productsfour = mapArrayToObject(PRODUCTS_FOUR);
export const productsfive = mapArrayToObject(PRODUCTS_FIVE);
export const productssix = mapArrayToObject(PRODUCTS_SIX);
export const cameras = mapArrayToObject(CAMERAS);
export const camerastwo = mapArrayToObject(CAMERAS_TWO);
export const camerasthree = mapArrayToObject(CAMERAS_THREE);
export const grounds = mapArrayToObject(GROUNDS);
export const groundsTwo = mapArrayToObject(GROUNDS_TWO);
export const groundsThree = mapArrayToObject(GROUNDS_THREE);
export const cameramixess = mapArrayToObject(CAMERAMIXES);




const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };
const responseGoogle = (response: any) =>{
  console.log(response)

}

const responseError = (error: any) =>{
  console.log(error)

}

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  canSelectOrgs: useSelector(canSelectOrgs),
})

export const Org: FC = () => {

  const { shortid }: any = useParams()

  const history = useHistory()

  const { isAdmin, canSelectOrgs } = useSelectors();

  const [org, setOrg] = useState<any>(null);

  const [fetchOrg, { loading }] = useFetchOrg({
    variables: { id: shortid },
    onCompleted: (result: any) => {
      const fetchedOrg = {
        ...result.organisation,
        parentOrgId: get(result, 'organisation.parentOrg.id'),
        sponsorId: get(result, 'organisation.sponsor.id')
      }
      setOrg({ ...fetchedOrg });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const [handleUpdate] = useMutation(UPDATE_ORG)

  const { fields, initialValues, ...formikCTX } = useFormData(orgSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Loading...', duration: 10 });
      const { id, parentOrg, orgAdmins, motd, createdAt, childOrgs, ownedBy, sponsor, parentOrgId, ownedById, primaryContactCellFull, documents, ...updateOrg } = values;
      return handleUpdate({ variables: { id: org.id, updateOrg: {
            ...updateOrg,
        }
      }})
    },
    onCompleted: () => {
      message.success({ content: 'Organisation updated!', duration: 2 });
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to update organisation'), duration: 4 });
      // presentError(e)
    }
  })

  const sponsorSuggestions = useSponsorSuggestions()

  const handleBack = useCallback(() => history.goBack(), [history]);

  const handleRefresh = useCallback(() => fetchOrg(), [fetchOrg])

  if (loading) return <FormLoading />;

  if (!org || isEmpty(org)) {
    return <NotFound subTitle={'Unable to find organisation'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={org} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title='Edit Organisation' onBack={handleBack} card>

            <Form layout={'vertical'}>
              <Tabs defaultActiveKey={'Player Info'} tabPosition={'top'} size={'large'}>
                <TabPane tab={'Primary Info'} key={'Primary Info'}>
                  <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />
                  <FormRow gutter={6}>
                      <Input {...fields.name} />

                      <Input {...fields.shortName} />
                     
                      

                      
                      <Input {...fields.scoreboard} />
                  
                      <Select {...fields.address.state} options={provinces} />
                      <Select {...fields.region} options={regions} allowClear disabled={!canSelectOrgs} />
                      <LocationSearch {...fields.address} parentKey={'address'} defaultValue={get(values, 'address.fullAddress')} />
                       
                      
              
                      
                      <Input {...fields.address.addressLine1} />
                      <Select {...fields.address.country} options={countries} />
                      <OrgSelector hidden={!get(values, 'parentOrg.id') || !canSelectOrgs} disabled {...fields.parentOrgId} />
                      
                      <Select {...fields.siteSpecs} options={siteSpecs} allowClear mode={'tags'} /> 
                     

                    </FormRow>
                  <Collapser
                    defaultActiveKey={[
                      'contact',
                      'stats',
                      'parent',
                      'security',
                      'location'
                    ]}
                     ghost
                  >
                    <Panel key='contact' header='Contact Information'>
                 

                      <FormRow gutter={6}>

                      <Col className="gutter-row" span={8} >
                        <Input {...fields.primaryContactName} />

                      </Col>
                      <Col className="gutter-row"span={8}>
                        <Input {...fields.primaryContactEmail} />

                      </Col>

                      <Col className="gutter-row" span={8}>

                        <ContactNumber
                          numberField={fields.primaryContactCell}
                          countryCodeField={fields.primaryContactCellCountryCode}
                        />

                      </Col>
                      
                      <Col className="gutter-row"span={8}>
                        <Input {...fields.headOfSchool} />
                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.headContactEmail} />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <ContactNumber
                          numberField={fields.headContactCell}
                          countryCodeField={fields.headContactCellCountryCode}
                        />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.headOfSport} />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.headOfSportContactEmail} />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <ContactNumber
                          numberField={fields.headOfSportContactCell}
                          countryCodeField={fields.headOfSportContactCellCountryCode}
                        />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.itOfSchool} />

                      </Col>
                      
                      <Col className="gutter-row"span={8}>
                        <Input {...fields.itContactEmail} />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <ContactNumber
                          numberField={fields.itContactCell}
                          countryCodeField={fields.itContactCellCountryCode}
                        />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.groundsOfSchool} />

                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.groundsContactEmail} />
                        
                      </Col>

                      <Col className="gutter-row"span={8}>
                        <ContactNumber
                          numberField={fields.groundsContactCell}
                          countryCodeField={fields.groundsContactCellCountryCode}
                        />
                        
                      </Col>

                      <Col className="gutter-row"span={8}>
                        <Input {...fields.marketingContactName} />

                      </Col>
                      <Col className="gutter-row"span={8}>
                        <Input {...fields.marketingContactEmail} />

                      </Col>
                      <Col className="gutter-row"span={8}>
                        <ContactNumber
                          numberField={fields.marketingContactCell}
                          countryCodeField={fields.marketingContactCellCountryCode}
                        />

                      </Col>
                      
                      
                      
                      {/* TODO: we will need to compare the selected policies to the attached policies */}
                      {/*<CheckBoxGroup*/}
                      {/*  hidden={!isAdmin}*/}
                      {/*  {...fields.attachPolicies}*/}
                      {/*  options={['CanCreateOrganisation', 'CanCreateUser', 'CanCreateTeam']}*/}
                      {/*  gridProps={GRID_PROPS}*/}
                      {/*/>*/}
                      <Select {...fields.schoolType} options={schoolTypes} allowClear disabled={!canSelectOrgs} />
                      
                    
                    
                    
                      <Select {...fields.orgType} options={orgTypes} hidden={!canSelectOrgs} />
                      <CheckBox {...fields.testing} hidden={!isAdmin} gridProps={GRID_PROPS}>Test data</CheckBox>
                      
                      
                    
                      
                      <TextArea {...fields.description} gridProps={GRID_PROPS}/>
                      
                    </FormRow>
                   </Panel>
                   

                   
                   
                   
                  </Collapser>
                  <FormButton>UPDATE</FormButton>
                </TabPane>
                {canSelectOrgs && <TabPane tab={'Contract'} key={'Contract'}>

                  <FormRow gutter={6}>
                    <SponsorSelector disabled={!canSelectOrgs} {...fields.sponsorId} />
                    <Select {...fields.sponsorSchool} options={sponsorSuggestions} allowClear mode={'tags'} />
                    <Select {...fields.sponsorInfo} options={sponsorSuggestions} allowClear mode={'tags'} /> 
                    <Select {...fields.sponsorOther} options={sponsorSuggestions} allowClear mode={'tags'} />
                    <Select {...fields.phase} options={phases} allowClear />
                    <DatePicker
                      allowClear
                      format='MMM Do YYYY, HH:mm'
                      { ...fields.dateContractStart }
                      showTime={{ format: 'HH:mm' }}
                      />

                    <DatePicker
                      allowClear
                      format='MMM Do YYYY, HH:mm'
                      { ...fields.dateContractEnd }
                      showTime={{ format: 'HH:mm' }}
                    /> 


                    <Select {...fields.contractType} options={contractTypes} allowClear />

                    <Select {...fields.contractStatus} options={contractStatuses} allowClear />
                    <Select {...fields.engagementStatus} options={engagementStatuses} allowClear hidden={!canSelectOrgs} />

                    <CheckBox {...fields.isContracted} gridProps={GRID_PROPS}>Contracted</CheckBox>
                   

                 
                   
                  </FormRow>

                  <FormButton>UPDATE</FormButton>
                </TabPane> }
              
                <TabPane tab={'Equipment'} key={'Equipment'}>
                  <FormRow gutter={6}>
                    <Select {...fields.cameraType} options={cameras} disabled={!canSelectOrgs} />
                    <Select {...fields.cameraMix} options={cameramixess} disabled={!canSelectOrgs} />
                    <Select {...fields.fieldLocation} options={grounds} disabled={!canSelectOrgs} /> 
                    <Select {...fields.product} options={products} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumber} />
                    <Select {...fields.productTwo} options={productstwo} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumberTwo} />
                   
                  </FormRow>  
                  <FormRow gutter={6}>
                    <Select {...fields.cameraTypeTwo} options={camerastwo} disabled={!canSelectOrgs} />
                    <Select {...fields.fieldLocationTwo} options={groundsTwo} disabled={!canSelectOrgs} /> 
                    <Select {...fields.productThree} options={productsthree} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumberThree} />
                    <Select {...fields.productFour} options={productsfour} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumberFour} />
                   
                  </FormRow> 

                  <FormRow gutter={6}>
                    <Select {...fields.cameraTypeThree} options={camerasthree} disabled={!canSelectOrgs} />
                    <Select {...fields.fieldLocationThree} options={groundsThree} disabled={!canSelectOrgs} /> 
                    <Select {...fields.productFive} options={productsfive} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumberFive} />
                    <Select {...fields.productSix} options={productssix} disabled={!canSelectOrgs} />
                    <Input {...fields.serialNumberSix} />
                   
                   
                  </FormRow> 
                  
                  <FormButton>UPDATE</FormButton>
                </TabPane>
                <TabPane tab={'Documents'} key={'Documents'}>
                  <FileUploader id={org.id} parentType={'organisation'} fileType={'DOCUMENT'} initialFiles={org.documents} />
                </TabPane>
              </Tabs> 
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

Org.defaultProps = {};

export default Org;
