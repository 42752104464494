import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useLogout, useAntTheme } from '../../hooks'
import { useFetchCurrentUser, useFetchSportList, useFetchSponsorList } from '../../graphql'
import { getIsSameSession } from '../../utils'
import { cleanError } from '../../helpers/error-helper'
import {
  useCurrentOrg,
  useSetSports,
  useSetSponsors,
  useCurrentSport
} from '../../redux'


/**
 * loads all app-level and user data required before UI render.
 */
export const useLoadData = () => {
  const history = useHistory()
  //set up logout handler for user/sportdata errors
  const [handleLogout] = useLogout({ onLogout: () => history.push('/login')})
  const [currentOrg, setCurrentOrg] = useCurrentOrg()
  const isSameSession = useSelector(getIsSameSession)
  const { loading: loadingUser } = useFetchCurrentUser({
    onCompleted: (result: any) => {
      if (isSameSession) return //include a check on currentUser.orglist
      setCurrentOrg(null)
    },
    //alert user and logout on error
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Loading Error'), duration: 4 })
      handleLogout()
    }
  })
  const setSports = useSetSports()
  const setSponsors = useSetSponsors()
  const [{ appTheme }, setCurrentSport] = useCurrentSport()
  const [theme, toggleTheme] = useAntTheme()

  const [fetchSports, { loading: loadingSports }] = useFetchSportList({
    onCompleted: (result: any) => {
      if ( result && result.sports ) setSports(result.sports)
    },
    //alert user and logout on error
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Failed to load Sports'), duration: 4 })
      handleLogout()
    }
  })

  const [fetchSponsors, { loading: loadingSponsors }] = useFetchSponsorList({
    onCompleted: (result: any) => {
      if ( result && result.sponsors ) setSponsors(result.sponsors)
    }
  })

  //couple theme here? alternatively toggle in specific components
  useEffect(() => {
   if (appTheme && theme && theme.keys && theme.keys[appTheme])
     toggleTheme(appTheme)
   else
     toggleTheme('DEFAULT')
  }, [appTheme])

  return loadingUser || loadingSports || loadingSponsors
}

export default useLoadData
