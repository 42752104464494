
import React from 'react'
import { Button, Space } from 'antd'
import styled from 'styled-components'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'

const SportTag = styled.span<{ $display?: boolean }>`
    display: ${ p => p.$display ? 'flex' : 'none' };
`

const OrgTag = styled(SportTag)`
` 

export const CardFooter = styled.div`
    align-self: stretch;
    padding: 0 10px;
`

export const Foot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`


interface WidgetFooterProps {
    sport?: React.ReactNode,
    org?: React.ReactNode,
    onView?: () => void,
    onAdd?: () => void,
    hidden?: boolean,
    className?: string
}

export const WidgetFooter: React.FC<WidgetFooterProps> = ({ 
    sport = '',
    org = '',
    onView,
    onAdd,
    hidden = false,
    className,
    ...props
}) => {

    if (hidden) return null

    return (
        <CardFooter className={className}>
            <SportTag $display={!!sport}>{sport}</SportTag>
            <Foot>
                <OrgTag $display={!!org}>{org}</OrgTag>
                <Space>
                    <Button
                        hidden={!onView}
                        shape='circle'
                        type='link'
                        onClick={onView}
                        icon={<EyeOutlined />}
                        size='small'
                    />
                    <Button
                        hidden={!onAdd}
                        shape='circle'
                        type='link'
                        onClick={onAdd}
                        icon={<PlusOutlined />}
                        size='small'
                    />
                </Space>
            </Foot>
        </CardFooter>
    )
}



export default WidgetFooter