import { regExPassword } from './regex';

export const generatePassword = (length: number = 8): string => {
  const lower = (Math.random().toString(36) + '00000000000000000').slice(2, length / 2 + 2).toLowerCase();
  const upper = (Math.random().toString(36) + '00000000000000000').slice(2, length / 2 + 2).toUpperCase();
  const pass = lower + upper;
  if (regExPassword.test(pass)) {
    return pass;
  }
  return generatePassword(length);
};

export default generatePassword;
