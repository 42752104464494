import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash'
import Yup, { reach } from 'yup';
import createSchemaConfig from '../helpers/yup/create-schema-config';
import createYupSchema from '../helpers/yup/create-yup-schema';
import { LoadedSport } from '../redux';
import { getFormOverrides, getTeamRoleOverrides } from '../utils';

interface Override {
  field: string;
  module: string;
  type: string;
  description?: string;
  options: string[];
}

const overrideFields = (yupSchema: Yup.ObjectSchema<any>, formOverrides: Override[]) => {
  const validations = formOverrides.map((o: Override) => {
    const field = reach(yupSchema, o.field) as any;
    const description = field.describe();
    const validation = {
      id: o.field,
      ...field,
      ...description,
    };
    if (o.options) validation.oneOf = [null, ...o.options];
    return Object.assign(validation);
  });
  try {
    const config = createSchemaConfig(validations);
    return config.reduce(createYupSchema, {});
  } catch (ex) {
    //console.log('unable to generate yup schema', ex);
    return {};
  }
};

export const useOverrideSchema = (yupSchema: Yup.ObjectSchema<any>, module?: string): Yup.ObjectSchema<any> => {
  const formOverrides: Override[] = useSelector((state: any) => getFormOverrides(state, module))
  const roleOverrides: Override[] = useSelector((state: any) => getTeamRoleOverrides(state, module))
  const schema = useMemo(() => yupSchema.shape(overrideFields(yupSchema, [...roleOverrides, ...formOverrides])), [yupSchema, formOverrides, roleOverrides]);
  return schema;
};
