import React from 'react'
import styled from 'styled-components'
import { SubmitButton, SubmitButtonProps } from './SubmitButton'

type FormButtonProps = SubmitButtonProps

const Component: React.FC<FormButtonProps> = ({ style, type = 'submit', buttonType = 'primary', block = true, className, ...props }) => {

    return (
        <div style={style} className={`${className}`}>
            <SubmitButton
                type={type}
                buttonType={buttonType}
                block={block}
                {...props}
            />
        </div>
    )
}

export const FormButton = styled(Component)`
    width: 96%;
    margin: 0 auto;
`
