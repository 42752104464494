import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import AutoForm from '../../components/auto-form';
import { PageAction as Action, Page, AuthGuard } from '../../components';
import { useFormData, useImport } from '../../hooks';
import { getActiveOrgId, getIsAdmin } from '../../utils/selectors';
import { coachSchema, Coach, confirmUserImportSchema } from '../../pages/user/schema';
import { exportDataToExcel } from '../../utils';
import { message, Modal, PageHeader, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FormButton, FormRow, FullButtonLg } from '../../components';
import { OrgSelector } from '../../components/org-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../components/importer';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../components'
import { useHideImportActions } from '../../hooks'
import { IMPORT_USERS_MUTATION } from '../../graphql/queries'
import { cleanError } from '../../helpers/error-helper';

const { confirm } = Modal;

const { schema, initialValues } = getFormData(coachSchema);

const additionalData = {
  role: 'User',
  isCoach: true
};

const addData = (data: any[]) => {
  return data.map((r: any) => Object.assign({
    ...r,
    ...additionalData,
  }));
};

export const CoachImport: React.FC<any> = () => {
  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
    handleUpdate,
  } = useImport(initialValues, 'user', schema);

  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);

  const activeOrganisationId = useSelector(getActiveOrgId);

  const [importCoaches] = useMutation(IMPORT_USERS_MUTATION);

  const exportCoaches = useCallback(() => {
    exportDataToExcel(fields, coaches, 'playpro-coach-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [coaches]);

  const clearData = useCallback(() => {
    handleClear();
    setCoaches([]);
    setImportSuccess(false);
  }, [handleClear, setCoaches, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setCoaches(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setCoaches, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData()
    } else if (coaches.length > 0) {
      setCoaches([]);
    } else {
      handleCancel();
    }
  }, [clearData, coaches, setCoaches, clearData, handleCancel, importSuccess]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmUserImportSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid coaches to your selected organisation account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, organisationId } = values;
          const coachData: Coach[] = [...sanitize(addData(coaches))];
          try {
            await importCoaches({
              variables: { newUserData: { users: coachData, organisationId } },
            });
            setImportSuccess(true);

            if (downloadExcel) {
              exportCoaches();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: cleanError(ex, 'Something went wrong'), duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrganisationId,
      }),
    [activeOrganisationId, isAdmin],
  );

  const {
    hideCancel,
    hideDownload,
    hideSave
  } = useHideImportActions(importSuccess, values, coaches)

  return (
    <AuthGuard needsActiveOrg>
      <Page
        card
        title='Import Coaches'
        onBack={hasValues ? back : undefined}
        extra={[
          <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
          <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
          <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
        ]}
      >
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || coaches.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />
            <FullButtonLg hidden={!hasValues || coaches.length > 0} onClick={next}>
              Next
            </FullButtonLg>
            {coaches.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {coaches.length} coach{coaches.length !== 1 && 'es'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrganisationId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import coach</ImportConfirm>}
                      <ConfirmForm>
                        <OrgSelector hidden={!isAdmin} {...cFields.organisationId} gridProps={fullWidth} />
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}
        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}
      </Page>
      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>
    </AuthGuard>
  );
};

export default CoachImport;
