import { useMutation } from "@apollo/client";
import { Col, Collapse as Collapser, message, Tabs } from "antd";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Form } from "formik-antd";
import React, { FC, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormButton, FormRow, Input, Select } from "../../components";
import FileUploader from "../../components/file-uploader";
import { AuthGuard, FormLoading, Page } from "../../components/page";
import {
    ADD_THIRD_PARTY_PROVIDER_CONTACT,
  UPDATE_THIRD_PARTY_PROVIDER_CONTACT,
  useFetchThirdPartyProviderContact,
} from "../../graphql";
import { cleanError } from "../../helpers/error-helper";
import { useFormData } from "../../hooks/use-form-data";

import { mapArrayToObject } from "../../utils";
import { ThirdPartyProviderContactSchema } from "./schema";

const { TabPane } = Tabs;

const { Panel } = Collapser;

export const providerType = mapArrayToObject([
  "COMMENTATOR",
  "SCORER",
  "CAMERAMAN",
]);

export const AddThirdPartyProviderContact: FC = () => {
  const { providerId }: any = useParams();
  const history = useHistory();
  const [handleUpdate, {loading}] = useMutation(ADD_THIRD_PARTY_PROVIDER_CONTACT);

  const { fields, initialValues, ...formikCTX } = useFormData(
    ThirdPartyProviderContactSchema,
    {
      onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        message.loading({ content: "Loading...", duration: 10 });
        return handleUpdate({
          variables: {
            newThirdPartyProviderContactData: {
                ...values,
                linkedThirdPartyProviderId: providerId
            }
          },
        });
      },
      onCompleted: () => {
        message.success({
          content: "Third Party Provider Contact updated!",
          duration: 2,
        });
      },
      onError: (e: any) => {
        message.error({
          content: cleanError(
            e,
            "Unable to update Third Party Provider Contact"
          ),
          duration: 4,
        });
      },
    }
  );

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrg>
      <Formik
        {...formikCTX}
        initialValues={{}}
        enableReinitialize
        validateOnBlur
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page
            title="View/Edit Third Party Provider Contact"
            onBack={handleBack}
            card
          >
            <Form layout={"vertical"}>
              <Tabs
                tabPosition={"top"}
                size={"large"}
              >
                <TabPane tab={"Primary Info"} key={"thirdPartyProviderName"}>
                      <FormRow gutter={6}>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.firstname} />
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.lastname} />
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.dateOfBirth} type="date"/>
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.email} />
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.phone} />
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Select
                            {...fields.type}
                            options={providerType}
                          />
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <Input {...fields.notes} />
                        </Col>
                      </FormRow>
                  <FormButton>UPDATE</FormButton>
                </TabPane>
                <TabPane tab={"Documents"} key={"Documents"}>
                  <FileUploader
                    id={"IMAGES"}
                    parentType={"organisation"}
                    fileType={"IMAGE"}
                    initialFiles={[]}
                  />
                  <FormButton>UPDATE</FormButton>
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

AddThirdPartyProviderContact.defaultProps = {};

export default AddThirdPartyProviderContact;
