import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Collapse as Collapser,
  message,
  Tabs,
  Tooltip,
} from "antd";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Form } from "formik-antd";
import { get } from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormButton, FormRow, Input, Table } from "../../components";
import FileUploader from "../../components/file-uploader";
import { AuthGuard, FormLoading, Page } from "../../components/page";
import {
  UPDATE_ORG,
  UPDATE_THIRD_PARTY_PROVIDER,
  useFetchOrg,
  useFetchThirdPartyProvider,
} from "../../graphql";
import { cleanError } from "../../helpers/error-helper";
import { useFormData } from "../../hooks/use-form-data";

import {
  CAMERAS,
  CAMERAS_TWO,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  COUNTRIES,
  ENGAGEMENT_STATUSES,
  GROUNDS,
  GROUNDS_TWO,
  mapArrayToObject,
  ORG_TYPES,
  PHASES,
  PRODUCTS,
  PRODUCTS_FOUR,
  PRODUCTS_THREE,
  PRODUCTS_TWO,
  REGIONS,
  SA_PROVINCES,
  SITE_SPECS,
  CAMERAMIXES,
} from "../../utils";
import { ThirdPartyProviderPrimaryInfoSchema } from "./schema";

const { TabPane } = Tabs;

const { Panel } = Collapser;

export const provinces = mapArrayToObject(SA_PROVINCES);
export const countries = mapArrayToObject(COUNTRIES);
export const contractTypes = mapArrayToObject(CONTRACT_TYPES);
export const siteSpecs = mapArrayToObject(SITE_SPECS);
export const contractStatuses = mapArrayToObject(CONTRACT_STATUSES);
export const engagementStatuses = mapArrayToObject(ENGAGEMENT_STATUSES);
export const orgTypes = mapArrayToObject(ORG_TYPES);
export const providerType = mapArrayToObject([
  "COMMENTATOR",
  "SCORER",
  "CAMERAMAN",
]);
export const regions = mapArrayToObject(REGIONS);
export const phases = mapArrayToObject(PHASES);
export const products = mapArrayToObject(PRODUCTS);
export const productstwo = mapArrayToObject(PRODUCTS_TWO);
export const productsthree = mapArrayToObject(PRODUCTS_THREE);
export const productsfour = mapArrayToObject(PRODUCTS_FOUR);
export const cameras = mapArrayToObject(CAMERAS);
export const camerastwo = mapArrayToObject(CAMERAS_TWO);
export const grounds = mapArrayToObject(GROUNDS);
export const groundsTwo = mapArrayToObject(GROUNDS_TWO);
export const cameramixess = mapArrayToObject(CAMERAMIXES);

type Props = Readonly<{
  contacts: any[];
  linkedThirdPartyProvider: string;
}>;

export const ContactTable: FC<Props> = ({
  contacts,
  linkedThirdPartyProvider,
}: Props) => {
  const history = useHistory();

  const columns: any[] = [
    {
      title: "Firstname",
      filtering: true,
      sorting: true,
      key: "firstname",
    },
    {
      title: "Lastname",
      filtering: true,
      sorting: true,
      key: "lastname",
    },
    {
      title: "Date Of Birth",
      filtering: true,
      sorting: true,
      key: "dateOfBirth",
    },
    {
      title: "Email",
      filtering: true,
      show: true,
      key: "email",
      sorting: true,
    },
    {
      title: "Phone",
      filtering: true,
      show: true,
      key: "phone",
      sorting: true,
    },
    {
      title: "Address",
      filtering: true,
      show: true,
      key: "address",
      sorting: true,
    },
    {
      title: "Type",
      filtering: true,
      show: true,
      key: "type",
      sorting: true,
    },
  ];

  return (
    <Table
      data={contacts}
      columns={columns}
      loading={false}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () =>
          history.push(`/third-party-provider/contact/view-edit/${record.id}`),
      })}
      title={"Contact List"}
      exportTitle={"Organisations"}
      actions={
        <Tooltip title={`Add New Contact`}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              history.push(
                `/third-party-provider/${linkedThirdPartyProvider}/contact/add`
              );
            }}
            shape="circle"
          />
        </Tooltip>
      }
    />
  );
};

export const ViewEditThirdPartyProvider: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const [thirdPartyProvider, setThirdPartyProvider] = useState<any>(null);
  const [, { loading }] = useFetchThirdPartyProvider({
    variables: { id },
    onCompleted: (result: any) => {
      const fetchedThirdPartyProvider = {
        ...result.thirdPartyProvider,
      };
      setThirdPartyProvider({ ...fetchedThirdPartyProvider });
    },
    onError: (error: any) => {
      //console.log(error)
    },
  });

  const [handleUpdate] = useMutation(UPDATE_THIRD_PARTY_PROVIDER);

  const { fields, initialValues, ...formikCTX } = useFormData(
    ThirdPartyProviderPrimaryInfoSchema,
    {
      onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
        message.loading({ content: "Loading...", duration: 10 });
        const { id, thirdPartyProviderName, registrationNumber, vatNumber } =
          values;
        return handleUpdate({
          variables: {
            updatedThirdPartyProvider: {
              id,
              thirdPartyProviderName,
              registrationNumber,
              vatNumber,
            },
          },
        });
      },
      onCompleted: () => {
        message.success({
          content: "Third Party Provider updated!",
          duration: 2,
        });
      },
      onError: (e: any) => {
        message.error({
          content: cleanError(e, "Unable to update Third Party Provider"),
          duration: 4,
        });
        // presentError(e)
      },
    }
  );

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrg>
      <Formik
        {...formikCTX}
        initialValues={thirdPartyProvider}
        enableReinitialize
        validateOnBlur
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title="View/Edit Third Party Provider" onBack={handleBack} card>
            <Form layout={"vertical"}>
              <Tabs
                defaultActiveKey={"Player Info"}
                tabPosition={"top"}
                size={"large"}
              >
                <TabPane tab={"Primary Info"} key={"Primary Info"}>
                  <FormRow gutter={6}>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.thirdPartyProviderName} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.registrationNumber} />
                    </Col>

                    <Col className="gutter-row" span={8}>
                      <Input {...fields.vatNumber} />
                    </Col>
                  </FormRow>
                  <FormButton style={{ marginBottom: "10px" }}>
                    UPDATE
                  </FormButton>
                  {thirdPartyProvider && (
                    <ContactTable
                      contacts={thirdPartyProvider.contacts}
                      linkedThirdPartyProvider={thirdPartyProvider.id}
                    />
                  )}
                </TabPane>
                <TabPane tab={"Documents"} key={"Documents"}>
                  <FileUploader
                    id={"IMAGES"}
                    parentType={"organisation"}
                    fileType={"IMAGE"}
                    initialFiles={[]}
                  />
                  <FormButton>UPDATE</FormButton>
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

ViewEditThirdPartyProvider.defaultProps = {};

export default ViewEditThirdPartyProvider;
