import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, message, Tooltip } from 'antd'
import { cloneDeep } from 'lodash'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Avatar, Table } from '../../components'

import { PENDING_FIXTURES, UPDATE_PENDING } from '../../graphql'
import { useCurrentOrg, useSports } from '../../redux'
import { useIsParentOrAdmin } from '../../hooks'
import { useSelector } from 'react-redux'
import { getActiveOrgId, getSelectedOrg } from '../../utils'
import { RenderOrg } from './components';
import { getThumbnail } from '@bit/necta.hooks.s3'

interface ActionCellProps {
    fixtureId?: string
    rec: any,
    handleClick: any
}

const ActionCell: React.FC<ActionCellProps> = ({
    fixtureId,
    rec,
    handleClick,
    ...props
}) => {

    const id = useSelector(getActiveOrgId)
    const isParentOrAdmin = useIsParentOrAdmin()

    const hide = useMemo(() => {
        if (isParentOrAdmin) return false
        if (rec.homeOrgId === id) {
            if (rec.homeOrgStatus === 'Pending') return false
            return true
        }
        if (rec.awayOrgId === id) {
            if (rec.awayOrgStatus === 'Pending') return false
            return true
        }
        return true
    }, [id, rec, isParentOrAdmin])

    if (hide) return null

    return (
        <ButtonWrapper>
            <Decline type='text' onClick={handleClick(fixtureId, false)}>
                Decline
            </Decline>
            <Accept type='text' onClick={handleClick(fixtureId, true)}>
                Confirm
            </Accept>
        </ButtonWrapper>
    )
}

const Decline = styled(Button)`
    span {
        color: red;
    }
`

const Accept = styled(Button)`
    span {
        color: green;
    }
`

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

interface PendingFixtureTableProps {
    size?: 'small' | 'medium' | 'large',
    title?: string | false
}

export const PendingFixtureTable: React.FC<PendingFixtureTableProps> = ({ title, size, ...props }) => {

    const history = useHistory()
    const [fixtures, setFixtures] = useState<any[]>([])
    const [currentOrg] = useCurrentOrg()
    const currentOrgId = useMemo(() => currentOrg?.id ?? '', [currentOrg])
    const selectedOrg = useSelector(getSelectedOrg);

    const [handleFetch, { loading }] = useLazyQuery(PENDING_FIXTURES, {
        variables: { orgId: selectedOrg?.id },
        onCompleted: (result: any) => {
            if (result && result.fixtures) setFixtures(cloneDeep(result.fixtures))
        },
        onError: (err: any) => {
            message.error({ content: 'Could not load pending Fixtures!', duration: 4 })
        }
    })

    useEffect(() => {
        handleFetch()
    }, [])

    const [update, { loading: updating }] = useMutation(UPDATE_PENDING, {
        onCompleted: (result: any) => {
            message.success({ content: 'Fixture Updated Successfully!', duration: 4 })
        },
        onError: (err: any) => {
            message.error({ content: 'Failed to update Fixture status', duration: 4 })
        }
    })

    const isParentOrAdmin = useIsParentOrAdmin()

    const handleClick = useCallback((id: string, accept: boolean) => async () => {
        try {
            const result = await update({
                variables: {
                    orgId: isParentOrAdmin ? undefined : currentOrgId,
                    accept,
                    fixtureId: id
                }
            })
            if (result && result.data.fixtureInvite) handleFetch();
        } catch (e) {
            console.error(e)
        }
    }, [update, currentOrgId, handleFetch, isParentOrAdmin])

    const [sportList, { getSport }] = useSports()

    const getName = useCallback((sportId: string) => {
        const sport = getSport(sportId);
        return sport?.name
    }, [getSport]);

    const getIcon = useCallback((sportId: string) => {
        return getThumbnail(getSport(sportId)?.appIcon);
    }, [getSport]);

    const columns = useMemo(() => [
        {
            title: 'Fixture',
            key: 'name',
            filter: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <Tooltip title={getName(rec.sportId)}><Avatar src={getIcon(rec.sportId)} size={18} inverse /> {val}</Tooltip>
        },
        {
            title: 'Home',
            export: true,
            key: 'homeOrgName',
            filtering: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <RenderOrg org={rec.homeOrg} name={val} result={rec.result} isHome={true} />
        },
        {
            title: 'Away',
            export: true,
            key: 'awayOrgName',
            filtering: true,
            sorting: true,
            getValues: (r: any) => r,
            render: (val: string, rec: any) => <RenderOrg org={rec.awayOrg} name={val} result={rec.result} isHome={false} />
        },
        {
            title: 'Actions',
            key: 'id',
            filter: false,
            render: (id: string, rec: any) => (
                <ActionCell rec={rec} handleClick={handleClick} fixtureId={id} />
            )
        }
    ], [handleClick, getIcon, getName])

    const $title = useMemo(() => {
        if (title) return title
        if (typeof title === 'boolean') return ''
        return 'Pending Fixture Invites'
    }, [title])

    return (
        <Table
            title={$title}
            data={fixtures}
            columns={columns}
            refresh={handleFetch}
            searchable={false}
            exportable={false}
            columnSelection={false}
            loading={loading || updating}
            pagination={{
                defaultPageSize: 5
            }}
            size={size}
        />
    )
}
