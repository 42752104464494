import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Collapse, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ReactComponent as QBar} from '../assets/welcome/faq_bar.svg';
import { ReactComponent as QBarSM} from '../assets/welcome/faq_bar_sm.svg';
import { PContent, Container, SubHeading, ButtonSizer, PanelVariant, ISizer } from '../pages/Welcome';
//PICTURES
import Stretch from '../assets/welcome/stretch.png';
import NetBall from '../assets/welcome/net-shoot.png';
import Hockey from '../assets/welcome/hockey.png';
import Ref from '../assets/welcome/ref.png';

const { Panel } = Collapse;

const FAQBar = styled(QBar)`
    width: 100%;
    height: auto;
    padding: 40px 0;

    @media only screen and (max-width: 990px) {
        display: none
      }
`;

const FAQBarSM = styled(QBarSM)`
    width: 100%;
    height: auto;
    padding: 40px 0;
    
    @media only screen and (min-width: 991px) {
        display: none
      }
`;

const Headline = styled.div`
  text-align: center;
  padding-bottom: 40px;
`;

const CustomCollapse = styled(Collapse)`
  margin: 10px 0;
  color: #666;

  && {

    .ant-collapse {
      border-radius: 5px !important;
    }
    .ant-collapse-item-active {
      transition: background 0.2s ease;
      background: ${p => p.theme.primaryColor} !important;
      color: #fff;
      .ant-collapse-header {
        color: #fff !important;
      }
    }
    .ant-collapse-header{
      color: #666 !important;
    }
    .ant-collapse-content{
      color: #666;
    }

  }
`;

const CollapseCol = styled(Col)`
    display: grid;
    justify-content: center;
    align-content: center;
`;

const text1 = (
  <Row gutter={[15,15]}>
    <CollapseCol xs={{ span:24 }} lg={{span:14}}>
      <p style={{ paddingLeft: 24 }}>
      In short, have your cake and eat it! Manage all your sports' Players, Teams, and Coaches in one place, while building a world class sport profile for your athletes that will ensure the very best opportunities come their way after school.
      <br />
      <br />
      PlayPro is an all new digital platform to completely digitise your sports programs and ensure everyone is working off of the same information, from parents and Administrators to Coaches and Players.<br/>
      Create profiles once, assign roles to Admins and Coaches, then sit back and watch boring manual admin fade away!
      </p>
      {/* <p><b>Check out this quick video for more info.</b></p> */}
    </CollapseCol>
    <CollapseCol xs={{ span:24 }} lg={{span:10}}>
      <ISizer size={'sm'} alt='PlayPro Sport desktop webapp' src={Stretch} />
    </CollapseCol>
  </Row>
  );

  const text2 = (
    <Row gutter={[15,15]}>
      <CollapseCol xs={{ span:24 }} lg={{span:14}}>
        <p style={{ paddingLeft: 24 }}>
        Nobody who isn’t deliberately granted access by your ‘Super Admin’ will get to see information on your portal.
        ‘Super Admins’ are representatives of your school (Sports Director or Administrator) who accept their roles as a ‘Responsible Party’ for your Organisation.
        <br />
        <br />
        They have the superpower to create, edit and manage user accounts. For example a Player account will allow for an athlete to view their own profile and Teams that they are linked to.
        <br />
        <br />
        Likewise Players can see other Teams within their school, much like a notice board.
        A Coach can be assigned to a Team which would give them the ability to add or remove Team Members, as well as the info in that Team Member’s profile like Roles in the Team, appointing a captain etc.
        <br />
        <br />
        Note that a Team Member profile is different to a Player profile, which belongs to the Player and encompasses ALL info relating to them and the sports they play.
        </p>
      </CollapseCol>
      <CollapseCol xs={{ span:24 }} lg={{span:10}}>
        <ISizer size={'md'} alt='PlayPro Sport desktop webapp' src={NetBall} />
      </CollapseCol>
    </Row>
  );

  const text3 = (
    <Row gutter={[15,15]}>
      <CollapseCol xs={{ span:24 }} lg={{span:14}}>
        <p style={{ paddingLeft: 24 }}>
        Absolutely. We want to hand you back your precious time at any possible opportunity.
        <br />
        <br />
        You can use our Import Players feature in the Player tab in the menu. There will be an option to download an excel template with the right format to populate the info, and a few clicks later your multiple profiles will be created.
        <br />
        <br />
        Alternatively you can send all athletes names, contact details and to us during your setup stage, and we’ll do it for you!
        </p>
      </CollapseCol>
      <CollapseCol xs={{ span:24 }} lg={{span:10}}>
        <ISizer size={'sm'} alt='PlayPro Sport desktop webapp' src={Hockey} />
      </CollapseCol>
    </Row>
  );

  const Text4 = () => {
    const history = useHistory()
    return (
      <Row gutter={[15,15]}>
        <CollapseCol xs={{ span:24 }} lg={{span:14}}>
          <p style={{ paddingLeft: 24 }}>
          We love innovating and growing so, in the name of relentless evolution we are continually looking to improve and advance.
          <br />
          <br />
          Check out our roadmap for what’s the horizon!
          <br />
          <br />
          It is you who matters most, so we also want to hear what you want and how you want it for future plans. <Button style={{marginLeft: '-14px'}} type='link' onClick={() => history.push('/contactus')}>Let us know!</Button>
          </p>
        </CollapseCol>
        <CollapseCol xs={{ span:24 }} lg={{span:10}}>
          <ISizer size={'sm'} alt='PlayPro Sport desktop webapp' src={Ref} />
        </CollapseCol>
      </Row>
    );
  }

export const FAQ: React.FC<any> = (props) => {

  const history = useHistory()

  return (
    <PContent>
        <Container>
            <FAQBar/>
            <FAQBarSM/>
            <Headline>
                <SubHeading>We’ve put together some commonly asked questions to give you more information about PlayPro and what we offer.</SubHeading>
            </Headline>
        </Container>

        <Container style={{paddingBottom: '60px'}}>
            <CustomCollapse
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}>
                <Panel header='Why should we use PlayPro?' key='1'>
                  {text1}
                </Panel>
            </CustomCollapse>
            <CustomCollapse
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}>
                <Panel header='Who can see my school’s info?' key='2'>
                  {text2}
                </Panel>
            </CustomCollapse>
            <CustomCollapse
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}>
                <Panel header="Can we add all our athletes at once?" key='3'>
                  {text3}
                </Panel>
            </CustomCollapse>
            <CustomCollapse
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}>
                <Panel header='What’s on the road map for PlayPro?' key='4'>
                  <Text4/>
                </Panel>
            </CustomCollapse>
        </Container>

        <Container style={{textAlign: 'center'}}>
          <SubHeading>If you would like to start your PlayPro journey or would like to know more, then please let us know!</SubHeading>
        </Container>

        <PanelVariant pad={'mixed'}>
          <ButtonSizer>
              <Button type="primary" onClick={() => history.push('/contactus')}>Contact Us!</Button>
          </ButtonSizer>
        </PanelVariant>

    </PContent>
  );
};

export default FAQ;
