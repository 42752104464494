import { useCallback } from 'react';

export const useScrollTo = (config: ScrollIntoViewOptions = CONFIG) => {
  return useCallback((id: string) => (e: any) => {
    e.stopPropagation();
    const el = document.getElementById(id)
    if (el) el.scrollIntoView(config)
  }, []);
}

const CONFIG: ScrollIntoViewOptions = { behavior: 'smooth' };
