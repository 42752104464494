import { useMemo } from 'react'

export const useHideImportActions = (importSuccess: boolean, values: any, data: any[]) => {
    const hide = useMemo(() => ({
      hideCancel: (data.length <= 0) || importSuccess,
      hideDownload: (data.length > 0) || importSuccess,
      hideSave: !(values.validData && values.validData.length > 0) || data.length > 0
    } as const), [data, values, importSuccess])

    return hide
}
