import { createStore } from "redux";

import { makeRootReducer } from "@bit/necta.hooks.make-root-reducer";
import { cognito as cognitoUser } from '@bit/necta.hooks.cognito-user'
import { currentUser } from '@bit/necta.hooks.current-user'
import { redirectOnLogin } from '../hooks'
import { pageTitle } from '@bit/necta.hooks.page-title'
import { logoutPaneActive } from '@bit/necta.hooks.logout-pane'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from 'redux-devtools-extension'
import { drawerOpen } from "@bit/necta.hooks.use-drawer-open";
import { antTheme as theme } from '../hooks/use-ant-theme'
import { config } from '../redux'

const persistConfig = {
  key: "root", //key for local storage
  storage, //storage adapter, defaults to local storage
};

const persistedReducer = persistReducer(
  persistConfig,
  makeRootReducer({ config, theme, drawerOpen, cognitoUser, currentUser, redirectOnLogin, pageTitle, logoutPaneActive })
);
//redux store to be passed to Provider
export const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(store);
