import { useRef, useMemo, useEffect, EffectCallback, DependencyList } from 'react'

export type Dependencies = ((ref: any) => DependencyList) | (() => DependencyList) | DependencyList

export const useAntRef = (useEffectCallback: EffectCallback = () => {}, dependencies: Dependencies) => {
    const ref = useRef<any>(null)
    const deps = useMemo(() => typeof dependencies === 'function' ? dependencies(ref) : dependencies, [dependencies])
    useEffect(useEffectCallback, deps)

    const handleRefInit = (node: any) => ref.current = node

    return [ref, handleRefInit] as const
}