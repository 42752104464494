import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import Truncate from 'react-truncate'
import { Fixture } from '../../redux'
import { Avatar } from '../Avatar';
import { getThumbnail } from '@bit/necta.hooks.s3/dist/s3-helpers';

interface CellProps {
  fixtures: Fixture[],
  cellTip: React.ReactNode,
  getSport?: any,
  loaded?: any
}

const Icon = styled(Avatar)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const List = styled.div<{small?: boolean}>`
    padding: 0;
    margin: 0;
    font-size: 12px;

    height: inherit;
    width: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: ${p => p.small ? 'row' : 'column'};
    justify-content: flex-start;
    align-items: stretch;

    &::-webkit-scrollbar {
        display: none;
    }
`

const Item = styled.div<{color?: string}>`
    width: 100%;
    color: white;
    border-radius: 2px;
    line-height: 14px;
    padding: 3px;
    margin: 1px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${ p => p.color ?? '#B9C0C3' };
`

const Dot = styled.div<{color?: string}>`
  height: 5px;
  width: 5px;
  margin: 1px;
  background-color: ${ p => p.color ?? '#B9C0C3' };
  border-radius: 50%;
`
/**
 * a Calendar cell component that renders the list of given fixtures. includes ellipsis based on
 * calendar cell's active client width. Has a tooltip that lists the full content of the cell on hover.
 */
export const Cell: React.FC<CellProps> = ({ cellTip, fixtures, children, getSport, loaded, ...props }) => {

  //slice first two elements available fixtures
  const _fixtures = fixtures.slice(0, 2)
  const overflow = fixtures.length > 2
  const rest = fixtures.length - 2
  //list ref used to fetch cell width
  const [width, setWidth] = useState(0);

  const ref = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, [setWidth]);

  const getSportColor = useCallback((sportId: string) => {
    const sport = getSport(sportId)
    return loaded[sport.appTheme]['@secondary-color']
  }, [getSport, loaded])

  //dont return anything if fixtures are empty
  if (fixtures.length === 0) return null

  return (
    <Tooltip
      title={cellTip}
    >
      <List ref={ref} small={width <= 60}>
        { width > 60 ? _fixtures.map((fixture: any) => (
          <Item color={getSportColor(fixture.sportId)} key={fixture.event}>
            <Icon src={getThumbnail(getSport(fixture.sportId)?.appIconAlt)} inverse />
            <Truncate width={width - 30} lines={1} ellipsis={'...'}>
              { fixture.name }
            </Truncate>
          </Item>
        )) :
          _fixtures.map((fixture: any) => <Dot color={getSportColor(fixture.sportId)} key={fixture.event}/>
        )}
        {  overflow && (width > 60 ? <Item>+{rest} more</Item> : <Dot />) }
      </List>
    </Tooltip>
  )
}
