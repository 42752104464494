export const ROLES = ['User', 'Organisation', 'Admin'];

export const SA_PROVINCES = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'Northern Cape',
  'North West',
  'Western Cape',
];

export const COUNTRIES = ['South Africa'];

export const GENDERS = ['Male', 'Female', 'Other'];

export const ETHNICITIES = ['Unspecified', 'Asian', 'Black', 'Coloured', 'Indian', 'White', 'Other'];

export const BROADCAST_STATUS = ['PENDING', 'DENIED','ACCEPTED']

export const CONTRACT_TYPES = ['School', 'Festival', 'School and Festival'];
export const CONTRACT_STATUSES = ['OUTSTANDING', 'SIGNED', 'TO BE DRAFTED','NOT YET STARTED'];
export const ENGAGEMENT_STATUSES = ['Engaged', 'Pending', 'Contract Sent'];
export const SITE_SPECS = ['Power points', 'Network', 'Generator'];
export const ORG_TYPES = ['School', 'Club', 'Academy', '3rd Party'];
export const CAMERAS = ['Pixallot S1', 'Pixallot S2', 'Pixallot S3', 'Pixallot Prime', '2 Camera Mix Kit', 'Pitch Vision', 'Air'];
export const CAMERAS_TWO = ['Pixallot S1', 'Pixallot S2', 'Pixallot S3', 'Pixallot Prime', '2 Camera Mix Kit', 'Pitch Vision', 'Air'];
export const CAMERAS_THREE = ['Pixallot S1', 'Pixallot S2', 'Pixallot S3', 'Pixallot Prime', '2 Camera Mix Kit', 'Pitch Vision', 'Air'];
export const CAMERAMIXES = ['2 Camera Mix Exclusive', '2 Camera Mix Regional'];
export const PRODUCTS = ['CHU', 'VPU'];
export const PRODUCTS_TWO = ['CHU', 'VPU'];
export const PRODUCTS_THREE = ['CHU', 'VPU'];
export const PRODUCTS_FOUR = ['CHU', 'VPU'];
export const PRODUCTS_FIVE = ['CHU', 'VPU'];
export const PRODUCTS_SIX = ['CHU', 'VPU'];
export const GROUNDS = ['Athletics', 'Basketball','Basketball/Pinelands', 'Cricket','Cultural', 'Football', 'Hockey', 'Hockey/Riverside','Hockey - A Field', 'Hockey - B Field','Indoor Hockey', 'Multi-Purpose', 'Netball',  'Rugby', 'Softball', 'Squash', 'Swimming', 'Tennis', 'Volleyball' , 'Water Polo', 'Other' ];
export const GROUNDS_TWO = ['Athletics', 'Basketball','Basketball/Pinelands', 'Cricket','Cultural', 'Football', 'Hockey', 'Hockey/Riverside','Hockey - A Field', 'Hockey - B Field','Indoor Hockey', 'Multi-Purpose', 'Netball',  'Rugby', 'Softball', 'Squash', 'Swimming', 'Tennis', 'Volleyball' , 'Water Polo', 'Other' ];
export const GROUNDS_THREE = ['Athletics', 'Basketball','Basketball/Pinelands', 'Cricket','Cultural', 'Football', 'Hockey', 'Hockey/Riverside','Hockey - A Field', 'Hockey - B Field','Indoor Hockey', 'Multi-Purpose', 'Netball',  'Rugby', 'Softball', 'Squash', 'Swimming', 'Tennis', 'Volleyball' , 'Water Polo', 'Other' ];
export const PHASES = ['1', '2', '3', '4', '5'];
export const REGIONS = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'Northern Cape',
  'North West',
  'Western Cape',
  'Namibia',
  'Zimbabwe'
];
export const SCHOOL_TYPES = ['Rural', 'Urban'];
export const SPONSOR_INFO = [
  'Nashua',
  'Bluu Car Rental'
]

export const AUTHORIZED_ROLES = ['Admin', 'Organisation'];
export const BIRTHDAY_ERROR = 'Please enter a valid date of birth';
export const EMAIL_ERROR = 'Please enter a valid email'
export const PASSWORD_ERROR =
  'Password must be 8-20 characters and include an uppercase letter, lowercase letter and a number';

export const FIXTURE_TYPES = [
  'Festival', 'Match', 'Tournament'
]

export const AGE_GROUPS = [
  {
    text: 'None',
    value: '0-1',
  },
  {
    text: 'U9',
    value: '1-9',
  },
  {
    text: 'U10',
    value: '10',
  },
  {
    text: 'U11',
    value: '11',
  },
  {
    text: 'U12',
    value: '12',
  },
  {
    text: 'U13',
    value: '13',
  },
  {
    text: 'U14',
    value: '14',
  },
  {
    text: 'U15',
    value: '15',
  },
  {
    text: 'U16',
    value: '16',
  },
  {
    text: 'U17',
    value: '17',
  },
  {
    text: 'U18',
    value: '18',
  },
  {
    text: 'O18',
    value: '18-999',
  },
];

export const AGE_GROUP_OPTIONS = AGE_GROUPS.map((a: any) => Object.assign({ name: a.text, id: a.value }));

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const FIXTURE_STATUSES = [
  'Pending',
  'Cancelled',
  'Confirmed',
  'In Progress',
  'Awaiting Results',
  'Complete',
];
