import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Page, NotFound, SelectAnOrg, Avatar } from '../../../components';
import Table, { Filter, Sort } from '../../../components/table';
import Moment from 'moment';
import { getThumbnail } from '@bit/necta.hooks.s3';
import { useFetchUserList } from '../../../graphql'
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { AGE_GROUPS, canSelectOrgs, getActiveOrgId, getIsAdmin, getSelectedOrg, toCaps } from '../../../utils';
import { get, compact } from 'lodash';
import { SmallSportTags as Tags } from '../components';
import { AuthGuard } from '../../../components'
import { useSports } from '../../../redux/config';
import { Button, Tooltip } from 'antd';
import { checkRange } from '../../../helpers';

const personalInfoColumns: any[] = [
  {
    title: 'Picture',
    key: 'profilePicUrl',
    width: 40,
    export: false,
    render: (r: any) => <Avatar src={getThumbnail(r)} icon={<UserOutlined />} inverse />
  },
  {
    title: 'First Name',
    filtering: true,
    sorting: true,
    key: 'firstname',
  },
  {
    title: 'Surname',
    filtering: true,
    sorting: true,
    key: 'lastname',
  },
  {
    title: 'Email',
    key: 'email',
    filtering: true,
  },
  {
    title: 'Cell',
    key: 'contactNumberFull',
    filtering: true,
  },
];

const sportColumn: any = {
  title: 'Sports',
  key: 'sportInfo',
  filtering: true,
  sorting: true,
  getValues: (r: any) => r.map((_r: any) => get(_r, 'sport.name')).join(', '),
  render: (r: any) => <Tags sportInfo={r}/>
};

const roleColumn: any = {
  title: 'Role',
  key: 'role',
  filtering: {
    filters: [
      {
        text: 'Player/Coach',
        value: 'User',
      },
      {
        text: 'School Admin',
        value: 'Organisation',
      },
      {
        text: 'Super Admin',
        value: 'Admin',
      },
    ],
    filterMultiple: true,
    type: Filter.SELECT
  },
  resizeable: false,
  render: (r: any) => {
    switch (r) {
      case 'User':
        return 'Player/Coach';
      case 'Organisation':
        return 'School Admin';
      case 'Admin':
        return 'Super Admin';
    }
  }
};

const signedUpColumn: any = {
  title: 'Signed Up',
  key: 'createdAt',
  width: 120,
  valueType: 'date',
  sorting: { compare: Sort.DATE },
  render: (r: any) => Moment(r).format('YYYY-MM-DD')
};

const ageGroupColumn: any = {
  title: 'Age Group',
  key: 'dateOfBirth',
  valueType: 'date',
  sorting: { compare: Sort.DATE },
  filtering: {
    filters: [...AGE_GROUPS],
    filterMultiple: true,
    type: Filter.SELECT,
    customFilter: Filter.DATE_RANGE()
  },
  getValues: (r: any) => r ? Moment(r).startOf('year') : Moment(),
  render: (r: any) => {
    const age = r ? Moment().diff(Moment(r).startOf('year'), 'years') : 0;
    return AGE_GROUPS.find((a: any) => checkRange(a.value, age))?.text;
  }
};

const hiddenColumns: any[] = [
  {
    title: 'Parent Email',
    key: 'parentContactEmail',
    show: false
  },
  {
    title: 'Parent Cell',
    key: 'parentContactNumberFull',
    show: false
  },
  {
    title: 'Address',
    key: 'address.addressLine1',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Province',
    key: 'address.state',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Shortid',
    key: 'shortid',
    filtering: true,
    sorting: true,
    show: false,
  },
]

const dateOfBirthColumn = {
  title: 'Date of Birth',
  key: 'dateOfBirth',
  valueType: 'date',
  show: false,
  sorting: { compare: Sort.DATE },
  render: (r: any) => r && Moment(r).format('YYYY-MM-DD'),
};

const hiddenPlayerColumns: any[] = [
  {
    title: 'Height',
    key: 'height',
    filtering: true,
    sorting: true,
    show: false
  },
  {
    title: 'Weight',
    key: 'weight',
    filtering: true,
    sorting: true,
    show: false
  },
  {
    title: 'Shoe Size',
    key: 'shoeSize',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Sports',
    key: 'csports',
    filtering: true,
    sorting: true,
    show: false,
  },
  { ...dateOfBirthColumn },
  {
    title: 'Langauage',
    key: 'language',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'AI Mobile Operation',
    key: 'aimobile',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'AI Operation',
    key: 'aiop',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Scorer',
    key: 'scorer',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Camera Operator',
    key: 'camop',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Producer',
    key: 'producer',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: ' Region',
    key: 'cregion',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Experience',
    key: 'experience',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Linear',
    key: 'clinear',
    filtering: true,
    sorting: true,
    show: false,
  },
  {
    title: 'Comments',
    key: 'ccomments',
    filtering: true,
    sorting: true,
    show: false,
  },
  { ...dateOfBirthColumn },
]

const coachColumns: any[] = [
  ...personalInfoColumns,
  { ...sportColumn },
  ...hiddenColumns,
  { ...signedUpColumn },
];

const playerColumns: any[] = [
  ...personalInfoColumns,
  { ...sportColumn },
  ...hiddenColumns,
  ...hiddenPlayerColumns,
  // { ...dateOfBirthColumn },
  { ...ageGroupColumn },
  { ...signedUpColumn },

];

const userColumns: any[] = [
  ...personalInfoColumns,
  { ...roleColumn },
  ...hiddenColumns,
  { ...signedUpColumn },
];

const getColumnShape = (userType: string) => {
  if (userType === 'player') return playerColumns
  if (userType === 'coach') return coachColumns
  return userColumns
}

const getUserType = (pathname: string) => {
  if (pathname.includes('player')) return 'player'
  if (pathname.includes('coach')) return 'coach';
  if (pathname.includes('user') && pathname.includes('admin')) return 'admin';
  if (pathname.includes('org')) return 'orgAdmin';
  return 'user';
}

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  activeOrgId: useSelector(getActiveOrgId),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedOrg: useSelector(getSelectedOrg),
})

export const UserList: React.FC<any> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { isAdmin, selectedOrg, canSelectOrgs, activeOrgId } = useSelectors()

  const userType = useMemo(() => getUserType(location.pathname), [location]);

  const handleBack = useCallback(() => history.push('/'), [history]);

  // const needsSport = useMemo(() => ['player', 'coach'].includes(userType), [userType]);

  if (!activeOrgId && !isAdmin) return <NotFound handleBack={handleBack} subTitle={'You need an active organisation to view this page.'} />;

  if (canSelectOrgs && !selectedOrg) return <SelectAnOrg handleBack={handleBack}/>

  return (
    <AuthGuard
      // checkForSport={needsSport}
    >
      <UserListInner key={userType} />
    </AuthGuard>
  )
};

export const UserListInner: React.FC<any> = (props) => {
  const [users, setUsers] = useState([]);
  const [sportList] = useSports();

  const history = useHistory();
  const location = useLocation()

  const userType = useMemo(() => getUserType(location.pathname), [location]);

  const englishReadableType = useMemo(() => toCaps(userType).replace(/([a-z])([A-Z])/g, `$1 $2`), [userType]);

  const columns = useMemo(() => {
    const _columns = getColumnShape(userType);
    // Add sport names to sport info column in multi-select mode
    const sportInfo = _columns.find((c: any) => c.key === 'sportInfo');
    if (sportInfo && sportList) {
      const sports = compact(sportList.map((s: any) => s.id !== '' && Object.assign({ text: s.name, value: s.name})));
      sportInfo.filtering = {
        filters: [ ...sports ],
        filterMultiple: true,
        type: Filter.SELECT
      };
    }
    return _columns;
  }, [userType, sportList])

  const [fetchUsers, { loading }] = useFetchUserList({
    userType,
    onCompleted: (result: any) => {
      if (result) {
        setUsers(cloneDeep(result));
      }
    }
  });

  const handleAdd = useCallback(() => {
    switch (userType) {
      case 'player':
        return history.push('/player/add');
      case 'coach':
        return history.push('/coach/add');
      default:
        return history.push('/user/add');
    }
  }, [userType, history]);

  useEffect(() => setUsers([]), []);

  return (
    <Page card>
      <Table
        data={users}
        columns={columns}
        loading={loading}
        onRow={(record: any, rowIndex: any) => ({
         onClick: () => history.push(`/user/${record.shortid}`)
        })}
        refresh={fetchUsers}
        title={englishReadableType + ' List'}
        exportTitle={toCaps(userType) + 's'}
        actions={<Tooltip title={`Add New ${englishReadableType}`}><Button icon={<PlusOutlined />} onClick={handleAdd} shape="circle"/></Tooltip>}
      />
    </Page>
  );
};

export default UserList;
