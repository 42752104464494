import React, { useCallback } from 'react';
import styled from 'styled-components'
import { BrowserRouter as Router, Route, useHistory, Redirect } from 'react-router-dom'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { Login, SendOTP, ResetPassword, Welcome, FAQ, ContactUs } from '../pages'
import { GlobalThemeProvider, Header, SessionGuard } from '../components';
import { Global } from '../styles/global'
import { Authorized } from './Authorized'
import { Button, Layout } from 'antd'
import { useLoadThemes, useAntTheme } from '../hooks/use-ant-theme'
import Themes from '../themes'
import 'antd/dist/antd.css'
import { UnlockOutlined } from '@ant-design/icons';
import Background from '../assets/background.svg';
import { NavButton } from '../components'
import ScrollToTop from '../components/scroll-to-top/ScrollToTop';
import { SCROLL_ID } from './Authorized';
import 'react-image-lightbox/style.css';

const { Content } = Layout

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
`

const StyledLayout = styled(Layout)`
  height: 100vh !important;
  background-image: url(${Background}) !important;
  background-size: cover !important;
`

const Btn = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainContent = styled(Content)`
  overflow-y: auto;
  background: none;
  height: calc(100vh - 60px);
`

const LoginButton = () => {

  const history = useHistory();

  const handleOnClick = useCallback(() => history.push('/login'), [history]);

  return (
    <Btn
      shape='circle'
      type='text'
      icon={<UnlockOutlined />}
      onClick={handleOnClick}
      size='large'
    />
  )
}

export const App = () => {

  useLoadThemes(Themes)
  const [{ inSession }] = useCognitoUser();
  const [{ theme }] = useAntTheme();

  return (
    <GlobalThemeProvider theme={theme}>
      <Global />
      <Router>
        <StyledLayout>
          <Header
            hidden={inSession}
            actions={!inSession ? [
              <NavButton url={'/faq'}>FAQ</NavButton>,
              <NavButton url={'/contactus'}>Contact Us</NavButton>,
              <LoginButton />
            ] : []}
          />
          { !inSession && (
            <MainContent id={SCROLL_ID}>
              <ScrollToTop scrollId={SCROLL_ID} />
              <Page>
                <Route exact path='/login' component={Login} />
                <Route exact path='/forgot-password' component={SendOTP} />
                <Route exact path='/reset-password' component={ResetPassword} />
                <Route exact path='/' component={Welcome} />
                <Route exact path='/faq' component={FAQ} />
                <Route exact path='/contactus' component={ContactUs} />
                <Route>
                  <Redirect to='/' />
                </Route>
              </Page>
            </MainContent>
          )}

          <SessionGuard
            pushTo='/login'
            validPaths={[
              '/register',
              '/forgot-password',
              '/reset-password',
              '/',
              '/login',
              '/faq',
              '/contactus'
            ]}
          >
            <Authorized />
          </SessionGuard>

        </StyledLayout>
      </Router>
    </GlobalThemeProvider>
  );
}

export default App;
