import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import {
  Avatar,
  Table,
  Page,
  AuthGuard, Sort, Filter
} from '../../components';
import { Button, message, PageHeader, Popconfirm, Tooltip } from 'antd';
import styled from 'styled-components';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { ARCHIVE_TEAM, useFetchTeamList } from '../../graphql';
import { useCurrentSport } from '../../redux/config';
import { TeamOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { cleanError } from '../../helpers/error-helper';
import { AGE_GROUPS } from '../../utils';

const Title = styled.div<{ color?: string}>`
  color: ${p => p.theme.primaryColor};
  display: flex;
  align-items: center;
  align-content: center;
  .ant-avatar {
    margin-right: 5px;
  }
`;

export const TeamList: React.FC<any> = (props) => {

  const history = useHistory();

  const [teams, setTeams] = useState([]);

  const [currentSport] = useCurrentSport()

  const [fetchTeamList, { loading }] = useFetchTeamList({
    onCompleted: (result: any) => {
      setTeams(cloneDeep(result.teams));
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const [removeTeam, { loading: removing }] = useMutation(ARCHIVE_TEAM);

  const handleRemove = useCallback((id: string) => async (e?: any) => {
    e.stopPropagation();
    message.loading({ content: 'Removing team...', duration: 10 });
    try {
      await removeTeam({ variables: { id } })
      const _teams = teams.filter((t: any) => t.id !== id);
      setTeams(_teams);
      message.success({ content: 'Team has been removed', duration: 2 })
    } catch (ex) {
      //console.log(ex);
      message.error({ content: cleanError(ex, 'Unable to remove team'), duration: 3 })
    }
  }, [teams, setTeams, removeTeam])

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Picture',
        export: false,
        key: 'primaryImage',
        render: (r: any) => <Avatar src={getThumbnail(r)} icon={<TeamOutlined />} inverse />
      },
      {
        title: 'Team',
        filtering: true,
        sorting: true,
        key: 'name',
      },
      {
        title: 'Coach',
        sorting: true,
        key: 'headCoach',
        dataIndex: [],
        render: (r: any) => r && r.headCoach ? `${r.headCoach.firstname} ${r.headCoach.lastname}` : 'None'
      },
      {
        title: 'Captain',
        sorting: true,
        key: 'captain',
        dataIndex: [],
        render: (r: any) => r && r.captain ? `${r.captain.firstname} ${r.captain.lastname}` : 'None'
      },
      {
        title: 'Members',
        sorting: true,
        key: 'teamMembers.length',
        render: (teamMembers: any) => teamMembers ? teamMembers : '0'
      },
      {
        title: 'Age Group',
        key: 'ageGroup',
        sorting: true,
        render: (r: any) => AGE_GROUPS.find((a: any) => a.value === r)?.text,
        filtering: {
          filters: [...AGE_GROUPS],
          filterMultiple: true,
          type: Filter.SELECT
        },
      },
      { title: '', key: 'id', render: (id: string) =>
          <Popconfirm
            title='Are you sure you want to remove this team?'
            onConfirm={handleRemove(id)}
            onCancel={handleCancel}
            okText='Yes'
            cancelText='No'
          >
            <Button onClick={handleCancel} type='link' danger>Remove</Button>
          </Popconfirm>
      },
    ];
  }, [handleCancel, handleRemove]);

  const handleAdd = useCallback(() => history.push('/team/add'), [history]);

  return (
    <AuthGuard
      checkForOrg
      checkForSport
      needsActiveOrg
    >
      <Page card>
        {/*<PageHeader style={{width: '100%'}} title={} />*/}
        <Table
          data={teams}
          columns={columns}
          loading={loading}
          onRow={(record: any, rowIndex: any) => ({
            onClick: () => history.push(`/team/${record.id}`)
          })}
          refresh={fetchTeamList}
          title={<Title><Avatar src={getThumbnail(currentSport.appIcon)} inverse /> {currentSport.name} Teams</Title>}
          exportTitle={'Teams'}
          actions={<Tooltip title={`Add New Team`}><Button icon={<PlusOutlined />} onClick={handleAdd} shape="circle"/></Tooltip>}
        />
      </Page>
    </AuthGuard>
  )
};

export default TeamList;
