import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox, CheckboxProps } from 'formik-antd'
import { FieldContainer } from './FieldContainer'
import { InputFieldProps, FieldProps } from './types'
import { useFormikContext } from 'formik'

const Component: React.FC<InputFieldProps<CheckboxProps, FieldProps>> = ({ 
  gridProps = {}, 
  label, 
  required, 
  hidden, 
  className, 
  name,
  ...props 
}) => {

  const { isSubmitting } = useFormikContext() || {}

    return (
        <FieldContainer
          { ...gridProps }
          name={name}
          required={required}
          label={label || undefined}
          className={`${className} ant-field-container`}
          hidden={hidden}
        >
            <Checkbox
              name={name}
              disabled={isSubmitting}
              { ...props }
            />
        </FieldContainer>
    )
}

export const CheckBox = styled(Component)`
`