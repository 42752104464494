import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

export declare interface SelectorProps {
    [name: string]: (state: any) => any
}

/**
 * A hook to for hoisting several selectors into a single function call.
 * @param selectors An object containing key-function pairs. The key is the name of the respective selector's
 * result in the return object.
 * @returns An object where each key represents the value of it's respective passed in selector.
 */
export const useSelectors = (selectors: SelectorProps) => {
    const deps = useMemo(() => [ ...Object.keys(selectors).map((selector: string) => selectors[selector])], [selectors])
    selectors = useMemo(() => ({ ...selectors }), deps)
    const hoist = useCallback((state: any) => Object.keys(selectors).reduce((result: any, key: string) => ({
        ...result,
        [key]: selectors[key](state)
    }), {}), [selectors])
    return useSelector(hoist)
}

export default useSelectors