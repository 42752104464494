import styled from 'styled-components'
import { Layout } from 'antd'
import React from 'react';

const { Footer: FooterContainer } = Layout

export const SFooter = styled(FooterContainer)`
  background: ${p => p.theme.primaryColor}77 !important;
  color: white !important;
  height: 55px
`

export const Footer = () => {
  return <SFooter>
    This is a footer
  </SFooter>
}

export default Footer;
