import React from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import { ReactComponent as CBar} from '../assets/welcome/contact_bar.svg';
import { ReactComponent as CBarSM} from '../assets/welcome/contact_bar_sm.svg';
import { PContent, Container, SCard, Text , ImgFlex } from '../pages/Welcome';
import { message, Row, Col, Button } from 'antd';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useFormData } from '../hooks/use-form-data';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { FormRow, Input, TextArea, FormButton } from '../components';
import { InfoCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import { cleanError } from '../helpers/error-helper';
// IMAGES
import Netball from '../assets/welcome/netball.png';

const CONTACT = gql`
  mutation SEND_MESSAGE($newContactRequest: NewContactRequest!) {
    addContactRequest(newContactRequest: $newContactRequest) {
      id
    }
  }
`;

const contactSchema = Yup.object().shape({
    firstname: Yup.string()
      .required()
      .label('First Name'),
    lastname: Yup.string()
      .required()
      .label('Last Name'),
    email: Yup.string()
      .required()
      .label('Email'),
    contactNumber: Yup.string()
      .required()
      .label('Contact Number'),
    companyName: Yup.string()
      .required()
      .label('School/Organisation'),
    message: Yup.string()
      .nullable()
      .label('Your message (Optional)'),
  });

const ContactBar = styled(CBar)`
width: 100%;
height: auto;
padding: 40px 0;

@media only screen and (max-width: 990px) {
    display: none
  }
`;

const ContactBarSM = styled(CBarSM)`
width: 100%;
height: auto;
padding: 40px 0;

@media only screen and (min-width: 991px) {
    display: none
  }
`;

const SubmitButton = styled(FormButton)`
  width: 100%;
`;

const Question = styled(QuestionCircleOutlined)`
  font-size: 1.5em;
  color: ${p => p.theme.primaryColor} !important;
  margin-right: 20px;
  display: inline-grid;
  vertical-align: middle;
`;

const Info = styled(InfoCircleOutlined)`
  font-size: 1.5em;
  color: ${p => p.theme.primaryColor} !important;
  margin-right: 20px;
  display: inline-grid;
  vertical-align: middle;
`;

const List = styled(Row)`
  padding-bottom: 40px;
`;

const AltFormRow = styled(FormRow)`
  padding: 0 0 40px 0;
`;

const AltInput = styled(Input)`
  &&{
    .ant-form-contact-label{
      color: #666 !important;
    }
  }
`;


export const ContactUs: React.FC<any> = (props) => {

  const history = useHistory()

  const [sendContact] = useMutation(CONTACT);

  const { fields, ...formikCTX } = useFormData(contactSchema, {
    onSubmit: async (values: any, actions: any) => {
      return sendContact({
        variables: {
          newContactRequest: {
            ...values,
          },
        },
      });
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Could not contact. Please check your connection and try again!') });
    },
    onCompleted: () => {
      message.success({ content: 'Thanks, we will be in contact!' });
    },
  });

  return (
    <PContent>
      <Container style={{paddingBottom: '120px'}}>
        <ContactBar/>
        <ContactBarSM/>
          <Row gutter={[40, 40]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <SCard>
                    <List gutter={[15, 20]}>
                      <Col span={2}>
                        <Info />
                      </Col>
                      <Col span={22}>
                        <Text>For general enquiries have a look at our <Button style={{margin: '0 -14px'}} type='link' onClick={() => history.push('/faq')}>FAQ's</Button> to see if your question has already been answered. </Text>
                      </Col>
                      <Col span={2}>
                        <Question />
                      </Col>
                      <Col span={22}>
                        <Text>For any technical issues please visit our <Button style={{marginLeft: '-14px'}} type='link' onClick={() => window.open("https://necta.atlassian.net/servicedesk/customer/portal/3")}>Help Center.</Button></Text>
                      </Col>
                    </List>
                    <Row gutter={20}>
                      <ImgFlex size={'md'}>
                        <img alt='PlayPro Sport Team Management Webapp Platform Contact' src={Netball} />
                      </ImgFlex>
                    </Row>
                  </SCard>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <SCard style={{display: 'grid', alignContent: 'center'}}>
                      <Formik {...formikCTX} enableReinitialize validateOnBlur>
                      {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                          <Form layout={'vertical'}>
                            <AltFormRow gutter={20} justify='space-between'>
                                <AltInput {...fields.firstname} />
                                <AltInput {...fields.lastname} />
                                <AltInput {...fields.email} />
                                <AltInput {...fields.contactNumber} />
                                <AltInput {...fields.companyName} />
                                {/* <ContactNumber numberField={fields.contactNumber} /> */}
                                <TextArea {...fields.message} rows={4} gridProps={{ md: 24, lg: 24, xl: 24, xxl: 24 }} />
                            </AltFormRow>
                            <Text>
                                By clicking “Submit,” I acknowledge receipt of the PlayPro <Button style={{marginLeft: '-14px'}} type='link' onClick={() => window.open(`${process.env.REACT_APP_S3_HOST}uploads/privacy-policy-playpro.pdf`)}>Privacy Policy.</Button>
                            </Text>
                            <SubmitButton type='submit'>Submit</SubmitButton>
                          </Form>
                      )}
                      </Formik>
                  </SCard>
              </Col>
          </Row>
      </Container>
    </PContent>
  );
};

export default ContactUs;
