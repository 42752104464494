import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { getFormData } from '@bit/necta.hooks.get-form-data';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import Moment from 'moment';
import AutoForm from '../../components/auto-form';
import { Page, AuthGuard, Select, FormRow, CheckBoxGroup } from '../../components';
import { useFormData, useImport } from '../../hooks';
import { getActiveOrgId, getIsAdmin, canSelectOrgs } from '../../utils/selectors';
import { exportDataToExcel } from '../../utils';
import { message, Modal, Result } from 'antd';
import { Form } from 'formik-antd';
import { CheckBox, FullButtonLg } from '../../components';
import { OrgSelector } from '../../components/org-selector';
import { SpreadsheetImport, ImportTables, NextButton, ConfirmForm, ImportFinalize, ImportConfirm, sanitize, fullWidth } from '../../components/importer';
import { ImportCancelAction, SaveAction, DownloadTemplateAction } from '../../components'
import { useHideImportActions } from '../../hooks'
import { cleanError } from '../../helpers/error-helper';
import { confirmOrgImportScheama, orgSchema } from './schema';
import { IMPORT_ORGS_MUTATION } from '../../graphql';
import { orgTypes } from './ViewEdit';

const { confirm } = Modal;

const { schema, initialValues } = getFormData(orgSchema);

const addData = (data: any[], attachPolicies: string[], orgType: string) => {
  return data.map((r: any) => Object.assign({
    ...r,
    attachPolicies,
    orgType
  }));
};

export const OrgImport: React.FC<any> = () => {
  const {
    values,
    schema: _schema,
    fields,
    editing,
    validateRows,
    hasValues,
    handleClear,
    handleEdit,
    handleEditCancel,
    handleEditComplete,
    handleSpreadsheet,
    handleRemove,
  } = useImport(initialValues, 'organisation', schema);

  const [organisations, setOrganisations] = useState<any[]>([]);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const isAdmin = useSelector(getIsAdmin);
  const canSelect = useSelector(canSelectOrgs)

  const activeOrganisationId = useSelector(getActiveOrgId);

  const [importOrganisations] = useMutation(IMPORT_ORGS_MUTATION);

  const exportOrganisations = useCallback(() => {
    exportDataToExcel(fields, organisations, 'playpro-organisation-data-import_' + Moment().format('YYYY-DD-MM') + '.xlsx');
  }, [organisations]);

  const clearData = useCallback(() => {
    handleClear();
    setOrganisations([]);
    setImportSuccess(false);
  }, [handleClear, setOrganisations, setImportSuccess])

  const handleCancel = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      content: 'This will discard all your changes',
      onOk() {
        clearData();
      },
    });
  }, [clearData]);

  const next = useCallback(() => {
    setOrganisations(values.validData.map((val: any) => Object.assign({ ...val, key: undefined })));
  }, [setOrganisations, values]);

  const back = useCallback(async () => {
    if (importSuccess) {
      clearData()
    } else if (organisations.length > 0) {
      setOrganisations([]);
    } else {
      handleCancel();
    }
  }, [clearData, organisations, setOrganisations, clearData, handleCancel, importSuccess]);

  const { fields: cFields, initialValues: cInitialValues, ...formikCTX } = useFormData(confirmOrgImportScheama, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      actions.setSubmitting(true);
      confirm({
        title: 'Are you sure?',
        content: 'This will attempt to import all the valid organisations to your selected organisation account. You will receive an email with the status of your import when it is complete.',
        async onOk () {
          const { downloadExcel, parentOrganisationId, attachPolicies, orgType } = values;
          const parentOrg = !parentOrganisationId.length ? undefined : parentOrganisationId;
          const _orgType = !orgType.length ? undefined : orgType;
          const organisationData: any[] = [...sanitize(addData(organisations, attachPolicies, _orgType))]
          try {
            await importOrganisations({
              variables: { newOrganisationData: { organisations: organisationData, parentOrganisationId: parentOrg } },
            });
            setImportSuccess(true);

            if (downloadExcel) {
              exportOrganisations();
            }
            actions.resetForm(initialValues);
          }
          catch (ex) {
            message.error({ content: cleanError(ex, 'Something went wrong'), duration: 4});
          } finally {
            actions.setSubmitting(false);
          }
        },
      });
    },
  });

  const cDefaultValues = useMemo(
    () =>
      Object.assign({
        ...cInitialValues,
        organisationId: isAdmin ? null : activeOrganisationId,
      }),
    [activeOrganisationId, cInitialValues, isAdmin],
  );

  const {
    hideCancel,
    hideDownload,
    hideSave
  } = useHideImportActions(importSuccess, values, organisations)

  return (
    <AuthGuard needsActiveOrg>
      <Page
        card
        title='Import Organisations'
        onBack={hasValues ? back : undefined}
        extra={[
          <ImportCancelAction hidden={hideCancel} onClick={handleCancel} />,
          <DownloadTemplateAction hidden={hideDownload} onClick={handleSpreadsheet} />,
          <SaveAction onClick={next} hidden={hideSave} tooltip='Next Step' />
        ]}
      >
        {!importSuccess && (
          <Fragment>
            <SpreadsheetImport hidden={hasValues} onComplete={validateRows} fields={fields} />
            <ImportTables
              hidden={!hasValues || organisations.length > 0}
              validData={values.validData}
              invalidData={values.invalidData}
              onDelete={handleRemove}
              fields={fields}
              editRow={handleEdit}
            />
            <FullButtonLg hidden={!hasValues || organisations.length > 0} onClick={next}>
              Next
            </FullButtonLg>
            {organisations.length > 0 && (
              <ImportFinalize>
                <ImportConfirm>
                  You are about to import {organisations.length} organisation{organisations.length !== 1 && 's'}.
                </ImportConfirm>
                <Formik
                  initialValues={cDefaultValues}
                  validateOnBlur
                  {...formikCTX}
                >
                  {({ }) => (
                    <Form layout={'vertical'}>
                      { !isAdmin && !activeOrganisationId &&
                      <ImportConfirm>You need to have an active organisation account to be able to import organisation</ImportConfirm>}
                      <ConfirmForm>
                        <OrgSelector hidden={!isAdmin} {...cFields.parentOrganisationId} gridProps={fullWidth} />
                        <Select hidden={!canSelectOrgs} {...cFields.orgType} options={orgTypes} defaultValue={'School'} />
                        <CheckBoxGroup
                          hidden
                          {...cFields.attachPolicies}
                          options={['CanCreateOrganisation', 'CanCreateUser', 'CanCreateTeam']}
                        />
                        <CheckBox {...cFields.downloadExcel} gridProps={fullWidth}>Download copy of export</CheckBox>
                        <NextButton>Confirm</NextButton>
                      </ConfirmForm>
                    </Form>
                  )}
                </Formik>
              </ImportFinalize>
            )}
          </Fragment>
        )}
        {importSuccess && (
          <Result
            status="success"
            title="Import successful"
            subTitle="Server is busy processing your data, please wait a few minutes and you will be sent a success email
                when your import has been completed."
            extra={[
              <FullButtonLg onClick={back}>Acknowledge</FullButtonLg>,
            ]}
          />
        )}
      </Page>
      <Modal
        title="Update Row"
        visible={!!editing}
        onCancel={handleEditCancel}
        footer={null}
      >
        <AutoForm schema={_schema} fields={fields} values={editing} onComplete={handleEditComplete} />
      </Modal>
    </AuthGuard>
  );
};

export default OrgImport;
