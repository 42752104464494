import React from 'react'
import {
    BlockOutlined as Dash,
    UserOutlined as Person,
    UserAddOutlined as PersonAdd,
    UsergroupAddOutlined,
    ContactsOutlined as ImportContacts,
    IdcardOutlined,
    TeamOutlined,
    AppstoreOutlined,
    AppstoreAddOutlined,
    SmileOutlined,
    SolutionOutlined,
    CalendarOutlined,
    InsertRowBelowOutlined,
    LayoutOutlined,
    OrderedListOutlined as List,
    MailOutlined,
    PlusSquareOutlined,
    GroupOutlined,
  } from '@ant-design/icons'

export const getRoutes = (canSelectOrgs: boolean) => [
  { name: 'Dashboard', path: '/', key: 'dashboard', icon: <Dash /> },
  { name: 'Players', path: '/player', key: 'players', icon: <Person />, subItems: [
      { name: 'View All', path: '/player/list', key: 'list-players', icon: <Person /> },
      { name: 'Add Player', path: '/player/add', key: 'add-players', icon: <PersonAdd /> },
      { name: 'Import Players', path: '/player/import', key: 'import-players', icon: <ImportContacts /> }
    ]},
  { name: 'Coaches', path: '/coach', key: 'coach', icon: <IdcardOutlined />, subItems: [
      { name: 'View All', path: '/coach/list', key: 'list-coaches', icon: <Person /> },
      { name: 'Add Coach', path: '/coach/add', key: 'add-coaches', icon: <PersonAdd /> },
      { name: 'Import Coaches', path: '/coach/import', key: 'import-coaches', icon: <ImportContacts /> }
    ]},
  { name: 'Teams', path: '/team', key: 'team', icon: <TeamOutlined />, subItems: [
      { name: 'View All', path: '/team/list', key: 'list-teams', icon: <TeamOutlined /> },
      { name: 'Add Team', path: '/team/add', key: 'add-teams', icon: <UsergroupAddOutlined /> },
    ]},
  { name: 'Events', path: '/event', key: 'event', icon: <GroupOutlined />,  subItems: [
      { name: 'Add Event', path: '/event/add', key: 'add-event', icon: <PlusSquareOutlined /> },
      { name: 'View All', path: '/event/list', key: 'list-event', icon: <List /> },
      { name: 'View Broadcast Requests', path: '/event/requests', key: 'event-requests', icon: <List /> },
    ]},
  { name: 'Fixtures', path: '/fixture', key: 'fixture', icon: <InsertRowBelowOutlined />, subItems: [
      { name: 'View Schedule', path: '/fixture/schedule', key: 'list-fixture', icon: <CalendarOutlined /> },
      { name: 'Add Fixture', path: '/fixture/add', key: 'add-fixture', icon: <LayoutOutlined /> },
      { name: 'View All', path: '/fixture/list', key: 'add-fixture', icon: <List /> },
      { name: 'Pending Invites', path: '/fixture/pending', key: 'pending-fixture', icon: <MailOutlined /> }
    ]},
  { name: 'Organisation', path: '/org', key: 'org', icon: <AppstoreOutlined />, subItems: [
      { name: 'View All', path: '/org/list', key: 'list-orgs', icon: <AppstoreOutlined /> },
      { name: 'Add Organisation', path: '/org/add', key: 'add-orgs', icon: <AppstoreAddOutlined />, hidden: !canSelectOrgs },
      { name: 'Import Organisations', path: '/org/import', key: 'import-orgs', icon: <ImportContacts /> },
      { name: 'View Org Admins', path: '/org/admins', key: 'list-org-admins', icon: <SolutionOutlined /> },
      { name: 'Add Org Admin', path: '/org/add/admin', key: 'add-org-admins', icon: <PersonAdd />, roles: ['Organisation'] },
    ]},
  { name: 'Management', path: '/user', key: 'user', icon: <SmileOutlined />, roles: ['Admin'], subItems: [
      { name: 'View All', path: '/user/list', key: 'list-users', icon: <Person /> },
      { name: 'View Admins', path: '/user/admins', key: 'list-admin-users', icon: <SolutionOutlined /> },
      { name: 'Add Admin', path: '/user/add', key: 'add-users', icon: <PersonAdd /> },
    ]},
  { name: 'Third Party Providers', path: '/third-party-provider', key: 'user', icon: <SmileOutlined />, subItems: [
      { name: 'View All', path: '/third-party-provider/list', key: 'list-providers', icon: <Person /> },
      { name: 'Add Third Party Providers', path: '/third-party-provider/add', key: 'add-provider', icon: <PersonAdd /> },
    ]},
]
