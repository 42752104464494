import React, { useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'
import { PieArcDatum } from 'd3-shape'
import { Data, Breakdown } from './types'

interface DonutProps {
    height: number,
    top?: number,
    bottom?: number,
    width: number,
    left?: number,
    right?: number,
    innerRadius?: number,
    data: Breakdown[],
    getColor: (d: Data) => string
}

const Base = styled.div`
    width: 100%;
    min-width: 350px;
    padding: 10px;

    h1 {
        color: ${ p => p.theme.primaryColor };
        font-size: 35px;
    }
`

export const DonutChart: React.FC<DonutProps> = ({
    height: _height,
    width: _width,
    top = 5,
    bottom = 5,
    left = 5,
    right = 5,
    innerRadius = 0.5,
    data,
    getColor,
    ...props 
}) => {

    const ref = useRef<SVGSVGElement>(null)

    const draw = useCallback((ref: any) => {

        const width = _width - left - right
        const height = _height - top - bottom
        const radius = Math.min(width, height) / 2
    
        const svg = d3
          .select(ref.current)
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr('transform', `translate(${width / 2},${height / 2})`)
    
        const pie = d3
          .pie<Data>()
          .sort(null)
          .value((record) => record.value)
    
        const path = d3.arc<PieArcDatum<Data>>().innerRadius(innerRadius * radius).outerRadius(radius)
    
        const pieData = pie(data)
    
        const arch = svg
          .selectAll('.arc')
          .data(pieData)
          .enter()
          .append('g')
          .attr('class', 'arc')
          .attr('opacity', 0.9)
          .attr('fill', (d) => getColor(d.data))
          .attr("stroke", "white")
          .style("stroke-width", "2px")

        arch.append('path').attr('d', path)
          
        const text = svg
          .selectAll('allLabels')
          .data(pieData)
          .enter()
          .append('text')
          .text((d: any) => {
              return d.data.name 
          })
          .attr('transform', (d) => {
              const pos = path.centroid(d)
              pos[0] -= d.data.name.length * 3
              return 'translate(' + pos + ')'
          })
          .attr('fill', 'grey')
          .attr('font-size', 12)

        const labels = svg
          .selectAll('allLabels')
          .data(pieData)
          .enter()
          .append('text')
          .text((d: any) => {
              return d.data.value
          })
          .attr('transform', (d: any) => {
              const pos = path.centroid(d)
              pos[0] -= d.data.name.length * 1
              pos[1] += 12
              return 'translate(' + pos + ')'
          })
          .attr('fill', 'grey')
          .attr('font-size', 12)
    
    }, [_width, _height, left, right, top, bottom, data, getColor, innerRadius])

    useEffect(() => {
        const current = ref.current
        if (current) draw(ref)
        return () => {
            if (current) {
                current.innerHTML = ''
            }
        }
    }, [draw, ref])

    return (
        <Base>
            <h1>Team Breakdown</h1>
            <svg ref={ref} />
        </Base>
    )
}