import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Dot = styled.span`
  font-size: 6px;
  color: red;
  margin-right: 5px;
  
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
`

const Status = styled.div<{ color: string, align?: 'left' | 'right' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${ p => p.color };
  text-align: left;
  ${ p => p.align && css`text-align: ${p.align};` };
  padding: 2px;
`

export const statusColors: any = {
  Pending: '#fb0',
  Confirmed: '#43ec3a',
  Complete: '#0e9106',
  Cancelled: '#ff3030',
  'In Progress': '#333',
  'Awaiting Results': '#1a6eff',
}

interface IProps {
  status: string;
  [x: string]: any;
}

const FixtureStatus: FC<IProps> = ({ status }) => {
  return (
    <Status color={statusColors[status] || '#333'}>
      { status === 'In Progress' && <Dot>&#11044;</Dot>}
      {status}
    </Status>
  );
};

FixtureStatus.defaultProps = {};

export default FixtureStatus;
