import styled from 'styled-components';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../../utils';
import { CrownOutlined } from '@ant-design/icons';

const OrgName = styled.div<{ bold?: boolean }>`
  font-weight: ${p => p.bold ? 'bold': 'normal'} !important;
  svg {
    margin-right: 5px;
  }
`;

interface RenderOrgProps {
  org?: any,
  name?:  string,
  result?: string,
  isHome?: boolean;
}

export const RenderOrg: React.FC<RenderOrgProps> = ({ org, name, result, isHome }) => {
  const myOrg = useSelector(getSelectedOrg);
  const orgName = useMemo(() => org ? org.name : name, [org, name]);
  const isVictor = useMemo(() => {
    if (!result || ['', 'D', 'P'].includes(result)) return false; // abort early
    if (isHome && result === 'W') return true;
    if (!isHome && result === 'L') return true;
    return false;
  }, [result, isHome]);

  const isBold = useMemo(() => myOrg?.id && myOrg.id === org?.id, [myOrg, org]);

  return (
    <OrgName bold={isBold}>
      {isVictor && <CrownOutlined />}
      <span>{orgName}</span>
    </OrgName>
  );
}

export default RenderOrg;
