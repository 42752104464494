import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react'
import Message, { MessageType, ArgsProps } from 'antd/lib/message'
import { createUUID } from '../../utils/create-uuid'

//TODO: i need to make sure that these package dependencies register as peer within a range, but fallback if peer doesnt exist

export type Options = {
    duration?: number,
    onClose?: (error: Error | undefined) => void,
    onClick?: (event: React.MouseEvent<HTMLDivElement>, error?: Error) => void,
    content?: React.ReactNode | string,
    disableAuto?: boolean,
    onError?: (error: Error | undefined) => void,
    icon?: React.ReactNode
}

const DefaultArgsProps: ArgsProps = {
    content: 'Something went wrong! Click to report!',
    duration: 3,
    type: 'error',
    onClose: () => {}
}

//mutator for adding a new key to the front of the active keys array
//(swap parent and arguments of concat to change direction)
const getKeys = (key: string) => (keys: string[]) => [key].concat(keys.map((k) => k))
//mutator for filtering a new array while excluding a single key
const filterKeys = (key: string) => (keys: string[]) => keys.filter((_key: string) => _key !== key)

/**
 * A hook that returns an alert callback generated with given options. All passed in options can be pverridden
 * by passing A config object to the returned @code alert callback.
 * @returns An array where the first element is an alert callback, while the second is a context object containing
 * a callback to destroy messages, as well as a an array containing the state of active message keys (id's)
 */
export const useErrorMessage = (error: Error | undefined, message: React.ReactNode | string | null, options: Options = {}) => {
    const { 
        duration, 
        onClose, 
        content, 
        onClick,
        disableAuto, 
        onError,
        icon
    } = options

    const [ _error, setError ] = useState(error)
    /**
     * keys: A list of active message id's
     */
    const [ keys, setKeys ] = useState<string[]>([])
    /**
     * hasCalled: State to determine if an error message has been auto triggered
     */
    const [ hasCalled, setHasCalled ] = useState<boolean>(false)

    //set Error state if and only if error state changes
    useEffect(() => {
        if (error) setError(e => e !== error ? error : e)
    }, [error, setError])

    //set error to undefined when ounmounted
    //useEffect(() => () => setError(undefined), [])

    //a react ref to catch the promise returned by the message api.
    //used for closing a triggered message programmatically.
    const ref = useRef<MessageType | null>(null)

    //onClick callback, defaults to close on click
    const _onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const hide = ref.current
        //call optional callback if defined
        if (onClick) onClick(e, _error)
        //trigger a message close if message exists
        if (hide) hide()
    }, [ref, onClick, _error])

    /**
     * makeOnClose: onClose callback. Defaults to clear error state and remove
     * its parent id from the list of active keys.
     */
    const makeOnClose = useCallback((key: string) => () => {
        if (onClose) onClose(_error)
        setError(undefined)
        setHasCalled(false)
        setKeys(filterKeys(key))
    }, [_error, onClose, setKeys, filterKeys, setError, setHasCalled])

    //build default args component based on DefaulArgsProps and hook props
    const _args = useMemo(() => ({
        duration: duration || DefaultArgsProps.duration,
        content: (content || message) || DefaultArgsProps.content,
        type: 'error',
        onClick: _onClick,
        icon
        //maybe add a default key?
    }) as ArgsProps, [duration, content, DefaultArgsProps, _onClick])
    
    /**
     * alert: The callback that fires the error message.
     */
    const alert = useCallback((props: Partial<ArgsProps>  = {}) => {
        //merge existing options with newer ones
        const key = createUUID()
        setKeys(getKeys(key))
        const args = {
            //attach the key
            key: createUUID(),
            //attach a callback with a link to the generated key
            onClose: makeOnClose(key),
            ..._args,
            ...props,
        }
        //trigger message if error is active
        const hide = Message.open(args)
        ref.current = hide
        return hide
    }, [createUUID])

    /**
     * destroy: A callback to destroy an active message with id of key.
     */
    const destroy = useCallback((key: string) => {
        setKeys(filterKeys(key))
        Message.destroy(key)
    }, [Message, setKeys, getKeys, filterKeys])

    //listen for error and trigger message unless disableAuto = true
    useEffect(() => {
        if (disableAuto) return
        if (_error && !hasCalled) {
            if (onError) onError(_error)
            alert()
            setHasCalled(true)
        }
    }, [disableAuto, _error, hasCalled, onError, alert, setHasCalled])

    return [alert, { destroy, keys }] as const
 }