import React from 'react'
import { Field } from 'formik'
//review this ASAP
import { Col, ColProps } from 'antd/lib/grid'

import styled from 'styled-components'
import { FieldProps } from 'formik'

interface Props {
    component: any,
    disabled?: boolean,
    [key: string]: any
}

const Component: React.FC<FieldProps & ColProps & Props> = ({
    flex, offset, push, pull, order, span, xs = 24, sm = 24, md = 12, lg = 12, xl, xxl, field, ...rest 
}) => {
    const colProps = { flex, offset, push, pull, order, span, xs, sm, md, lg, xl, xxl } as ColProps

    return (
        <Col {...colProps}>
            <Field
                {...field}
                {...rest}
            />
        </Col>
    )
}

export const FormCol = Component

export const StyledFormCol = styled(Component)`
    
`