import { ConfigState, LoadedSport, LoadedOrg, LoadedSponsor, LoadedEvent } from './types'
/**
 * A set of available action types on a config slice.
 */
export const ActionTypes = Object.freeze({
    SET_CONFIG: 'SET_CONFIG',
    SET_CURRENT_SPORT: 'SET_CURRENT_SPORT',
    SET_CURRENT_ORG: 'SET_CURRENT_ORG',
    SET_CURRENT_TABLE_CONFIG: 'SET_CURRENT_TABLE_CONFIG',
    SET_SPORTS: 'SET_SPORTS',
    SET_SPONSORS: 'SET_SPONSORS',
    SET_EVENTS: 'SET_EVENTS',
})

/**
 * returns a disptach object
 * @param config the new config
 */
const setConfig = (config: ConfigState) => {
    return { type: ActionTypes.SET_CONFIG, payload: config }
}

/**
 * returns a dispatch object
 * @param sport the new selected sport name as a string.
 */
const setCurrentSport = (sport: LoadedSport) => {
    return { type: ActionTypes.SET_CURRENT_SPORT, payload: sport }
}

/**
 * returns a dispatch object.
 * @param sports an array of sport config objects.
 */
const setSports = (sports: LoadedSport[]) => {
    return { type: ActionTypes.SET_SPORTS, payload: sports }
}

/**
 * returns a dispatch object to set the current org.
 * @param org the new current org identifier.
 */
const setCurrentOrg = (org: LoadedOrg) => {
    return { type: ActionTypes.SET_CURRENT_ORG, payload: org }
}

/**
 * returns a dispatch object to set the currently loaded sponsor array.
 * @param sponsors The list of loaded sponsors to set to config.
 */
const setSponsors = (sponsors: LoadedSponsor[]) => {
    return { type: ActionTypes.SET_SPONSORS, payload: sponsors }
}

/**
 * returns a dispatch object to set the currently loaded event array.
 * @param events The list of loaded events to set to config.
 */
const setEvents = (events: LoadedEvent[]) => {
    return { type: ActionTypes.SET_EVENTS, payload: events }
}

export const Actions = {
    setConfig,
    setCurrentSport,
    setSports,
    setCurrentOrg,
    setSponsors,
    setEvents
}
