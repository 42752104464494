import * as yup from 'yup'

export const importFromTeamSchema = yup.object().shape({
    name: yup.string().label('Name'),
    teamId: yup.string().label('Team'),
    description: yup.string().label('Description'),
    primaryImage: yup.string().label('Image'),
    organisationId: yup.string().label('Organisation'),
    sportId: yup.string().label('Sport'),
    captainId: yup.string().label('Captain'),
    headCoachId: yup.string().label('Head Coach'),
    ageGroup: yup.string().label('Age Group'),
})
