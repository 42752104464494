import styled, { css } from 'styled-components'
import { DashCard } from '../dash-cards'

interface WidgetCardProps {
    width?: number,
    height?: number
}

export const WidgetCard = styled(DashCard)<WidgetCardProps>`
    margin-top: 6px;
    width: 100%;
    .ant-card {
      background: white;
      color: ${ p => p.theme.primaryColor };
      font-size: 24px;
      min-height: 260px;
      border: 3px solid ${ p => p.theme.primaryColor } !important;
      border-color: ${ p => p.theme.primaryColor } !important;
      padding: 0;
    }

    .ant-card {
        ${ p => p.width ? css`width: ${p.width}px;` : ''}
        ${ p => p.height ? css`height: ${p.height}px;` : ''}
    }
    div.ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      min-height: 260px;
      width: 260px;
      padding: 0;
      margin: 0;
    }
    div.ant-card-body {
        ${ p => p.width ? css`width: ${p.width}px;` : ''}
        ${ p => p.height ? css`height: ${p.height}px;` : ''}
    }
    .ant-card-body {
        cursor: default;
    }
`

export default WidgetCard
