import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Select, Input, SelectProps, InputProps } from 'formik-antd'
import { useFormikContext } from 'formik'
import { FieldContainer } from '../FieldContainer'
import { InputFieldProps, FieldProps } from '../types'
import DefaultCodes from './country-codes'
import { useErrors } from '../../hooks'

interface Code {
    label: string,
    value: string
}

interface YupProps {
    name: string,
    required?: boolean,
    label?: string
}

interface ContactNumberProps {
    inputProps?: InputProps,
    selectProps?: SelectProps,
    codes?: Code[],
    countryCodeField: YupProps,
    numberField: YupProps
}

type Props = Omit<InputFieldProps<ContactNumberProps, FieldProps>, "name">

const ContactNumberComponent: React.FC<Props> = ({ 
    numberField, countryCodeField, inputProps = {}, selectProps = {}, disabled, label, required, codes = DefaultCodes, hidden, gridProps, className, ...props
}) => {

    const { isSubmitting, setFieldValue } = useFormikContext() || {}

    const fields = useMemo(() => [numberField.name, countryCodeField.name], [numberField, countryCodeField])
    const [{ errorText }] = useErrors(fields)

    const handleOnCodeChange = useCallback(
        (value: string) => {
          const name = (countryCodeField ?? { name: '' }).name;
          setFieldValue(name, value || name);
        },
        [countryCodeField, setFieldValue],
      );

    return (
        <FieldContainer
            className={`${className} ant-field-container`}
            hidden={hidden}
            label={numberField.label || ''}
            required={numberField.required || countryCodeField.required}
            errorText={errorText}
        >
            <Input
                name={numberField.name}
                addonBefore={
                    <Select
                        style={{ minWidth: '70px' }}
                        onChange={handleOnCodeChange}
                        name={countryCodeField.name}
                        options={codes}
                        showSearch
                        disabled={isSubmitting || disabled}
                        { ...selectProps }
                    />
                }
                disabled={isSubmitting || disabled}
                { ...inputProps }
            />
        </FieldContainer>
    )
}

export const ContactNumber = styled(ContactNumberComponent)`
`
