export const initializeTheme = (theme: any, payload: any) => ({
    loaded: generateThemes(payload),
    active: getActiveTheme(Object.keys(payload), theme.active)
})

const getActiveTheme = (keys: any, active: string) => {
    if (active) return active
    if (keys.length > 0) {
        if ((keys as unknown as any).includes('DEFAULT')) return 'DEFAULT'
        return keys[0]
    }
    return ''
}

const generateThemes = (themes: any) => {
    const themeOverride: any = process.env.REACT_APP_DEFAULT_THEME;
    if (!themeOverride || !themes) return themes;
    const themeOverrideObj = themes[themeOverride];
    const _themes = {...themes};
    Object.keys(themes).map((theme: any) => {
        const _theme: any = _themes[theme];
        _theme['@primary-color'] = themeOverrideObj['@primary-color'];
        if (theme === 'DEFAULT') if (themeOverride) _theme['@secondary-color'] = themeOverrideObj['@secondary-color'];
    })
    return _themes;
}

export const parseKey = (key: string) => key.substring(1).split('-').reduce((rec: string, curr: string) => (
    rec + capitalize(curr)
))

const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.substring(1)
}
