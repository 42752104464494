import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';
import { Avatar } from '../../../components';
import Table, { Filter, Sort } from '../../../components/table';
import { Card, Image } from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import { getThumbnail } from '@bit/necta.hooks.s3'

const Img = styled(Image)`
  border-radius: 50%;
  width: 35px;
`;

const columns: any[] = [
  {
    title: 'Sport',
    filtering: true,
    sorting: true,
    key: 'team.sport',
    getValues: (r: any) => r?.name,
    render: (r: any) => <><Avatar src={getThumbnail(r?.appIcon)} inverse />&nbsp;&nbsp;{r.name}</>
  },
  {
    title: 'Team',
    filtering: true,
    sorting: true,
    key: 'team.name',
  },
  {
    title: 'Coach',
    sorting: true,
    key: 'team.coach',
    render: (coach: any) => coach ? `${coach.firstname} ${coach.lastname}` : 'None'
  },
  {
    title: 'Captain',
    sorting: true,
    key: 'team.captain',
    render: (captain: any) => captain ? `${captain.firstname} ${captain.lastname}` : 'None'
  },
  {
    title: 'Primary Role',
    filtering: true,
    sorting: true,
    key: 'teamRole.name',
  },
];
export const UserTeamList: React.FC<any> = (props) => {
  const { user, loading } = props;

  const teams = useMemo(() => user.teams ? cloneDeep(user.teams) : [], [user])

  const history = useHistory();

  return (
    <Table
      data={teams}
      columns={columns}
      loading={loading}
      searchable={false}
      exportable={false}
      columnSelection={false}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/team/${record?.team?.id}`)
      })}
    />
  );
};

export default UserTeamList;
