import { useHistory } from 'react-router';
import React, { useCallback, useMemo } from 'react';
import { Button, Col, Empty, Popconfirm, Row } from 'antd';
import { Avatar, TeamRole } from '../../../components';
import { orderBy } from 'lodash';
import { Teamsheet, TeamsheetMember } from '../../../redux';
import { UserOutlined, CloseOutlined, SoundOutlined, SkinOutlined } from '@ant-design/icons';
import { getThumbnail } from '@bit/necta.hooks.s3';
import { CCard, Divide, Full, Fullname, Nick, Role, Section } from './styles';

interface TeamSheetMemberProps {
  teamMember: TeamsheetMember,
  handleNavigate?: (t: TeamsheetMember) => (e?: any) => void,
  handleRemove?: (id: string) => (e?: any) => void,
  removing?: boolean,
}
export const TeamSheetMember = ({ teamMember, handleRemove, handleNavigate, removing }: TeamSheetMemberProps) => {
  const history = useHistory();

  const _handleNavigate = useCallback((record: any) => (e: any) => {
    e.stopPropagation();
    if (handleNavigate) return handleNavigate(record)(e);
    history.push(`/fixture/teamsheet/member/edit/${record.id}`)
  }, [history, handleNavigate]);

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  return (
    <Col xs={24} md={12} lg={8} xl={6} xxl={6} onClick={_handleNavigate(teamMember)}>
      <CCard
        title={
          <Avatar size={{ xs: 80, sm: 80, md: 80, lg: 100, xl: 100, xxl: 120 }} src={getThumbnail(teamMember.primaryImage)} icon={<UserOutlined />} inverse />
        }
        hiddenActions={!handleRemove}
        extra={
          handleRemove && <Popconfirm
            title='Are you sure you want to remove this team member?'
            onConfirm={handleRemove(teamMember.id)}
            onCancel={handleCancel}
            okButtonProps={{ loading: removing }}
            okText='Yes'
            cancelText='No'
          >
            <Button type="link" onClick={handleCancel} danger>
              <CloseOutlined />
            </Button>
          </Popconfirm>
        }
        hoverable
      >
        <Fullname>
          <Full>{teamMember.firstname} {teamMember.lastname}</Full>
          <TeamRole row={teamMember} />
        </Fullname>
        <Nick>{teamMember.nickname ? `"${teamMember.nickname}"`: null}</Nick>
        <Role>{teamMember.teamRole?.name}</Role>
      </CCard>
    </Col>
  )
}

interface TeamSheetCardsProps {
  teamsheet: Teamsheet,
  handleRemove?: (id: string) => (e?: any) => void,
  removing?: boolean,
  hidden?: boolean
}
export const TeamSheetCards = ({ teamsheet, handleRemove, removing, hidden }: TeamSheetCardsProps) => {

  const players = useMemo(() => {
    if (!teamsheet?.teamsheetMembers?.length) return [];
    // Player = not coach
    const _players = teamsheet.teamsheetMembers.filter((t: TeamsheetMember) => !t.isCoach);
    return orderBy(_players, 'isCaptain', 'desc');
  }, [teamsheet]);

  const coaches = useMemo(() => {
    if (!teamsheet?.teamsheetMembers?.length) return [];
    const _coaches = teamsheet.teamsheetMembers.filter((t: TeamsheetMember) => t.isCoach);
    return orderBy(_coaches, 'isHeadCoach', 'desc');
  }, [teamsheet]);

  if (hidden || !teamsheet) return null;

  if (!teamsheet?.teamsheetMembers?.length) return <Empty description={'No team members yet!'} />;

  return (
    <>
      {
        coaches?.length > 0 && (
          <Section>
            <Divide plain orientation="left">Coaches <SoundOutlined /></Divide>
            <Row gutter={[8, 8]}>
              {coaches.map((t: TeamsheetMember) => <TeamSheetMember teamMember={t} handleRemove={handleRemove} removing={removing} /> )}
            </Row>
          </Section>
        )
      }
      {
        players?.length > 0 && (
          <Section>
            <Divide plain orientation="left">Players <SkinOutlined /></Divide>
            <Row gutter={[8, 8]}>
              {players.map((t: TeamsheetMember) => <TeamSheetMember teamMember={t} handleRemove={handleRemove} removing={removing} /> )}
            </Row>
          </Section>
        )
      }
    </>
  )
}

export default TeamSheetCards;
