import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { NotificationOutlined as Coach, CrownOutlined as Captain } from '@ant-design/icons'

export const TeamRole: React.FC<any> = ({ row, ...props }) => {
    const { isHeadCoach, isCaptain } = row

    const title = useMemo(() => {
      if (isHeadCoach) return 'Head Coach'
      if (isCaptain) return 'Captain'
      return undefined
    }, [isHeadCoach, isCaptain])

    const icon = useMemo(() => {
      if (isHeadCoach) return <Coach />
      if (isCaptain) return <Captain />
      return null
    }, [isHeadCoach, isCaptain])

    return <Tooltip title={title}>
      {icon}
    </Tooltip>

}

export default TeamRole
