import React, { useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Result, Button } from 'antd'
import NotFoundSvg from '../assets/404.svg';

import { Container, NotFoundProps } from '../components'

interface Props extends NotFoundProps {
    pathTo?: string
}

//TODO: flesh out these types
export type NotAuthorizedTypes = 'expired'

/**
 * Generates a string based on the type of message that should be rendered.
 * @param type The type of message to render.
 * @retuns The appropriate message for the given type.
 */
const getSubtitle = (type: NotAuthorizedTypes) => {
    switch (type) {
        case 'expired':
            return 'Your session has expired.'
        default:
            return 'You don\'t have the right permissions to view this page.'
    }
}

/**
 * Takes all the same props as the NotFound page component. Reads a type from url params and renders different messages
 * based on the type as defined by NoAuthorizeTypes. If props are specified then teh messages will be overriden.
 */
export const NotAuthorized: React.FC<Props> = ({ pathTo, subTitle, handleBack, handleRefresh, icon }) => {

  const history = useHistory()
  const params = useParams()
  const type = get(params, 'type')

  const _subTitle = useMemo(() => getSubtitle(type), [type])

  const _handleBack = useCallback(() => history.push(pathTo || '/'), [history])

  return (
    <Container>
      <Result
        title='Not Authorized'
        subTitle={subTitle || _subTitle}
        icon={icon || <img src={NotFoundSvg} alt={'Not found'}/>}
        extra={
          <>
            <Button onClick={handleBack || _handleBack}>Go Back</Button>
            <Button type="primary" onClick={handleRefresh}>Refresh</Button>
          </>
        }
      />
    </Container>
  );
}