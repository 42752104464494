import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
const { Text } = Typography;

const Content = styled(Text)`
  max-width: 500px;
`

export const clean = (error: any, title?: string): string => {
  if (typeof error === 'string') {
    return error;
  } else if (error.graphQLErrors) {
    const _error = JSON.parse(JSON.stringify(error));
    if (_error?.message) {
      return noQL(_error.message.length > 0 ? _error.message : title);
    } else if (_error?.graphQLErrors?.length > 0) {
      return noQL(_error.graphQLErrors[0]?.message?.length > 0 ? _error.graphQLErrors[0].message : title);
    }
  } else {
    return error.message ? error.message : title;
  }
  return title + '';
}

const noQL = (error: string) => {
  return error.replace('GraphQL error: ', '')
}

export const cleanError = (error: any, title?: string): React.ReactNode =>  {
  const _error = clean(error);
  if (_error === title) return <Content ellipsis copyable>{title}</Content>;
  return (
    <>
      <b>{title}</b><br/>
      <Content code ellipsis copyable>{_error}</Content>
    </>
  )
}

cleanError.defaultProps = {
  title: 'An unknown error occurred. Please check your internet connection.'
}
