import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Page,
  NotFound,
  ProfilePictureUploader,
  FormRow,
  Input,
  TextArea,
  FormButton, FormLoading,
  AuthGuard, Select
} from '../../components';
import { message, PageHeader, Skeleton } from 'antd';
import styled from 'styled-components';
import { UPDATE_TEAM, useFetchTeam } from '../../graphql';
import { Form } from 'formik-antd';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import PicPlaceholder from '../../assets/default_profile.png';
import { teamSchema } from './schema';
import { useMutation } from '@apollo/client';
import { useFormData } from '../../hooks/use-form-data';
import { cleanError } from '../../helpers/error-helper';
import { AGE_GROUP_OPTIONS } from '../../utils';

const finalSchema = teamSchema.shape({
})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const EditTeam: React.FC = () => {
  const { shortid }: any = useParams()

  const history = useHistory()

  const [team, setTeam] = useState<any>(null);

  const [fetchTeam, { loading }] = useFetchTeam({
    variables: { id: shortid },
    onCompleted: (result: any) => {
      setTeam({ ...result.team });
    },
    onError: (error: any) => {
      //console.log(error)
    }
  })

  const [handleUpdate] = useMutation(UPDATE_TEAM)

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      const { id, teamMembers, captain, headCoach, organisation, organisationId, sportId, createdAt, sport, ...restValues } = values;
      message.loading({ content: 'Updating team...', duration: 10 });
      return handleUpdate({ variables: { id, updateTeam: { ...restValues } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team updated!', duration: 2 });
      history.push('/team/' + shortid);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to update team'), duration: 4 });
    }
  })

  const handleRefresh = useCallback((e?: any) => fetchTeam(), [fetchTeam])

  const handleBack = useCallback(() => history.push('/team/' + shortid), [history]);

  const hasTeam = useMemo(() => team && !isEmpty(team), [team]);

  if (loading) return <FormLoading />;

  if (!hasTeam) {
    return <NotFound subTitle={'Unable to find team'} handleBack={handleBack} handleRefresh={handleRefresh} />;
  }

  return (
    <AuthGuard needsActiveOrg>
      <Formik { ...formikCTX} initialValues={team} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page card>
            <Form layout={'vertical'}>
              <PageHeader style={{width: '100%'}} title='Edit Team' onBack={handleBack} />
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <Input {...fields.name} />
                <Select options={AGE_GROUP_OPTIONS} {...fields.ageGroup} allowClear />
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              <FormButton>
                UPDATE
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
};

export default EditTeam;
