import * as yup from 'yup';

// Create a yup schema based off a config and a base schema object
const createYupSchema = (schema: any, config: any) => {
  const { id, validationType, validations = [], _nullable, ...rest } = config;

  if (!(yup as any)[validationType]) {
    return schema;
  }

  let validator = (yup as any)[validationType]();

  validations.forEach((validation: any) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  if (rest) {
    Object.keys(rest).map((k: string) => {
      validator = validator[k](rest[k]);
    });
  }

  schema[id] = validator;
  return schema;
};

export default createYupSchema;

