import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { Col, message } from 'antd'
import { Form } from 'formik-antd'
import { useMutation } from '@apollo/client'
import { useAntTheme, useFormData, Yup } from '../../../hooks'
import { TeamRoleSelector, Tags, FormRow, TextArea, FormButton, PageHeader } from '../../../components'
import { UPDATE_SPORT_INFO } from '../../../graphql'
import { LoadedSport } from '../../../redux'
import { SportInfo } from './types'
import { Avatar } from '../../../components';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { cleanError } from '../../../helpers/error-helper';

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 36px;
    height: 36px;
  }
`;

const Title = styled(PageHeader)<{ color?: string}>`
  .ant-page-header-heading-title {
    color: ${p => p.color} !important;
    font-size: 26px;
  }
`;

const Button = styled(FormButton)`
  margin: 0;
  width: 100%;
`;

export interface SportInfoData {
    id: string
    accolades?: string | null
    sportId: string
    userId: string | null
    teamRoleId: string | null
    teamRoleAltId: string | null
    tags: string[] | null
    archived?: boolean
}

const schema = Yup.object().shape({
    teamRoleId: Yup.string()
        .nullable()
        .label('Primary Role'),
    teamRoleAltId: Yup.string()
        .nullable()
        .label('Secondary Role'),
    accolades: Yup.string()
        .nullable()
        .label('Accolades'),
    tags: Yup.array()
        .nullable()
        .label('Tags')

})

interface SportInfoFormProps {
    disabled?: boolean,
    sportInfo: SportInfo
}

const GRID_PROPS = { sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };

const prune = (values: FormikValues) => {
    const obj = { ...values }
    Object.keys(obj).forEach((key: string) => {
        if (!obj[key]) obj[key] = undefined
    })
    return obj
}

export const SportInfoForm: React.FC<SportInfoFormProps> = ({ sportInfo, disabled = false, ...props }) => {

    const [{ loaded }] = useAntTheme()
    const sportId = sportInfo.sport.id
    const sportName = sportInfo.sport.name
    const appIcon = sportInfo.sport.appIcon
    const theme = sportInfo.sport.appTheme;
    const color = loaded[theme] ? loaded[theme]['@secondary-color'] : 'black';

    const initialValues = useMemo(() => ({
        teamRoleId: sportInfo.teamRoleId,
        teamRoleIdAlt: sportInfo.teamRoleAltId,
        tags: sportInfo.tags,
        accolades: sportInfo.accolades
    }), [sportInfo])

    const [updateSportInfo] = useMutation(UPDATE_SPORT_INFO)

    const { fields, ...formikCTX } = useFormData(schema, {
        onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
            message.loading({ content: 'Updating sport info...', duration: 10 });
            return updateSportInfo({ variables: {
                id: sportInfo.id,
                updateSportInfo: { ...prune(values) }
            } })
        },
        onCompleted: (result: any) => {
            message.success({ content: 'Sport info updated!', duration: 2 });
        },
        onError: (e: any) => {
            message.error({ content: cleanError(e, 'Unable to update sport info'), duration: 4 });
        }
    })

    return <Formik {...formikCTX} initialValues={initialValues} enableReinitialize validateOnBlur>
        {({ dirty }) => (
            <Form style={{width: '100%'}} layout='vertical' id={sportName + '-form'}>
                <Title color={color} title={
                    <React.Fragment>
                        <Icon src={getThumbnail(appIcon || '')} size='small' />
                        {sportName}
                    </React.Fragment>
                } />
                <FormRow disabled={disabled} >
                    <TeamRoleSelector sport={sportId} {...fields.teamRoleId } />
                    <TeamRoleSelector sport={sportId} {...fields.teamRoleAltId } />
                    <TextArea gridProps={{ ...GRID_PROPS }} {...fields.accolades} />
                    <Tags gridProps={{ ...GRID_PROPS }} {...fields.tags} />
                    <Button hidden={!dirty} >Save</Button>
                </FormRow>
            </Form>
        )}
    </Formik>
}
