import React, { useEffect, useMemo, useCallback, useState } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import moment, { Moment } from 'moment'
import {
  FormButton,
  FormRow,
  Page,
  Input,
  OrgSelector,
  OrgSelectOption as Option,
  DatePicker,
  ContactNumber,
  TextArea,
  Select
} from '../../components'
import { useFormData } from '../../hooks';
import { Form } from 'formik-antd'
import { message, Collapse as Collapser, Col, Radio, Checkbox } from 'antd'
import { AuthGuard, CheckBox } from '../../components'
import { cleanError } from '../../helpers/error-helper';
import { SportSelector } from '../../components'
import { fixtureSchema } from './schema'
import { LocationSearch } from '../../components/location-search';
import { useCurrentOrg, useCurrentSport, LoadedOrg as Org } from '../../redux';
import { mapArrayToObject, SA_PROVINCES, COUNTRIES, canSelectOrgs, capitalize, getActiveOrg, getIsAdmin } from '../../utils'

import { useMutation } from '@apollo/client'
import { ADD_FIXTURE, SEARCH_ORGS } from '../../graphql'
import { useSelector } from 'react-redux';
import { EventSelector } from '../../components/event-selector';

const countries = mapArrayToObject(COUNTRIES)
const provinces = mapArrayToObject(SA_PROVINCES)

const { Panel } = Collapser

const Participant = styled.h1`
  font-size: 14px;
  font-weight: 200;
`

const FULL_PROPS = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24
}

const Check = ({ hidden, children }: any) => {
  if (hidden) return null;
  return (
    <Col {...FULL_PROPS} style={{ marginBottom: 15 }}>
      {children}
    </Col>
  )
}

const cleanValues = (values: any) => {
  return {
    ...values,
    isHosting: undefined,
    isAway: undefined,
    showAwayOrgName: undefined,
    showHomeOrgName: undefined,
    time: undefined,
    homeOrgId: values.homeOrgId === 'other' ? undefined : values.homeOrgId,
    awayOrgId: values.awayOrgId === 'other' ? undefined : values.awayOrgId,
  }
}

export const FixtureAdd: React.FC<any> = (props) => {

  const history = useHistory()
  const [currentSport] = useCurrentSport()
  const activeOrg = useSelector(getActiveOrg)
  const isAdmin = useSelector(getIsAdmin)

  const isParent = useMemo(() => activeOrg?.childOrgs?.length > 0, [activeOrg])

  const defaultSportId = useMemo(() => currentSport.name !== 'PlayPro' ? currentSport.id : '', [currentSport])

  const [addFixture, { error }] = useMutation(ADD_FIXTURE)

  const { fields, initialValues, ...formikCTX } = useFormData(fixtureSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating Fixture...', duration: 10 })
      ///if not parent, default owner to current user's active org

      const ownedById = isParent ? activeOrg?.id : values.activeOrg;
      const result = await addFixture({ variables: {
        newFixtureData: { ...cleanValues(values), ownedById }
      }})
      if (!error) actions.resetForm()
      if (result) {
        const fixtureId = result.data?.addFixture?.id
        if (!fixtureId) return result
        history.push(`/fixture/${fixtureId}`)
      }
      return result
    },
    onCompleted: (result: any) => {
        message.success({ content: 'New Fixture created!'})
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create Fixture'), duration: 4 });
    }
  })

  const [fixture] = useState({
    ...initialValues,
    sportId: currentSport.id,
    homeOrgId: !isParent && !isAdmin ? activeOrg?.id : undefined,
    isHosting: !(isAdmin || isParent),
  })

  const handleOrgChange = useCallback((key: string, setFieldValue: any, ownedById: string, currentId: string) => (value: Org) => {
    setFieldValue(key, !value)
    if (ownedById === currentId && value?.id !== currentId) setFieldValue('ownedById', null);
  }, []);

  const filterOptions = useCallback((orgs: Org[]) => {

    const placeholder: Org = { id: 'other', name: 'Other' };

    if (isParent) return [placeholder].concat(orgs.filter((org: Org) => {
      return org.name !== activeOrg.name
    }));

    return [placeholder].concat(orgs);
  }, [isParent, activeOrg])

  const getOwnerOrgOptions = useCallback((values: any) => (orgs: Org[]) => {
    const { showHomeOrgName, showAwayOrgName } = values;
    if (!isAdmin && !isParent) return [];
    if (showHomeOrgName || showAwayOrgName) return [];
    const options = orgs.filter((org: Org) => {
      const passes = org.id === values.homeOrgId
        || org.id === values.awayOrgId
        || org.id === activeOrg?.id
      const isOther = org.id === 'other'
      return passes && !isOther
    })
    return options.map((org: Org) => org ? (
      <Option key={org.id} value={org.id} label={org.name}>{org.name}</Option>
    ) : null)
  }, [isParent, isAdmin, activeOrg])

  const handleRadioChange = useCallback((setFieldValue: any, values: any) => (e: any) => {
    if (e.target.value === 'Home') {
      setFieldValue('isHosting', true)
      setFieldValue('isAway', false)
      if (!isParent) {
        setFieldValue('homeOrgId', activeOrg.id)
        setFieldValue('awayOrgId', values.homeOrgId || '');
        setFieldValue('homeOrgName', '');
        setFieldValue('showHomeOrgName', false);
        if (values.homeOrgId === 'other') setFieldValue('showAwayOrgName', true);
        setFieldValue('awayOrgName', values.homeOrgName || '');
      }
    }
    if (e.target.value === 'Away') {
      setFieldValue('isHosting', false)
      setFieldValue('isAway', true)
      if (!isParent) {
        setFieldValue('awayOrgId', activeOrg.id)
        setFieldValue('homeOrgId', values.awayOrgId || '')
        setFieldValue('awayOrgName', '');
        setFieldValue('showAwayOrgName', false);
        if (values.awayOrgId === 'other') setFieldValue('showHomeOrgName', true);
        setFieldValue('homeOrgName', values.awayOrgName || '');
      }
    }
  }, [activeOrg, isParent]);

  const handleTime = useCallback((startDate: string, setFieldValue: any) => (e: any) => {
    const endDate = moment(startDate)
    if (e.target.value === 'other') {
      setFieldValue('time', e.target.value)
      return
    }
    endDate.add(parseInt(e.target.value), 'minutes')
    setFieldValue('dateEnd', endDate.format())
    setFieldValue('time', e.target.value)
  }, [])

  const updateTime = useCallback((time: string, setFieldValue: any) => (date: any, dateString: string) => {
    if (time === 'other') {
      return;
    }
    date.add(parseInt(time), 'minutes')
    setFieldValue('dateEnd', date.format())
  }, [])

  const copyLocation = useCallback((values: any, setValue: any) => (e: any) => {
    if (e.target.checked) {
      setValue('address.addressLine1', activeOrg?.address?.addressLine1);
      setValue('address.country', activeOrg?.address?.country);
      setValue('address.state', activeOrg?.address?.state);
    } else {
      setValue('address.addressLine1', '');
      setValue('address.country', '');
      setValue('address.state', '');
    }
  }, [activeOrg]);

  const copyContact = useCallback((values: any, setValue: any) => (e: any) => {
    if (e.target.checked) {
      setValue('primaryContactCell', activeOrg?.primaryContactCell);
      setValue('primaryContactCellCountryCode', activeOrg?.primaryContactCellCountryCode);
      setValue('primaryContactEmail', activeOrg?.primaryContactEmail);
      setValue('primaryContactName', activeOrg?.primaryContactName)
    } else {
      setValue('primaryContactCell', '');
      setValue('primaryContactCellCountryCode', '');
      setValue('primaryContactEmail', '');
      setValue('primaryContactName', '')
    }
  }, [activeOrg]);

  return (
    <AuthGuard
      needsActiveOrg
    >
      <Formik { ...formikCTX} initialValues={fixture} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors, setErrors }) => (
          <Page
            title='Add Fixture'
            card
          >
            <Form layout={'vertical'}>

              <Collapser
                defaultActiveKey={[
                    'event',
                    'contact',
                    'time',
                    'participants',
                    'location'
                ]}
                ghost
              >

                <Panel key='event' header='Event Information'>
                    <FormRow>
                        <Input
                          placeholder='e.g. u19 final'
                          { ...fields.name }
                        />

                        <SportSelector
                          { ...fields.sportId }
                          showSearch
                          allowClear={false}
                          defaultValue={defaultSportId}
                        />
                        <EventSelector
                          {...fields.eventId}
                          showSearch
                          allowClear
                        />
                        <TextArea
                          gridProps={FULL_PROPS}
                          {...fields.description}
                        />
                    </FormRow>
                </Panel>

                <Panel key='participants' header='Participants'>
                  <FormRow>

                    <Col {...FULL_PROPS}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                        { !isAdmin && !isParent && <>
                          <Participant>I am:</Participant>
                          <Radio.Group
                            value={values.isHosting ? 'Home' : 'Away'}
                            onChange={handleRadioChange(setFieldValue, values)}
                            options={[
                              { label: 'Home', value: 'Home' },
                              { label: 'Away', value: 'Away' }
                            ]}
                            optionType='button'
                            style={{ marginBottom: '30px' }}
                          />
                        </>}
                      </Col>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <OrgSelector
                        QUERY={SEARCH_ORGS}
                        queryKey={'organisationsSafe'}
                        gridProps={FULL_PROPS}
                        { ...fields.homeOrgId }
                        filterOptions={filterOptions}
                        disabled={!isAdmin && !isParent && values.isHosting}
                        onChange={handleOrgChange('showHomeOrgName', setFieldValue, values?.ownedById, values?.homeOrgId)}
                      />
                      <Input gridProps={FULL_PROPS} hidden={!values.showHomeOrgName} {...fields.homeOrgName} />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <OrgSelector
                        QUERY={SEARCH_ORGS}
                        queryKey={'organisationsSafe'}
                        gridProps={FULL_PROPS}
                        { ...fields.awayOrgId }
                        filterOptions={filterOptions}
                        disabled={!isAdmin && !isParent && values.isAway}
                        onChange={handleOrgChange('showAwayOrgName', setFieldValue, values?.ownedById, values?.awayOrgId)}
                      />
                      <Input gridProps={FULL_PROPS} hidden={!values.showAwayOrgName} {...fields.awayOrgName} />
                    </Col>

                      <OrgSelector
                        hidden={!(isAdmin || isParent) || values.showHomeOrgName || values.showAwayOrgName}
                        {...fields.ownedById}
                        getOptions={getOwnerOrgOptions(values)}
                      />
                  </FormRow>
                </Panel>

                <Panel key='time' header='Time'>
                  <FormRow>
                    <Col {...FULL_PROPS}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <Participant>Duration:</Participant>
                        <Radio.Group
                          value={values.time}
                          options={[
                            { label: '60 Min', value: '60' },
                            { label: '80 Min', value: '80' },
                            { label: '90 Min', value: '90' },
                            { label: 'Other', value: 'other'}
                          ]}
                          onChange={handleTime(values.dateStart, setFieldValue)}
                          optionType='button'
                          style={{ marginBottom: '30px' }}
                        />
                      </Col>
                    </Col>

                    <DatePicker
                      format='MMM Do YYYY, HH:mm'
                      { ...fields.dateStart }
                      showTime={{ format: 'HH:mm' }}
                      onChange={updateTime(values.time, setFieldValue)}
                    />
                    <DatePicker
                      format='MMM Do YYYY, HH:mm'
                      disabled={values.time !== 'other'}
                      { ...fields.dateEnd }
                      showTime={{ format: 'HH:mm' }}
                    />
                  </FormRow>
                </Panel>

                <Panel key='location' header='Location'>
                  <FormRow>
                    <Check hidden={isAdmin || isParent}>
                      <Checkbox onChange={copyLocation(values, setFieldValue)}>Same as My Organisation</Checkbox>
                    </Check>
                    <LocationSearch { ...fields.address} parentKey='address' />
                    <Input {...fields.address.addressLine1} />
                    <Select {...fields.address.country} options={countries} />
                    <Select {...fields.address.state}  options={provinces} />
                  </FormRow>
                </Panel>

                <Panel key='contact' header='Contact Information'>
                  <FormRow>
                    <Check hidden={isAdmin || isParent}>
                      <Checkbox onChange={copyContact(values, setFieldValue)}>Same as My Organisation</Checkbox>
                    </Check>
                    <Input { ...fields.primaryContactName } />
                    <ContactNumber
                      numberField={fields.primaryContactCell}
                      countryCodeField={fields.primaryContactCellCountryCode}
                    />
                    <Input { ...fields.primaryContactEmail } />
                  </FormRow>
                </Panel>

              </Collapser>

              <FormButton>
                ADD
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default FixtureAdd
