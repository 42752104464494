import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { FieldContainer } from '../FieldContainer'
import { useSports, useCurrentSport, LoadedSport } from '../../redux'
import { get, isEmpty } from 'lodash'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

type Sport = LoadedSport

const { Option } = Select

interface SportSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  loading?: boolean
  //TODO: define these properly
  valueKey?: keyof Sport,
  labelKey?: keyof Sport,
  getOptions?: (orgs: Partial<Sport>[]) => React.ReactNode[],
  showPicture?: boolean;
  filterDefault?: boolean; // Filter out the default sport from options
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  allowClear?: boolean,
  showSearch?: boolean,
  filterOptions?: (sportList: Sport[]) => Sport[],
  onClear?: () => void,
  placeholder?: string
}

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const SportSelectorComponent: React.FC<SportSelectorProps & (FormikSelectProps | SelectProps<string>)> = ({
    getFormik,
    getOptions,
    labelKey = 'name',
    valueKey = 'id',
    name = '',
    onChange,
    disabled,
    label,
    required,
    hidden,
    gridProps,
    className,
    defaultValue,
    loading,
    showPicture = true,
    filterDefault = true,
    filterOptions,
    placeholder,
    ...props
  }) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')

  // fetch currently loaded sport configs
  const [sportList] = useSports()
  // fetch currently selected sport if exists
  const [currentSport] = useCurrentSport()

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const getSport = useCallback((key: string | number | symbol, value: any) => sportList.find((sport: Sport) => get(sport, key, null) === value), [sportList])

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value)
    if (onChange) onChange(getSport(valueKey, value))
    setSelected(value)
  }, [getSport, valueKey, name, formik, onChange, isFormik])

  /**
   * Select Options constructor. Builds an array of options children from loaded sport list.
   */
  const _getOptions = useCallback(() => {
    const filtered = filterOptions ? filterOptions(sportList) : sportList
    return filtered.map((sport: Sport) => {
      if (!filterDefault || (sport.id && sport.id !== '')) {
        return (
          <Option key={sport.id} value={sport[valueKey] as string} label={sport[labelKey]}>
            {showPicture && <Icon src={getThumbnail(sport.appIcon || '')} size="small" inverse />}
            {sport[labelKey]}
          </Option>
        )
      }
      return null
    })
  }, [sportList, filterDefault, showPicture, valueKey, labelKey])

  useEffect(() => {
    if (sportList.length > 0) setSelected(defaultValue)
    if (sportList.length === 0) setSelected('sports unavailable')
  }, [sportList, defaultValue])

  //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)


  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <FormikSelect
        prefix={<TeamOutlined className="site-form-item-icon" />}
        name={name}
        onChange={handleOnChange}
        disabled={disabled || formik.isSubmitting}
        placeholder={placeholder || 'Choose a Sport,,,'}
        {...props}
      >
        { /* call option getOptions prop to fetch children. default to map over orgs. */}
        {getOptions ? getOptions(sportList) : _getOptions()}
      </FormikSelect>
    </FieldContainer>
  )

  if (hidden) return null;

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<TeamOutlined className='site-form-item-icon' />}
      placeholder={placeholder || 'Choose a sport...'}
      loading={loading}
      className={`${className} ant-sport-selector`}
      onChange={handleOnChange}
      disabled={disabled}
      {...props}
    >
      {sportList.length > 0 && (getOptions ? getOptions(sportList) : _getOptions())}
    </Select>
 )
}

SportSelectorComponent.defaultProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'label',
  getFormik: true,
}

export const SportSelector = styled(SportSelectorComponent)`
    width: 100%;
    min-width: 170px;
    .ant-select-selector, input {
      min-width: 170px;
    }
`

interface Options {
  background?: string
}

export const FilledSportSelector = styled(SportSelector)<Options>`
    .ant-select-selector, input {
      border: none !important;
    }
    .ant-select-selector {
      background: ${p => p.background ?? p.theme.secondaryColor} !important;
      border-radius: 6px !important;
      -webkit-box-shadow: 0 1px 2px #33333333 !important;
      -moz-box-shadow: 0 1px 2px #33333333 !important;
      box-shadow: 0 1px 2px #33333333 !important;
    }
    .ant-select-clear {
      background: ${p => p.theme.secondaryColor};
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      color: #eee !important;
    } 
`;

FilledSportSelector.defaultProps = {
  filterDefault: false
}
