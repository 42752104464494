import styled from 'styled-components';
import { Card, Divider } from 'antd';

export const CCard = styled(Card)<{ hiddenActions?: boolean }>`
  .ant-card-head {
    background: #FFF;
    padding: 0;
  }
  .ant-card-head-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: ${p => p.hiddenActions ? '0' : '46px'};
  }
  .ant-card-extra {
    align-self: flex-start;
    padding-top: 5px;
  }
  .ant-card-head-wrapper {
    display: flex;
    width: 100%;
  }
  .ant-card-body {
  }
  cursor: pointer;
  background: #FAFAFA !important;
  height: 100%;
`;

export const Nick = styled.div`
  color: #979797;
`;

export const Role = styled.div`

`;

export const Fullname = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
`;

export const Full = styled.div`
  flex: 1;
`;

export const Divide = styled(Divider)`
  .anticon {
    margin-left: 8px;
  }
  svg {
    color: #00000073;
  }
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;
