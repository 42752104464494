import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIsParentOrAdmin } from './use-is-parent-or-admin'
import { getActiveOrgId } from '../utils'
import { Fixture } from '../redux';

  export const useHideActions = (orgId?: string, fixture?: Fixture) => {
    const isParentOrAdmin = useIsParentOrAdmin()
    const activeOrgId = useSelector(getActiveOrgId)
    const hide = useMemo(() => {
      if (fixture?.status === 'Cancelled') return true;
      if (isParentOrAdmin) return false
      if (!orgId) return false;
      if (orgId === activeOrgId) return false
      return true;
    }, [isParentOrAdmin, orgId, activeOrgId, fixture])
    return hide;
  }
