import { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { SPORT_INFO_ADDED, SPORT_INFO_DELETED } from '../queries';
import { sortByDate } from '../../helpers/sort-helper';

// TODO: maybe we can make a more generic subscription hook for similar patterns:
// Props: ADD/DELETE queries, initial values, id to subscribe to
export const useSportInfoSubscription = (
  id: string,
  initialSportInfo?: any[],
  options?: {
    onAdded?: (result: any) => void;
    onDeleted?: (result: any) => void;
  },
) => {
  const [sportInfo, setSportInfo] = useState<any[]>([]);

  const onAdded = useSubscription(SPORT_INFO_ADDED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const newSportInfo = sub.subscriptionData.data.SportInfoAdded;
        setSportInfo([...sportInfo, newSportInfo]);
        if (options && options.onAdded) options.onAdded(newSportInfo);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  const onDeleted = useSubscription(SPORT_INFO_DELETED, {
    variables: { id },
    onSubscriptionData: (sub: any) => {
      try {
        const removedSportInfo = sub.subscriptionData.data.SportInfoDeleted;
        const filteredSportInfo = sportInfo.filter((sportInfo: any) => sportInfo.id !== removedSportInfo.id);
        setSportInfo([...filteredSportInfo]);
        if (options && options.onDeleted) options.onDeleted(removedSportInfo);
      } catch (ex) {}
    },
    shouldResubscribe: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (initialSportInfo && initialSportInfo.length) setSportInfo([...sortByDate(initialSportInfo)]);
  }, [initialSportInfo]);

  return [sportInfo, onAdded, onDeleted] as const;
};
