import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { useFetchOrgList } from '../../graphql'
import { LoadedOrg } from '../../redux'
import { FieldContainer } from '../FieldContainer'
import { get, isEmpty } from 'lodash'

type Org = LoadedOrg

const { Option } = Select

export { Option as OrgSelectOption }

interface OrgSelectorProps {
    disabled?: boolean,
    label?: string,
    required?: boolean,
    hidden?: boolean,
    gridProps?: ColProps,
    defaultValue?: any,
    name?: string,
    onChange?: (value: Org) => void,
    //TODO: define these properly
    valueKey?: keyof Org,
    labelKey?: keyof Org,
    filterOptions?: (orgList: Org[]) => Org[],
    getOptions?: (orgs: Org[]) => React.ReactNode[],
    //TODO: update this to a more sophisticated prop
    getFormik?: boolean,
    value?: string,
    QUERY?: any,
    queryKey?: string
}

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const OrgSelectorComponent: React.FC<OrgSelectorProps & (FormikSelectProps | SelectProps<string>)> = ({
    value,
    getFormik,
    getOptions,
    labelKey = 'name',
    valueKey = 'id',
    name = '',
    onChange,
    disabled,
    label,
    required,
    hidden,
    gridProps,
    className,
    defaultValue,
    filterOptions,
    QUERY,
    queryKey,
    ...props
}) => {

    //the loaded list of orgs from the api
    const [orgs, setOrgs] = useState<any[]>([])

    //the current selected value state if no formik context
    const [selected, setSelected] = useState<string>(value || '')

    //if incoming value defined, diffcheck and set as current value (should override other routines)
    useEffect(() => {
        if (value !== undefined && value !== selected) setSelected(value)
    }, [value, setSelected, selected])

    const formik = useFormikContext() || {}
    const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

    const getOrg = useCallback((key: string | number | symbol, value: any) => orgs.find((org: Org) => get(org, key, null) === value), [orgs])

    const getOrgLabel = useCallback((org: Org) => {
        const isParent = org.childOrgs && org.childOrgs.length > 0
        return `${ org[labelKey] } ${ isParent ? '*' : '' }`
    }, [labelKey])

    //TODO: review the flow of handleOnChange and defaultValue
    /**
     * onChange handler to select component.
     * defaults to local state if formik context is empty.
     * calls optional onChange prop to parent component.
     */
    const handleOnChange = useCallback((value: any) => {
        if (isFormik) formik.setFieldValue(name, value)
        if (onChange) onChange(getOrg(valueKey, value))
        setSelected(value)
    }, [getOrg, valueKey, name, formik, isFormik, onChange])

    /**
     * Select Options constructor. Builds an array of options children from loaded orgs list.
     */
    const _getOptions = useCallback(() => {
        const filtered = filterOptions ? filterOptions(orgs) : orgs
        return filtered.map((org: any) => (
            <Option key={org.id} value={org[valueKey]} label={getOrgLabel(org)}>{getOrgLabel(org)}</Option>
        ))
    }, [orgs, valueKey, getOrgLabel, filterOptions])

    //query here
    const [fetchOrgList, { loading, data }] = useFetchOrgList({
        QUERY,
        // onCompleted: (result: any) => {
        //     if (result && result.organisations)
        //         setOrgs(result.organisations)
        // },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (orgs.length > 0) setSelected(defaultValue)
    }, [])

    // TODO: review this flow
    useEffect(() => {
        const key = queryKey || 'organisations';
        if (data && data[key]) setOrgs(data[key]);
    }, [data, setOrgs, queryKey])

    //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)

    //if formik context is not empty, render with Formik FieldContainer
    if (isFormik) return (
        <FieldContainer
            { ...gridProps }
            name={name}
            required={required}
            label={label}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <FormikSelect
                name={name}
                loading={loading}
                disabled={disabled || (loading && orgs.length <= 0)}
                onChange={handleOnChange}
                {...props}
            >
                { /* call option getOptions prop to fetch children. default to map over orgs. */}
                {getOptions ? getOptions(orgs) : _getOptions()}
            </FormikSelect>
        </FieldContainer>
    )

    if (hidden) return null;

    //fallback return using no formik context
    return (
        <Select
            value={(loading && orgs.length <= 0) ? 'Loading...' : selected}
            placeholder={ 'Select organisation' }
            loading={loading}
            disabled={disabled || (loading && orgs.length <= 0)}
            className={`${className} ant-org-selector`}
            onChange={handleOnChange}
            {...props}
        >
            {orgs.length > 0 && (getOptions ? getOptions(orgs) : _getOptions())}
        </Select>
    )
}

OrgSelectorComponent.defaultProps = {
    allowClear: true,
    showSearch: true,
    optionFilterProp: 'label',
    getFormik: true
}

export const OrgSelector = styled(OrgSelectorComponent)`
    width: 100%;
    min-width: 170px;
    .ant-select-selector, input {
      min-width: 170px;
    }
`

export const FilledOrgSelector = styled(OrgSelector)`
    .ant-select-selector, input {
      border: none !important;
    }
    .ant-select-selector, .an {
      background: ${p => p.theme.secondaryColor} !important;
      border-radius: 6px !important;
      -webkit-box-shadow: 0 1px 2px #33333333 !important;
      -moz-box-shadow: 0 1px 2px #33333333 !important;
      box-shadow: 0 1px 2px #33333333 !important;
    }
    .ant-select-clear {
      background: ${p => p.theme.secondaryColor};
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      color: #eee !important;
    } 
`;
