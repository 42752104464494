import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Table,
  Page,
  AuthGuard, Filter
} from '../../components';
import { Modal, Input as ScoreInput, Button, Popconfirm, Tooltip } from 'antd';
import moment, { Moment } from 'moment'
import styled from 'styled-components';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { useCurrentSport, useSports } from '../../redux/config';
import { Fixture, LoadedSport, LoadedOrg } from '../../redux'
import { UpdateFixtureResult } from './update-fixture-result'
import { ScoreCell } from './ScoreCell'
import { FIXTURE_STATUSES, getSelectedOrg, mapArrayToOptions } from '../../utils';
import FixtureStatus from '../../components/FixtureStatus';
import { useSelector } from 'react-redux';
import { RenderOrg } from './components';

const statuses = mapArrayToOptions(FIXTURE_STATUSES);




const Title = styled.div<{ color?: string}>`
  color: ${p => p.theme.primaryColor};
  display: flex;
  align-items: center;
  align-content: center;
  .ant-avatar {
    margin-right: 5px;
  }
`;

interface FixtureTableProps {
  fixtures: Fixture[],
  loading?: boolean,
  onRefresh?: () => void,
  withUpdate?: boolean
}

export const FixtureTable: React.FC<FixtureTableProps> = ({
  fixtures,
  loading,
  onRefresh,
  withUpdate,
  ...props
}) => {



  const history = useHistory();

  const [showModal, setShowModal] = useState(false)
  const [selectedFixtureId, setFixtureId] = useState('')

  const handleOnClick = useCallback((id: string) => (e: any) => {
    e.stopPropagation()
    setFixtureId(id)
    setShowModal(true)
  }, [setShowModal])

  const onCancel = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const [sportList, { getSport }] = useSports()

  const getName = useCallback((sportId: string) => {
    const sport = getSport(sportId);
    return sport?.name
  }, [getSport]);

  const getIcon = useCallback((sportId: string) => {
    return getThumbnail(getSport(sportId)?.appIcon);
  }, [getSport]);

  const handleCancel = useCallback((e?: any) => {
    e.stopPropagation();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Event',
        export: true,
        key: 'name',
        filtering: true,
        sorting: true,
        getValues: (r: any) => r,
        render: (val: string, rec: any) => <Tooltip title={getName(rec.sportId)}><Avatar src={getIcon(rec.sportId)} size={18} inverse /> {val}</Tooltip>
      },
      {
        title: 'Home',
        export: true,
        key: 'homeOrgName',
        filtering: true,
        sorting: true,
        getValues: (r: any) => r,
        render: (val: string, rec: any) => <RenderOrg org={rec.homeOrg} name={val} result={rec.result} isHome={true} />
      },
      {
        title: 'Away',
        export: true,
        key: 'awayOrgName',
        filtering: true,
        sorting: true,
        getValues: (r: any) => r,
        render: (val: string, rec: any) => <RenderOrg org={rec.awayOrg} name={val}  result={rec.result} isHome={false} />
      },
      {
        title: 'Status',
        key: 'status',
        hidden: true,
        filtering: {
          filters: statuses,
          filterMultiple: true,
          type: Filter.SELECT
        },
        sorting: true,
        getValues: (r: string) => r,
        render: (r: string) => <FixtureStatus status={r} />,
        export: true
      },
      {
        title: 'Contact Name',
        export: true,
        key: 'primaryContactName',
        filtering: true,
        show: false,
      },
      {
        title: 'Contact Cell',
        export: true,
        key: 'primaryContactCellFull',
        filtering: true,
        show: false,
      },
      {
        title: 'Contact Email',
        export: true,
        key: 'primaryContactEmail',
        filtering: true,
        show: false,
      },
      {
        title: 'Date',
        key: 'dateStart',
        sorting: true,
        render: (r: any) => moment(r).format('MMMM, Do - HH:mm')
      },
      {
        title: 'Result',
        key: 'currentScore',
        sorting: false,
        getValues: (r: any) => r,
        render: (result: string, rec: any) => (
          <ScoreCell fixture={rec} onClick={handleOnClick(rec.id)}>
            UPDATE
          </ScoreCell>
        ),
        hidden: !withUpdate
      }
    ];
  }, [handleOnClick, withUpdate, getIcon, getName]);



  return <React.Fragment>
    <Modal
      title='Update Fixture Results'
      visible={showModal}
      footer={
          <Button type='primary' onClick={onCancel}>
            Close
          </Button>
      }
      onCancel={onCancel}
    >
      <UpdateFixtureResult
        fixtureId={selectedFixtureId}
        handleClose={onCancel}
      />
    </Modal>
    <Table
      title='All Fixtures'
      data={fixtures}
      columns={columns}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/fixture/${record.id}`)
      })}
      refresh={onRefresh}
      exportTitle={'Teams'}
      loading={loading}
    />
    
  </React.Fragment>

};

export default FixtureTable;
