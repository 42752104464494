import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table as AntTable } from 'ant-table-extensions';
import { Resizable } from 'react-resizable';
import styled from 'styled-components';

const Resize = styled(Resizable)`
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  
  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
`;

const ResizeableTitle = (props: any) => {
    const { onResize, width, onClick, ...restProps } = props;

    const [resizing, setResizing] = useState(false);

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resize
        width={width}
        height={0}
        onResizeStart={() => setResizing(true)}
        onResizeStop={() => {
          setTimeout(() => setResizing(false));
        }}
        onResize={onResize}
      >
        <th
          onClick={(...args) => {
            if (!resizing && onClick) {
              onClick(...args);
            }
          }}
          {...restProps}
        />
      </Resize>
    );
}

interface IProps {
  dataSource: any[];
  columns: any[];
  loading?: boolean;
  [x: string]: any;
}
const ResizeableTable: React.FC<IProps> = (props: any) => {

  const [state, setState] = useState({ columns: props.columns });

  const components = useMemo(() => ({
    ...props.components,
    header: {
      cell: ResizeableTitle
    }
  }), []);

  const handleResize = useCallback((index: number) => (e: any, { size }: any) => {
    e.stopImmediatePropagation();
    setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };
      return { columns: nextColumns };
    });
  }, [setState]);

  useEffect(() => {
    const handlers = document.querySelectorAll(
      '.react-resizable .react-resizable-handle'
    );
    handlers.forEach(handler =>
      handler.addEventListener('click', e => {
        return false;
      })
    );
  }, []);

  const columns = useMemo(() => state.columns.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index)
    })
  })), [state, handleResize]);

  return <AntTable {...props} columns={columns} components={components} />;
}

export default ResizeableTable;
