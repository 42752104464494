import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  FormRow,
  Page,
  DatePicker,
  Input,
  LocationSearch,
  FormLoading,
  ContactNumber,
  Select
} from '../../components'
import { useFormData, useIsParentOrAdmin, useHideActions } from '../../hooks';
import { Form } from 'formik-antd'
import { message, Collapse as Collapser, Button, Popconfirm } from 'antd'
import { TextArea, AuthGuard, SportSelector } from '../../components'
import { UPDATE_FIXTURE, UPDATE_PENDING, useFetchFixture } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';
import { fixtureSchema } from './schema'
import { ViewFixture } from './ViewFixture'
import { COUNTRIES, SA_PROVINCES, mapArrayToObject, getIsAdmin, getActiveOrgId } from '../../utils'
import { UpdateFixtureResult } from './update-fixture-result'
import { useCurrentOrg } from '../../redux';
import { EventSelector } from '../../components/event-selector';

const countries = mapArrayToObject(COUNTRIES)
const provinces = mapArrayToObject(SA_PROVINCES)

const Save = styled(FormButton)`
  margin-top: 30px;
`

const { Panel } = Collapser

const FULL_PROPS = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24
}

const packValues = (values: any) => {

  const {
    id,
    shortid,
    createdBy,
    homeOrg,
    homeOrgId,
    awayOrg,
    awayOrgId,
    ownedById,
    homeTeam,
    awayTeam,
    homeOrgStatus,
    awayOrgStatus,
    currentScore,
    primaryContactCellFull,
    event,
    currentScoreDesc,
    ...updateFixture
  } = values

  return updateFixture
}

export const Fixture: React.FC<any> = (props) => {

  const history = useHistory()
  const { id }: any = useParams()
  const [fixture, setFixture] = useState<any>({ id })

  const [currentOrg] = useCurrentOrg()
  const isAdmin = useSelector(getIsAdmin)
  const activeOrgId = useSelector(getActiveOrgId)
  const [handleUpdate, { loading: uploading }] = useMutation(UPDATE_FIXTURE)
  const [visible, setVisible] = useState(false);

  const [cancelFixture, { loading: cancelling }] = useMutation(UPDATE_PENDING, {
    variables: { fixtureId: id, accept: false },
    onCompleted: (result: any) => {
      setVisible(false);
      message.success({ content: 'Fixture Cancelled', duration: 4 });
      history.push('/fixture/list');
    },
    onError: (err: any) => {
      setVisible(false);
      message.error({ content: 'Failed to cancel fixture', duration: 4 })
    }
  })

  const [fetchFixture, { loading }] = useFetchFixture(id, {
    onCompleted: (result: any) => {
      if (result && result.fixture) {
        const { dateStart, dateEnd }: any = result.fixture
        setFixture({ ...fixture, ...result.fixture, dateStart: moment(dateStart), dateEnd: moment(dateEnd) })
      }
    }
  })

  const onSubmitted = useCallback(() => {
    fetchFixture({ variables: { id }})
  }, [id, fetchFixture])

  const { fields, initialValues, ...formikCTX } = useFormData(fixtureSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Updating Fixture...', duration: 10 })
      const updateFixture = packValues(values)
      // Check if eventId has been cleared
      if (!updateFixture.eventId && !!fixture.eventId) updateFixture.eventId = '';
      const result = await handleUpdate({ variables: {
        id,
        //review following line!
        updateFixture
      }})
      if (result) {
        fetchFixture()
      }
      return result
    },
    onCompleted: (result: any) => {
      if (result && result.fixture) setFixture({ ...fixture, ...result.fixture })
      message.success({ content: 'Fixture updated!', duration: 4 })
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to view Fixture'), duration: 4 });
    }
  })

  const showPopconfirm = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleBack = useCallback(() => history.goBack(), [history]);
  const handleCancelClick = useCallback(() => cancelFixture(), [history]);
  const handleClosePopconfirm = useCallback(() => setVisible(false), [setVisible]);

  const hideActions = useHideActions(undefined, fixture)
  const isParentOrAdmin = useIsParentOrAdmin()

  if (loading) return <FormLoading />

  return (
    <AuthGuard
      needsActiveOrg
    >
      <Formik { ...formikCTX} initialValues={fixture} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page
            title={fixture.name}
            onBack={handleBack}
            card
          >
            <ViewFixture hideActions={hideActions} hidden={loading} fixture={fixture} />
            <Form layout={'vertical'}>

              <Collapser
                defaultActiveKey={[
                    'event',
                    'time',
                    'contact',
                    'location',
                    'results'
                ]}
                ghost
              >

                <Panel key='results' header='Results'>
                  <UpdateFixtureResult onSubmitted={onSubmitted} fixtureId={id} submitText='UPDATE SCORE' />
                </Panel>

                <Panel key='event' header='Event Information'>
                    <FormRow disabled={hideActions}>
                        <Input { ...fields.name } />
                        <SportSelector
                            disabled
                            { ...fields.sportId }
                            showSearch
                            allowClear={false}
                        />
                        <EventSelector
                          {...fields.eventId}
                          defaultValue={fixture.eventId}
                          currentEvent={fixture.event}
                          showSearch
                          allowClear
                        />
                        <TextArea
                          gridProps={FULL_PROPS}
                          {...fields.description}
                        />
                    </FormRow>
                </Panel>

                <Panel key='time' header='Time'>
                    <FormRow disabled={hideActions}>
                      <DatePicker
                        format='MMM Do YYYY, HH:mm'
                        { ...fields.dateStart }
                        showTime={{ format: 'HH:mm' }}
                      />
                      <DatePicker
                        format='MMM Do YYYY, HH:mm'
                        { ...fields.dateEnd }
                        showTime={{ format: 'HH:mm' }}
                      />
                    </FormRow>
                </Panel>

                <Panel key='location' header='Location'>
                  <FormRow disabled={hideActions}>
                    <LocationSearch { ...fields.address} parentKey='address' />
                    <Input {...fields.address.addressLine1} />
                    <Select {...fields.address.country} options={countries} />
                    <Select {...fields.address.state}  options={provinces} />
                  </FormRow>
                </Panel>

                <Panel key='contact' header='Contact Information'>
                  <FormRow disabled={hideActions}>
                    <Input { ...fields.primaryContactName } />
                    <ContactNumber
                      numberField={fields.primaryContactCell}
                      countryCodeField={fields.primaryContactCellCountryCode}
                    />
                    <Input { ...fields.primaryContactEmail } />
                  </FormRow>
                </Panel>

                { !hideActions && (
                  <Panel key='settings' header='Settings'>
                    <p>Cancel fixture and notify participants:</p>
                    <Popconfirm
                      title="Are you sure you want to cancel this fixture?"
                      visible={visible}
                      onConfirm={handleCancelClick}
                      okButtonProps={{ loading: cancelling }}
                      onCancel={handleClosePopconfirm}
                    >
                      <Button type={'primary'} danger onClick={showPopconfirm}>Cancel Fixture</Button>
                    </Popconfirm>
                  </Panel>
                )}

              </Collapser>

              <Save hidden={hideActions} loading={uploading} disabled={cancelling}>
                SAVE
              </Save>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default Fixture
