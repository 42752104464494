import React, { useEffect, useCallback } from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { DatePicker as DatePickerBase, DatePickerProps } from 'formik-antd'
import { useFormikContext } from 'formik'
import { FieldContainer } from './FieldContainer'
import { InputFieldProps, FieldProps } from './types'

const DatePickerComponent: React.FC<InputFieldProps<DatePickerProps, FieldProps>> = ({ hidden, label, required, gridProps, className, ...props }) => {

    const{ values, isSubmitting, setFieldValue } = useFormikContext() || {}

    const { name } = props

    return (
        <FieldContainer
            { ...gridProps }
            name={name}
            label={label}
            required={required}
            className={`${className} ant-field-container`}
            hidden={hidden}
        >
            <DatePickerBase
              disabled={isSubmitting}
              { ...props }
            />
        </FieldContainer>
    )
}

DatePickerComponent.defaultProps = {
    allowClear: false
}

export const DatePicker = styled(DatePickerComponent)`
    .ant-picker {
        width: 100%;
    }
`
