
import { useState, useMemo, useCallback } from 'react'
import moment, { Moment } from 'moment'

export type FilterFunction<T, D> = (data: T[], date: D) => T[]

export const useCalendar = function<T = any>(data: T[], filter: FilterFunction<T, Moment>, initialValue?: Moment) {

    const [selectedDate, setSelectedDate] = useState<Moment>(initialValue ?? moment())
    const [value, setValue] = useState<Moment>(initialValue ?? moment())
    const selected = useMemo(() => filter(data, selectedDate), [data, selectedDate, filter])
    const [showDay, setShowDay] = useState<boolean>(false)
    const onSelect = useCallback((date: Moment) => {
        setSelectedDate(date)
        const check = filter(data, date)
        if (check.length === 0) return
        setShowDay(true)
    }, [setSelectedDate, setShowDay, data, filter])
    const onPanelChange = useCallback(({ value }: any) => {
        if (value === null) return
        setSelectedDate(value)
    }, [setSelectedDate])
    const onDayClose = useCallback(() => setShowDay(false), [setShowDay])

    return useMemo(() => ({
        value,
        selectedDate,
        setValue,
        selected,
        showDay,
        setShowDay,
        onSelect,
        onDayClose,
        onPanelChange
    } as const), [
        value,
        selectedDate,
        setValue,
        selected,
        showDay,
        setShowDay,
        onSelect,
        onDayClose,
        onPanelChange
    ])
}

export default useCalendar
