import Moment from 'moment';

const DEFAULT_FORMAT = 'YYYY/MM/DD';

// NB this will require an auth token if you want to fetch the dates by date expressions
const createSchemaConfig = (validationSchema: any[]) => {
  return validationSchema.map((currentValidation: any) => {
    const { id, label, meta, type, _nullable } = currentValidation;
    const configItem: any = {
      id,
      label,
      meta,
      nullable: _nullable,
      validationType: type,
      validations: [],
    };

    if (currentValidation.isRequired === true) {
      configItem.validations.push({ type: 'required', params: ['This field is required.'] });
      configItem.validations.push({ type: 'typeError', params: ['This field is required.'] });
    }
    if (currentValidation.minValue != null) {
      configItem.validations.push({ type: 'min', params: [currentValidation.minValue, 'Must have a min value of ' + currentValidation.minValue] });
    }
    if (currentValidation.maxValue != null) {
      configItem.validations.push({ type: 'max', params: [currentValidation.maxValue, 'Must have a max value of ' + currentValidation.maxValue] });
    }
    if (currentValidation.minLength != null) {
      configItem.validations.push({ type: 'min', params: [currentValidation.minLength, 'Must have a min length of ' + currentValidation.minLength] });
    }
    if (currentValidation.maxLength != null) {
      configItem.validations.push({ type: 'max', params: [currentValidation.maxLength, 'Must have a max length of ' + currentValidation.maxLength] });
    }
    if (currentValidation.minDateTime != null) {
      configItem.validations.push({ type: 'min', params: [currentValidation.minDate, 'Must have a min date of ' + Moment(currentValidation.minDate).format(DEFAULT_FORMAT)] });
    }
    if (currentValidation.maxDateTime != null) {
      configItem.validations.push({ type: 'max', params: [currentValidation.maxDate, 'Must have a max date of ' + Moment(currentValidation.maxDate).format(DEFAULT_FORMAT)] });
    }
    if (currentValidation.regexExpression) {
      configItem.validations.push({ type: 'matches', params: [currentValidation.regexExpression, currentValidation.regexMessage || 'Invalid format.'] });
    }
    if (currentValidation.oneOf) {
      configItem.oneOf = [...currentValidation.oneOf];
    }
    return configItem;
  });
};

export default createSchemaConfig;
