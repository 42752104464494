import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { FallbackProps } from '../error-boundary'
import { Button } from 'antd'
import Alert, { AlertProps } from 'antd/lib/alert'
import { captureException } from '@sentry/react'

const Container = styled.div<{width?: string}>`
    ${ p => p.width ? (p.width + `;`) : `` }
`

type Props = FallbackProps & Omit<AlertProps, 'className'> & {
    sentry?: boolean,
    triggerOnMount?: boolean,
    showButton?: boolean,
    width?: string,
    className?: string
}

/**
 * Fallback component when using an ErrorBoundary.
 * Takes additional props as defined by implementation type.
 * @param sentry A boolean flag to send the error to sentry.
 * @param error The error object caught by the parent boundary.
 * @param info The info object of the error.
 * @param triggerOnMount Whether to trigger a sentry capture when the fallback mounts.
 * @param showButton Whether to show a trigger button
 * @param onClose The callback to call when the component in manually close by the user
 * @width The optional width as a css string
 */
export const Error: React.FC<Props> = ({ className, onClose, sentry, showButton, triggerOnMount, error, info, ...props }) => {


    const [hasReported, setHasReported] = useState(false)

    /**
     * The handler to trigger a sentry event for the given error parameter.
     * Only triggers if the sentry flag is active.
     */
    const makeHandleException = useCallback(() => (e?: any) => {
        if (sentry && !hasReported) {
            captureException(error)
            setHasReported(true)
        }
    }, [sentry, error, hasReported, setHasReported])

    const _onClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClose) onClose(event)
    }, [onClose])

    const getAction = useCallback((hasReported) => !hasReported ? [
        <Button onClick={makeHandleException()}>
            Let us know
        </Button>
    ] : null, [makeHandleException])

    const reported = "We've logged the error!\nThanks for letting us know!"
    const report = "We're on it. Click report to let us know!"

    /**
     * useEffect: triggers an optional sentry event on fallback mount.
     * Only takes effect if sentry flag is active.
     */
    useEffect(() => {
        const handleException = makeHandleException()
        if (sentry && triggerOnMount) handleException()
    }, [triggerOnMount, sentry, makeHandleException, setHasReported])

    return (
        <Container className={className}>
          <Alert
            message={ hasReported ? 'Got it' : error.message }
            description={ hasReported ? reported : report }
            type={ hasReported ? 'success' : 'error' }
            closable
            onClose={_onClose}
            action={getAction(hasReported)}
            {...props}
          />
        </Container>
    )
}

export default Error