import { useCallback, useState, useEffect, useMemo } from 'react'
import { TypedDocumentNode, useLazyQuery, useSubscription, useMutation, QueryLazyOptions, useQuery } from '@apollo/client'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { useCurrentUser } from '@bit/necta.hooks.current-user'
import {
  FILE_DELETED,
  FILE_ADDED,
  FETCH_USERS_BY_ORG,
  FETCH_MY_USER,
  FETCH_USER_BY_SHORTID,
  UPDATE_USER,
  FETCH_ORG,
  FETCH_ORGS,
  FETCH_TEAM,
  FETCH_TEAMS,
  FETCH_SPORT,
  FETCH_SPORTS,
  FETCH_TEAM_MEMBER,
  ALL_USERS,
  SEARCH_USERS,
  FETCH_SPONSORS,
  UPDATE_MY_USER,
  REVOKE_ORG_ACCESS,
  FIXTURES,
  FIXTURE,
  FETCH_TEAMSHEET,
  FETCH_TEAMSHEET_MEMBER, EVENT, EVENTS, FETCH_THIRD_PARTY_PROVIDERS, FETCH_THIRD_PARTY_PROVIDER, FETCH_THIRD_PARTY_PROVIDER_CONTACT, FETCH_THIRD_PARTY_PROVIDERS_BY_EVENT_ID,
} from '../queries'
import { useSelector } from 'react-redux';
import { useCurrentSport } from '../../redux/config';
import { getSelectedOrg } from '../../utils';
import { get } from 'lodash';
import { useErrorMessage } from '../../hooks/use-error-message'
import { captureException } from '@sentry/react'

interface Options {
    onCompleted?: (result: any) => void,
    onError?: (error: any) => void,
    QUERY?: TypedDocumentNode
    variables?: any;
    fetchPolicy?: 'cache-first' | 'cache-only' | 'cache-and-network' | 'network-only' | 'no-cache' | 'standby',
    nextFetchPolicy?: 'cache-first' | 'cache-only' | 'cache-and-network' | 'network-only' | 'no-cache' | 'standby'
}

export const useFetchFixtureList = ({ QUERY = FIXTURES, onCompleted, onError, variables }: Options = { QUERY: FIXTURES }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if (onCompleted) onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    fetch()
  }, [])

  return useMemo(() => [fetch, ctx] as const, [fetch, ctx])
}

export const useFetchFixture = (id: string, { QUERY = FIXTURE, onCompleted: $onCompleted, onError, variables }: Options = { QUERY: FIXTURE }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if ($onCompleted) $onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  const handleFetch = useCallback((content?: QueryLazyOptions<any>) => fetch(content || { variables: { id } }), [fetch, id])

  useEffect(() => {
    fetch({ variables: { id } })
  }, [])

  return useMemo(() => [handleFetch, ctx] as const, [handleFetch, ctx])
}

export const useFetchEvent = (id: string, { QUERY = EVENT, onCompleted: $onCompleted, onError, variables }: Options = { QUERY: EVENT }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if ($onCompleted) $onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  const handleFetch = useCallback((content?: QueryLazyOptions<any>) => fetch(content || { variables: { id } }), [fetch, id])

  useEffect(() => {
    fetch({ variables: { id } })
  }, [])

  return useMemo(() => [handleFetch, ctx] as const, [handleFetch, ctx])
}


export const useFetchEventList = ({ QUERY = EVENTS, onCompleted, onError, variables }: Options = { QUERY: EVENTS }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if (onCompleted) onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    fetch()
  }, [])

  return useMemo(() => [fetch, ctx] as const, [fetch, ctx])
}

export const useFetchTeamsheet = (id: string, { QUERY = FETCH_TEAMSHEET, onCompleted: $onCompleted, onError, variables }: Options = { QUERY: FETCH_TEAMSHEET }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if ($onCompleted) $onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  const handleFetch = useCallback((content?: QueryLazyOptions<any>) => fetch(content || { variables: { id } }), [fetch, id])

  useEffect(() => {
    fetch({ variables: { id } })
  }, [])

  return useMemo(() => [handleFetch, ctx] as const, [handleFetch, ctx])
}

export const useFetchTeamsheetMember = (id: string, { QUERY = FETCH_TEAMSHEET_MEMBER, onCompleted: $onCompleted, onError, variables }: Options = { QUERY: FETCH_TEAMSHEET_MEMBER }) => {
  const [fetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if ($onCompleted) $onCompleted(result)
    },
    onError: (error: any) => {
      if (onError) onError(error)
    },
    fetchPolicy: 'network-only'
  })

  const handleFetch = useCallback((content?: QueryLazyOptions<any>) => fetch(content || { variables: { id } }), [fetch, id])

  useEffect(() => {
    fetch({ variables: { id } })
  }, [])

  return useMemo(() => [handleFetch, ctx] as const, [handleFetch, ctx])
}


export const useFetchCurrentUser = ({ QUERY = FETCH_MY_USER, onCompleted, onError, variables }: Options) => {
    const [{ inSession, username }] = useCognitoUser()
    const [currentUser, setUser] = useCurrentUser()
    const [fetch, ctx] = useLazyQuery(QUERY, {
        variables,
        onCompleted: (result: any) => {
          if (result) setUser(result.user)
          if (onCompleted) onCompleted(result);
        },
        onError: (error: any) => {
          if (onError) onError(error);
        },
    })

    const { error } = ctx

    const [alert, { keys }] = useErrorMessage(error, 'could not load current user!\nclick to notify us', {
      duration: 3,
      onClick: (e: any, error?: Error) => {
        captureException(error)
        alert({
          duration: 3,
          type: 'success',
          content: 'error reported successfully!'
        })
      }
    })

    useEffect(() => {
        if (inSession) {
            if (username !== currentUser?.id) {
                setUser(null)
            }
            fetch()
        }
    }, [username])

    return ctx
}

export const useRefetchCurrentUser = ({ QUERY = FETCH_MY_USER, onCompleted, onError, variables }: Options) => {
  const [{}, setUser] = useCurrentUser()
  const [refetch, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted: (result: any) => {
      if (result) setUser(result.user)
      if (onCompleted) onCompleted(result);
    },
    onError: (error: any) => {
      console.error(error);
      if (onError) onError(error);
    },
  })

  return [refetch, ctx] as const;
}

interface FetchUserByShortidOptions extends Options {
  variables: {
    shortid: string
  }
}

export const useFetchUserByShortid = ({ QUERY = FETCH_USER_BY_SHORTID, onCompleted, onError, variables }: FetchUserByShortidOptions) => {
    const [fetchUser, ctx] = useLazyQuery(QUERY, {
        variables,
        onCompleted,
        onError,
    })

    useEffect(() => {
        fetchUser()
    }, [])

    return [
        fetchUser,
        ctx
    ] as const
}

interface FetchUserListOptions extends Options {
  variables?: any; // This will be added to the queryOptions
  userType: 'user' | 'orgAdmin' | 'player' | 'coach' | 'admin',
  onCompleted: (users: any[]) => void;
}

interface UserQueryVariables {
  organisationId?: string;
  role?: string;
  queryOptions?: {
    where: any[]; // Only the first element of the array will be used for initial fetch
  }
}

const getQueryVariables = (query: TypedDocumentNode, organisationId: string, variables: any = {}, userType: 'user' | 'orgAdmin' | 'player' | 'coach' | 'admin') => {
  const queryVariables: UserQueryVariables = {};
  switch (userType) {
    case 'user':
      return {
        //role: 'User'; // For now, this will return ALL users
        query: ALL_USERS,
      };
    case 'admin':
      return {
        role: 'Admin',
        queryOptions: {
          where: [{ isPlayer: false, isCoach: false, ...queryVariables }]
        },
        query: ALL_USERS,
      };
    case 'orgAdmin':
      return {
        query,
        organisationId,
        role: 'Organisation',
        queryOptions: {
          where: [{ ...queryVariables }]
        }
      };
    case 'player':
      return {
        query,
        organisationId,
        role: 'User',
        queryOptions: {
          where: [{ isPlayer: true, ...queryVariables }]
        }
      };
    case 'coach':
      return {
        query,
        organisationId,
        role: 'User',
        queryOptions: {
          where: [{ isCoach: true, ...queryVariables }]
        }
      };
  }
}

export const useFetchUserList = ({ QUERY = FETCH_USERS_BY_ORG, onCompleted, onError, variables = {}, userType }: FetchUserListOptions) => {
  // const orgId = useSelector(getOrgId);
  const organisation = useSelector(getSelectedOrg)
  const { query, ...queryVariables } = getQueryVariables(QUERY, get(organisation, 'id'), variables, userType);

  const [fetchUsers, ctx] = useLazyQuery(query, {
    variables: queryVariables,
    onError,
    onCompleted: (result: any) => {
      if (result && result.usersByOrg && onCompleted) onCompleted(result.usersByOrg);
      else if (result && result.users && onCompleted) onCompleted(result.users);
    },
  });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, organisation]);

  return [fetchUsers, ctx] as const;
};

interface SearchUsersOptions extends Options {
  variables?: {
    keyword: string,
    queryVariables?: any,
  }
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  }
}

export const useFetchUserSearch = ({ QUERY = SEARCH_USERS, onCompleted, onError, variables = { keyword: '' }, filters = { role: 'User' } }: SearchUsersOptions) => {

  const organisation = useSelector(getSelectedOrg)

  const _variables = useMemo<any>(() => {
    return {
      role: filters.role === false ? undefined : filters.role,
      filters: { activeOrgId: filters.organisationId === false ? undefined : (filters.organisationId ? filters.organisationId : get(organisation, 'id')) },
      ...variables
    }
  }, [filters, organisation]);

  const [fetchUsers, ctx] = useLazyQuery(QUERY, {
    variables: _variables,
    onError,
    onCompleted: (result: any) => {
      if (result && onCompleted) onCompleted(result.userSearch || []);
    },
  });

  useEffect(() => {
    if (variables && variables.keyword !== '') fetchUsers()
  }, [fetchUsers, variables]);

  // Clear data when important variables update
  useEffect(() => {
    if (onCompleted) onCompleted([]);
  }, [filters, organisation])

  return [fetchUsers, ctx] as const;
};


interface AdminUpdateOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useAdminUpdateUser = ({onCompleted, onError}: AdminUpdateOptions) => {
    const ctx = useMutation(UPDATE_USER, {
        onCompleted,
        onError
    })
    return ctx
}

interface UpdateOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useUpdateUser = ({onCompleted, onError}: UpdateOptions) => {
  const ctx = useMutation(UPDATE_MY_USER, {
    onCompleted,
    onError
  })
  return ctx
}


export const useFileSubscription = (
  id: string,
  initialFiles?: any[],
  options?: {
    onAdded?: (result: any) => void,
    onDeleted?: (result: any) => void
  }
) => {
  const [files, setFiles] = useState<any[]>([]);

  const onAdded = useSubscription(
    FILE_ADDED,
    {
      variables: { id },
      onSubscriptionData: (sub: any) => {
        try {
          const newFile = sub.subscriptionData.data.FileAdded;
          setFiles([...files, newFile]);
          if (options && options.onAdded) options.onAdded(newFile);
        } catch (ex) { }
      },
      shouldResubscribe: true,
    },
  );

  const onDeleted = useSubscription(
    FILE_DELETED,
    {
      variables: { id },
      onSubscriptionData: (sub: any) => {
        try {
          const removedFile = sub.subscriptionData.data.FileDeleted;
          const filteredFiles = files.filter((file: any) => file.id !== removedFile.id);
          setFiles([...filteredFiles]);
          if (options && options.onDeleted) options.onDeleted(removedFile);
        } catch (ex) { }
      },
      shouldResubscribe: true,
    }
  );

  useEffect(() => {
    if (initialFiles && initialFiles.length) setFiles([...initialFiles])
  }, [initialFiles]);

  return [
    files,
    onAdded,
    onDeleted
  ] as const
}

interface FetchOrgOptions extends Options {
  variables: {
    id: string
  }
}

// Fetch an org by id
export const useFetchOrg = ({ QUERY = FETCH_ORG, onCompleted, onError, variables }: FetchOrgOptions) => {
  const [fetchOrg, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchOrg()
  }, [])

  return [
    fetchOrg,
    ctx
  ] as const
}

const getTestParams = () => {
  if (process.env.REACT_APP_HIDE_TESTING_DATA) return { testing: false };
  return {};
}

// Fetch all orgs that you have access to
export const useFetchOrgList = ({ QUERY = FETCH_ORGS, onCompleted, onError, variables = { organisationQuery: { ...getTestParams() } }, fetchPolicy }: Options) => {
  const [fetchOrg, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
    fetchPolicy
  })

  useEffect(() => {
    fetchOrg()
  }, [])

  return [
    fetchOrg,
    ctx
  ] as const
}

// Fetch all ThirdPartyProviders
export const useFetchThirdPartyProviders = ({QUERY = FETCH_THIRD_PARTY_PROVIDERS, onCompleted, onError}: Options) => {
  const [fetchProviders, ctx] = useLazyQuery(FETCH_THIRD_PARTY_PROVIDERS, {
    onCompleted,
    onError
  })

  useEffect(() => fetchProviders(), [fetchProviders])

  return [fetchProviders, ctx] as const
}

interface FetchTeamOptions extends Options {
  variables: {
    id: string
  }
}

// Fetch one ThirdPartyProvider by it's ID
export const useFetchThirdPartyProvider = ({ QUERY = FETCH_THIRD_PARTY_PROVIDER, onCompleted, onError, variables }: FetchOrgOptions) => {
  const [fetchThirdPartyProvider, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchThirdPartyProvider()
  }, [fetchThirdPartyProvider])

  return [
    fetchThirdPartyProvider,
    ctx
  ] as const
}

// Fetch one ThirdPartyProviders linked to an Event
export const useFetchThirdPartyProvidersLinkedToEvent = ({ QUERY = FETCH_THIRD_PARTY_PROVIDERS_BY_EVENT_ID, onCompleted, onError, variables }: Options) => {
  return useQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })
}

// Fetch ThirdPartyProviderContact by it's ID
export const useFetchThirdPartyProviderContact = ({ QUERY = FETCH_THIRD_PARTY_PROVIDER_CONTACT, onCompleted, onError, variables }: FetchOrgOptions) => {
  const [fetchThirdPartyProviderContact, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })
  useEffect(() => {
    fetchThirdPartyProviderContact()
  }, [fetchThirdPartyProviderContact])

  return [
    fetchThirdPartyProviderContact,
    ctx
  ] as const
}


// Fetch a team by id
export const useFetchTeam = ({ QUERY = FETCH_TEAM, onCompleted, onError, variables }: FetchTeamOptions) => {
  const [fetchTeam, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchTeam()
  }, [])

  return [
    fetchTeam,
    ctx
  ] as const
}

// Fetch all teams that you can access
export const useFetchTeamList = ({ QUERY = FETCH_TEAMS, onCompleted, onError, variables = { teamQuery: {} } }: Options) => {
  const organisation = useSelector(getSelectedOrg)
  const [currentSport] = useCurrentSport();

  const _variables = { teamQuery: { ...variables.teamQuery, organisationId: get(organisation, 'id'), sportId: currentSport.id }};

  const [fetchTeam, ctx] = useLazyQuery(QUERY, {
    variables: _variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchTeam()
  }, [])

  return [
    fetchTeam,
    ctx
  ] as const
}

interface FetchTeamMemberOptions extends Options {
  variables: {
    id: string
  }
}

// Fetch a single team member to edit
export const useFetchTeamMember = ({ QUERY = FETCH_TEAM_MEMBER, onCompleted, onError, variables }: FetchTeamMemberOptions) => {
  const [fetchTeamMember, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchTeamMember()
  }, [])

  return [
    fetchTeamMember,
    ctx
  ] as const
}

const defaultVariables = {
  sportQuery: {},
  queryOptions: {
    order: {
      createdAt: 'ASC'
    }
  }
}
// Fetch all sports
export const useFetchSportList = ({ QUERY = FETCH_SPORTS, onCompleted, onError, variables = defaultVariables }: Options) => {
  const [fetchSports, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchSports()
  }, [])

  return [
    fetchSports,
    ctx
  ] as const
}

interface FetchSportOptions extends Options {
  variables: {
    id: string
  }
}

// Fetch a single sport by id
export const useFetchSport = ({ QUERY = FETCH_SPORT, onCompleted, onError, variables }: FetchSportOptions) => {
  const [fetchSport, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchSport()
  }, [])

  return [
    fetchSport,
    ctx
  ] as const
}



const sponsorDefaultVariables = {
  sponsorQuery: {},
  queryOptions: {
    order: {
      createdAt: 'ASC'
    }
  }
}
// Fetch all sports
export const useFetchSponsorList = ({ QUERY = FETCH_SPONSORS, onCompleted, onError, variables = sponsorDefaultVariables }: Options) => {
  const [fetchSponsors, ctx] = useLazyQuery(QUERY, {
    variables,
    onCompleted,
    onError,
  })

  useEffect(() => {
    fetchSponsors()
  }, [])

  return [
    fetchSponsors,
    ctx
  ] as const
}

interface RevokeOrgAccessOptions {
  onCompleted?: (result: any) => void,
  onError?: (error: any) => void,
}

export const useRevokeOrgAccess = ({onCompleted, onError}: RevokeOrgAccessOptions) => {
  const ctx = useMutation(REVOKE_ORG_ACCESS, {
    onCompleted,
    onError
  })
  return ctx
}

export * from './use-file-subscription';
export * from './use-sport-info-subscription';
export * from './use-files';
