import { useMemo, useCallback } from 'react'
import { FormikValues } from 'formik'
import { User } from '../pages/user/components/types'
import { coachEditSchema, playerEditSchema, userEditSchema } from '../pages/user/schema'
import { useFormData, FormDataOptions } from './use-form-data'

export const useUserSchema = (user: User, options: FormDataOptions) => {
    const { isPlayer, isCoach } = user
    const _schema = useMemo(() => {
        if (user.isPlayer) return playerEditSchema
        if (user.isCoach) return coachEditSchema
        return userEditSchema
    }, [isPlayer, isCoach])

    const { fields, ...rest } = useFormData(_schema, options)

    const sanitize = useCallback((values: FormikValues) => {
        const clean: any = {}
        Object.keys(fields).forEach((field: string) => {
            clean[field] = values[field]
        })
        return clean
    }, [fields])

    return useMemo(
        () => [{ fields, ...rest } as const, sanitize] as const, 
        [fields, rest, sanitize]
    )

}