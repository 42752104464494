import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import { useLazyQuery } from '@apollo/client'
import { cloneDeep } from 'lodash'
import { useWindowWidth } from '@bit/necta.hooks.use-window-width'
import { Button, DatePicker, message } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, SearchOutlined } from '@ant-design/icons'
import { Fixture, useCurrentSport, useSports } from '../../redux'
import {
  Page,
  Calendar,
  RefreshAction,
  CalendarFilter,
  FilterValues,
  Cell,
  CellTip,
  useCalendar,
  AuthGuard, MobileCalendar
} from '../../components'
import { FIXTURE_SEARCH } from '../../graphql'
import { FixturesModal } from './FixturesModal'
import Fuse from 'fuse.js'
import { useAntTheme } from '../../hooks/use-ant-theme';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../utils';

const filterFixtures = (fixtures: any[], date: Moment) => {
  return fixtures.filter((fixture: any) => {
    if (fixture.darteStart === null) return false
    return moment(fixture.dateStart).isSame(date, 'day')
  })
}

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const FilterButton = styled(Button)`

    color: ${ p => p.theme.primaryColor };
`

interface Options {
  debug?: boolean,
  onSubmit?: (values: FilterValues) => void,
  withCurrentSport?: boolean
}

const useFilter = (options: Options = {}) => {

  const {
    debug,
    onSubmit,
    withCurrentSport
  } = options

  const [currentSport] = useCurrentSport()
  const [values, setValues] = useState<FilterValues>({
    name: '',
    sportId: withCurrentSport ? currentSport.id : ''
  })

  const handleFilterSubmit = useCallback((values: FilterValues) => {
    if (onSubmit) onSubmit(values)
  }, [onSubmit])

  const handleFilterChange = useCallback((values: FilterValues) => {
    setValues({ ...values })
  }, [setValues])

  return useMemo(() => ({
    values,
    setValues,
    handleFilterSubmit,
    handleFilterChange
  } as const), [
    values,
    setValues,
    handleFilterSubmit,
    handleFilterChange
  ])
}

interface Range {
  dateStart?: Moment | null,
  dateEnd?: Moment | null
}

const useFixtureSearch = (criteria: FilterValues = {})=> {
  const [fixtures, setFixtures] = useState<any[]>([])
  const fetchInitial = useRef<boolean>(true)
  const selectedOrg = useSelector(getSelectedOrg);

  const [fetch, ctx] = useLazyQuery(FIXTURE_SEARCH, {
    onCompleted: (result: any) => {
      if (result && result.fixtureSearch) {
        setFixtures(result.fixtureSearch)
      }
    },
    onError: (error: any) => {
      message.error({ content: 'Failed to load Fixtures!', duration: 4 })
    }
  })

  const handleFetch = useCallback((values: FilterValues = {}) => {
    const { dateStart, dateEnd, ...rest } = values;
    fetch({
      variables: {
        fixtureQuery: {
          dateStart: dateStart ? dateStart.format() : moment().startOf('month').format(),
          dateEnd: dateEnd ? dateEnd.format() : moment().endOf('month').format()
        },
        orgId: selectedOrg?.id,
        status: 'Confirmed'
      }
    })
  }, [fetch, selectedOrg]);

  useEffect(() => {
    if (fetchInitial.current) {
      fetch({ variables: {
          fixtureQuery: {
            dateStart: moment().startOf('month').format(),
            dateEnd: moment().endOf('month').format()
          },
          orgId: selectedOrg?.id,
          status: 'Confirmed'
        }})
      fetchInitial.current = false
    }
  }, [fetchInitial, selectedOrg, fetch])

  return useMemo(() => ([
    handleFetch,
    {
      ...ctx,
      fixtures
    }
  ] as const), [
    handleFetch,
    ctx,
    fixtures
  ])
}

const Header = ({ onChange, handleOnNext, handleOnPickerChange, handleOnPrev, handleFilterSubmit, handleFilterChange, hideFilter, value, values }: any) => {
  return (
    <React.Fragment>
      <CalendarFilter
        initialValues={values}
        onChange={handleFilterChange}
        onSubmit={handleFilterSubmit}
        hidden={hideFilter}
      >
        <Navigation>
          <Button
            type='text'
            shape='circle'
            onClick={handleOnPrev(onChange)}
          >
            <ArrowLeftOutlined />
          </Button>
          <DatePicker.MonthPicker
            value={value}
            onChange={handleOnPickerChange(onChange)}
            format={'MMMM'}
          />
          <Button
            type='text'
            shape='circle'
            onClick={handleOnNext(onChange)}
          >
            <ArrowRightOutlined />
          </Button>
        </Navigation>
      </CalendarFilter>
    </React.Fragment>
  )
}

interface FixturesProps {

}

export const Fixtures: React.FC<FixturesProps> = ({ ...props }) => {

  const [width] = useWindowWidth()
  const fullScreen = useMemo(() => width >= 768, [width])

  const [hideFilter, setHideFilter] = useState(true)
  const selectedOrg = useSelector(getSelectedOrg);

  const handleFilterClick = useCallback(() => (e: any) => {
    setHideFilter((hide: boolean) => !hide)
  }, [setHideFilter])

  const [handleFetch, { loading, fixtures }] = useFixtureSearch()

  const {
    handleFilterChange,
    handleFilterSubmit,
    values
  } = useFilter({
    debug: true,
  })

  const result = useMemo(() => {
    if (fixtures?.length <= 0) return [];
    const $fixtures = cloneDeep(fixtures)
    const fuse = new Fuse($fixtures, {
      keys: ['name', 'sportId']
    })
    if (values.name && values.sportId) {
      return fuse.search({
        $and: [{name: values.name}, {sportId: `'${values.sportId}`}]
      }).map((result: any) => result.item)
    }
    if (values.name) {
      return fuse.search(values.name).map((result: any) => result.item)
    }
    if (values.sportId) {
      return fuse.search(`'${values.sportId}`).map((result: any) => result.item)
    }

    return fixtures
  }, [fixtures, values])

  const {
    value,
    setValue,
    selectedDate,
    selected,
    onDayClose,
    showDay,
    onSelect,
  } = useCalendar<Fixture>(result, filterFixtures, moment())

  const handleOnPrev = useCallback((onChange?: any) => () => {
    const newMonth = moment(value).subtract(1, 'month');
    if (onChange) onChange(newMonth);
    setValue(newMonth);
  }, [value, setValue])

  const handleOnNext = useCallback((onChange?: any) => () => {
    const newMonth = moment(value).add(1, 'month');
    if (onChange) onChange(newMonth);
    setValue(newMonth);
  }, [value, setValue])


  const handleOnPickerChange = useCallback((onChange?: any) => (date: Moment | null) => {
    if (!date) return;
    if (onChange) onChange(date);
    setValue(date);
  }, [setValue])

  useEffect(() => {
    handleFetch({
      dateStart: moment(value).startOf('month'),
      dateEnd: moment(value).endOf('month')
    });
  }, [value, selectedOrg, handleFetch]);

  const [sportList, { getSport }] = useSports()
  const [{ loaded }] = useAntTheme()

  const getCell = useCallback((date: Moment) => (
    <Cell
      cellTip={<CellTip fixtures={filterFixtures(result, date)} />}
      fixtures={filterFixtures(result, date)}
      getSport={getSport}
      loaded={loaded}
    />
  ), [result, getSport, loaded])

  const extra = useMemo(() => [
    <RefreshAction onClick={() => handleFetch(values)} />,
    <FilterButton
      type='primary'
      shape='circle'
      onClick={handleFilterClick()}
      icon={<SearchOutlined />}
    />
  ], [handleFetch, values, handleFilterClick]);

  const headerProps = { handleOnNext, handleOnPickerChange, handleOnPrev, handleFilterSubmit, handleFilterChange, hideFilter, value, values };

  return (
    <AuthGuard needsActiveOrg>
      <Page>
        <FixturesModal
          fixtures={selected}
          show={showDay}
          date={selectedDate}
          onClose={onDayClose}
        />
        { fullScreen ? (
          <Calendar
            style={{ minWidth: 290, paddingBottom: 20 }}
            title='Fixtures'
            extra={extra}
            headerRender={({ onChange }: any) => <Header onChange={onChange} {...headerProps} />}
            card
            fullScreen={fullScreen}
            dateCellRender={getCell}
            onSelect={onSelect}
            loading={loading}
            value={value}
          />
        ) : (
          <MobileCalendar
            style={{ paddingBottom: 20 }}
            fixtures={result}
            title='Fixtures'
            headerRender={<Header {...headerProps} />}
            extra={extra}
            loading={loading}
            card
          />
        )}
      </Page>

    </AuthGuard>
  )
}
