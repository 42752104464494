import React, { useCallback, useMemo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Col, Button as Btn, message, PageHeader, Skeleton } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { compact } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSports } from '../../redux'
import { CardWrapper } from '../CardWrapper'
import { FETCH_TEAM, UPDATE_TEAMSHEET, useFetchTeam } from '../../graphql'
import { useMutation } from '@apollo/client'
import { useColumns } from './columns'
import { Table } from '../table'
import { Filter } from '../table'
import { Select, Checkbox, Form as AntForm, Collapse as Collapser } from 'antd'
import { Formik, FormikValues, FormikHelpers } from 'formik'

import { cloneDeep } from 'lodash'
import { FETCH_TEAMS, ADD_TEAMSHEET_MEMBERS } from '../../graphql'
import { useFormData } from '../../hooks'
import { useLazyQuery, QueryLazyOptions } from '@apollo/client'
import { TeamQuery } from '../../redux'
import { FormLoading } from '../page'
import { importFromTeamSchema } from './schema'
import { FormRow } from '../FormRow'
import { Input } from '../Input'
import { TextArea } from '../TextArea'
import { Select as Selector, SelectOption } from '../Select'
import { UserSelector } from '../user-selector'
import ProfilePictureUploader from '../profile-picture-uploader'
import PicPlaceholder from '../../assets/default_profile.png';
import { AGE_GROUP_OPTIONS } from '../../utils';

const { Option } = Select
const { Panel } = Collapser

const CaptainSelector = styled(Selector)`

`

const Collapse = styled(Collapser)`
    width: 100%;
`

const FormPanel = styled(Panel)<{$show?: boolean}>`
    ${ p => !p.$show && css`display: none;` }
`

const Prompt = styled.h3`
    text-align: left;
    margin: 30px 40px;

    @media only screen and (max-width: 768px) {
        margin: 20px 10px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 300px;
`

const Form = styled(AntForm)<{ maxWidth?: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    width: 100%;
    ${ p => p.maxWidth && css`max-width: ${p.maxWidth};` }
`

const TeamSelector = styled(Select)`
    width: 100%;
`

const Check = styled(Checkbox)`
    margin-top: 20px;
    margin-bottom: 1px;
`

const Button = styled(Btn)`
    width: 100%;
`

const convertMembers = (teamsheetId: string, teamMembers: any[]) => {
    return teamMembers.map((member: any) => {
        const {
            shortid,
            teamId,
            ...rest
        } = member
        return {
            ...rest,
            teamsheetId
        }
    })
}

const convertTeam = (team: any) => {
    if (team === null) return {}
    const {
        id,
        shortid,
        teamMembers,
        captain,
        headCoach,
        organisation,
        sport,
        ...rest
    } = team

    return {
        ...rest,
        teamId: id
    }

}

const packMembers = (members: any[]) => {

    const newMembers = members.map((member: any) => {
        const {
            id,
            teamRole,
            teamRoleAlt,
            ...rest
        } = member
        return { ...rest }
    })

    return newMembers
}

const useFetchSelectTeams = (initialTeamQuery: TeamQuery = {}) => {
    const [teams, setTeams] = useState<any>([])
    const [fetch, context] = useLazyQuery(FETCH_TEAMS, {
        onCompleted: (result: any) => {
            if (result && result.teams) setTeams(cloneDeep(result.teams))
        },
        onError: (error: any) => {
        }
    })

    const handleFetch = useCallback(($teamQuery?: TeamQuery) => {
        fetch({
            variables: {
                teamQuery: $teamQuery ?? {}
            }
        })
    }, [fetch])

    return useMemo(() => [
        handleFetch,
        { ...context, teams } as const
    ] as const, [
        handleFetch,
        context,
        teams
    ])

}

interface MassAddTeamsheetMembersProps {
    teamsheetId: string,
    sportId: string,
    organisationId: string,
    card?: boolean,
    hidden?: boolean,
    onSubmitted?: () => void,
    title?: string,
    onCancel?: (stage: 'select' | 'commit', setStage: Function) => void,
    onBack?: () => void
}

export const MassAddTeamsheetMember: React.FC<MassAddTeamsheetMembersProps> = ({
    teamsheetId,
    sportId,
    organisationId,
    card = true,
    hidden = false,
    onSubmitted,
    title,
    onCancel,
    onBack,
    ...props
}) => {

    const history = useHistory()

    const [handleFetch, { loading, teams }] = useFetchSelectTeams()

    const length = teams.length

    useEffect(() => {
        if (!organisationId || !sportId || !teamsheetId) return
        if (length > 0) return
        handleFetch({
            organisationId,
            sportId
        })
    }, [handleFetch, organisationId, sportId, teamsheetId, length])

    const [selectedTeam, setSelectedTeam] = useState<any>(null)

    const [stage, setStage] = useState<'select' | 'commit'>('select')

    const [members, setMembers] = useState<any>([])

    const [withTeamInfo, setWithTeamInfo] = useState(false)

    const handleCheck = useCallback((e: any) => setWithTeamInfo(e.target.checked), [setWithTeamInfo])

    const { fields, initialValues: $initialValues, ...ctx } = useFormData(importFromTeamSchema, {
        onSubmit: async () => {
            return false
        },
        onCompleted: (result: any) => {
            //console.log(result)
        },
        onError: (e: any) => {
            console.error(e)
        }
    })


    const initialValues = useMemo(() => ({
        ...$initialValues,
        ...convertTeam(selectedTeam)
    }), [$initialValues, selectedTeam])

    const [fetchTeam, { loading: loadingTeam }] = useLazyQuery(FETCH_TEAM, {
        variables: {
            id: selectedTeam?.id
        },
        onCompleted: (result: any) => {
            if (result && result.team) {
                const newMembers = convertMembers(teamsheetId, cloneDeep(result.team.teamMembers))
                setMembers(newMembers)
            }
        }
    })

    const getOptions = useCallback(() => {
        const filtered = teams.filter((team: any) => {
            return team.teamMembers.length > 0
        })
        return filtered.map((team: any) => (
            <Option value={team.id} label={team.name}>
                {team.name}
            </Option>
        ))
    }, [teams])

    const loadMembers = useCallback(async () => {
        try {
            await fetchTeam()
            setStage('commit')
        } catch (e) {
            message.error({ content: 'Could not load members from team!', duration: 4 })
        }
    }, [setStage, fetchTeam])

    const handleRemove = useCallback((id: string) => (e?: any) => {
      e.stopPropagation();
      message.loading({ content: 'Removing team member...', duration: 10 });
      setMembers((members: any[]) => (
          members.filter((t: any) => t.id !== id)
      ));
      message.success({ content: 'Team member has been removed', duration: 2 })
    }, [setMembers])

    const handleCancel = useCallback((e?: any) => {
      e.stopPropagation();

    }, []);

    const handleOnSelectChange = useCallback((value: any) => {
        const team = teams.find((team: any) => team.id === value )
        if (team) setSelectedTeam(team)
    }, [teams, setSelectedTeam])

    const [sportList] = useSports()

    const { teamRoles } = useMemo(() => sportList.find((sport: any) => sport.id === sportId || []), [sportList, sportId])
    const allTeamRoles = compact((teamRoles || []).map((tr: any) => tr.id !== '' && Object.assign({ text: tr.name, value: tr.name})));


    const filters = {
      filters: [ ...allTeamRoles ],
      filterMultiple: true,
      type: Filter.SELECT
    }

    const columns = useColumns({
        filters,
        handleCancel,
        handleRemove,
        allTeamRoles
    })

    const [addTeamsheetMembers, { loading: submitting }] = useMutation(ADD_TEAMSHEET_MEMBERS, {
        onCompleted: (result: any) => {
            if (onSubmitted) onSubmitted()
        },
        onError: (e: any) => {
            message.error({ content: 'Failed to upload teamsheet...', duration: 4 })
        }
    })

    const [updateTeamsheet, { loading: updating }] = useMutation(UPDATE_TEAMSHEET, {
        onCompleted: (result: any) => {
            //console.log(result)
        },
        onError: (error: any) => {
            console.error(error)
        }
    })

    const handleFinalize = useCallback((values: any) => async () => {
        try {
            message.loading({ content: 'Uploading team members...', duration: 10 })
            const result = await addTeamsheetMembers({
                variables: {
                    newTeamsheetMembersData: {
                        teamsheetMembers: packMembers(members),
                        teamsheetId
                    }
                }
            })
            if (result?.data?.addTeamsheetMembers)
                message.loading({ content: 'Updating Teamsheet...', duration: 10 })
            if (withTeamInfo) {
                const update = await updateTeamsheet({
                    variables: {
                        id: teamsheetId,
                        updateTeamsheet: convertTeam(selectedTeam)
                    }
                })
                if (update?.data?.updateTeamsheet) {
                    message.success({
                        content: 'Successfully imported Teamsheet!',
                        duration: 4
                    })
                    history.push(`/fixture/teamsheet/${teamsheetId}`)
                }
            } else {
                history.push(`/fixture/teamsheet/${teamsheetId}`)
            }

        } catch (e) {
        }
    }, [addTeamsheetMembers, updateTeamsheet, history, members, teamsheetId, selectedTeam, withTeamInfo])

    const teamsWithMembers = useMemo(() => getOptions().length > 0, [getOptions])

    const handleLoadMembers = useCallback(() => {
        if (selectedTeam === null) return
        loadMembers()
    }, [loadMembers, selectedTeam])


    const handleSelectCaptain = useCallback((values: any, setFieldValue: any) => (value: string) => {
        if (value !== values.captainId) setFieldValue('captainId', value)
        const $members = cloneDeep(members).map((member: any) => {
            if (member.isCaptain) return {
                ...member,
                isCaptain: false
            }
            if (member.id === value) return {
                ...member,
                isCaptain: true
            }
            return member
        })
        setMembers($members)
    }, [setMembers, members])

    const captainOptions = useMemo(() => (
        members.map(
            (member: any) => ({
                value: member.id,
                label: `${member.firstname} ${member.lastname}`
            })
        )
    ), [members])

    const handleOnCancel = useCallback(() => {
        if (onCancel) return () => {
            onCancel(stage, setStage)
            handleFetch({
                organisationId,
                sportId
            })
        }
        return undefined
    }, [onCancel, stage, setStage, organisationId, sportId, handleFetch])

    if (hidden) return null

    if ((loadingTeam && teams.length === 0) || loading) return (
        <Skeleton paragraph />
    )


    if (stage === 'select') return (
        <CardWrapper card={card}>
            { (title || onBack) && <PageHeader title={title} onBack={onBack} /> }
            { teamsWithMembers && (
                <Container>
                    <Form maxWidth='400px'>
                        <TeamSelector placeholder='Select a team...' onSelect={handleOnSelectChange}>
                            {getOptions()}
                        </TeamSelector>

                        <Check checked={withTeamInfo} onChange={handleCheck}>
                            Include Team Info
                        </Check>
                        <Button type='primary' onClick={handleLoadMembers}>
                            Import
                        </Button>
                    </Form>
                </Container>
            )}
            { !teamsWithMembers && (
                <Container>
                    <h1>No matching teams found, add a team member below:</h1>
                    <Button type='primary' onClick={() => history.push(`/fixture/teamsheet/${teamsheetId}/member/add`)}>
                        Add Team Member
                    </Button>
                </Container>
            )}
        </CardWrapper>
    )

    return (
        <CardWrapper
            card={card}
            extra={[
                <Button
                    onClick={() => setStage('select')}
                    shape='circle'
                >
                    <CloseOutlined />
                </Button>
            ]}
        >
            <Formik
                {...ctx}
                initialValues={initialValues}
                enablelReinitialize
                validateOnBlur
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <React.Fragment>

                        { (title || handleOnCancel()) && <PageHeader title={title} onBack={handleOnCancel()} /> }

                        <Prompt>View edit and finalize the imported Team Info and Member list below!</Prompt>

                        <Collapse defaultActiveKey={['details', 'table']} ghost>
                            <FormPanel $show={withTeamInfo} header='Teamsheet Details' key='details'>
                                <Form layout='vertical'>
                                    <FormRow>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <ProfilePictureUploader
                                                disabled={updating || submitting}
                                                fieldKey="primaryImage"
                                                defaultPic={PicPlaceholder}
                                            />
                                        </Col>
                                        <Input {...fields.name} />
                                        <Selector options={AGE_GROUP_OPTIONS} {...fields.ageGroup} allowClear />
                                        <CaptainSelector
                                            {...fields.captainId}
                                            onChange={handleSelectCaptain(values, setFieldValue)}
                                        >
                                            { captainOptions.map((option: any) => (
                                                <SelectOption
                                                    value={option.value}
                                                    label={option.label}
                                                >{option.label}</SelectOption>
                                            ))}
                                        </CaptainSelector>
                                        <TextArea
                                            {...fields.description}
                                            gridProps={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24}}
                                        />
                                    </FormRow>
                                </Form>
                            </FormPanel>

                            <Panel header='Members to import' key='table'>
                                <Table
                                    loading={submitting || updating}
                                    data={members}
                                    columns={columns}
                                />
                            </Panel>

                        </Collapse>
                        <Button type='primary' loading={submitting || updating} disabled={submitting} onClick={handleFinalize(values)}>
                          Finalize
                        </Button>
                    </React.Fragment>
                )}
            </Formik>
        </CardWrapper>
    )
}
