import gql from 'graphql-tag';
import {
  allUserData, allUserDataSingle, fileFields,
  locationFields,
  organisationFields,
  sponsorFields,
  sportFields, sportInfoFields,
  teamFields, teamMemberFields,
  teamRoleFields,
  teamsheetMemberFields, teamsheetFields,
  fixtureFields,
  userFields,
  fixtureQueryFields,
  scoreResultFields, teamsheetFieldsMin, eventFields, thirdPartyViewAllFields, thirdPartyProviderFields, thirdPartyFields, thirdPartyProviderContactFields
} from './fragments';

export const PENDING_FIXTURES = gql`
    query ($orgId: String) {
        fixtures(fixtureQuery: {}, status: "Pending", orgId: $orgId) {
            ${fixtureFields}
        }
    }
`

export const UPDATE_PENDING = gql`
    mutation($orgId: String, $accept: Boolean!, $fixtureId: String!) {
        fixtureInvite(orgId: $orgId, accept: $accept, fixtureId: $fixtureId) {
            ${ fixtureFields }
        }
    }
`

export const ADD_SCORE = gql`
  mutation($newScoreData: NewScoreInput!) {
    addScore(newScoreData: $newScoreData) {
      ${scoreResultFields}
    }
  }
`

export const FIXTURES = gql`
  query($fixtureQuery: FixtureQuery!, $orgId: String) {
    fixtures(fixtureQuery: $fixtureQuery, orgId: $orgId) {
      ${fixtureFields}
    }
  }
`

export const FIXTURE = gql`
  query($id: String!, $queryOptions: BaseQuerySingle) {
    fixture(queryOptions: $queryOptions, id: $id) {
      ${fixtureFields}
    }
  }
`

export const FIXTURE_SEARCH = gql`
  query($status: String, $fixtureQuery: FixtureQuery!, $orgId: String) {
    fixtureSearch(status: $status, fixtureQuery: $fixtureQuery, orgId: $orgId) {
      ${fixtureQueryFields}
    }
  }
`

export const UPDATE_FIXTURE = gql`
  mutation($id: String!, $updateFixture: UpdateFixtureInput!) {
    updateFixture(updateFixture: $updateFixture, id: $id) {
      id
      name
      homeOrgId
      awayOrgId
      homeOrgName
      awayOrgName
      dateStart
      dateEnd
    }
  }
`

// USAGE: query for creating new fixtures
export const ADD_FIXTURE = gql`
  mutation($newFixtureData: NewFixtureInput!) {
    addFixture(newFixtureData: $newFixtureData) {
      id
      name
      dateStart
      dateEnd
    }
  }
`

// USAGE: query for creating new events
export const ADD_EVENT = gql`
    mutation($newEventData: NewEventInput!) {
        addEvent(newEventData: $newEventData) {
            id
            name
            dateStart
            dateEnd
        }
    }
`

export const EVENTS = gql`
    query ($eventQuery: EventQuery!) {
        events(eventQuery: $eventQuery, queryOptions: {relations: ["broadcastingRequest"]}) {
            ${eventFields}
        }
    }
`
export const EVENT = gql`
    query($id: String!) {
        event(id: $id) {
            ${eventFields}
        }
    }
`

export const ARCHIVE_EVENT = gql`
    mutation($id: String!) {
        archiveEvent(id: $id)
    }
`

export const UPDATE_EVENT = gql`
    mutation ($id: String!, $updateEvent: UpdateEventInput!) {
      updateEvent(updateEvent: $updateEvent, id: $id) {
          ${eventFields}
      }
    }
`

// USAGE: query for fetching dashboard widget data
export const WIDGET_QUERY = gql`
    query($orgId: String!) {
        widgets (widgetQuery:{}, widgetParams: { organisationId: $orgId }){
            id
            name
            gridProps
            data
          }
    }
`

// USAGE: Subscription for when a file has been added
// ID param refers to the parent object that it is linked to (eg user ID)
export const FILE_ADDED = gql`
  subscription FILE_ADDED ($id: String!, $fileType: String!) {
    FileAdded(id: $id, fileType: $fileType) {
      ${fileFields}
    }
  }
`;

// USAGE: Subscription for when a file has been deleted
// ID param refers to the parent object that it is linked to (eg user ID)
export const FILE_DELETED = gql`
  subscription FILE_DELETED ($id: String!, $fileType: String!) {
    FileDeleted(id: $id, fileType: $fileType) {
      ${fileFields}
    }
  }
`;

// USAGE: Fetch another user (eg /user/edit/<shortid> page)
// This will be for fetching other users
export const FETCH_USER_BY_SHORTID = gql`
  query FETCH_USER_BY_SHORTID($shortid: String!) {
    userByShortid(shortid: $shortid) {
      ${allUserDataSingle}
    }
  }
`;

// USAGE: Fetch own user account
// This will be used to fetch your own user account and data
export const FETCH_MY_USER = gql`
  query FETCH_MY_USER {
    user {
      ${allUserDataSingle}
    }
  }
`;

// USAGE: fetching users to display in a table
// This fetches all users at a specific organisation
// Filtered by role eg (User/Organisation)
// Further filtering can happen using where in the queryOptions
// Eg: queryOptions = { where: { isPlayer: true } }
export const FETCH_USERS_BY_ORG = gql`
  query USERS_BY_ORG ($organisationId: String!, $role: String, $archived: Boolean, $queryOptions: BaseQuery) {
    usersByOrg(organisationId: $organisationId, role: $role, archived: $archived, queryOptions: $queryOptions) {
      ${allUserData}
    }
  }
`;

// TODO: role-based filters
export const ALL_USERS = gql`
  query ALL_USERS ($queryOptions: BaseQuery, $role: String) {
    users (queryOptions: $queryOptions, role: $role) {
      ${allUserData}
    }
  }
`;

export const SEARCH_USERS = gql`
  query SEARCH_USERS ($keyword: String!, $queryOptions: BaseQuery, $role: String, $filters: JSONObject) {
    userSearch(keyword: $keyword, queryOptions: $queryOptions, role: $role, filters: $filters) {
      id
      firstname
      lastname
      email
      shortid
      isPlayer
      isCoach
      role
    }
  }
`;

// USAGE: creating a new user account
export const ADD_USER = gql`
  mutation ADD_USER ($newUser: NewLoginUserInput!) {
    addLogin(newUser: $newUser) {
      id
      shortid
    }
  }
`;

// USAGE: updating any user (permission check applies)
// This can be used to update passwords and emails too
export const UPDATE_USER = gql`
  mutation UPDATE_USER ($id: String!, $user: UpdateUserInput!) {
    adminUpdateUser(id: $id, user: $user) {
      id
    }
  }
`

// USAGE: updating own user
// This can be used to update passwords and emails too
export const UPDATE_MY_USER = gql`
  mutation UPDATE_MY_USER ($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
    }
  }
`

// USAGE: Mass importing users
export const IMPORT_USERS = gql`
  mutation IMPORT_USERS ($newUserData: NewUserMultipleInput!) {
    addUserMultiple (newUserData: $newUserData)
  }
`;

// USAGE: Adding a new organisation
// If an org performs this mutation, they will be set as the parent organisation
export const ADD_ORG = gql`
  mutation ADD_ORG ($newOrg: NewOrganisationInput!){
    addOrganisation(newOrganisationData: $newOrg){
      ${organisationFields}
    }
  }
`;

// USAGE: Updating an organisation
export const UPDATE_ORG = gql`
  mutation UPDATE_ORG ($id: String!, $updateOrg: UpdateOrganisationInput!) {
   updateOrganisation(id: $id, updateOrganisation: $updateOrg) {
      ${organisationFields}
    }
  }
`;

// USAGE: Fetching a single org by ID
export const FETCH_ORG = gql`
  query FETCH_ORG ($id: String!) {
    organisation (id: $id) {
      ${organisationFields}
      sponsor {
        ${sponsorFields}
      }
      orgAdmins {
        ${userFields}
      }
      childOrgs {
        ${organisationFields}
      }
      address {
        ${locationFields}
      }
      parentOrg {
        ${organisationFields}
      }
      documents {
        ${fileFields}
      }
    }
  }
`;

// USAGE: Fetching multiple orgs (eg for a table)
export const FETCH_ORGS = gql`
  query FETCH_ORGS ($organisationQuery: OrganisationQuery!) {
    organisations (organisationQuery: $organisationQuery) {
      ${organisationFields}
      sponsor {
        ${sponsorFields}
      }
      childOrgs {
        ${organisationFields}
      }
      address {
        ${locationFields}
      }
      parentOrg {
        ${organisationFields}
      }
    }
  }
`;

// Add a new ThirdPartyProvider
export const ADD_THIRD_PARTY_PROVIDER = gql`
  mutation ADD_THIRD_PARTY_PROVIDER ($newThirdPartyProvider: NewThirdPartyProviderInput!) {
    addThirdPartyProvider(newThirdPartyProviderData:$newThirdPartyProvider){
      id
      linkedOrgId
      thirdPartyProviderName
      registrationNumber
      vatNumber
      policeClearance
      serviceAgreement
      insuranceDocs
    }
  }
`

// Update ThirdPartyProvider
export const UPDATE_THIRD_PARTY_PROVIDER = gql`
  mutation UPDATE_THIRD_PARTY_PROVIDER($updatedThirdPartyProvider: UpdateThirdPartyProviderInput!) {
    updateThirdPartyProvider(updateThirdPartyProviderData: $updatedThirdPartyProvider) {
      id
      thirdPartyProviderName
      registrationNumber
      vatNumber
    }
  }
`;

// Fetch one ThirdPartyProvider by ID
export const FETCH_THIRD_PARTY_PROVIDER = gql`
  query FETCH_THIRD_PARTY_PROVIDER($id: String!){
    thirdPartyProvider(id: $id){
      ${thirdPartyFields}
    }
  }
`

// Fetch all ThirdPartyProviders for the logged in Org
export const FETCH_THIRD_PARTY_PROVIDERS = gql`
  query FETCH_THIRD_PARTY_PROVIDERS{
    thirdPartyProviders{
      ${thirdPartyViewAllFields}
    }
  } 
`;

// Fetch all ThirdPartyProviders for the logged in Org
export const FETCH_THIRD_PARTY_PROVIDERS_BY_EVENT_ID = gql`
  query FETCH_THIRD_PARTY_PROVIDERS($id: String!){
    event(id: $id, queryOptions: { relations: "thirdPartyProviders" }){
      thirdPartyProviders{
        id
        thirdPartyProviderName
        registrationNumber
        vatNumber
      }
    }
  } 
`;

export const ADD_THIRD_PARTY_PROVIDER_CONTACT = gql`
  mutation UPDATE_THIRD_PARTY_PROVIDER_CONTACT($newThirdPartyProviderContactData: NewThirdPartyProviderContactInput!) {
    addThirdPartyProviderContact(newThirdPartyProviderContactData: $newThirdPartyProviderContactData) {
      ${thirdPartyProviderContactFields}
    }
  }
`;

export const UPDATE_THIRD_PARTY_PROVIDER_CONTACT = gql`
  mutation UPDATE_THIRD_PARTY_PROVIDER_CONTACT($updateThirdPartyProviderContactData: UpdateThirdPartyProviderContactInput!) {
    updateThirdPartyProviderContact(updateThirdPartyProviderContactData: $updateThirdPartyProviderContactData) {
      ${thirdPartyProviderContactFields}
    }
  }
`;

// Fetch ThirdPartyProviderContact by ID
export const FETCH_THIRD_PARTY_PROVIDER_CONTACT = gql`
  query FETCH_THIRD_PARTY_PROVIDER($id: String!){
    thirdPartyProviderContact(id: $id){
      ${thirdPartyProviderContactFields}
    }
  }
`

// USAGE: Fetching multiple orgs (eg for a table)
export const SEARCH_ORGS = gql`
  query SEARCH_ORGS ($organisationQuery: OrganisationQuery!) {
    organisationsSafe (organisationQuery: $organisationQuery) {
      id
      name
      primaryImage
    }
  }
`;

// USAGE: Grant a user access to an org
// organisationId: id of org
// userId: id of user
// permission: permission string to grant (eg FULL_ACCESS)
export const ADD_ORG_ACCESS = gql`
  mutation ADD_ORG_ACCESS ($access: NewOrganisationAccessInput!) {
    addOrganisationAccess(access: $access) {
      id
    }
  }
`;

// USAGE: Revoke a user's access to an org
export const REVOKE_ORG_ACCESS = gql`
  mutation REVOKE_ORG_ACCESS ($userId: String!, $organisationId: String!) {
    revokeOrganisationAccess(userId: $userId, organisationId: $organisationId)
  }
`;

export const ADD_TEAMSHEET = gql`
  mutation ($newTeamsheet: NewTeamsheetInput!) {
    addTeamsheet (newTeamsheetData: $newTeamsheet) {
      ${teamsheetFields}
    }
  }
`

export const UPDATE_TEAMSHEET = gql`
  mutation ($updateTeamsheet: UpdateTeamsheetInput!, $id: String!) {
    updateTeamsheet (id: $id, updateTeamsheet: $updateTeamsheet) {
      ${teamsheetFieldsMin}
      team {
        ${teamFields}
      }
    }
  }
`


export const FETCH_TEAMSHEET = gql`
  query ($id: String!) {
    teamsheet (id: $id) {
      ${teamsheetFields}
    }
  }
`

export const ADD_TEAMSHEET_MEMBERS = gql`
  mutation ($newTeamsheetMembersData: NewTeamsheetMembersInput!) {
    addTeamsheetMembers(newTeamsheetMembersData: $newTeamsheetMembersData) {
      ${teamsheetMemberFields}
    }
  }
`

export const ADD_TEAMSHEET_MEMBER = gql`
  mutation ($newTeamsheetMemberData: NewTeamsheetMemberInput!) {
    addTeamsheetMember (newTeamsheetMemberData: $newTeamsheetMemberData) {
      ${ teamsheetMemberFields}
    }
  }
`

export const FETCH_TEAMSHEET_MEMBER = gql`
  query ($id: String!) {
    teamsheetMember (id: $id) {
      ${teamsheetMemberFields}
      teamsheet {
       id
       name
       sportId
      }
      user {
       id
       isPlayer
       isCoach
      }
    }
  }
`

export const UPDATE_TEAMSHEET_MEMBER = gql`
  mutation ($id: String!, $updateTeamsheetMember: UpdateTeamsheetMemberInput!) {
    updateTeamsheetMember(id: $id, updateTeamsheetMember: $updateTeamsheetMember) {
      ${teamsheetMemberFields}
    }
  }
`

// USAGE: Add a new team
export const ADD_TEAM = gql`
  mutation ($newTeam: NewTeamInput!) {
    addTeam (newTeamData: $newTeam){
      ${teamFields}
    }
  }
`;

// USAGE: Update a team
export const UPDATE_TEAM = gql`
  mutation ($id: String!, $updateTeam: UpdateTeamInput!) {
    updateTeam (id: $id, updateTeam: $updateTeam){
      ${teamFields}
    }
  }
`;

// USAGE: Fetching a single team by ID
export const FETCH_TEAM = gql`
  query FETCH_TEAM ($id: String!) {
    team (id: $id) {
      ${teamFields}
      sport {
        ${sportFields}
      }
      teamMembers {
        ${teamMemberFields}
        teamRoleId
        teamRole {
          ${teamRoleFields}
        }
        teamRoleAltId
        teamRoleAlt {
          ${teamRoleFields}
        }
      }
      captain {
        ${teamMemberFields}
      }
      headCoach {
        ${teamMemberFields}
      }
      organisation {
        ${organisationFields}
      }
    }
  }
`;

// USAGE: Fetching multiple teams by query
export const FETCH_TEAMS = gql`
  query FETCH_TEAMS ($teamQuery: TeamQuery!) {
    teams (teamQuery: $teamQuery) {
      ${teamFields}
      sport {
        ${sportFields}
      }
      teamMembers {
        ${teamMemberFields}
      }
      captain {
        ${teamMemberFields}
      }
      headCoach {
        ${teamMemberFields}
      }
      organisation {
        ${organisationFields}
      }
    }
  }
`;

// USAGE: Add a team member to a team
export const ADD_TEAM_MEMBER = gql`
  mutation ADD_TEAM_MEMBER ($newTeamMemberData: NewTeamMemberInput!) {
    addTeamMember (newTeamMemberData: $newTeamMemberData){
      ${teamMemberFields}
    }
  }
`;

// USAGE: Update a team member
export const UPDATE_TEAM_MEMBER = gql`
  mutation UPDATE_TEAM_MEMBER ($id: String!, $updateTeamMember: UpdateTeamMemberInput!) {
    updateTeamMember (id: $id, updateTeamMember: $updateTeamMember) {
      ${teamMemberFields}
    }
  }
`;

// USAGE: Fetch a single team member by ID
export const FETCH_TEAM_MEMBER = gql`
  query FETCH_TEAM_MEMBER ($id: String!) {
    teamMember (id: $id) {
      ${teamMemberFields}
      teamId
      team {
        ${teamFields}
        sport {
          ${sportFields}
        }
      }
      user {
        ${userFields}
      }
      teamRoleId
      teamRole {
        ${teamRoleFields}
      }
      teamRoleAltId
      teamRoleAlt {
        ${teamRoleFields}
      }
    }
  }
`;

// USAGE: Fetch multiple team members
// This won't be usable until the backend supports teamId as a query parameter
// Until then, fetch a team by ID and retrieve the team members from that query
export const FETCH_TEAM_MEMBERS = gql`
  query FETCH_TEAM_MEMBERS ($teamMemberQuery: TeamMemberQuery!) {
    teamMember (teamMemberQuery: $teamMemberQuery) {
      ${teamMemberFields}
      team {
        ${teamFields}
      }
      user {
        ${userFields}
      }
      teamRoleId
      teamRole {
        ${teamRoleFields}
      }
      teamRoleAltId
      teamRoleAlt {
        ${teamRoleFields}
      }
    }
  }
`;

// USAGE: Fetch a single sport by ID
export const FETCH_SPORT = gql`
  query FETCH_SPORT ($id: String!) {
    sport (id: $id) {
      ${sportFields}
      teamRoles {
        ${teamRoleFields}
      }
    }
  }
`;

// USAGE: Fetch sports by query
// We will use this to fetch the sport config
export const FETCH_SPORTS = gql`
  query FETCH_SPORTS ($sportQuery: SportQuery!, $queryOptions: BaseQuery) {
    sports (sportQuery: $sportQuery, queryOptions: $queryOptions) {
      ${sportFields}
      teamRoles {
        ${teamRoleFields}
      }
    }
  }
`;

// USAGE: Add sport info to a user
// Currently needs userId to be added
export const ADD_SPORT_INFO = gql`
  mutation ADD_SPORT_INFO ($newSportInfoData: NewSportInfoInput!) {
    addSportInfo (newSportInfoData: $newSportInfoData) {
      ${sportInfoFields}
    }
  }
`;

// USAGE: Update a single sport info object
export const UPDATE_SPORT_INFO = gql`
  mutation UPDATE_SPORT_INFO ($id: String!, $updateSportInfo: UpdateSportInfoInput!) {
    updateSportInfo (id: $id, updateSportInfo: $updateSportInfo) {
      ${sportInfoFields}
    }
  }
`;

// USAGE: Archive a single sport info object
export const ARCHIVE_SPORT_INFO = gql`
  mutation ARCHIVE_SPORT_INFO ($id: String!) {
    archiveSportInfo (id: $id)
  }
`;

export const SPORT_INFO_ADDED = gql`
  subscription SportInfoAdded($id: String!) {
    SportInfoAdded(id: $id) {
      ${sportInfoFields}
      teamRole {
        ${teamRoleFields}
      }
      teamRoleAlt {
        ${teamRoleFields}
      }
      sport {
        ${sportFields}
      }
    }
  }
`;

export const SPORT_INFO_DELETED = gql`
  subscription SportInfoDeleted($id: String!) {
    SportInfoDeleted(id: $id) {
      id
    }
  }
`;

// USAGE: Fetching multiple sponsors (on app load)
export const FETCH_SPONSORS = gql`
  query FETCH_SPONSORS ($sponsorQuery: SponsorQuery!, $queryOptions: BaseQuery) {
    sponsors (sponsorQuery: $sponsorQuery, queryOptions: $queryOptions) {
      ${sponsorFields}
    }
  }
`;

export const ADD_FILE_MUTATION = gql`
  mutation ADD_FILE_MUTATION($newFileData: NewFileInput!) {
    addFile(newFileData: $newFileData) {
      ${fileFields}
    }
  }
`;


export const DELETE_FILE_MUTATION = gql`
  mutation DELETE_FILE_MUTATION($id: String!) {
    removeFile(id: $id)
  }
`;

export const UPDATE_FILE_MUTATION = gql`
  mutation UPDATE_FILE_MUTATION($id: String!, $updateFile: UpdateFileInput!) {
    updateFile(id: $id, updateFile: $updateFile) {
      ${fileFields}
    }
  }
`;

// USAGE: Archive a single team member
export const ARCHIVE_TEAM_MEMBER = gql`
  mutation ARCHIVE_TEAM_MEMBER ($id: String!) {
    archiveTeamMember (id: $id)
  }
`;

export const ARCHIVE_TEAMSHEET_MEMBER = gql`
  mutation ($id: String!) {
    archiveTeamsheetMember (id: $id)
  }
`

// USAGE: Archive a single team
export const ARCHIVE_TEAM = gql`
  mutation ARCHIVE_TEAM ($id: String!) {
    archiveTeam (id: $id)
  }
`;

// type NewUserMultipleInput
// users: list of users to import
// organisationId: id of the organisation
export const IMPORT_USERS_MUTATION = gql`
    mutation IMPORT_USERS_MUTATION($newUserData: NewUserMultipleInput!) {
        addUserMultiple(newUserData: $newUserData)
    }
`;

export const IMPORT_ORGS_MUTATION = gql`
    mutation IMPORT_ORGS_MUTATION($newOrganisationData: NewOrganisationMultipleInput!) {
        addOrganisationMultiple(newOrganisationData: $newOrganisationData)
    }
`;
