import { isSafari } from 'react-device-detect';

export type Document = {
  file: any;
  name: string;
  type: string;
  isBufferArray: boolean;
};

/**
 * Downloads a file
 * @param {Document}           file         A file to download
 */
export const downloadFile = (file: Document): void => {
  if (isSafari) {
    extractBlobInNewWindow(file);
  } else {
    extractBlobInCurrentWindow(file);
  }
};

/**
 * Extracts blob in the current window (used for all browsers except Safari)
 * @param {Document}           file         A file to download
 */
const extractBlobInCurrentWindow = (file: Document): void => {
  // create a download anchor tag
  const downloadLink = document.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.download = file.name;

  // convert downloaded data to a Blob
  const blob = new Blob(file.isBufferArray ? [file.file] : getBufferArraysFromBase64String(file), { type: file.type });

  // create an object URL from the Blob
  const URL = window.URL;
  const downloadUrl = URL.createObjectURL(blob);

  // set object URL as the anchor's href
  downloadLink.href = downloadUrl;

  // append the anchor to document body
  document.getElementsByTagName('body')[0].appendChild(downloadLink);

  // fire a click event on the anchor
  downloadLink.click();

  // cleanup: remove element and revoke object URL
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadUrl);
};

/**
 * Extracts blob in a new window (used only for Safari)
 * @param {Document}           file         A file to download
 */
const extractBlobInNewWindow = (file: Document): void => {
  const blob = new Blob(file.isBufferArray ? [file.file] : getBufferArraysFromBase64String(file), { type: file.type });
  const downloadUrl = window.URL.createObjectURL(blob);
  window.open(downloadUrl, '_blank');
};

/**
 * For extracting raw file data
 * @param {Document}           file         The file to process
 */
const getBufferArraysFromBase64String = (file: Document): any[] => {
  const sliceSize = 512;
  const base64String = file.file.replace('data:' + file.type + ';base64,', '');
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return byteArrays;
};
