import React, { useMemo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { useFetchUserSearch } from '../../graphql'
import { FieldContainer } from '../FieldContainer'
import { isEmpty, get, compact } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities';
import useDebouncedEffect from 'use-debounced-effect-hook'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from '../Avatar';

const { Option } = Select

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

const getValues = (user: LoadedUser, labelKeys: (keyof LoadedUser)[]) => {
  return compact(labelKeys.map((l: keyof LoadedUser) => get(user, l, null))).join(' ');
}

interface LoadedUser {
  id: string,
  firstname: string,
  lastname: string,
  shortid: string,
  email: string,
  isPlayer: boolean,
  isCoach: boolean,
}

interface UserSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  //TODO: define these properly
  valueKey?: keyof LoadedUser,
  labelKeys?: (keyof LoadedUser)[],
  showPicture?: boolean;
  getOptions?: (users: Partial<LoadedUser>[]) => React.ReactNode[],
  getUser?: (value: any) => void,
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  filters?: {
    role?: string | false;
    organisationId?: string | false;
  }
}

/*
 * Select component for selecting a current active org. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the loaded orgs type.
 */
export const UserSelectorComponent: React.FC<UserSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  { getFormik = true, getOptions, getUser, labelKeys = ['firstname', 'lastname'], valueKey = 'id', name = '', showPicture = true, onChange, disabled, label, required, hidden, gridProps, className, filters = { role: 'User' }, defaultValue, ...props }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')
  const [users, setUsers] = useState<LoadedUser[]>([])
  const [keyword, setKeyword] = useState<string>('');

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const [fetchUsers, { loading }] = useFetchUserSearch({
    filters,
    onCompleted: (result: any) => {
      if (result) {
        setUsers(cloneDeep(result));
      }
    },
  });

  const handleSearch = useCallback((_keyword: string) => setKeyword(_keyword), [setKeyword]);

  useDebouncedEffect(() => {
    if (keyword && keyword !== '') fetchUsers({ variables: { keyword } });
  }, [ keyword, fetchUsers, setUsers ], 500);

  const _getUser = useCallback((value: any, key: string) => users.find((u: LoadedUser) => get(u, key) === value), [users]);

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value);
    if (onChange) onChange(getUser ? getUser(value) : _getUser(value, valueKey));
    setSelected(value);
    setKeyword('');
  }, [name, setSelected, formik, isFormik, onChange, getUser, valueKey, _getUser, setKeyword]);

  /**
   * Select Options constructor. Builds an array of options children from fetched user list.
   */
  const _getOptions = useCallback(() => users.map((user: any) => (
    <Option key={user.id} value={user.id} label={getValues(user, labelKeys)}>
      {showPicture && <Icon src={getThumbnail(user.profilePicUrl)} size="small" icon={<UserOutlined />} inverse />}
      {getValues(user, labelKeys)}
    </Option>
  )), [users, labelKeys, showPicture])

  const selectOptions = useMemo(() => users.length > 0 && (getOptions ? getOptions(users) : _getOptions()), [users, getOptions, _getOptions]);

  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <FormikSelect
        prefix={<TeamOutlined className="site-form-item-icon" />}
        name={name}
        onSearch={handleSearch}
        onChange={handleOnChange}
        showSearch
        placeholder={'Search users...'}
        disabled={disabled || formik.isSubmitting}
        loading={loading}
        filterOption={false}
        {...props}
      >
        {selectOptions}
      </FormikSelect>
    </FieldContainer>
  )

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<TeamOutlined className='site-form-item-icon' />}
      placeholder={'Search users...'}
      loading={loading}
      className={`${className} ant-user-selector`}
      onSearch={handleSearch}
      onSelect={handleOnChange}
      showSearch
      filterOption={false}
      disabled={disabled}
      {...props}
    >
      {selectOptions}
    </Select>
  )
}

export const UserSelector = styled(UserSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`
