import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { ColProps, Col } from 'antd/lib/grid'
import { useHistory } from 'react-router';
import { Button } from 'antd';
import { Doughnut } from 'react-chartjs-2'
import { WidgetCard } from './WidgetCard'
import { WidgetFooter } from './WidgetFooter'
import { useFindSportTheme } from '../../hooks'
import { Data, WidgetData, Breakdown } from './types'
import { useCurrentSport } from '../../redux';
import { useWidgetOrg } from './use-widget-data';

const Title = styled.h1`
    font-size: 35px;
    color: ${ p => p.theme.primaryColor };
`

const OrgTag = styled.div`
    font-size: 15px;
`

const TeamsCard = styled(WidgetCard)`
`

const TeamsFooter = styled(WidgetFooter)`
    padding: 10px;
    width: 90%;
    align-self: center;
`

const NoTeamData = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  h1 {
    color: ${p => p.theme.primaryColor} !important;  
  }
`;

interface Props {
    width?: number,
    gridProps?: ColProps,
    height?: number,
    data: WidgetData,
    pathOnView?: string,
    pathOnAdd?: string
}


const useTeamsData = (data: WidgetData) => {
    const findTheme = useFindSportTheme()
    const nonEmptyData = useMemo(() => data.breakdown.filter((sport: Breakdown) => sport.value > 0), [data])
    const _data = useMemo(() => {
        const labels = nonEmptyData.map((sport: Breakdown) => sport.name)
        const data = nonEmptyData.map((sport: Breakdown) => sport.value)
        const backgroundColor = nonEmptyData.map((sport: Breakdown) => findTheme({ name: sport.name })['@secondary-color'])
        return { 
            labels, 
            datasets: [{
                data,
                backgroundColor,
                hoverBackgroundColor: backgroundColor
            }]
        }
    }, [nonEmptyData])
    const hasData = useMemo(() => nonEmptyData && nonEmptyData.length > 0, [nonEmptyData]);

    return {
        nonEmptyData,
        data: _data,
        hasData
    }
}

export const TeamsWidget: React.FC<Props> = ({
    width = 350,
    height = 350,
    data: $data,
    gridProps,
    pathOnView,
    pathOnAdd,
    ...props
}) => {

    const findTheme = useFindSportTheme()
    const [{ name: sportName }] = useCurrentSport()

    const { name: orgName } = useWidgetOrg()

    const history = useHistory();

    const getColor = useCallback((d: Data) => {
        return findTheme({ name: d.name })['@secondary-color']
    }, [findTheme])

    const handleAdd = useCallback(() => history.push('/team/add'), [history]);

    const onView = useMemo(() => pathOnView ? () => history.push(pathOnView) : undefined, [history, pathOnView])
    const onAdd = useMemo(() => pathOnAdd ? () => history.push(pathOnAdd) : undefined, [history, pathOnAdd])

    const { data, hasData } = useTeamsData($data)


    return (
        <TeamsCard gridProps={gridProps}>
            <Title>Teams</Title>
            {hasData ?
                <Doughnut
                    width={150}
                    data={data}
                /> :
              <NoTeamData>
                  <h1>Team Breakdown</h1>
                  <p>No team data to show yet</p>
                  <Button onClick={handleAdd} type={'primary'}>Add Team</Button>
              </NoTeamData>
            }
            <TeamsFooter 
                hidden={!hasData} 
                org={<OrgTag>{orgName}</OrgTag>} 
                onView={onView} 
                onAdd={onAdd} 
            />
        </TeamsCard>
    )
}