import React, { FC } from 'react';
import { Button, Dropdown, Menu as AntMenu } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd'
import styled from 'styled-components';

const Menu = styled(AntMenu)`
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #F0F0F0;
`;

export const ColumnsMenu = ({ cols, handleOnColumnToggle }: any) => {

  return (
    <Menu>
      <Menu.ItemGroup title="Hide/Show Columns" >
        {
          cols.map((col: any) => (
            <Menu.Item key={col.key}>
              <Checkbox onChange={handleOnColumnToggle(col.key)} name={col.key} checked={col.show}>
                {col.title}
              </Checkbox>
            </Menu.Item>
          ))
        }
      </Menu.ItemGroup>
    </Menu>
  );
}

interface IColumnSelectProps {
  columns: any[];
  handleOnColumnToggle: (key: string) => void;
}

export const ColumnSelect: FC<IColumnSelectProps> = ({ columns, handleOnColumnToggle }) => {

  return <Dropdown
    overlay={
      <ColumnsMenu
        handleOnColumnToggle={handleOnColumnToggle}
        cols={columns}
      />
    }
  >
    <Button shape="circle" icon={<DatabaseOutlined />} />
  </Dropdown>
};

export default ColumnSelect;
