import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useCognitoUser } from '@bit/necta.hooks.cognito-user'
import { Space, Card as AntCard, Result, Button, Skeleton, Divider as Divide } from 'antd'
import { CardProps } from 'antd/lib/card'
import { PageHeaderProps } from 'antd/lib/page-header'
import { FilledOrgSelector, FilledSportSelector, FormCard as Card, PageHeader, ErrorBoundary } from '../../components'
import { Error } from '../error'
import { get, isEmpty } from 'lodash';
import { useCurrentOrg, useCurrentSport } from '../../redux/config';
import { LoadedOrg, LoadedSport } from '../../redux';
import NotFoundSvg from '../../assets/404.svg';
import NoAccessSvg from '../../assets/403.svg';
import { useAuthRedirect, useSelectors } from '../../hooks'
import { getIsAdmin, getActiveOrgId, canSelectOrgs as getCanSelectOrgs, getSelectedOrg } from '../../utils'

export * from './PageAction'

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Divider = styled(Divide)`
  width: 100%;
`;

export const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: ${ props => props.width || '100%' };

    transition: width 0.3s ease;
    padding-top: 30px;
    min-height: 100vh;
    padding-bottom: 30px;
`

const FullCard = styled(AntCard)`
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 2px;
  .ant-card-body {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .ant-card-body {
      padding: 10px;
    }
  }
`;

const SkeletonOuter = styled(Space)`
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;
`;

const Skelecard = styled(Skeleton.Button)`
  width: 200px;
  height: 200px;
  .ant-skeleton-button {
    width: 100%;
    height: 100%;
  }
`

//
// const Form = styled(AntForm)\`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding-bottom: 20px;
//
//     width: 70%;
//     background-color: white;
//     border-radius: 2px;
//     border: 1px solid lightgrey;
//     transition-property: box-shadow;
//     transition-duration: 10.2s;
//     transition-timing-function: linear;
//
//     :hover, &:focus-within {
//         box-shadow: 2px 2px 10px 1px lightgrey;
//     }
// \`
// `;

interface Props {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    back?: (e: any) => void;
    adornments?: React.ReactNodeArray;
    extra?: React.ReactNode;
    headerProps?: PageHeaderProps;
    style?: React.CSSProperties;
    className?: string
}

export const PageLayout: React.FC<Props> = ({ style, className = '', headerProps = {}, extra, adornments, back, title, subTitle, children, ...props }) => {

  return (
    <Card className={`${className} page-card`} width="95%">
      <PageHeader
        className='page-card-header'
        onBack={back}
        title={title}
        subTitle={subTitle}
        extra={
          extra || <Space>{adornments}</Space>
        }
        { ...headerProps }
      />

      <Divider />
      { children }
    </Card>
  )
}

export interface NotFoundProps {
  subTitle?: string;
  handleBack?: () => void;
  handleRefresh?: () => void;
  icon?: React.ReactNode;
}

export const NotFound: React.FC<NotFoundProps> = ({ subTitle, handleBack, handleRefresh, icon }) => {
  return (
    <Container>
      <Result
        title='404'
        subTitle={subTitle || `We couldn't find what you were looking for`}
        icon={icon || <img src={NotFoundSvg} alt={'Not found'}/>}
        extra={
          <>
            { handleBack && <Button onClick={handleBack}>Go Back</Button> }
            { handleRefresh && <Button type="primary" onClick={handleRefresh}>Refresh</Button> }
          </>
        }
      />
    </Container>
  );
}

interface AuthGuardProps extends NotFoundProps {
  checkForSport?: boolean,
  checkForOrg?: boolean,
  needsActiveOrg?: boolean,
  needsSelectOrgs?: boolean,
  pushTo?: string,
}

const selectors = {
  isAdmin: getIsAdmin,
  activeOrgId: getActiveOrgId,
  canSelectOrgs: getCanSelectOrgs,
  selectedOrg: getSelectedOrg
}

export const AuthGuard: React.FC<AuthGuardProps> = ({
  pushTo = '/',
  subTitle,
  handleBack,
  handleRefresh,
  icon,
  children,
  checkForSport,
  checkForOrg,
  needsActiveOrg = true,
  needsSelectOrgs,
  ...props
}) => {

  const [{ inSession }] = useCognitoUser()
  const AuthContext = useSelectors(selectors)
  const [currentSport] = useCurrentSport()

  const {
    isAdmin,
    activeOrgId,
    canSelectOrgs,
    selectedOrg
  } = AuthContext

  const history = useHistory()

  const predicate = useCallback(() => !inSession, [inSession])
  useAuthRedirect('/not-authorized', predicate)

  const back = useCallback(() => history.push(pushTo), [pushTo, history])
  const _handleBack = useMemo(() => handleBack || back, [handleBack, back])

  if (needsSelectOrgs && !canSelectOrgs)
    return <NotFound handleBack={_handleBack} subTitle={subTitle || 'You dont have the correct permissions to view this page'} />
  if (needsActiveOrg && !activeOrgId && !isAdmin)
    return <NotFound handleBack={_handleBack} subTitle={subTitle || 'You need an active organisation to view this page.'} />;
  if (checkForSport && !get(currentSport, 'id'))
    return <SelectSport subTitle={subTitle} handleBack={_handleBack} />
  if (checkForOrg && canSelectOrgs && !selectedOrg)
    return <SelectAnOrg subTitle={subTitle} handleBack={_handleBack} />

  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  )
}

interface SelectAnOrgProps {
  subTitle?: string;
  handleBack?: () => void;
  icon?: React.ReactNode;
}

export const SelectAnOrg: React.FC<SelectAnOrgProps> = ({ subTitle, handleBack, icon }) => {

  const [currentOrg, setCurrentOrg] = useCurrentOrg()

  const handleOnOrgSelect = useCallback((value: LoadedOrg) => setCurrentOrg(value), [setCurrentOrg]);

  // TODO: org selection for child org should set and update active org not select current org

  return (
    <Container>
      <Result
        title='Please select an organisation'
        subTitle={subTitle || <>You need to select an organisation to view, this can also be done from the top menu. For more info, see <a target='_blank' rel='noreferrer' href='https://necta.atlassian.net/servicedesk/customer/portal/3'>Help</a></>}
        icon={icon || <img src={NoAccessSvg} alt={'No organisation'}/>}
        extra={
          <Space>
            { handleBack && <Button onClick={handleBack}>Go Home</Button> }
            <FilledOrgSelector onChange={handleOnOrgSelect} defaultValue={get(currentOrg, 'id')} />
          </Space>
        }
      />
    </Container>
  );
}

export const SelectSport: React.FC<SelectAnOrgProps> = ({ subTitle, handleBack, icon }) => {

  const [currentSport, setCurrentSport] = useCurrentSport()

  const handleOnSportSelect = useCallback((value: LoadedSport) => setCurrentSport(value), [setCurrentSport]);

  return (
    <Container>
      <Result
        title='Please select a sport'
        subTitle={subTitle || <>You need to select a sport to view, this can also be done from the top menu. For more info, see <a target='_blank' rel='noreferrer' href='https://necta.atlassian.net/servicedesk/customer/portal/3'>Help</a></>}
        icon={icon || <img src={NoAccessSvg} alt={'No sport'}/>}
        extra={
          <Space>
            { handleBack && <Button onClick={handleBack}>Go Home</Button> }
            <FilledSportSelector onChange={handleOnSportSelect} defaultValue={get(currentSport, 'id')} />
          </Space>
        }
      />
    </Container>
  );
}

interface FormLoadingProps {
  children?: any;
}

export const FormLoading: React.FC<FormLoadingProps> = ({ children }: FormLoadingProps) => {
  return (
    <Page card>
      {children || <Skeleton active avatar paragraph={{ rows: 5 }}/>}
    </Page>
  );
}

interface CardsLoadingProps {
  children?: any;
  iterations?: number;
}
export const CardsLoading: React.FC<CardsLoadingProps> = ({ children, iterations = 3 }: CardsLoadingProps) => {
  return (
    <Page>
      {children || <SkeletonOuter>
        {
          Array.from(Array(iterations).keys()).map((i: number) =>
            <AntCard key={i}><Skelecard active /></AntCard>
          )
        }
      </SkeletonOuter>
      }
    </Page>
  );
}

interface PageProps {
  card?: boolean;
  notFound?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onBack?: (e: any) => void;
  adornments?: React.ReactNodeArray;
  extra?: React.ReactNode;
  headerProps?: PageHeaderProps;
  style?: React.CSSProperties;
  className?: string;
  sentry?: boolean;
  disableBoundary?: boolean;
  cardProps?: CardProps;
}

const ErrorStyle: React.CSSProperties = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

export const Page: React.FC<PageProps> = ({
  card = false,
  style,
  className = '',
  headerProps = {},
  cardProps = {},
  extra,
  onBack,
  title,
  subTitle,
  children,
  sentry = true,
  disableBoundary = false,
  ...props
}) => {
  const withHeader = useMemo(() => !isEmpty(headerProps) || extra || onBack || title || subTitle, [
    headerProps,
    extra,
    onBack,
    title,
    subTitle,
  ]);

  if (card) {
    return (
      <ErrorBoundary triggerOnCatch style={ErrorStyle} disabled={disableBoundary}>
        <Container>
          <FullCard {...cardProps}>
            {withHeader ? (
              <PageHeader title={title} onBack={onBack} subTitle={subTitle} extra={extra} {...headerProps} />
            ) : null}
            {children}
          </FullCard>
        </Container>
      </ErrorBoundary>
    );
  }

  return (
      <ErrorBoundary triggerOnCatch style={ErrorStyle} disabled={disableBoundary}>
        <Container>{children}</Container>
      </ErrorBoundary>
  );
};

export default Page;
