import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Input,
  OrgSelector,
  SportSelector,
  TextArea,
  AuthGuard,
  PageHeader,
  CardWrapper
} from '../../components'
import { canSelectOrgs, getIsAdmin, getSelectedOrg, getSelectedSport } from '../../utils';
import { useFormData } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import {  message, Card as AntCard } from 'antd'
import { get, cloneDeep } from 'lodash';
import { ADD_TEAMSHEET } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';
import { NewTeamsheet, UpdateTeamsheet, newTeamsheetSchema } from './schema'

const Card = styled(AntCard)`
    width: 90%;
`

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  selectedOrgId: useSelector(getSelectedOrg),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedSport: useSelector(getSelectedSport)
})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

interface NewTeamSheetProps {
    fixtureId?: string,
    organisationId?: string,
    sportId?: string,
    onSubmitted?: (values: CreatedTeamsheet) => void,
    after?: React.ReactNode,
    onBack?: () => void,
    card?: boolean,
    buttonPos?: 'top' | 'bottom'
}

export type CreatedTeamsheet = UpdateTeamsheet & { id: string, fixtureId: string }

export const NewTeamSheet: React.FC<NewTeamSheetProps> = ({
    fixtureId,
    onSubmitted,
    organisationId,
    sportId,
    after,
    onBack,
    card,
    buttonPos = 'bottom',
    ...props
}) => {

  const history = useHistory()
  const [created, setCreated] = useState<NewTeamsheet | null>(null)

  const { isAdmin, selectedOrgId, canSelectOrgs, selectedSport } = useSelectors()

  const [handleAdd] = useMutation(ADD_TEAMSHEET)

  const { fields, initialValues, ...formikCTX } = useFormData(newTeamsheetSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating Teamsheet...', duration: 10 });
      const result = await handleAdd({ variables: { newTeamsheet: { ...values } } })
      const { fixtureId, organisationId, name, description } = values
      if (result) {
        const id = result.data.addTeamsheet.id
          if (onSubmitted) onSubmitted({
              ...cloneDeep(result.data.addTeamsheet)
          })
          actions.resetForm()
      }
      return result;
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Teamsheet created!', duration: 2 });
      const teamId = get(result, 'data.addTeamsheet.id');
      if (teamId) history.push('/team/' + teamId);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create team'), duration: 4 });
    }
  })

  const [teamsheet, setTeamsheet] = useState({
      ...initialValues,
      fixtureId,
      organisationId: get(selectedOrgId, 'id')
    });

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (!created) return (
    <AuthGuard>
      <Formik { ...formikCTX} initialValues={teamsheet} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values, touched, errors }) => (
          <Card>
            <PageHeader
              title='Add Teamsheet'
              onBack={onBack}
              extra={[
                <FormButton hidden={buttonPos === 'bottom'}>
                  ADD
                </FormButton>
              ]}
            />
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <Input {...fields.name} />
                <OrgSelector hidden={organisationId} disabled={!canSelectOrgs} {...fields.organisationId} />
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              { buttonPos === 'bottom' && <FormButton>Add</FormButton> }

            </Form>
          </Card>
        )}
      </Formik>
    </AuthGuard>
  )

  return (
    <CardWrapper card={card}>
      { after }
      { !after && <>
        <PageHeader title='Add Teamsheet' onBack={handleBack} />
        <h1>{created.name}</h1>
        <h3>populate a the teamsheet below!</h3>
      </>}
    </CardWrapper>
  )
}

export default NewTeamSheet
