import { useMutation } from "@apollo/client";
import { Col, message, Tabs } from "antd";
import { Formik, FormikValues } from "formik";
import { Form } from "formik-antd";
import React, { FC, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormButton, FormRow, Input, Select } from "../../components";
import FileUploader from "../../components/file-uploader";
import { AuthGuard, FormLoading, Page } from "../../components/page";
import {
  UPDATE_THIRD_PARTY_PROVIDER_CONTACT,
  useFetchThirdPartyProviderContact
} from "../../graphql";
import { cleanError } from "../../helpers/error-helper";
import { useFormData } from "../../hooks/use-form-data";

import { mapArrayToObject } from "../../utils";
import { ThirdPartyProviderContactSchema } from "./schema";

const { TabPane } = Tabs;

export const providerType = mapArrayToObject([
  "COMMENTATOR",
  "SCORER",
  "CAMERAMAN",
]);

export const ViewEditThirdPartyProviderContact: FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const [thirdPartyProviderContact, setThirdPartyProviderContact] =
    useState<any>(null);
  const [, { loading }] = useFetchThirdPartyProviderContact({
    variables: { id },
    onCompleted: (result: any) => {
      const fetchedThirdPartyProviderContact = {
        ...result.thirdPartyProviderContact,
      };
      setThirdPartyProviderContact({ ...fetchedThirdPartyProviderContact });
    },
    onError: () => {
      //console.log(error)
    },
  });

  const [handleUpdate] = useMutation(UPDATE_THIRD_PARTY_PROVIDER_CONTACT);

  const { fields, initialValues, ...formikCTX } = useFormData(
    ThirdPartyProviderContactSchema,
    {
      onSubmit: async (values: FormikValues) => {
        message.loading({ content: "Loading...", duration: 10 });
        const updateThirdPartyProviderContactData = values;
        return handleUpdate({
          variables: {
            updateThirdPartyProviderContactData,
          },
        });
      },
      onCompleted: () => {
        message.success({
          content: "Third Party Provider Contact updated!",
          duration: 2,
        });
      },
      onError: (e: any) => {
        message.error({
          content: cleanError(
            e,
            "Unable to update Third Party Provider Contact"
          ),
          duration: 4,
        });
        // presentError(e)
      },
    }
  );

  const handleBack = useCallback(() => history.goBack(), [history]);

  if (loading) return <FormLoading />;

  return (
    <AuthGuard needsActiveOrg>
      <Formik
        {...formikCTX}
        initialValues={thirdPartyProviderContact}
        enableReinitialize
        validateOnBlur
      >
        {() => (
          <Page
            title="Add Third Party Provider Contact"
            onBack={handleBack}
            card
          >
            <Form layout={"vertical"}>
              <Tabs
                defaultActiveKey={"Player Info"}
                tabPosition={"top"}
                size={"large"}
              >
                <TabPane tab={"Primary Info"} key={"Primary Info"}>
                  <FormRow gutter={6}>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.firstname} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.lastname} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.dateOfBirth} type="date" />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.email} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.phone} />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Select
                        {...fields.type}
                        options={providerType}
                        defaultValue={
                          thirdPartyProviderContact &&
                          thirdPartyProviderContact.type
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <Input {...fields.notes} />
                    </Col>
                  </FormRow>
                  <FormButton>UPDATE</FormButton>
                </TabPane>
                <TabPane tab={"Documents"} key={"Documents"}>
                  <FileUploader
                    id={"IMAGES"}
                    parentType={"organisation"}
                    fileType={"IMAGE"}
                    initialFiles={[]}
                  />
                  <FormButton>UPDATE</FormButton>
                </TabPane>
              </Tabs>
            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  );
};

ViewEditThirdPartyProviderContact.defaultProps = {};

export default ViewEditThirdPartyProviderContact;
