import { get } from 'lodash';
import { LoadedSport, Override } from '../redux';

export const getIsSameSession = (state: any) => {
  const id = get(state, 'cognitoUser.username') || null
  const username = get(state, 'currentUser.id')
  return id === username
}

export const getIsCurrentOrgOwner = (state: any): any => {
  const owner = get(state, 'currentUser.activeOrg.ownedBy') || null;
  const id = get(state, 'cognitoUser.username') || null;
  if (!owner) return false;
  return id === owner;
};

export const getIsAdmin = (state: any): any => {
  const permissions = get(state, 'currentUser.role') || null;
  return permissions === 'Admin';
};

export const getIsOrg = (state: any): any => {
  const permissions = get(state, 'currentUser.role') || null;
  const isOrg = permissions === 'Organisation';
  if (!isOrg) return false;
  const active = get(state, 'currentUser.activeOrg.id') || null;
  if (!active) return false;
  return true;
};

export const getActiveOrg = (state: any): any => {
  const permissions = get(state, 'currentUser.role') || {};
  const isOrg = permissions === 'Organisation';
  if (!isOrg) return null;
  return get(state, 'currentUser.activeOrg') || {};
};

export const getActiveOrgId = (state: any): any => {
  const permissions = get(state, 'currentUser.role') || null;
  const isOrg = permissions === 'Organisation';
  if (!isOrg) return null;
  return get(state, 'currentUser.activeOrg.id') || null;
};


export const getSelectedOrg = (state: any) => {
  const isOrg = getIsOrg(state);
  const selectedOrg = get(state, 'config.currentOrg', null);
  if (isOrg)  {
    const activeOrg = get(state, 'currentUser.activeOrg', null);
    if (activeOrg.childOrgs && activeOrg.childOrgs.length > 0) {
      return selectedOrg;
    }
    return activeOrg || null;
  }
  const isAdmin = getIsAdmin(state);
  if (isAdmin) {
    return selectedOrg || null;
  }
  return null;
}

export const getParentOrg = (state: any) => {
  const isOrg = getIsOrg(state);
  if (!isOrg) return null;
  const isParent = get(state, 'currentUser.activeOrg.childOrgs.length', 0) > 0;
  if (isParent) return get(state, 'currentUser.activeOrg', null);
  return get(state, 'currentUser.activeOrg.parentOrg', null);
}

export const canSelectOrgs = (state: any): boolean => {
  const isAdmin = getIsAdmin(state)
  if (isAdmin) return true;
  const isOrg = getIsOrg(state);
  if (isOrg)  {
    const activeOrg = get(state, 'currentUser.activeOrg', {});
    if (activeOrg.childOrgs && activeOrg.childOrgs.length > 0) {
      return true;
    }
  }
  return false;
}

export const getSelectedSport = (state: any): LoadedSport | null => {
  return get(state, 'config.currentSport');
}

export const getCurrentUser = (state: any): any => {
  return get(state, 'currentUser');
}

export const getCurrentUserRole = (state: any): any => {
  return get(state, 'currentUser.role');
}


export const getFieldOverrides = (state: any, fieldKey: string, module: string): Override | null => {
  const overrides = get(state, 'currentUser.activeSupplier.formOverrides', null);
  if (overrides == null || overrides.length < 0) return null;
  return overrides.find((o: any) => o.field === fieldKey && o.module === module);
}

export const getFormOverrides = (state: any, module?: string): Override[] => {
  if (module == null || module == '') return [];
  const overrides = get(state, 'currentUser.activeSupplier.formOverrides', null);
  return overrides ? overrides.filter((o: any) => o.module === module) : [];
}

export const getTeamRoleOverrides = (state: any, module?: string) => {

  const currentSport = get(state, 'config.currentSport')
  const overrides = get(state, 'config.roleOverrides')

  const $module = module ?? currentSport.name;

  return overrides.filter((override: Override) => override.module === $module);

}


export default {
  getIsCurrentOrgOwner,
  getIsAdmin,
  getIsOrg,
  getActiveOrgId
}

export const Selectors = {
  isAdmin: getIsAdmin,
  isOrg: getIsOrg,
  activeOrgId: getActiveOrgId,
  activeOrg: getActiveOrg,
  isCurrentOrgOwner: getIsCurrentOrgOwner,
  selectedOrg: getSelectedOrg,
  canSelectOrgs,
  selectedSport: getSelectedSport,
  currentUser: getCurrentUser,
  currentUserRole: getCurrentUserRole,
  isSameSession: getIsSameSession,
  getParentOrg,
}
