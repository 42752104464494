import React from 'react'
import { Fixture } from '../../redux'
import styled from 'styled-components'

interface CellTipProps {
    fixtures: Fixture[]
}

const Content = styled.div`
    min-width: 150px;
    max-height: 250px;
    min-height: 60px;
    overflow-y: scroll;
    font-size: 12px;
`

export const CellTip: React.FC<CellTipProps> = ({ fixtures, ...props }) => {
    return (
        <Content>
            <div>
                { fixtures.map((fixture: any) => (
                    <div key={fixture.event}>- { fixture.name }</div>
                )) }
            </div>
        </Content>
    )
}
