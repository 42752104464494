import { useMemo } from 'react'
import { useSelector } from "react-redux"
import { useCurrentOrg } from "../redux"
import { getActiveOrg, getIsAdmin } from "../utils"

export const useIsParentOrAdmin = () => {
    const isAdmin = useSelector(getIsAdmin)
    const activeOrg = useSelector(getActiveOrg)
    const isParent = useMemo(() => activeOrg && activeOrg.childOrgs && activeOrg.childOrgs.length > 0, [activeOrg])

    return useMemo(() => isAdmin || isParent, [isAdmin, isParent])
}