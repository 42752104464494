import { Yup } from '../../hooks';

export const teamSchema = Yup.object().shape({
  name: Yup
    .string()
    .required()
    .label('Team name'),
  description: Yup
    .string()
    .nullable()
    .label('Description'),
  primaryImage: Yup
    .string()
    .nullable()
    .label('Primary image'),
  captainId: Yup
    .string()
    .nullable()
    .label('Captain'),
  headCoachId: Yup
    .string()
    .nullable()
    .label('Head Coach'),
  ageGroup: Yup
    .string()
    .nullable()
    .label('Age Group')
})

export const teamMemberSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .required()
    .label('First name'),
  lastname: Yup
    .string()
    .required()
    .label('Last name'),
  nickname: Yup
    .string()
    .nullable()
    .label('Nickname'),
  description: Yup
    .string()
    .nullable()
    .label('Description'),
  primaryImage: Yup
    .string()
    .nullable()
    .label('Primary image'),
  secondaryImage: Yup
    .string()
    .nullable()
    .label('Secondary image'),
  isCaptain: Yup
    .boolean()
    .default(false)
    .label(''),
  isHeadCoach: Yup
    .boolean()
    .default(false)
    .label(''),
  teamRoleId: Yup
    .string()
    .nullable()
    .label('Primary Role'),
  teamRoleAltId: Yup
    .string()
    .nullable()
    .label('Secondary Role'),
  tags: Yup.array()
    .nullable()
    .label('Additional Roles'),
})
