import React, { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup'
import { useHistory } from 'react-router'
import { WidgetCard } from './WidgetCard'
import { WidgetFooter } from './WidgetFooter'
import { useCurrentSport } from '../../redux'
import { Data, WidgetData } from './types'
import { LoadedSport } from '../../redux'
import { ColProps } from 'antd/lib/grid'
import { useWidgetOrg } from './use-widget-data'
import { FilledSportSelector } from '../sport-selector'
import { useFindSportTheme } from '../../hooks'

const SportSelector = styled(FilledSportSelector)`
  margin: 0;
  padding: 0;
  min-width: 70px;
  width: 100%;
  .ant-select-selector {
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent !important;
    padding: 0 !important;
    border: 1px solid ${ p => p.theme.primaryColor } !important;
  }
  .ant-select-selector, input {
    min-width: 50px !important;
  }
  .ant-select-selection-placeholder, .ant-select-selection-item {
    color: ${ p => p.theme.primaryColor } !important;
  }
  .ant-select-clear {
    background: transparent;
  }
`

const Title = styled.span`

`

const OrgTag = styled.div`
  font-size: 15px;
`

const CountCard = styled(WidgetCard)`
    ${Title} {
      font-size: 35px;
      color: ${ p => p.theme.primaryColor };
    }
`

export interface CountWidgetProps {
  duration?: number,
  count?: number,
  data: WidgetData,
  gridProps?: ColProps,
  pathOnView?: string,
  pathOnAdd?: string,
  name: string
}

export const CountWidget: React.FC<CountWidgetProps> = ({ 
  duration = 3,
  data,
  pathOnView,
  pathOnAdd,
  name,
  gridProps,
  ...props
}) => {

  //the currentSport selected in redux config slice
  const [currentSport] = useCurrentSport()
  const [selectedSport, setSelectedSport] = useState(currentSport)
  const findTheme = useFindSportTheme()
  const history = useHistory()
  //callback handlers for viewing and adding actions
  const onView = useMemo(() => pathOnView ? () => history.push(pathOnView) : undefined, [history, pathOnView])
  const onAdd = useMemo(() => () => pathOnAdd ? history.push(pathOnAdd) : undefined, [history, pathOnAdd])
  //fetches the Org object (either currentOrg or activeOrg)
  const { name: orgName } = useWidgetOrg()
  //determines the secondary theme color of the currently selected theme
  const color = useMemo(() => findTheme({ id: selectedSport.id })['@secondary-color'], [selectedSport, findTheme])

  const filterOptions = useCallback((sports: any[]) => sports.map((sport: any) => {
    if (sport.name === 'PlayPro') return { ...sport, name: 'All Sports' }
    return sport
  }), [])

  const handleOnSelect = (sport: LoadedSport) => {
    setSelectedSport(sport)
  }

  //determines the value of count based on the selected sport, accounting for 
  //all edge cases
  const count = useMemo(() => {
    if (!data) return 0
    if (!selectedSport) return 0
    if (selectedSport.name === 'PlayPro') return data.total
    const sport = data.breakdown.find((sport: Data) => sport.name === selectedSport.name)
    if (sport) return sport.value
    return 0
  }, [selectedSport, data])

  //determines if the counter component should be used
  const withCount = useMemo(() => count > 15, [count])

  return (
      <CountCard gridProps={gridProps}>
          <Title id='title'>{name}</Title>
          { withCount ? <CountUp 
              duration={duration}
              end={count}
              useEasing={false}
              redraw
            /> : <span>{count}</span>
          }
          <WidgetFooter 
            sport={<SportSelector 
              showPicture={false}
              background={color} 
              onChange={handleOnSelect} 
              defaultValue={currentSport.id}
              allowClear={false}
              showSearch
              filterOptions={filterOptions}
            />}
            org={<OrgTag>{orgName}</OrgTag>} 
            onView={onView} 
            onAdd={onAdd} 
          />
      </CountCard>
  )
}

export default CountWidget