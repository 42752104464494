import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthGuard, Page } from '../../components'
import { PendingFixtureTable } from './PendingFixtureTable'

interface PendingfFixturesProps {

}

export const PendingFixtures: React.FC<PendingfFixturesProps> = ({ ...props }) => {

    const history = useHistory()

    const handleBack = useCallback(() => {
        history.goBack()
    }, [history])

    return (
        <AuthGuard needsActiveOrg>
            <Page
                title='Pending Fixture Invites'
                onBack={handleBack}
                card
            >
                <PendingFixtureTable title={false} />
            </Page>
        </AuthGuard>
    )
}
