import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Table,
} from '../../components';
import moment, { Moment } from 'moment'
import styled from 'styled-components';
import { useCurrentOrg } from '../../redux/config';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { PictureOutlined } from '@ant-design/icons';

// const statuses = mapArrayToOptions(EVENT_STATUSES);

interface EventTableProps {
  events: any[],
  loading?: boolean,
  onRefresh?: () => void,
  withUpdate?: boolean
}

export const EventRequestTable: React.FC<EventTableProps> = ({
  events,
  loading,
  onRefresh,
  withUpdate,
  ...props
}) => {

  const history = useHistory();
  const [selectedOrg] = useCurrentOrg()

  const columns = useMemo(() => {
    return [
      
      {
        title: 'Event',
        export: true,
        key: 'name',
        filtering: true,
        sorting: true,
      },
      {
        title: 'Venue',
        export: true,
        key: 'venue',
        filtering: true,
        sorting: true,
      },
      // {
      //   title: 'Status',
      //   key: 'status',
      //   hidden: true,
      //   filtering: {
      //     filters: statuses,
      //     filterMultiple: true,
      //     type: Filter.SELECT
      //   },
      //   sorting: true,
      //   getValues: (r: string) => r,
      //   render: (r: string) => <EventStatus status={r} />,
      //   export: true
      // },
    
     
      {
        title: 'Broadcasting Request',
        export: true,
        key: 'broadcastRequest',
        hidden: false,
        filtering: true,
        sorting: true,
      },
    ];
  }, []);

  const extra = useMemo(() => selectedOrg?.id ? `- ${selectedOrg?.name}` : '', [selectedOrg]);

  return (
    <Table
      title={`Broadcast Requests ${extra}`}
      data={events}
      columns={columns}
      onRow={(record: any, rowIndex: any) => ({
        onClick: () => history.push(`/event/${record.id}`)
      })}
      refresh={onRefresh}
      exportTitle={'Teams'}
      loading={loading}
    />
  );

};

export default EventRequestTable;
