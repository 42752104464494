import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from './actions'
import { ConfigState, LoadedEvent, LoadedOrg, LoadedSponsor, LoadedSport } from './types'
import { State } from '../types'
import { get as lodashGet } from 'lodash';

/**
 * returns a setConfig hook
 */
export const useSetConfig = () => {
    const dispatch = useDispatch()
    const setConfig = useCallback((config: ConfigState) => Actions.setConfig(config), [dispatch])
    return setConfig
}

/**
 * returns a the config slice ([config, setConfig]), and setConfig hook
 */
export const useConfig = () => {
    const selector = useCallback((state: State) => state.config, [])
    const config = useSelector(selector)
    const setConfig = useSetConfig()
    return [config, setConfig] as const
}

/**
 * returns setCurrentSport hook
 */
export const useSetCurrentSport = () => {
    const dispatch = useDispatch()
    const setCurrentSport = useCallback((sport: LoadedSport) => {
        dispatch(Actions.setCurrentSport(sport))
    }, [dispatch])
    return setCurrentSport
}

/**
 * returns current sport config (loaded sports config) and setCurrentSport hook.
 * sport defaults to empty object when respective sport config is undefined.
 */
export const useCurrentSport = () => {
    // Temporary fix, these need to be fetched with selectors to avoid errors when the keys do not exist
    const selector = useCallback((state: State) => ({
        currentSport: lodashGet(state, 'config.currentSport', null),
        sports: lodashGet(state, 'config.sports', []),
    }), [])
    const { currentSport, sports } = useSelector(selector)
    const setCurrentSport = useSetCurrentSport()
    const sport = useMemo(() => currentSport || {}, [currentSport]);

    return [sport, setCurrentSport] as const
}

/**
 * returns a hook to set the loaded sport configs.
 */
export const useSetSports = () => {
    const dispatch = useDispatch()
    const setSports = useCallback((sports: LoadedSport[]) => dispatch(Actions.setSports(sports)), [dispatch])
    return setSports
}

/**
 * returns array of loaded sport configs and an object of setter functions.
 */
export const useSports = () => {
    const selector = useCallback((state: State) => lodashGet(state, 'config.sports', []), [])
    const sports = useSelector(selector)
    const setSports = useSetSports()

    /**
     * Takes a sport id as string and returns the respective sport config with matching id.
     * Returns an empty object if no config matches the id.
     */
    const getSport = useCallback((sportId: string) => sports.find((sport: LoadedSport) => sport.id === sportId) || {}, [sports])

    /**
     * Takes a predicate function and returns the first sport config to match the predicate.
     */
    const get = useCallback((predicate: (sport: LoadedSport) => boolean) => sports.find(predicate), [sports])

    const helpers = useMemo(() => ({
        setSports,
        getSport,
        get
     }), [setSports, getSport, get])

    return [sports, helpers] as const
}

export const useSetSponsors = () => {
    const dispatch = useDispatch()
    const setSponsors = useCallback((sponsors: LoadedSponsor[]) => dispatch(Actions.setSponsors(sponsors)), [dispatch])
    return setSponsors
}

/**
 * returns an array where the first index is an array of Loaded Sponsor configs, and the second is an object containing helper callbacks.
 */
export const useSponsors = () => {
    const selector = useCallback((state: State) => lodashGet(state, 'config.sponsors', []), [])
    const sponsors = useSelector(selector)
    const setSponsors = useSetSponsors()

    /**
     * Takes a sponsor id as string and returns the respective sponsor config with matching id.
     * Returns an empty object if no conifig matches.
     */
    const getSponsor = useCallback((sponsorId: string) => sponsors.find((sponsor: LoadedSponsor) => sponsor.id === sponsorId) || {}, [sponsors])

    /**
     * Takes a predicate function and returns the first sponsor config to match the predicate.
     */
    const get = useCallback((predicate: (sponsor: LoadedSponsor) => boolean) => sponsors.find(predicate), [sponsors])

    const helpers = useMemo(() => ({
        setSponsors,
        getSponsor,
        get
    }), [get, getSponsor, setSponsors])

    return [sponsors, helpers] as const
}



export const useSetEvents = () => {
    const dispatch = useDispatch()
    const setEvents = useCallback((events: LoadedEvent[]) => dispatch(Actions.setEvents(events)), [dispatch])
    return setEvents
}

/**
 * returns an array where the first index is an array of Loaded Events, and the second is an object containing helper callbacks.
 */
export const useEvents = () => {
    const selector = useCallback((state: State) => lodashGet(state, 'config.events', []), [])
    const events = useSelector(selector)
    const setEvents = useSetEvents()

    /**
     * Takes a event id as string and returns the respective event config with matching id.
     * Returns an empty object if no conifig matches.
     */
    const getEvent = useCallback((eventId: string) => events.find((event: LoadedEvent) => event.id === eventId) || {}, [events])

    /**
     * Takes a predicate function and returns the first event config to match the predicate.
     */
    const get = useCallback((predicate: (event: LoadedEvent) => boolean) => events.find(predicate), [events])

    const helpers = useMemo(() => ({
        setEvents,
        getEvent,
        get
    }), [get, getEvent, setEvents])

    return [events, helpers] as const
}

/**
 * returns a global current org handler.
 */
export const useSetCurrentOrg = () => {
    const dispatch = useDispatch()
    const setCurrentOrg = useCallback((org: LoadedOrg) => dispatch(Actions.setCurrentOrg(org)), [dispatch])
    return setCurrentOrg
}

/**
 * returns the global currentOrg state slice and a handler for said slice.
 * [currentOrg: string, (org: LoadedOrg) => void]
 */
export const useCurrentOrg = () => {
    const currentOrg = useSelector((state: State) => lodashGet(state, 'config.currentOrg', null))
    const org = useMemo(() => currentOrg || {}, [currentOrg])
    const setCurrentOrg = useSetCurrentOrg()
    return [org, setCurrentOrg]
}

export const useSponsorSuggestions = () => {

    const [sponsorList] = useSponsors()

    return useMemo(() => {
        return sponsorList ? sponsorList.map((s: any) => Object.assign({ id: s.name, name: s.name })) : [];
    }, [sponsorList])
}
