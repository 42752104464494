import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { message, Button } from 'antd'
import { ADD_TEAMSHEET } from '../../graphql'
import { ButtonLink } from './ButtonLink'


export interface LinkProps {
    id: string | undefined,
    fixtureId: string,
    hidden?: boolean,
    orgId?: string,
    align?: 'left' | 'right'
}

export const TeamLink: React.FC<LinkProps> = ({ 
    hidden, 
    id, 
    fixtureId, 
    orgId,
    align = 'left',
    ...props 
}) => {

    const history = useHistory()

    const [handleCreate, { loading }] = useMutation(ADD_TEAMSHEET, {
        onError: (error: any) => {
            message.error({ content: 'Could not create Teamsheet! please use the form below.'})
            history.push(`/fixture/teamsheet/add/${fixtureId}`)
        }
    })
    
    const getPath = useCallback((teamsheetId: string, orgId?: string) => {
        if (orgId) return `/fixture/teamsheet/${teamsheetId}/members/add/`
        return `/fixture/teamsheet/${teamsheetId}/members/import`
    }, [])

    const handleClick = useCallback(async () => {
        const result = await handleCreate({
            variables: {
                newTeamsheet: {
                    name: '',
                    fixtureId,
                    organisationId: orgId ?? undefined
                }
            },
        })
        if (result && result.data) {
            history.push(getPath(result.data.addTeamsheet.id, orgId))
        }
    }, [handleCreate, history, orgId, fixtureId, getPath])

    const handleView = useCallback(() => {
        history.push(`/fixture/teamsheet/${id}`)
    }, [history, id])

    if (hidden) return null
    if (!id) return (
        <ButtonLink align={align} type='text' loading={loading} onClick={handleClick}>
            Add Teamsheet
        </ButtonLink>
    )
    return (
        <ButtonLink align={align} type='text' onClick={handleView}>
            View Team List
        </ButtonLink>
    )
}

export default TeamLink