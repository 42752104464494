import { useCallback, useEffect, useState } from 'react';
import { sortByDate } from '../../helpers/sort-helper';
import { useFileSubscription } from './use-file-subscription';
import { cloneDeep } from '@apollo/client/utilities';

export const useFiles = (
  id: string,
  fileType?: 'IMAGE' | 'DOCUMENT' | null,
  initialFiles?: any[],
  urlParams?: string,
  options: {
    onAdded?: (result: any) => void;
    onDeleted?: (result: any) => void;
  } = {},
) => {
  const [fileList, setFileList] = useState<any[]>([]);

  const updateFile = useCallback(async (id: string, newValues: any) => {
    try {
      setFileList(_fileList => {
        const foundFile = _fileList.find((f: any) => f.uid === id);
        Object.keys(newValues).map((k: string) => foundFile[k] = newValues[k]);
        return [..._fileList];
      })
    } catch (ex) {
      console.log(ex)
    }
  }, [setFileList])

  const addFile = useCallback((values: any) => {
    setFileList(_fileList => _fileList.concat(values));
  }, [setFileList]);

  const removeFile = useCallback((id: string) => {
    setFileList(_fileList => [..._fileList.filter((f: any) => f.uid !== id)]);
  }, [setFileList]);

  useFileSubscription(id, fileType, initialFiles, {
    onAdded: (fileAdded: any) => {
      const id = fileAdded.id;
      const url = fileAdded.signedUrl ? fileAdded.signedUrl : `${process.env.REACT_APP_S3_HOST}${fileAdded.key}${urlParams || ''}`;
      updateFile(fileAdded.key, { status: 'done', id, url });
      if (options?.onAdded) options.onAdded(fileAdded);
    },
    onDeleted: (fileDeleted: any) => {
      removeFile(fileDeleted.key);
      if (options?.onDeleted) options.onDeleted(fileDeleted);
    }
  });

  useEffect(() => {
    if (initialFiles?.length) {
      const orderedFiles = [...sortByDate(initialFiles)];
      const transformedFiles = orderedFiles.map((file: any) => Object.assign({
        ...file,
        uid: file.key,
        id: file.id,
        name: file.name,
        status: 'done',
        url: file.signedUrl ? file.signedUrl : `${process.env.REACT_APP_S3_HOST}${file.key}${urlParams || ''}`,
      }));
      setFileList(() => cloneDeep(transformedFiles));
    }
  }, [initialFiles, setFileList, urlParams]);

  return [fileList, { updateFile, addFile, removeFile }] as const;
};
