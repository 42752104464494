import * as Yup from 'yup'

export const addressSchema = Yup.object().shape({
    addressLine1: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Address Line 1'),
    state: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Province'),
    country: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Country'),
    postalCode: Yup
      .string()
      .nullable()
      .notRequired()
      .label('Postal Code')
  }).nullable().notRequired().label('Search for address')