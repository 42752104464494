import React, { useMemo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select as FormikSelect, SelectProps as FormikSelectProps } from 'formik-antd'
import Select, { SelectProps } from 'antd/lib/select'
import { ColProps } from 'antd/lib/grid'
import { useFormikContext } from 'formik'
import { TeamOutlined } from '@ant-design/icons'
import { FieldContainer } from '../FieldContainer'
import { useSponsors, LoadedSponsor } from '../../redux'
import { get, isEmpty } from 'lodash'
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Avatar } from '../Avatar';

const Icon = styled(Avatar)`
  margin-right: 5px;
  margin-left: 2px;
  &&.ant-avatar {
    width: 22px;
    height: 22px;
  }
`;

type Sponsor = LoadedSponsor

const { Option } = Select

interface SponsorSelectorProps extends React.HTMLAttributes<HTMLElement>{
  disabled?: boolean,
  label?: string,
  required?: boolean,
  hidden?: boolean,
  gridProps?: ColProps,
  defaultValue?: any,
  name?: string,
  onChange?: (value: any) => void,
  loading?: boolean
  //TODO: define these properly
  valueKey?: keyof Sponsor,
  labelKey?: keyof Sponsor,
  getOptions?: (orgs: Partial<Sponsor>[]) => React.ReactNode[],
  showPicture?: boolean;
  filterDefault?: boolean; // Filter out the default sponsor from options
  //TODO: update this to more sophisticated prop
  getFormik?: boolean,
  allowClear?: boolean
}

//TODO: split the formik and select instances and handle conditional renders in a container

/*
 * Select component for selecting a sponsor from a currently loaded list. Can be formik coupled if a formik context
 * exists and name, label and required props are provided. If valueKey and labelKey provided, the options list will use these to
 * render Option.value and Option.label respectively where valueKey and labelKey are any keys on the LoadedSponsor type.
 */
export const SponsorSelectorComponent: React.FC<SponsorSelectorProps & (FormikSelectProps | SelectProps<string>)> = (
  { getFormik, getOptions, labelKey = 'name', valueKey = 'id', name = '', onChange, disabled, label, required, hidden, gridProps, className, defaultValue, loading, showPicture = true, filterDefault = true, ...props }
) => {

  //the current selected value state if no formik context
  const [selected, setSelected] = useState<string>('')

  // fetch currently loaded sponsor configs
  const [sponsorList] = useSponsors()

  const formik = useFormikContext() || {}

  const isFormik = useMemo(() => !isEmpty(formik) && getFormik, [formik, getFormik])

  const getSponsor = useCallback((key: string | number | symbol, value: any) => sponsorList.find((sponsor: Sponsor) => get(sponsor, key, null) === value), [sponsorList])

  /**
   * onChange handler to select component.
   * defaults to local state if formik context is empty.
   * calls optional onChange prop to parent component.
   */
  const handleOnChange = useCallback((value: any) => {
    if (isFormik) formik.setFieldValue(name, value)
    if (onChange) onChange(getSponsor(valueKey, value))
    setSelected(value)
  }, [getSponsor, valueKey, name, formik, onChange, isFormik])

  /**
   * Select Options constructor. Builds an array of options children from loaded sponsor list.
   */
  const _getOptions = useCallback(() => sponsorList.map((sponsor: Sponsor) => {
    if (!filterDefault || (sponsor.id && sponsor.id !== '')) {
      return (
        <Option key={sponsor.id} value={sponsor[valueKey] as string} label={sponsor[labelKey]}>
          {showPicture && <Icon src={getThumbnail(sponsor.primaryImage || '')} size="small" inverse />}
          {sponsor[labelKey]}
        </Option>
      )
    }
    return null
  }), [sponsorList, filterDefault, valueKey, labelKey, showPicture])

  useEffect(() => {
    if (sponsorList.length > 0) setSelected(defaultValue)
    if (sponsorList.length === 0) setSelected('None')
  }, [])

  //potential for useEffect callbacks dependent on props? (manual refetch triggers etc)


  //if formik context is not empty, render with Formik FieldContainer
  if (isFormik) return (
    <FieldContainer
      { ...gridProps }
      name={name}
      required={required}
      label={label}
      className={`${className} ant-field-container`}
      hidden={hidden}
    >
      <FormikSelect
        prefix={<TeamOutlined className="site-form-item-icon" />}
        name={name}
        onChange={handleOnChange}
        disabled={disabled || (loading && sponsorList.length <= 0)}
        {...props}
      >
        { /* call option getOptions prop to fetch children. default to map over orgs. */}
        {getOptions ? getOptions(sponsorList) : _getOptions()}
      </FormikSelect>
    </FieldContainer>
  )

  if (hidden) return null;

  //fallback return using no formik context
  return (
    <Select
      value={selected}
      prefix={<TeamOutlined className='site-form-item-icon' />}
      placeholder={'Choose a sponsor'}
      loading={loading}
      className={`${className} ant-sponsor-selector`}
      onChange={handleOnChange}
      disabled={disabled || (loading && sponsorList.length <= 0)}
      {...props}
    >
      {sponsorList.length > 0 && (getOptions ? getOptions(sponsorList) : _getOptions())}
    </Select>
 )
}

SponsorSelectorComponent.defaultProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'label',
  getFormik: true,
}

export const SponsorSelector = styled(SponsorSelectorComponent)`
    width: 100%;
    min-width: 120px;
    .ant-select-selector, input {
      min-width: 120px;
    }
`

export const FilledSponsorSelector = styled(SponsorSelector)`
    .ant-select-selector, input {
      border: none !important;
    }
    .ant-select-selector {
      background: ${p => p.theme.secondaryColor} !important;
      border-radius: 6px !important;
      -webkit-box-shadow: 0 1px 2px #33333333 !important;
      -moz-box-shadow: 0 1px 2px #33333333 !important;
      box-shadow: 0 1px 2px #33333333 !important;
    }
    .ant-select-clear {
      background: ${p => p.theme.secondaryColor};
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      color: #eee !important;
    } 
`;

FilledSponsorSelector.defaultProps = {
  filterDefault: false
}
