import React, { useCallback, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { getThumbnail } from '@bit/necta.hooks.s3'
import { Upload as AntUpload, Image, Progress, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';
import { useUploadFile } from '@bit/necta.hooks.upload-file';

const HelperText = styled.div`
  margin-top: 5px;
`;

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

const Upload = styled(AntUpload)`
  flex: 1;
  .ant-upload-select {
    width: 100%;
  }
`;

const Avatar = styled.div`
  width: 103px;
  height: 103px;
  background: #FAFAFA;
  box-shadow: 1px 1px 2px 0 #ddd;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 10px;
  @media only screen and (max-width: 420px) {
    width: 200px;
    height: 200px;
    margin: 5px auto;
  }
`;

interface CropProps {
  noCrop: boolean;
  children?: any;
}

export const Crop: React.FC<CropProps> = ({ noCrop, children }: CropProps) => {
  if (noCrop) return <>{children}</>;
  return (
    <ImgCrop quality={0.7} grid>
      {children}
    </ImgCrop>
  )
}

interface IProfilePictureProps {
  profilePic?: string;
  fieldKey?: string;
  defaultPic?: any;
  disabled?: boolean
  loadingText?: string; // Alternative loading text to display
  infoText?: string; // Alternative helper text to display
}

export const ProfilePictureUploader: React.FC<IProfilePictureProps> = ({ disabled = false, defaultPic, fieldKey, loadingText, infoText, children }) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const fkey = useMemo(() => fieldKey || 'profilePicUrl', [fieldKey]);
  const value = useMemo(() => (values as any)[fkey], [fkey, values])

  const imageUploaded = useCallback((key: any) => {
    setFieldValue(fkey, key);
  }, [setFieldValue, fkey]);

  const Default = defaultPic ? defaultPic : require('../../assets/default_profile.png')

  const profilePicUrl = value ? getThumbnail(value) : Default;

  const {
    handleSubmit,
    loading,
    progress
  } = useUploadFile(result => {
    imageUploaded(result.key);
  });

  const handleDrop = useCallback(({
    data,
    file,
    filename,
  }) => {
    handleSubmit(file, { path: `profilePicture`, prefix: fieldKey || 'profilePicUrl', replace: true });
  }, [handleSubmit, fieldKey]);

  const percent = useMemo(() => progress < 15 ? 15 : progress, [progress]);

  const [crop, setCrop] = useState(true);

  const onCheck = useCallback((checked: boolean, event: Event) => {
    event.stopPropagation();
    setCrop(checked);
  }, [setCrop]);

  const UploadButton = () => {
    if (children) return <>{children}</>;
    if (!loading) return (
      <div>
        <UploadOutlined />
        <HelperText>{ infoText ? infoText : 'Drag and Drop or Click to search' }</HelperText>
        <Switch checkedChildren="Crop" unCheckedChildren="Raw" defaultChecked checked={crop} onChange={onCheck} />
      </div>
    )
    return (
      <div>
        <HelperText>{ loadingText ? loadingText : 'Uploading now, please wait...' }</HelperText>
        <Progress percent={percent} status={percent < 100 ? 'active': 'success'} showInfo={false} />
      </div>
    )
  };

  return (
    <Outer>
      <Avatar>
        <Image src={profilePicUrl} alt={'avatar'} />
      </Avatar>
      <Crop noCrop={!crop}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={handleDrop}
          disabled={loading || isSubmitting}
        >
          <UploadButton />
        </Upload>
      </Crop>
    </Outer>
  );
}

export default ProfilePictureUploader;
