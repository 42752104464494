import * as Yup from 'yup'
import moment from 'moment'
import { addressSchema, BROADCAST_STATUS } from '../../utils'

const valiDate = (dateStart: any, schema: any) => {
    if (!dateStart) return schema
    const date = moment(dateStart)
    if (!date.isValid()) return schema
    return schema.min(date.toDate())
}

export const eventSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    venue: Yup.string().required().label('Venue'),
    club: Yup.string().required().label('Club'),
    sportType: Yup.string().required().label('Sport Type'),
    description: Yup.string().label('Description'),
    time: Yup.string().oneOf(['8', '24', '48', '72', 'other']),
    dateStart: Yup.date()
    .required('Please enter a valid start date')
    .typeError('Please enter a valid start date')
    .meta(['date'])
    .label('Start Date'),
    dateEnd: Yup.date()
    .required('Please enter a valid end date')
    .when('dateStart', valiDate)
    .typeError('Please enter a valid end date')
    .meta(['date'])
    .label('End Date'),
    organiserId: Yup.string().nullable().label('Owned By'),
    address: addressSchema.label('Location'),
    primaryContactName: Yup.string().nullable().label('Primary Contact Person'),
    primaryContactCell: Yup.string().nullable().label('Contact Number'),
    primaryContactCellCountryCode:  Yup.string().nullable(),
    primaryContactEmail: Yup.string().nullable().label('Email'),
    primaryImage: Yup.string().nullable().label('Primary Image'),
    broadcastRequest: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...BROADCAST_STATUS])
    .meta({ type: 'select' })
    .label('Broadcast Status'),
    requestBroadcasting: Yup 
    .boolean()
    .notRequired()
    .default(false)
    .meta({ exclude: true })
    .label(''),

})
