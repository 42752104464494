import { hot } from 'react-hot-loader'
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate as Gate } from 'redux-persist/integration/react';
import { App } from './app';
import { message } from 'antd';

message.config({
  maxCount: 1
});

const Root = ({ client, store, persistor }: any) => {

  const Apollo = client ? ApolloProvider : React.Fragment;
  const Provider = store ? ReduxProvider : React.Fragment;
  const PersistGate = store ? Gate : React.Fragment;

  return (
    <Apollo client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Apollo>
  );
};

export default hot(module)(Root)
