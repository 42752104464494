export default {
    DEFAULT: {
        '@primary-color': '#3ECC80',
        '@secondary-color': '#83DAAB'
    },
    LIME: {
        '@primary-color': '#79D063',
        '@secondary-color': '#9EE48C'
    },
    BLUE: {
        '@primary-color': '#52C1F0',
        '@secondary-color': '#8FD9F9'
    },
    YELLOW: {
        '@primary-color': '#F2C94C',
        '@secondary-color': '#F8D776'
    },
    ORANGE: {
        '@primary-color': '#F2994A',
        '@secondary-color': '#F4B175'
    },
    PURPLE: {
        '@primary-color': '#A676E2',
        '@secondary-color': '#C09DEC'
    },
    RED: {
        '@primary-color': '#F05252',
        '@secondary-color': '#F57575'
    },
    GRASS: {
        '@primary-color': '#CDE14F',
        '@secondary-color': '#D9E58D'
    },
    LEMON: {
        '@primary-color': '#F5E050',
        '@secondary-color': '#F0E280'
    },
    DARK: {
        '@primary-color': '#103464',
        '@secondary-color': '#CC0203'
    }
}
