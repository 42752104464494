import * as Yup from 'yup';
import { valiDate } from '../fixture/schema';
import {
  CAMERAS,CAMERAS_TWO,CAMERAS_THREE,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  ENGAGEMENT_STATUSES, GROUNDS, ORG_TYPES, PHASES,GROUNDS_TWO,GROUNDS_THREE,
  PRODUCTS,PRODUCTS_TWO,PRODUCTS_THREE,PRODUCTS_FOUR,PRODUCTS_FIVE,PRODUCTS_SIX,
  REGIONS, SA_PROVINCES,
  SCHOOL_TYPES,CAMERAMIXES
} from '../../utils';

export const orgSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .notRequired()
    .label('Organisation Name'),
  primaryContactName: Yup.string()
    .nullable()
    .notRequired()
    .label('Primary Contact'),
  marketingContactName: Yup.string()
    .nullable()
    .notRequired()
    .label('Marketing Contact'),
  primaryContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),
  marketingContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),  
  headContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'),  
  itContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'), 
  groundsContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'), 
  headOfSportContactEmail: Yup.string()
    .trim()
    .nullable()
    .notRequired()
    .label('Email'), 
  primaryContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  marketingContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  headContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  itContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  headOfSportContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  groundsContactCellCountryCode: Yup.string()
    .nullable()
    .notRequired()
    .label('Country Code'),
  primaryContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  marketingContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  itContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  headOfSportContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  groundsContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  headContactCell: Yup.string()
    .nullable()
    .notRequired()
    .label('Contact Number'),
  primaryImage: Yup.string()
    .nullable()
    .notRequired()
    .meta({ exclude: true })
    .label('Primary Image'),
  parentOrgId: Yup.string()
    .nullable()
    .notRequired()
    .meta({ exclude: true })
    .label('Main Organisation'),
  sponsorId: Yup.string()
    .nullable()
    .notRequired()
    .meta({ exclude: true })
    .label('SS Schools sponsor'),
  attachPolicies: Yup.array()
    .default([])
    .meta({ exclude: true })
    .notRequired()
    .label('Policies'),
  address: Yup.object().shape({
    addressLine1: Yup.string()
      .nullable()
      .notRequired()
      .label('Address Line 1'),
    state: Yup.string()
      .nullable()
      .notRequired()
      .oneOf([null, ...SA_PROVINCES])
      .meta({ type: 'select' })
      .label('Province'),
    country: Yup.string()
      .nullable()
      .notRequired()
      .label('Country'),
    postalCode: Yup.string()
      .nullable()
      .notRequired()
      .label('Postal Code'),
  }).nullable().label('Search for address'),
  description: Yup.string()
    .nullable()
    .notRequired()
    .label('Notes'),
  siteSpecs: Yup.array()
    .default([])
    .notRequired()
    .label('Equipment'),
  orgType: Yup.string()
    .nullable()
    .notRequired()
    .meta({ exclude: true })
    .label('Organisation Type'),
  contractStatus: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CONTRACT_STATUSES])
    .meta({ type: 'select' })
    .label('Contract Status'),
  contractType: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CONTRACT_TYPES])
    .meta({ type: 'select' })
    .label('Contract Type'),
  headOfSchool: Yup.string()
    .nullable()
    .notRequired()
    .label('Head of School'),
  itOfSchool: Yup.string()
    .nullable()
    .notRequired()
    .label('IT Contact'),
  
  scoreboard: Yup.string()
    .nullable()
    .notRequired()
    .label('Scoreboard Name ( 4 letters)'),

  link: Yup.string()
    .nullable()
    .notRequired()
    .label('Fixtures Link'),

  shortName: Yup.string()
    .nullable()
    .notRequired()
    .label('Short Name'),
  groundsOfSchool: Yup.string()
    .nullable()
    .notRequired()
    .label('Groundsman'),
  headOfSport: Yup.string()
    .nullable()
    .notRequired()
    .label('Director of Sport'),
  engagementStatus: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...ENGAGEMENT_STATUSES])
    .meta({ type: 'select' })
    .label('Engagement Status'),
  region: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...REGIONS])
    .meta({ type: 'select' })
    .label('Region'),
  cameraType: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CAMERAS])
    .meta({ type: 'select' })
    .label('Camera Type'),
  cameraMix: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CAMERAMIXES])
    .meta({ type: 'select' })
    .label('Camera Mix'),
  cameraTypeTwo: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CAMERAS_TWO])
    .meta({ type: 'select' })
    .label('Camera Type'),
  cameraTypeThree: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...CAMERAS_THREE])
    .meta({ type: 'select' })
    .label('Camera Type'),
  fieldLocation: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...GROUNDS])
    .meta({ type: 'select' })
    .label('Location'),
  fieldLocationTwo: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...GROUNDS_TWO])
    .meta({ type: 'select' })
    .label('Location'),
  fieldLocationThree: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...GROUNDS_THREE])
    .meta({ type: 'select' })
    .label('Location'),
  schoolType: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...SCHOOL_TYPES])
    .meta({ type: 'select' })
    .label('School Type'),
  product: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS])
    .meta({ type: 'select' })
    .label('Component'),
  productTwo: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS_TWO])
    .meta({ type: 'select' })
    .label('Component'),
  productThree: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS_THREE])
    .meta({ type: 'select' })
    .label('Component'),
  productFour: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS_FOUR])
    .meta({ type: 'select' })
    .label('Component'),
  productFive: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS_FIVE])
    .meta({ type: 'select' })
    .label('Component'),
  productSix: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PRODUCTS_SIX])
    .meta({ type: 'select' })
    .label('Component'),
  phase: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...PHASES])
    .meta({ type: 'select' })
    .label('Phase'),
  serialNumber: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  serialNumberTwo: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  serialNumberThree: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  serialNumberFour: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  serialNumberFive: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  serialNumberSix: Yup.string()
    .nullable()
    .notRequired()
    .label('Serial Number'),
  sponsorInfo: Yup.array()
    .default([])
    .meta({ exclude: true })
    .notRequired()
    .label('On Field Advertiser'),
  sponsorOther: Yup.array()
    .default([])
    .meta({ exclude: true })
    .notRequired()
    .label('Other Sponsor'),
  sponsorSchool: Yup.array()
    .default([])
    .meta({ exclude: true })
    .notRequired()
    .label('School Sponsor – Tier 1'),
  dateContractStart: Yup.date()
    .nullable()
    .notRequired()
    .when('dateStart', valiDate)
    .typeError('Please enter a valid start date')
    .meta({ type: 'date' })
    .label('Contract Start Date'),
  dateContractEnd: Yup.date()
    .nullable()
    .notRequired()
    .when('dateStart', valiDate)
    .typeError('Please enter a valid end date')
    .meta({ type: 'date' })
    .label('Contract End Date'),
  isContracted: Yup
    .boolean()
    .notRequired()
    .default(false)
    .meta({ exclude: true })
    .label(''),
  testing: Yup
    .boolean()
    .notRequired()
    .default(false)
    .meta({ exclude: true })
    .label(''),
});

export const confirmOrgImportScheama = Yup.object().shape({
  parentOrganisationId: Yup.string()
    .nullable()
    .notRequired()
    .label('Parent Organisation'),
  orgType: Yup.string()
    .nullable()
    .notRequired()
    .oneOf([null, ...ORG_TYPES])
    .label('Organisation Type'),
  attachPolicies: Yup.array()
    .default([])
    .notRequired()
    .label('Policies'),
  // sendWelcome: yup
  //   .boolean()
  //   .default(true)
  //   .label('Send Welcome Email'),
  downloadExcel: Yup.boolean()
    .default(true)
    .label(''),
});
