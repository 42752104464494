import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Formik, FormikValues, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import {
  FormButton,
  ProfilePictureUploader,
  FormRow,
  Page,
  Input,
  OrgSelector,
  SportSelector,
  TextArea,
  AuthGuard, Select
} from '../../components'
import {
  AGE_GROUP_OPTIONS,
  canSelectOrgs,
  getActiveOrgId,
  getIsAdmin,
  getSelectedOrg,
  getSelectedSport
} from '../../utils';
import { useFormData, Yup } from '../../hooks';
import PicPlaceholder from '../../assets/default_profile.png'
import { Form } from 'formik-antd'
import { PageHeader, message } from 'antd'
import { teamSchema } from './schema';
import { get } from 'lodash';
import { ADD_TEAM } from '../../graphql';
import { cleanError } from '../../helpers/error-helper';

const finalSchema = teamSchema.shape({
  organisationId: Yup
    .string()
    .required()
    .label('Organisation'),
  sportId: Yup
    .string()
    .required()
    .label('Sport'),
})

const useSelectors = () => ({
  isAdmin: useSelector(getIsAdmin),
  selectedOrgId: useSelector(getSelectedOrg),
  canSelectOrgs: useSelector(canSelectOrgs),
  selectedSport: useSelector(getSelectedSport)
})

const GRID_PROPS = { md: 24, lg: 24, xl: 24, xxl: 24 };

export const TeamAdd: React.FC<any> = (props) => {

  const history = useHistory()

  const { isAdmin, selectedOrgId, canSelectOrgs, selectedSport } = useSelectors()

  const [handleAdd] = useMutation(ADD_TEAM)

  const { fields, initialValues, ...formikCTX } = useFormData(finalSchema, {
    onSubmit: async (values: FormikValues, actions: FormikHelpers<any>) => {
      message.loading({ content: 'Creating team...', duration: 10 });
      return handleAdd({ variables: { newTeam: { ...values } } })
    },
    onCompleted: (result: any) => {
      message.success({ content: 'Team created!', duration: 2 });
      const teamId = get(result, 'data.addTeam.id');
      if (teamId) history.push('/team/' + teamId);
    },
    onError: (e: any) => {
      message.error({ content: cleanError(e, 'Unable to create team'), duration: 4 });
    }
  })
  const [team] = useState({ ...initialValues, organisationId: get(selectedOrgId, 'id'), sportId: get(selectedSport, 'id') });

  const handleBack = useCallback(() => history.goBack(), [history]);

  return (
    <AuthGuard>
      <Formik { ...formikCTX} initialValues={team} enableReinitialize validateOnBlur>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <Page title='Add Team' onBack={handleBack} card>
            <Form layout={'vertical'}>
              <ProfilePictureUploader disabled={isSubmitting} fieldKey="primaryImage" defaultPic={PicPlaceholder} />

              <FormRow gutter={6}>
                <Input {...fields.name} />
                <Select options={AGE_GROUP_OPTIONS} {...fields.ageGroup} allowClear />
                <OrgSelector disabled={!canSelectOrgs} {...fields.organisationId} />
                <SportSelector disabled={isSubmitting} {...fields.sportId} />
                <TextArea {...fields.description} gridProps={GRID_PROPS}/>
              </FormRow>

              <FormButton>
                ADD
              </FormButton>

            </Form>
          </Page>
        )}
      </Formik>
    </AuthGuard>
  )
}

export default TeamAdd
